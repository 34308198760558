import React from 'react'
import AuthStore from '../auth/authStore'
import QuestionStore from '../questions/questionStore'
import DataStore from '../data/dataStore'
import MenuStore from '../menu/menuStore'
import MyAccountStore from "../myAccount/myAccountStore";
import AuditStore from "../audit/auditStore";
import InclusionCriteriaStore from "../questions/inclusionCriteria/inclusionCriteriaStore";
import ExclusionCriteriaStore from "../questions/exclusionCriteria/exclusionCriteriaStore";
import SocioDemographicCharacteristicsStore
    from "../questions/socioDemographicCharacteristics/socioDemographicCharacteristicsStore";
import ClinicalHistoryStore from "../questions/clinicalHistory/clinicalHistoryStore";
import EnvironmentalExposureStore from "../questions/environmentalExposure/environmentalExposureStore";
import ClinicalCharacterizationStore from "../questions/clinicalCharacterization/clinicalCharacterizationStore";
import TumorBiologyStore from "../questions/tumorBiology/tumorBiologyStore";
import TreatmentPatternsStore from "../questions/treatmentPatterns/treatmentPatternsStore";
import SystemicTherapyStore from "../questions/systemicTherapy/systemicTherapyStore";
import AfterInitialTreatmentStore from "../questions/afterInitialTreatment/afterInitialTreatmentStore";
import DispositionStore from "../questions/dispositionData/dispositionStore";

class RootStore {
    constructor() {
        this.authStore = new AuthStore(this,)
        this.questionStore = new QuestionStore(this,)
        this.dataStore = new DataStore(this,)
        this.menuStore = new MenuStore(this,)
        this.myAccountStore = new MyAccountStore(this,)
        this.auditStore = new AuditStore(this,)
        this.inclusionCriteriaStore = new InclusionCriteriaStore(this,)
        this.exclusionCriteriaStore = new ExclusionCriteriaStore(this,)
        this.socioDemographicCharacteristicsStore = new SocioDemographicCharacteristicsStore(this,)
        this.clinicalHistoryStore = new ClinicalHistoryStore(this,)
        this.environmentalExposureStore = new EnvironmentalExposureStore(this,)
        this.clinicalCharacterizationStore = new ClinicalCharacterizationStore(this,)
        this.tumorBiologyStore = new TumorBiologyStore(this,)
        this.treatmentPatternsStore = new TreatmentPatternsStore(this,)
        this.systemicTherapyStore = new SystemicTherapyStore(this,)
        this.afterInitialTreatmentStore = new AfterInitialTreatmentStore(this,)
        this.dispositionStore = new DispositionStore(this,)
    }
}

const StoresContext = React.createContext(new RootStore(),)
// this will be the function available for the app to connect to the stores
export const useStores = () => React.useContext(StoresContext,)

export const Stores = StoresContext._currentValue
