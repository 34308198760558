import { useStores,} from '../store'
import * as React from 'react';
import {useEffect} from 'react';
import {observer} from "mobx-react";
import RenderTableHeader from "../components/RenderTableHeader";
import MyDataGrid from "../components/MyDataGrid";
import {useNavigate, useParams} from "react-router-dom";
import {CheckCircleOutlined} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import QuestionsLayout from "../layout/QuestionsLayout";
import {Stack} from "@mui/material";
import MyButton from "../components/MyButton";
import saveIcon from "../images/svgs/Save.svg";
import Loading from "../components/Loading";
import Paper from "@mui/material/Paper";
import YesNo from "../components/YesNo";

const ChooseArea = () => {
    const {
        dataStore, authStore,questionStore,menuStore
    } = useStores()
    let urlParams = useParams();

    useEffect(() => {
        console.log('params.patient',parseInt(urlParams.patient))
        dataStore.getPatient(parseInt(urlParams.patient))


    }, [])
    const navigate = useNavigate()

    const [removeCompleted, setRemoveCompleted] = React.useState(null);
    const [removeDelete, setRemoveDelete] = React.useState(null);
    const [disabled, setDisabled] = React.useState(false);
    const [showCompletedAlert, setShowCompletedAlert] = React.useState(false);
    const [showDeleteAlert, setShowDeleteAlert] = React.useState(false);

    const commonColumnsParams = {
        pinnable: false,
        renderHeader: RenderTableHeader,
        flex: 1,
        minWidth: 150,
        align: 'left',
        headerAlign: 'left',
        hideSortIcons:true
    }

    const columns = [
        {
            field: 'title', headerName: 'Areas',
            ...commonColumnsParams,
            renderCell: (params) => {
                return (
                    <Typography sx={{fontSize: 18}} color={"txtGrey"}>{params.value}</Typography>
                )
            }
        },
        {
            field: 'date', headerName: 'Date',
            ...commonColumnsParams,
            flex: 0,
        },
        {
            field: 'completed', headerName: 'Completed',
            ...commonColumnsParams,
            flex: 0,
            renderCell: (params) => {
                return (params.value === true || params.value === 'completed') ?
                    <CheckCircleOutlined color="primary"/> : ''
            }
        },
    ];


    console.log('dataStore.areas', dataStore.areas)
    const selectCorrectArea = () => {
        questionStore.resetAllQuestions()
        if (!dataStore.patient.inclusion_exclusion.completed) {
            navigate('/inclusionCriteriaPage1/'+parseInt(urlParams.patient))
        } else {
            if (dataStore.patient.sociodemographic.completed !== 'completed') {
                navigate('/socioDemographicCharacteristicsPage1/'+parseInt(urlParams.patient))
            } else {
                if (dataStore.patient.clinical_history.completed !== 'completed') {
                    navigate('/clinicalHistoryPage1/'+parseInt(urlParams.patient))
                } else {
                    if (dataStore.patient.environmental_exposure.completed !== 'completed') {
                        navigate('/environmentalExposurePage1/'+parseInt(urlParams.patient))
                    } else {
                        if (dataStore.patient.clinical_characterization.completed !== 'completed') {
                            navigate('/clinicalCharacterizationPage1/'+parseInt(urlParams.patient))
                        } else {
                            if (dataStore.patient.tumor_biology.completed !== 'completed') {
                                navigate('/tumorBiologyPage1/'+parseInt(urlParams.patient))
                            } else {
                                if (dataStore.patient.initial_treatment.completed !== 'completed') {
                                    navigate(`/treatmentPatternsDuration/${parseInt(urlParams.patient)}/0`)
                                } else {
                                    if (dataStore.patient.after_initial_treatment.completed !== 'completed') {
                                        navigate(`/imagingAssessmentsAfterInitialTreatmentPage1/${parseInt(urlParams.patient)}/0`)
                                    } else {
                                        if( dataStore.patient.sub_treatments.length === 0){
                                            navigate(`/subsequentTreatmentPage1/${parseInt(urlParams.patient)}/0`)
                                            questionStore.resetAllQuestions()
                                        }else{
                                            for (let i = 0; i < dataStore.patient.sub_treatments.length; i++) {
                                                if (dataStore.patient.sub_treatments[i].treatment_completed !== 'completed') {
                                                    navigate(`/subsequentTreatmentTumorBiologyPage1/${parseInt(urlParams.patient)}/${i + 1}`)
                                                } else {
                                                    if (dataStore.patient.sub_treatments[i].after_completed !== 'completed') {
                                                        navigate(`/imagingAssessmentsAfterInitialTreatmentPage1/${parseInt(urlParams.patient)}/${i + 1}`)
                                                    }
                                                }
                                            }
                                            if (dataStore.patient.sub_treatments[dataStore.patient.sub_treatments.length - 1].after_completed === 'completed') {
                                                navigate('/dispositionDataPage1/'+parseInt(urlParams.patient))
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{onClick: () => navigate(-1)}}
                         showMissing={false}
                         onCloseMissing={null}
                         nextPress={null}
                         questionFooterProps={{onClick: selectCorrectArea}}
                         backPress={() => navigate(-1)}
                         loading={false}
                         disabled={disabled}
                            showMissingForce={false}>
            {authStore.userType === 'X2' &&
                <Stack direction={'row'} spacing={5} sx={{ width: '100%', alignItems: 'center', px: 8}}>

                    {dataStore.selectedPatient?.completed &&
                        <MyButton iconStyle={{marginRight: 15}} icon={saveIcon} label={'Edit Patient'}
                                  onClick={() => {
                                      setDisabled(true)
                                      setShowCompletedAlert(true)
                                  }}/>}
                    <MyButton iconStyle={{marginRight: 15}} icon={saveIcon} label={'Delete Patient'} onClick={() => {
                        setDisabled(true)
                        setShowDeleteAlert(true)
                    }}/>
                </Stack>}
            <MyDataGrid columns={columns}
                        rows={dataStore.areas}
                        getRowId={(row) => row.title}
                        onRowClick={(params) => {
                            navigate(params.row.link)
                        }}
                        disableColumnFilter={true}
                        disableMultipleSelection={true}
                        disableColumnSelector={true}
                        disableSelectionOnClick={true}
                        pageSize={7}
                        sx={{
                            "& .MuiDataGrid-virtualScrollerRenderZone": {
                                "& .MuiDataGrid-row": {
                                    cursor: 'pointer',
                                    "&:hover": {
                                        backgroundColor: "transparent",
                                    },
                                    "&:nth-child(2n+1)": {backgroundColor: "rgba(235, 235, 235, .7)"}
                                }
                            },
                        }}
            />

            {dataStore.loading && <Loading/>}
            {showCompletedAlert && <Paper elevation={7} sx={{
                display: 'flex',
                position: 'absolute',
                top: '47%',
                left: '20%',
                p: 5,
                width: '55%',
                zIndex: 1000
            }}>
                <Stack direction={'column'} spacing={5}>
                    <Typography sx={{fontSize: 18}}>
                        Are you sure you want to remove the "Patient Complete"?
                    </Typography>
                    <YesNo value={removeCompleted}
                           handleChange={async (event) => {
                               setRemoveCompleted(event.target.value)
                               setDisabled(false)
                               setShowCompletedAlert(false)
                               if (event.target.value === 'true') {
                                   await dataStore.updatePatient(dataStore.selectedPatient.id, {completed: false})
                                   await dataStore.getPatient(dataStore.selectedPatient.id)
                               }
                           }}
                    />
                </Stack>
            </Paper>}

            {showDeleteAlert && <Paper elevation={7} sx={{
                display: 'flex',
                position: 'absolute',
                top: '47%',
                left: '20%',
                p: 5,
                width: '55%',
                zIndex: 1000
            }}>
                <Stack direction={'column'} spacing={5}>
                    <Typography sx={{fontSize: 18}}>
                        Are you sure you want to eliminate this patient?
                    </Typography>
                    <YesNo value={removeDelete}
                           handleChange={async (event) => {
                               setRemoveDelete(event.target.value)
                               setDisabled(false)
                               setShowDeleteAlert(false)
                               if (event.target.value === 'true') {
                                   await dataStore.deletePatient(dataStore.selectedPatient.id)
                                   navigate('/addData')
                               }
                           }}
                    />
                </Stack>
            </Paper>}
        </QuestionsLayout>
    );
}

export default observer(ChooseArea);
