import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {Modal, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import YesNo from "../../components/YesNo";
import QuestionText from "../../components/QuestionText";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import QuestionsLayout from "../../layout/QuestionsLayout";
import AlertModal from "../../components/AlertModal";
import theme from "../../styles/theme";
import MyButton from "../../components/MyButton";


const InclusionCriteriaPage1 = () => {
    const {
        inclusionCriteriaStore,menuStore
    } = useStores()
    let urlParams = useParams();
    const [showNotEligibleError, setShowNotEligibleError] = React.useState(false);

    const navigate = useNavigate()
    useEffect(() => {
        inclusionCriteriaStore.getInclusionExclusion(parseInt(urlParams.patient))
    }, [])

    const handleNext = () => {
        if(inclusionCriteriaStore.q1 !== null && inclusionCriteriaStore.q2 !== null){
            return navigate('/exclusionCriteriaPage1/'+parseInt(urlParams.patient))
        }else{
            alert('You need to answer all questions')
        }

    }

    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={inclusionCriteriaStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>
                    <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32, lineHeight: 1.2}}>
                        Inclusion Criteria
                    </Typography>
                    <Typography sx={{fontSize: 25,}}>
                        The patient must meet <strong>all</strong> of the following inclusion criteria to be eligible
                        for the
                        study.
                    </Typography>
                </Stack>
                <Stack direction={'column'} spacing={4} sx={{width: '100%', mt: 3}}>
                    <QuestionBox>
                        <QuestionText sx={{fontWeight: 'normal'}}>
                            Patients aged ≥ 18 years diagnosed with <em>de novo</em> resectable stage I-III NSCLC
                            between
                            1st July 2016 and 31st December 2017
                        </QuestionText>
                        <YesNo value={inclusionCriteriaStore.q1}
                               disabled={inclusionCriteriaStore.completed}
                               sx={{mt: 2}} handleChange={(event) => {
                            if (event.target.value === 'false') {
                                setShowNotEligibleError(true)
                            }
                            inclusionCriteriaStore.updateInclusionExclusion({q1: event.target.value}, parseInt(urlParams.patient))
                        }}/>
                    </QuestionBox>

                    {inclusionCriteriaStore.q1 === true ? <QuestionBox>
                        <QuestionText sx={{fontWeight: 'normal'}}>
                            Patients with the minimum following information available
                        </QuestionText>
                        <div style={{marginLeft: 25, marginTop: 10}}>

                            <li style={{fontSize: 20}}>
                                    Demographics (Age and gender)

                            </li>
                            <li style={{fontSize: 20}}>
                                    Tumor biology (Histology) and Stage at diagnosis
                            </li>
                            <li style={{fontSize: 20}}>
                                Initial Treatment Information
                            </li>
                        </div>

                        <YesNo
                            value={inclusionCriteriaStore.q2}
                            disabled={inclusionCriteriaStore.completed}
                            sx={{mt: 2}} handleChange={(event) => {
                            inclusionCriteriaStore.updateInclusionExclusion({q2: event.target.value}, parseInt(urlParams.patient))
                            if (event.target.value === "true") {
                                navigate('/exclusionCriteriaPage1/' + parseInt(urlParams.patient))
                            } else {
                                setShowNotEligibleError(true)
                            }
                        }}/>
                    </QuestionBox> : <div/>}
                </Stack>
            </Box>
            <Modal
                open={showNotEligibleError}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '53%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    height:200,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius:5,
                    justifyContent:'center',
                    display:'flex',
                    alignItems:'center',
                    flexDirection:'column'
                }}>
                    <Typography id="modal-modal-title" variant="h5" component="h3" fontWeight="bold">
                        Are you sure patient is not eligible?
                    </Typography>
                    <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',mt:4}}>
                        <MyButton sx={{px: 6,mr:5}} label={'YES'} onClick={()=>navigate('/addData')}/>
                        <MyButton sx={{px: 6}} label={'NO'} onClick={()=>setShowNotEligibleError(false)}/>
                    </Box>
                </Box>
            </Modal>
        </QuestionsLayout>
    );
}

export default observer(InclusionCriteriaPage1);
