import {action, makeObservable, observable,} from 'mobx'
import {BaseService, OpenAPI, UserService} from "../services/openapi";
import {Stores} from "../store";

class AuthStore {
    email = '';
    password = '';
    delegateFirstName = '';
    delegateLastName = '';
    delegateEmail = '';
    definePhone = '';
    piName = '';
    piEmail = '';
    piPhone = '';
    rememberMe = false;
    userEnum = ['PI', 'Delegate', 'X2', 'SuperAdmin']
    userType = null
    confirmPhone = ''
    inviteUserOtpToken = ''
    inviteUserOtpPhone = ''
    definePassword = ''
    confirmPassword = ''
    inviteOtpValue = ''
    loading = false
    inviteUserOtpLoading = false
    inviteUserOtpDisable = false
    createPiErr = false
    openSuccessInvitationAlert = false
    errorMessage = ''
    loginErr = ''
    loginErrorMessage = ''
    successAlertEnable = false
    errorAlertEnable = false
    centers = []
    users = []
    center = ''
    showErrMessage = false
    piUsers = []
    delegateUsers = []
    piFullUsers = []
    delegateFullUsers = []
    selectedUser = {}
    delegateSearchTxt = ''
    piSearchTxt = ''

    reset() {
        this.email = ''
        this.password = ''
        this.rememberMe = false
        this.userType = null
        this.delegateFirstName = ''
        this.delegateLastName = ''
        this.delegateEmail = ''
        this.definePhone = ''
        this.piName = ''
        this.piEmail = ''
        this.piPhone = ''
        this.confirmPhone = ''
        this.inviteUserOtpToken = ''
        this.inviteUserOtpPhone = ''
        this.definePassword = ''
        this.confirmPassword = ''
        this.inviteOtpValue = ''
        this.loading = false
        this.inviteUserOtpLoading = false
        this.inviteUserOtpDisable = false
        this.createPiErr = false
        this.openSuccessInvitationAlert = false
        this.errorMessage = ''
        this.loginErr = ''
        this.loginErrorMessage = ''
        this.successAlertEnable = false
        this.errorAlertEnable = false
        this.centers = []
        this.users = []
        this.center = ''
        this.showErrMessage = false
        this.piUsers = []
        this.delegateUsers = []
        this.piFullUsers = []
        this.delegateFullUsers = []
        this.selectedUser = {}
        this.delegateSearchTxt = ''
        this.piSearchTxt = ''
    }

    constructor() {
        makeObservable(this, {
            email: observable,
            password: observable,
            rememberMe: observable,
            userType: observable,
            delegateFirstName: observable,
            delegateLastName: observable,
            delegateEmail: observable,
            definePhone: observable,
            piName: observable,
            piEmail: observable,
            piPhone: observable,
            confirmPhone: observable,
            inviteUserOtpToken: observable,
            inviteUserOtpPhone: observable,
            definePassword: observable,
            confirmPassword: observable,
            inviteOtpValue: observable,
            loading: observable,
            inviteUserOtpLoading: observable,
            inviteUserOtpDisable: observable,
            createPiErr: observable,
            openSuccessInvitationAlert: observable,
            errorMessage: observable,
            loginErr: observable,
            loginErrorMessage: observable,
            successAlertEnable: observable,
            errorAlertEnable: observable,
            centers: observable,
            center: observable,
            users: observable,
            showErrMessage: observable,
            delegateUsers: observable,
            piUsers: observable,
            piFullUsers: observable,
            selectedUser: observable,
            delegateSearchTxt: observable,
            piSearchTxt: observable,
            delegateFullUsers: observable,

            setDelegateSearchTxt: action,
            setErrorAlertEnable: action,
            setSuccessAlertEnable: action,
            setLoginErr: action,
            setLoginErrorMessage: action,
            setEmail: action,
            setPassword: action,
            setRememberMe: action,
            login: action,
            setUserType: action,
            setDelegateFirstName: action,
            setDelegateLastName: action,
            setDelegateEmail: action,
            setDefinePhone: action,
            setPiName: action,
            setPiEmail: action,
            setPiPhone: action,
            setConfirmPhone: action,
            setInviteUserOtpToken: action,
            setInviteUserOtpPhone: action,
            setDefinePassword: action,
            setConfirmPassword: action,
            setInviteOtpValue: action,
            createX2Delegate: action,
            deleteUser: action,
            logout: action,
            checkUser: action,
            setUserTypeByName: action,
            setLoading: action,
            setInviteUserOtpLoading: action,
            setInviteUserOtpDisable: action,
            setOpenSuccessInvitationAlert: action,
            setErrorMessage: action,
            setCenters: action,
            setCenter: action,
            getUsers: action,
            handleError: action,
            setDelegateUsers: action,
            setPiUsers: action,
            setSelectedUser: action,
            getDetailUser: action,
            setUerTypeText: action,
            filterPiBySearch: action,
            setPiFullUsers: action,
            setDelegateFullUsers: action,
            setPiSearchTxt: action,
            filterDelegateBySearch: action,
        },)
    }

    setPiSearchTxt = (value) => {
        this.piSearchTxt = value
    }
    setDelegateSearchTxt = (value) => {
        this.delegateSearchTxt = value
    }
    setSelectedUser = (value) => {
        this.selectedUser = value
    }

    setShowErrMessage = (value) => {
        this.showErrMessage = value
    }

    setUsers = (value) => {
        this.users = value
    }

    setDelegateUsers = (value) => {
        this.delegateUsers = value
    }
    setPiUsers = (value) => {
        this.piUsers = value
    }
    setPiFullUsers = (value) => {
        this.piFullUsers = value
    }
    setDelegateFullUsers = (value) => {
        this.delegateFullUsers = value
    }

    setCenter = (value) => {
        this.center = value
    }

    setCenters = (value) => {
        this.centers = value
    }
    setErrorAlertEnable = (value) => {
        this.errorAlertEnable = value
    }

    setSuccessAlertEnable = (value) => {
        this.successAlertEnable = value
    }
    setLoginErr = (value) => {
        this.loginErr = value
    }
    setLoginErrorMessage = (value) => {
        this.loginErrorMessage = value
    }
    setErrorMessage = (value) => {
        this.errorMessage = value
    }
    setOpenSuccessInvitationAlert = (value) => {
        this.openSuccessInvitationAlert = value
    }

    setCreatePiErr = (value) => {
        this.createPiErr = value
    }
    setInviteUserOtpLoading = (value) => {
        this.inviteUserOtpLoading = value
    }
    setInviteUserOtpDisable = (value) => {
        this.inviteUserOtpDisable = value
    }

    setLoading = (value) => {
        this.loading = value
    }

    login = async () => {
        try {
            this.setLoading(true)
            const response = await UserService.login({
                username: this.email,
                password: this.password,
            })
            console.log('response', response)
            return response.success
        } catch (err) {
            console.log('login err', err)
            this.handleError(err)
        } finally {
            this.setLoading(false)
        }
    }

    confirmOtp = async () => {
        try {
            this.setLoading(true)
            let params = {
                email: this.email,
                code: this.confirmPhone,
            }
            console.log('confirmOtp params', params)

            const response = await UserService.otp(params)
            console.log('confirmOtp response', response)

            if (response.api_key) {
                sessionStorage.removeItem('selectedMenu')
                this.setUserLogin(response.api_key, response.user)
                return response.user;
            }
        } catch (err) {
            console.log('login err', err.body.message)
            this.handleError(err)
        } finally {
            this.setLoading(false)
        }
    }

    getCenters = async () => {
        try {
            this.setLoading(true)

            const centers = await BaseService.getCenters()
            console.log('getCenters centers', centers)
            if (centers) {
                this.setCenters(centers)
            }
            this.setLoading(false)

        } catch (err) {
            this.setLoading(false)
            this.handleError(err)

        }
    }

    createDelegate = async () => {
        try {
            this.setLoading(true)
            let params = {
                first_name: this.delegateFirstName,
                last_name: this.delegateLastName,
                role: 1,
                email: this.delegateEmail,
            }
            console.log('params=>', params)

            const response = await UserService.inviteUser(params)
            console.log('response', response)
            this.setLoading(false)
            if (response) {
                this.setOpenSuccessInvitationAlert(true)
            }

        } catch (err) {
            this.setLoading(false)
            this.setOpenSuccessInvitationAlert(false)
            this.handleError(err)

        }
    }

    createX2Delegate = async () => {
        try {
            this.setLoading(true)
            let params = {
                first_name: this.delegateFirstName,
                last_name: this.delegateLastName,
                role: 1,
                email: this.delegateEmail,
                center: this.center
            }
            console.log('params=>', params)

            const response = await UserService.inviteUser(params)
            console.log('response', response)
            this.setLoading(false)
            if (response) {
                this.setOpenSuccessInvitationAlert(true)
            }

        } catch (err) {
            this.setLoading(false)
            this.setOpenSuccessInvitationAlert(false)
            this.handleError(err)

        }
    }

    checkUser = async () => {
        let token = localStorage.getItem('token') || sessionStorage.getItem('token')
        console.log('checkUser token',token)
        if (token) {
            OpenAPI.TOKEN = token
            try {
                const response = await UserService.getMe()
                console.log('checkUser=>',response)


                this.setUserType(response.role)
            } catch (e) {
                console.log(e.body.message)
                this.logout()
            }
        }
    }

    inviteUserOtp = async (token) => {
        try {
            this.setInviteUserOtpLoading(true)
            this.setInviteUserOtpDisable(true)
            let params = {
                token: token,
                phone: this.definePhone,
            }
            console.log('params=>', params)

            const response = await UserService.inviteOtp(params)
            console.log('response', response)
            alert('Code Sent')
            this.setInviteUserOtpLoading(false)
        } catch (err) {
            this.setInviteUserOtpDisable(false)
            this.setInviteUserOtpLoading(false)
            console.log('inviteUserOtp err', err)
            this.setOpenSuccessInvitationAlert(false)
            this.handleError(err)
        }

    }

    setUserLogin = (api_key, user) => {
        this.setUserType(user.role)
        if (this.rememberMe) {
            localStorage.setItem('token', api_key)
        }
        sessionStorage.setItem('token', api_key)
        sessionStorage.setItem('role', this.userType)
        OpenAPI.TOKEN = api_key;
    }


    inviteUserDefinePass = async (token) => {
        try {
            if (this.confirmPassword === this.definePassword) {
                if (this.confirmPassword.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,30}$/)) {
                    let params = {
                        token: token,
                        password: this.confirmPassword,
                        sms_token: this.inviteOtpValue,
                    }
                    console.log('params=>', params)

                    const response = await UserService.acceptInvite(params)
                    console.log('inviteUserDefinePass response', response)
                    if (response.api_key) {
                        this.setUserLogin(response.api_key, response.user)
                        return true
                    }
                } else {
                    alert('password should between 6 to 30 characters which contain at least one numeric digit,' +
                        'one uppercase and one lowercase letter')
                }

            } else {
                alert('Passwords are not match')
            }


        } catch (err) {
            console.log('inviteUserDefinePass err', err)
            this.setOpenSuccessInvitationAlert(false)
            this.handleError(err)

        }

    }

    logout = () => {
        this.reset();
        localStorage.removeItem('token')
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('selectedMenu')
        this.setUserTypeByName(null)
        OpenAPI.TOKEN = null
        Stores.dataStore.cleanPatient()
    }
    createPi = async () => {
        try {
            this.setLoading(true)
            let params = {
                first_name: this.delegateFirstName,
                last_name: this.delegateLastName,
                role: 0,
                email: this.delegateEmail,
                center: this.center,
            }
            console.log('createPi params=>', params)

            const response = await UserService.inviteUser(params)
            console.log('createPi response', response)
            this.setLoading(false)
            if (response) {
                this.setOpenSuccessInvitationAlert(true)
            }

        } catch (err) {
            this.setLoading(false)
            this.setOpenSuccessInvitationAlert(false)
            this.handleError(err)

        }
    }

    setInviteUserOtpToken(value,) {
        this.inviteUserOtpToken = value
    }

    setInviteUserOtpPhone(value,) {
        this.inviteUserOtpPhone = value
    }

    setDefinePassword(value,) {
        this.definePassword = value
    }

    setConfirmPassword(value,) {
        this.confirmPassword = value
    }

    setPiName(value,) {
        this.piName = value
    }

    setPiEmail(value,) {
        this.piEmail = value
    }

    setPiPhone(value,) {
        this.piPhone = value
    }

    setInviteOtpValue(value,) {
        this.inviteOtpValue = value
    }

    setDelegateFirstName(value,) {
        this.delegateFirstName = value
    }


    setDelegateLastName(value,) {
        this.delegateLastName = value
    }

    setDelegateEmail(value,) {
        this.delegateEmail = value
    }

    setDefinePhone(value,) {
        this.definePhone = value
    }

    setConfirmPhone(value,) {
        this.confirmPhone = value
    }

    setUerTypeText = (value)=>{
        this.userType = value
    }

    setUserType(value) {
        if (value === 0) {
            this.userType = 'PI'
        } else if (value === 1) {
            this.userType = 'Delegate'
        } else if (value === 2) {
            this.userType = 'X2'
        } else if (value === 3) {
            this.userType = 'SuperAdmin'
        }
    }

    setUserTypeByName(value) {
        this.userType = value
    }

    setRememberMe(value,) {
        this.rememberMe = value
    }

    setEmail(value,) {
        this.email = value
    }

    setPassword(value,) {
        this.password = value
    }

    handleError = (err) => {
        if (err?.body?.message) {
            this.setErrorMessage(err.body.message)
            this.setShowErrMessage(true)
            console.log('handleError err', err.body.message)
        } else if (err?.message) {
            this.setErrorMessage(err.message)
            this.setShowErrMessage(true)
            console.log('handleError err', err.message)
        }
    }

    resetPasswordRequest = async () => {
        try {
            this.setLoading(true)
            const response = await UserService.resetPassword()
            console.log('resetPasswordRequest response', response)
            this.setLoading(false)
            Stores.myAccountStore.setPasswordResetAlert(true)
        } catch (err) {
            console.log('resetPasswordRequest err', err)
            this.setLoading(false)
            this.handleError(err)

        }
    }

    resetPassword = async (token) => {
        try {
            if (this.confirmPassword === this.definePassword) {
                if (this.confirmPassword.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,30}$/)) {

                    this.setLoading(true)
                    let params = {
                        token: token,
                        password: this.confirmPassword,
                    }
                    const response = await UserService.changePassword(params)
                    console.log('resetPasswordRequest response', response)
                    this.setLoading(false)
                    this.setSuccessAlertEnable(true)

                } else {
                    alert('password should between 6 to 30 characters which contain at least one numeric digit,' +
                        'one uppercase and one lowercase letter')
                }

            } else {
                alert('Passwords are not match')
            }


        } catch (err) {
            console.log('resetPassword err', err)
            this.setLoading(false)
            this.handleError(err)

        }
    }

    getUsers = async () => {
        try {
            this.setLoading(true)
            const users = await UserService.getUsers()
            console.log('getUsers response', users)
            let pis = []
            let delegate = []
            for (const user of users) {
                if (user.role === 0) {
                    pis.push(user)
                }else if(user.role === 1){
                    delegate.push(user)
                }
            }
            this.setPiUsers(pis)
            this.setPiFullUsers(pis)
            this.setDelegateFullUsers(delegate)
            this.setDelegateUsers(delegate)

            this.setUsers(users)
            this.setLoading(false)

        } catch (err) {
            console.log('getUsers err', err)
            this.setLoading(false)
            this.handleError(err)

        }
    }

    mapUserRoleToName = (role) => {

        if (role === 0) {
            return 'PI'
        } else if (role === 1) {
            return 'Delegate'
        } else if (role === 2) {
            return 'X2'
        } else if (role === 3) {
            return 'Super Admin'
        }
    }

    deleteUser = async (userId) => {
        try {
            this.setLoading(true)
            const response = await UserService.deleteUser(userId)
            console.log('deleteUser response', response)
            this.setLoading(false)

        } catch (err) {
            console.log('deleteUser err', err)
            this.setLoading(false)
        }
    }

    getDetailUser = async (userId) => {
        try {
            this.setLoading(true)
            const response = await UserService.getDelegates(userId)
            console.log('getDetailUser response', response)
            this.setLoading(false)
            this.setDelegateUsers(response)
        } catch (err) {
            console.log('getDetailUser err', err)
            this.setLoading(false)
        }
    }


    filterPiBySearch = (search) =>{
        let foundUsers = []
        this.piUsers = []
        if(search !== ''){


           for(const user of this.piFullUsers){
               console.log('user',user)
               console.log('search.toLowerCase()',search.toLowerCase())
               console.log('user.first_name.includes(search.toLowerCase())',user.first_name.includes(search.toLowerCase()))

               if(user.code.toLowerCase().includes(search.toLowerCase())){
                   // if(!this.piUsers.find((u)=> u.id == user.id)){
                       this.piUsers.push(user)
                   // }
               }

               else if(user.first_name.toLowerCase().includes(search.toLowerCase())){
                   // if(!this.piUsers.find((u)=> u.id == user.id)){
                       this.piUsers.push(user)
                   // }
               }

               else if(user.last_name.toLowerCase().includes(search.toLowerCase())){
                   // if(!this.piUsers.find((u)=> u.id == user.id)){
                       this.piUsers.push(user)
                   // }
               }
               else if(user.center.name.toLowerCase().includes(search.toLowerCase())){
                   // if(!this.piUsers.find((u)=> u.id == user.id)){
                       this.piUsers.push(user)
                   // }
               }
           }

           console.log('foundUsers',foundUsers)




        }

    }


    filterDelegateBySearch = (search) => {
        this.delegateUsers = []
        if (search !== '')
            for (const user of this.delegateFullUsers) {
                console.log('user',user)
                if (user.code.toLowerCase().includes(search.toLowerCase())) {
                    this.delegateUsers.push(user)
                } else if (user.first_name.toLowerCase().includes(search.toLowerCase())) {
                    this.delegateUsers.push(user)
                } else if (user.last_name.toLowerCase().includes(search.toLowerCase())) {
                    this.delegateUsers.push(user)
                } else if (user.center.name.toLowerCase().includes(search.toLowerCase())) {
                    this.delegateUsers.push(user)
                }
            }
    }
}


export default AuthStore
