import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {Checkbox, FormControlLabel, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import YesNo from "../../components/YesNo";
import QuestionText from "../../components/QuestionText";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import TextField from "@mui/material/TextField";
import QuestionsLayout from "../../layout/QuestionsLayout";
import MissingReason from "../../components/MissingReason";

const invalidChars = [
    "-",
    "+",
    "e",
];

const HospitalizationsAfterInitialTreatmentPage1 = () => {
    const {
        afterInitialTreatmentStore, dataStore,menuStore
    } = useStores()
    const [showMissing, setShowMissing] = React.useState('not active');

    let params = useParams();
    useEffect(() => {
        (async () => {
            await afterInitialTreatmentStore.getAfterTreatment(parseInt(params.patient), parseInt(params.number))
            if (parseInt(afterInitialTreatmentStore.q4.numberOfSurgery) !== afterInitialTreatmentStore.q5.length) {
                for (let i = 0; i < parseInt(afterInitialTreatmentStore.q4.numberOfSurgery) - 1; i++) {
                    console.log('response.q4.numberOfSurgery', afterInitialTreatmentStore.q4.numberOfSurgery)
                    afterInitialTreatmentStore.q5.push({
                        ward: {selected: null, amount: ''},
                        intermediateCareUnit: {selected: null, amount: ''},
                        intensiveCareUnit: {selected: null, amount: ''},
                    })
                }
            }

        })();
    }, [])
    const navigate = useNavigate()
    const [selectedSurgery, setSelectedSurgery] = React.useState(1);

    const handleNext = async () => {
        if (parseInt(afterInitialTreatmentStore.q4.numberOfSurgery) > selectedSurgery) {
            setSelectedSurgery(selectedSurgery + 1)
            return
        }

        if (afterInitialTreatmentStore.q5.length === selectedSurgery) {
            if (!afterInitialTreatmentStore.checkMissing4) {
                await afterInitialTreatmentStore.updateAfterTreatment(
                    {
                        q4: JSON.stringify(afterInitialTreatmentStore.q4),
                        q5: JSON.stringify(afterInitialTreatmentStore.q5),
                    }, parseInt(params.number),parseInt(params.patient))
                if(dataStore.forceUpdate == true){
                    dataStore.setShowUpdateModal('not active')
                }
                if(dataStore.showUpdateModal !== 'active'){
                    navigate(`/diseaseRelatedAfterInitialPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
                }

            } else {
                alert('Please add reason for missing questions.')

                // if (!dataStore.globalMissing) {
                //     if (showMissing === 'active') {
                //         await afterInitialTreatmentStore.updateAfterTreatment(
                //             {
                //                 q4: afterInitialTreatmentStore.q4?.main ? JSON.stringify(afterInitialTreatmentStore.q4) : dataStore.returnOtherObjectMissing,
                //                 q5: afterInitialTreatmentStore.q5?.main ? JSON.stringify(afterInitialTreatmentStore.q5) : dataStore.returnOtherObjectMissing,
                //             }, parseInt(params.number),parseInt(params.patient))
                //         navigate(`/diseaseRelatedAfterInitialPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
                //         setShowMissing('not active')
                //     } else {
                //         setShowMissing('active')
                //     }
                // } else {
                //     await afterInitialTreatmentStore.updateAfterTreatment(
                //         {
                //             q4: afterInitialTreatmentStore.q4?.main ? JSON.stringify(afterInitialTreatmentStore.q4) : dataStore.returnOtherObjectMissing,
                //             q5: afterInitialTreatmentStore.q5?.main ? JSON.stringify(afterInitialTreatmentStore.q5) : dataStore.returnOtherObjectMissing,
                //         }, parseInt(params.number),parseInt(params.patient))
                //     navigate(`/diseaseRelatedAfterInitialPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
                // }
            }
        } else {
            setSelectedSurgery(selectedSurgery + 1)
        }
    }

    console.log('dataStore.questionsMissing',dataStore.questionsMissing)

    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => {
                             if (selectedSurgery === 1) {
                                 navigate(-1)
                             } else {
                                 setSelectedSurgery(selectedSurgery - 1)
                             }
                         }}
                         loading={afterInitialTreatmentStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>
                    {parseInt(params.number) === 0 ?
                        <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                            Health Care Resource utilization after treatment completion or discontinuation
                        </Typography> :
                        <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                            {`Health Care Resource utilization after completion or discontinuation of treatment n = ${params.number}`}
                        </Typography>
                    }
                    <Typography color={'txtGrey'} sx={{fontWeight: 'bold', fontSize: 18,}}>
                        [Please complete this section only during the time between treatments. Subsequent treatment data to be collected in section “Subsequent Treatment (n=2, 3, x ….)”.]
                    </Typography>
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>
                    <Typography sx={{fontWeight: 'bold', fontSize: 30,}}>
                        Hospitalizations
                    </Typography>
                    <QuestionBox allInRow={true}>
                        <YesNo value={afterInitialTreatmentStore.q4?.main}
                               handleChange={(event) => {
                                   dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q4'), 1)

                                   if (event.target.value === 'false') {
                                       afterInitialTreatmentStore.setQ4(
                                           {...afterInitialTreatmentStore.q4, numberOfSurgery: '',}
                                       )
                                       afterInitialTreatmentStore.setQ5(
                                           [{
                                               ward: {selected: null, amount: ''},
                                               intermediateCareUnit: {selected: null, amount: ''},
                                               intensiveCareUnit: {selected: null, amount: ''},
                                           }]
                                       )
                                   }
                                   afterInitialTreatmentStore.setQ4(
                                       {...afterInitialTreatmentStore.q4, main: event.target.value,})
                               }}
                        >
                            {dataStore.questionsMissing.includes('q4')
                                ? <MissingReason
                                    value={afterInitialTreatmentStore.q4.main}
                                    style={{right:60,marginTop:-3}}
                                    onSubmitMissing={()=>{
                                        afterInitialTreatmentStore.setQ4(
                                            {...afterInitialTreatmentStore.q4, main:dataStore.returnOtherTextMissing,})
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q4'), 1)
                                    }}/> : <></>}
                        </YesNo>
                        {afterInitialTreatmentStore.q4?.main && afterInitialTreatmentStore.q4.main === 'true' ? <Stack direction={'row'} sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography sx={{fontSize: 18, mr: 5}}>
                                {dataStore.questionsMissing.includes('q4numberOfSurgery') ?
                                    <MissingReason
                                        value={afterInitialTreatmentStore.q4.numberOfSurgery}
                                        style={{marginLeft:-80}}
                                        onSubmitMissing={()=>{
                                            afterInitialTreatmentStore.setQ4(
                                                {
                                                    ...afterInitialTreatmentStore.q4,
                                                    numberOfSurgery: dataStore.returnOtherTextMissing,
                                                }
                                            )
                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q4numberOfSurgery'), 1)
                                        }}/>
                                    : <></>}
                                Number of hospitalizations
                            </Typography>
                            <TextField
                                size={'small'}
                                type={"number"}
                                sx={{width: 100}}
                                inputProps={{min: 0, max: 10, style: {textAlign: 'center'}}}
                                onKeyDown={(e) => {
                                    if (invalidChars.includes(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                onWheel={(e) => e.target.blur()}
                                onChange={(event) => {
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q4numberOfSurgery'), 1)

                                    setSelectedSurgery(1)
                                    afterInitialTreatmentStore.setQ4(
                                        {
                                            ...afterInitialTreatmentStore.q4,
                                            numberOfSurgery: event.target.value,
                                        }
                                    )
                                    if (afterInitialTreatmentStore.q5.length > event.target.value) {
                                        let numberOfSurgeryRemove = afterInitialTreatmentStore.q5.length - afterInitialTreatmentStore.q4.numberOfSurgery
                                        for (let i = 0; i < numberOfSurgeryRemove; i++) {
                                            afterInitialTreatmentStore.q5.pop()
                                        }
                                    } else if (event.target.value > afterInitialTreatmentStore.q5.length) {
                                        let numberOfSurgeryAdd = afterInitialTreatmentStore.q4.numberOfSurgery - afterInitialTreatmentStore.q5.length
                                        for (let i = 0; i < numberOfSurgeryAdd; i++) {
                                            afterInitialTreatmentStore.q5.push({
                                                ward: {selected: null, amount: ''},
                                                intermediateCareUnit: {selected: null, amount: ''},
                                                intensiveCareUnit: {selected: null, amount: ''},
                                            })
                                        }
                                    }
                                    afterInitialTreatmentStore.setQ5(
                                        afterInitialTreatmentStore.q5
                                    )
                                }}
                                value={afterInitialTreatmentStore.q4?.numberOfSurgery && afterInitialTreatmentStore.q4.numberOfSurgery.startsWith('missing|') ? '': afterInitialTreatmentStore.q4.numberOfSurgery}

                            />
                        </Stack> : <></>}
                    </QuestionBox>
                    {afterInitialTreatmentStore.q4?.main && afterInitialTreatmentStore.q4.main === 'true' &&
                        afterInitialTreatmentStore.q4.numberOfSurgery !== '' && !afterInitialTreatmentStore.q4.numberOfSurgery.includes('missing') &&
                        afterInitialTreatmentStore.q5.map((item, index) => {
                            console.log('item=>',item)
                            let value = null
                            if(afterInitialTreatmentStore.q5[index]?.ward && typeof afterInitialTreatmentStore.q5[index].ward.selected === 'string' && afterInitialTreatmentStore.q5[index].ward.selected.startsWith('missing|')){
                                value = afterInitialTreatmentStore.q5[index].ward.selected
                            }else if(afterInitialTreatmentStore.q5[index]?.intermediateCareUnit && typeof afterInitialTreatmentStore.q5[index].intermediateCareUnit.selected === 'string' && afterInitialTreatmentStore.q5[index].intermediateCareUnit.selected.startsWith('missing|')){
                                value = afterInitialTreatmentStore.q5[index].intermediateCareUnit.selected
                            }else if(afterInitialTreatmentStore.q5[index]?.intensiveCareUnit && typeof afterInitialTreatmentStore.q5[index].intensiveCareUnit.selected === 'string' && afterInitialTreatmentStore.q5[index].intensiveCareUnit.selected.startsWith('missing|')){
                                value = afterInitialTreatmentStore.q5[index].intensiveCareUnit.selected

                            }
                            if (index + 1 === selectedSurgery) {
                                return (
                                    <QuestionBox>
                                        <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 25,}}>
                                            Hospitalizations {index + 1}
                                            {dataStore.questionsMissing.includes('hosp'+index)
                                                ? <MissingReason
                                                    value={value}

                                                    style={{right:60,marginTop:3}}
                                                    onSubmitMissing={async ()=>{

                                                        let q5Array = afterInitialTreatmentStore.q5
                                                        q5Array[index].ward = {...q5Array[index].ward,selected: dataStore.returnOtherTextMissing}
                                                        q5Array[index].intermediateCareUnit = {...q5Array[index].intermediateCareUnit,selected: dataStore.returnOtherTextMissing}
                                                        q5Array[index].intensiveCareUnit = {...q5Array[index].intensiveCareUnit,selected: dataStore.returnOtherTextMissing}

                                                        afterInitialTreatmentStore.setQ5(
                                                            q5Array
                                                        )
                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('hosp'+index), 1)
                                                    }}/> : <></>}
                                        </Typography>

                                        <QuestionText>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                flexDirection: 'column',
                                                pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0

                                            }}>
                                                <li>level of hospitalization</li>
                                                <Box sx={{
                                                    width: '340px',
                                                    ml: 3,
                                                    display: "flex",
                                                    justifyContent: 'space-between',
                                                }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={(event) => {
                                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('hosp'+index), 1)

                                                                    if (event.target.value === 'on' && item.ward.selected === 'on') {
                                                                        let q5Array = afterInitialTreatmentStore.q5
                                                                        q5Array[index] = {
                                                                            ...item,
                                                                            ward: {selected: null, amount: ''}
                                                                        }
                                                                        afterInitialTreatmentStore.setQ5(q5Array)

                                                                    } else {
                                                                        let q5Array = afterInitialTreatmentStore.q5
                                                                        q5Array[index] = {
                                                                            ...item,
                                                                            ward: {
                                                                                ...q5Array[index].ward,
                                                                                selected: event.target.value
                                                                            }
                                                                        }
                                                                        afterInitialTreatmentStore.setQ5(q5Array)
                                                                    }

                                                                }}
                                                                checked={item.ward?.selected && item.ward.selected.startsWith('missing|') ? false : item.ward.selected}

                                                            />
                                                        }
                                                        sx={{
                                                            '& .MuiFormControlLabel-label': {fontSize: 18},

                                                        }}
                                                        label={'Ward'}
                                                    />

                                                    {item.ward.selected &&
                                                        <Stack direction={'row'} spacing={2} sx={{display:'flex',alignItems:'center'}}>
                                                            <Typography>

                                                                Number:</Typography>
                                                            <TextField
                                                                size={'small'}
                                                                placeholder={'000'}
                                                                onWheel={(e) => e.target.blur()}
                                                                sx={{width: 100}}
                                                                value={item.ward?.amount && item.ward.amount.startsWith('missing|') ? '':item.ward.amount}

                                                                onChange={(event) => {
                                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('hosp'+index+'ward'), 1)

                                                                    let q5Array = afterInitialTreatmentStore.q5
                                                                    q5Array[index] = {
                                                                        ...item,
                                                                        ward: {
                                                                            ...q5Array[index].ward,
                                                                            amount: event.target.value
                                                                        }
                                                                    }
                                                                    afterInitialTreatmentStore.setQ5(q5Array)
                                                                }}
                                                            />
                                                            {dataStore.questionsMissing.includes('hosp'+index+'ward') ?
                                                                <MissingReason
                                                                    value={afterInitialTreatmentStore.q5[index]?.ward?.amount}
                                                                    style={{marginLeft:200}}
                                                                    onSubmitMissing={()=>{
                                                                        let q5Array = afterInitialTreatmentStore.q5
                                                                        q5Array[index] = {
                                                                            ...item,
                                                                            ward: {
                                                                                ...q5Array[index].ward,
                                                                                amount:dataStore.returnOtherTextMissing
                                                                            }
                                                                        }
                                                                        afterInitialTreatmentStore.setQ5(q5Array)

                                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('hosp'+index+'ward'), 1)
                                                                    }}/>
                                                                : <></>}
                                                        </Stack>

                                                    }
                                                </Box>


                                                <Box sx={{
                                                    width: '340px',
                                                    ml: 3,
                                                    display: "flex",
                                                    justifyContent: 'space-between',
                                                }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={(event) => {
                                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('hosp'+index+'intermediateCareUnit'), 1)

                                                                    if (event.target.value === 'on' && item.intermediateCareUnit.selected === 'on') {
                                                                        let q5Array = afterInitialTreatmentStore.q5
                                                                        q5Array[index] = {
                                                                            ...item,
                                                                            intermediateCareUnit: {
                                                                                selected: null,
                                                                                amount: ''
                                                                            }
                                                                        }
                                                                        afterInitialTreatmentStore.setQ5(q5Array)

                                                                    } else {
                                                                        let q5Array = afterInitialTreatmentStore.q5
                                                                        q5Array[index] = {
                                                                            ...item,
                                                                            intermediateCareUnit: {
                                                                                ...q5Array[index].intermediateCareUnit,
                                                                                selected: event.target.value
                                                                            }
                                                                        }
                                                                        afterInitialTreatmentStore.setQ5(q5Array)
                                                                    }

                                                                }}
                                                                checked={item.intermediateCareUnit?.selected && item.intermediateCareUnit.selected.startsWith('missing|') ? false : item.intermediateCareUnit.selected}
                                                            />
                                                        }
                                                        sx={{
                                                            '& .MuiFormControlLabel-label': {fontSize: 18},

                                                        }}
                                                        label={'Intermediate care unit'}
                                                    />

                                                    {item.intermediateCareUnit.selected &&
                                                        <Stack direction={'row'} spacing={2} sx={{display:'flex',alignItems:'center'}}>
                                                            <Typography>
                                                                {dataStore.questionsMissing.includes('hosp'+index+'intermediateCareUnit') ?
                                                                    <MissingReason
                                                                        value={afterInitialTreatmentStore.q5[index]?.intermediateCareUnit?.amount}
                                                                        style={{marginLeft:200,marginTop:-10}}
                                                                        onSubmitMissing={()=>{
                                                                            let q5Array = afterInitialTreatmentStore.q5
                                                                            q5Array[index] = {
                                                                                ...item,
                                                                                intermediateCareUnit: {
                                                                                    ...q5Array[index].intermediateCareUnit,
                                                                                    amount: dataStore.returnOtherTextMissing
                                                                                }
                                                                            }
                                                                            afterInitialTreatmentStore.setQ5(q5Array)

                                                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('hosp'+index+'intermediateCareUnit'), 1)
                                                                        }}/>
                                                                    : <></>}
                                                                Number:</Typography>
                                                            <TextField
                                                                size={'small'}
                                                                onWheel={(e) => e.target.blur()}
                                                                placeholder={'000'}
                                                                sx={{width: 100}}
                                                                value={item.intermediateCareUnit?.amount && item.intermediateCareUnit.amount.startsWith('missing|') ? '':item.intermediateCareUnit.amount}

                                                                onChange={(event) => {
                                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('hosp'+index+'intensiveCareUnit'), 1)

                                                                    let q5Array = afterInitialTreatmentStore.q5
                                                                    q5Array[index] = {
                                                                        ...item,
                                                                        intermediateCareUnit: {
                                                                            ...q5Array[index].intermediateCareUnit,
                                                                            amount: event.target.value
                                                                        }
                                                                    }
                                                                    afterInitialTreatmentStore.setQ5(q5Array)
                                                                }}
                                                            />
                                                        </Stack>

                                                    }
                                                </Box>

                                                <Box sx={{
                                                    width: '340px',
                                                    ml: 3,
                                                    display: "flex",
                                                    justifyContent: 'space-between',
                                                }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={(event) => {
                                                                    if (event.target.value === 'on' && item.intensiveCareUnit.selected === 'on') {
                                                                        let q5Array = afterInitialTreatmentStore.q5
                                                                        q5Array[index] = {
                                                                            ...item,
                                                                            intensiveCareUnit: {
                                                                                selected: null,
                                                                                amount: ''
                                                                            }
                                                                        }
                                                                        afterInitialTreatmentStore.setQ5(q5Array)

                                                                    } else {
                                                                        let q5Array = afterInitialTreatmentStore.q5
                                                                        q5Array[index] = {
                                                                            ...item,
                                                                            intensiveCareUnit: {
                                                                                ...q5Array[index].intensiveCareUnit,
                                                                                selected: event.target.value
                                                                            }
                                                                        }
                                                                        afterInitialTreatmentStore.setQ5(q5Array)
                                                                    }

                                                                }}
                                                                checked={item.intensiveCareUnit?.selected && item.intensiveCareUnit.selected.startsWith('missing|') ? false : item.intensiveCareUnit.selected}
s                                                            />
                                                        }
                                                        sx={{
                                                            '& .MuiFormControlLabel-label': {fontSize: 18},

                                                        }}
                                                        label={'Intensive care unit'}
                                                    />

                                                    {item.intensiveCareUnit.selected &&
                                                        <Stack direction={'row'} spacing={2} sx={{display:'flex',alignItems:'center'}}>
                                                            <Typography>
                                                                {dataStore.questionsMissing.includes('hosp'+index+'intensiveCareUnit') ?
                                                                    <MissingReason
                                                                        value={afterInitialTreatmentStore.q5[index]?.intensiveCareUnit?.amount}
                                                                        style={{marginLeft:200,marginTop:-10}}
                                                                        onSubmitMissing={()=>{
                                                                            let q5Array = afterInitialTreatmentStore.q5
                                                                            q5Array[index] = {
                                                                                ...item,
                                                                                intensiveCareUnit: {
                                                                                    ...q5Array[index].intensiveCareUnit,
                                                                                    amount: dataStore.returnOtherTextMissing
                                                                                }
                                                                            }
                                                                            afterInitialTreatmentStore.setQ5(q5Array)

                                                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('hosp'+index+'intermediateCareUnit'), 1)
                                                                        }}/>
                                                                    : <></>}

                                                                Number:</Typography>
                                                            <TextField
                                                                size={'small'}
                                                                onWheel={(e) => e.target.blur()}
                                                                placeholder={'000'}
                                                                sx={{width: 100}}
                                                                value={item.intensiveCareUnit?.amount && item.intensiveCareUnit.amount.startsWith('missing|') ? '':item.intensiveCareUnit.amount}

                                                                onChange={(event) => {
                                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('hosp'+index+'intermediateCareUnit'), 1)

                                                                    let q5Array = afterInitialTreatmentStore.q5
                                                                    q5Array[index] = {
                                                                        ...item,
                                                                        intensiveCareUnit: {
                                                                            ...q5Array[index].intensiveCareUnit,
                                                                            amount: event.target.value
                                                                        }
                                                                    }
                                                                    afterInitialTreatmentStore.setQ5(q5Array)
                                                                }}
                                                            />
                                                        </Stack>

                                                    }
                                                </Box>
                                            </Box>

                                        </QuestionText>
                                    </QuestionBox>
                                );
                            } else {
                                return (
                                    <></>
                                )
                            }

                        })
                    }

                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(HospitalizationsAfterInitialTreatmentPage1);
