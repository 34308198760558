import * as React from 'react';
import {Checkbox, ClickAwayListener, FormControlLabel, InputAdornment, Stack} from "@mui/material";
import Paper from "@mui/material/Paper";
import arrowUp from "../../images/svgs/Arrow-up.svg";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Joi from "joi";
import Typography from "@mui/material/Typography";
import MissingReason from "../../components/MissingReason";
import {useStores} from "../../store";


const DoseMenu = ({setOpenMenu, text, sx, checked, index, item, therapies, question, store, deletePress}) => {

    const {
       dataStore
    } = useStores()

    const update = () => {
        if (question === 'q3') {
            store.setQ3(
                {
                    ...store[question],
                    therapies: therapies
                })
        }
        if (question === 'q14') {
            store.setQ14(
                {
                    ...store[question],
                    therapies: therapies
                })
        }
        if (question === 'q15') {
            store.setQ15(
                {
                    ...store[question],
                    therapies: therapies
                })
        }
    }

    const DoseSchema = Joi.number().min(25).max(250).integer().required();
    const CyclePlannedSchema = Joi.number().min(1).max(6).integer().required();
    const CycleActualSchema = Joi.number().min(0).max(6).integer().required();
    const TotalDuration = Joi.number().min(0).max(24).integer().required();

    return (
        <ClickAwayListener onClickAway={setOpenMenu}>
            <Paper sx={{
                display: 'flex',
                backgroundColor: 'white',
                mt: -6,
                ml: -3,
                position: 'absolute',
                p: 3,
                pt: 1,
                borderRadius: 3,
                flexDirection: 'column',
                zIndex: 999, ...sx
            }} elevation={5}>
                <Stack direction={'row'} sx={{display: "flex", alignItems: "center"}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checked}
                            />
                        }
                        sx={{
                            '& .MuiFormControlLabel-label': {fontSize: 18},
                        }}
                        label={text}
                    />
                    <img onClick={setOpenMenu} style={{
                        cursor: 'pointer',
                        width: 18,
                        height: 18,
                    }} src={arrowUp}/>
                </Stack>
                <Stack direction={'column'}>
                    <ul style={{width: '100%', paddingLeft: 24, display: "flex", flexDirection: "column"}}>
                        <Box sx={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <li style={{fontSize: 18}}>Dose
                                {dataStore.questionsMissing.includes(text+'dose') ?
                                <MissingReason
                                    value={item.params.dose}
                                    onSubmitMissing={async ()=>{
                                        therapies[index].params = {...item.params, dose: dataStore.returnOtherTextMissing}
                                        update()
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf(text+'dose'), 1)
                                    }}/>
                                : <></>}
                            </li>
                            <TextField
                                size={'small'}
                                sx={{width: 160}}
                                placeholder={'00 00 00'}
                                value={item.params?.dose && item.params.dose.startsWith('missing|') ? '': item.params.dose}

                                error={DoseSchema.validate(item.params.dose).error}
                                onChange={(event) => {
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf(text+'dose'), 1)

                                    therapies[index].params = {...item.params, dose: event.target.value}
                                    update()
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">units</InputAdornment>,
                                }}
                            />
                        </Box>
                        {item.params.dose !== '' && DoseSchema.validate(item.params.dose).error && <Typography
                            variant="caption" color="error" sx={{alignSelf: "flex-end"}}>Between 25 and 250 (unit
                            mg/m2)</Typography>}
                        <Box sx={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            mt: 1,
                            justifyContent: 'space-between',
                            gap: 2
                        }}>
                            <li style={{fontSize: 18}}>Number of cycles (planned)
                                {dataStore.questionsMissing.includes(text+'numberOfCyclesPlanned') ?
                                    <MissingReason
                                        value={item.params.numberOfCyclesPlanned}
                                        onSubmitMissing={async ()=>{
                                            therapies[index].params = {...item.params, numberOfCyclesPlanned: dataStore.returnOtherTextMissing}
                                            update()
                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf(text+'numberOfCyclesPlanned'), 1)
                                        }}/>
                                    : <></>}
                            </li>
                            <TextField
                                id="numberMonths"
                                size={'small'}
                                sx={{width: 100}}
                                placeholder={'00'}
                                value={item.params?.numberOfCyclesPlanned && item.params.numberOfCyclesPlanned.startsWith('missing|') ? '': item.params.numberOfCyclesPlanned}

                                error={CyclePlannedSchema.validate(item.params.numberOfCyclesPlanned).error}
                                onChange={(event) => {
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf(text+'numberOfCyclesPlanned'), 1)

                                    therapies[index].params = {
                                        ...item.params,
                                        numberOfCyclesPlanned: event.target.value
                                    }
                                    update()

                                }}
                            />
                        </Box>
                        {item.params.numberOfCyclesPlanned !== '' && CyclePlannedSchema.validate(item.params.numberOfCyclesPlanned).error &&
                            <Typography
                                variant="caption" color="error" sx={{alignSelf: "flex-end"}}>Between 1 and
                                6</Typography>}
                        <Box sx={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            mt: 1,
                            justifyContent: 'space-between',
                            gap: 2
                        }}>
                            <li style={{fontSize: 18}}>Number of cycles (actual)
                                {dataStore.questionsMissing.includes(text+'numberOfCyclesActual') ?
                                    <MissingReason
                                        value={item.params.numberOfCyclesActual}
                                        onSubmitMissing={async ()=>{
                                            therapies[index].params = {...item.params, numberOfCyclesActual: dataStore.returnOtherTextMissing}
                                            update()
                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf(text+'numberOfCyclesActual'), 1)
                                        }}/>
                                    : <></>}
                            </li>
                            <TextField
                                size={'small'}
                                sx={{width: 100}}
                                placeholder={'00'}
                                value={item.params?.numberOfCyclesActual && item.params.numberOfCyclesActual.startsWith('missing|') ? '': item.params.numberOfCyclesActual}

                                error={CycleActualSchema.validate(item.params.numberOfCyclesActual).error}
                                onChange={(event) => {
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf(text+'numberOfCyclesActual'), 1)

                                    therapies[index].params = {...item.params, numberOfCyclesActual: event.target.value}
                                    update()

                                }}
                            />
                        </Box>
                        {item.params.numberOfCyclesActual !== '' && CycleActualSchema.validate(item.params.numberOfCyclesActual).error &&
                            <Typography variant="caption" color="error" sx={{alignSelf: "flex-end"}}>Between 0 and
                                6</Typography>}
                        <Box sx={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            mt: 1,
                            justifyContent: 'space-between',
                            gap: 2
                        }}>
                            <li style={{fontSize: 18}}>Total Duration in weeks
                                {dataStore.questionsMissing.includes(text+'totalDurationInWeeks') ?
                                    <MissingReason
                                        value={item.params.totalDurationInWeeks}
                                        onSubmitMissing={async ()=>{
                                            therapies[index].params = {...item.params, totalDurationInWeeks: dataStore.returnOtherTextMissing}
                                            update()
                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf(text+'totalDurationInWeeks'), 1)
                                        }}/>
                                    : <></>}
                            </li>
                            <TextField
                                size={'small'}
                                sx={{width: 100}}
                                placeholder={'00'}
                                value={item.params?.totalDurationInWeeks && item.params.totalDurationInWeeks.startsWith('missing|') ? '': item.params.totalDurationInWeeks}

                                error={TotalDuration.validate(item.params.totalDurationInWeeks).error}
                                onChange={(event) => {
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf(text+'totalDurationInWeeks'), 1)

                                    therapies[index].params = {...item.params, totalDurationInWeeks: event.target.value}
                                    update()
                                }}
                            />
                        </Box>
                        {item.params.totalDurationInWeeks !== '' && TotalDuration.validate(item.params.totalDurationInWeeks).error &&
                            <Typography variant="caption" color="error" sx={{alignSelf: "flex-end"}}>Between 0 and
                                24</Typography>}
                    </ul>
                </Stack>

                <Button
                    variant="contained"
                    onClick={deletePress}
                    sx={{
                        color: "white",
                        fontWeight: 'bold',
                        borderRadius: 12,
                        py: 1,
                        fontSize: 18,
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: `2px 2px 10px #D3302F`,
                        textTransform: 'none',
                        "&.Mui-disabled": {
                            color: '#ffffff'
                        },
                        px: 6, width:'50%',alignSelf:'flex-end'
                    }}
                    color={'error'}
                >Delete</Button>

            </Paper>
        </ClickAwayListener>
    );
}

export default DoseMenu;
