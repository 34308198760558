import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import MissingQuestions from "../data/MissingQuestions";
import UpdateModal from "../data/UpdateModal";
import {useStores} from "../store";
import {observer} from "mobx-react";

const QuestionWrapper = ({children, sx, showMissing, onClose, onSubmitMissing,disabled,
                             // showUpdateModal, onCloseUpdate
}) => {

    const {
        dataStore
    } = useStores()


    return (
        <Grid
            item
            xs={false}
            sm={6}
            md={12}
            sx={{position: 'relative', display: 'flex',}}
        >
            {disabled && <Box sx={{display:'flex',position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(255,255,255,0.7)',zIndex:999}}/>}
            <Box sx={{
                width: '100%',
                py: 1,
                borderRadius: 7,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                position: 'relative',
                flex: 1,
                minHeight: '98vh', px: 2, ...sx
            }}>
                {children}
            </Box>
            {showMissing === 'active' ? <MissingQuestions onClose={onClose} onSubmitMissing={onSubmitMissing}/> : <></>}
            {dataStore.showUpdateModal === 'active' ? <UpdateModal onClose={()=>{
                    dataStore.setShowUpdateModal('not active')
                    dataStore.resetUpdateModalData()
            }} onSubmitMissing={onSubmitMissing}/> : <></>}
        </Grid>
    );
}

export default observer(QuestionWrapper)
