import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import DeleteIcon from '@mui/icons-material/Delete';
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import YesNo from "../../components/YesNo";
import QuestionText from "../../components/QuestionText";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import TextField from "@mui/material/TextField";
import QuestionsLayout from "../../layout/QuestionsLayout";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import MissingReason from "../../components/MissingReason";


const ClinicalHistoryPage1 = () => {
    const {
        clinicalHistoryStore, dataStore, menuStore
    } = useStores()
    const [showMissing, setShowMissing] = React.useState('not active');
    const [openOther, setOpenOther] = React.useState(null);
    let urlParams = useParams();

    const [newOther, setNewOther] = React.useState('');

    const navigate = useNavigate()
    useEffect(() => {
        clinicalHistoryStore.getClinicalHistory(parseInt(urlParams.patient))

    }, [])
    const handleNext = async () => {

        if (!clinicalHistoryStore.checkMissing1) {

            await clinicalHistoryStore.updateClinicalHistory(
                {
                    q1: JSON.stringify(clinicalHistoryStore.q1),
                    q2: clinicalHistoryStore.q2,
                },parseInt(urlParams.patient))
            if(dataStore.forceUpdate == true){
                dataStore.setShowUpdateModal('not active')
            }
            if(dataStore.showUpdateModal !== 'active'){
                navigate('/clinicalHistoryPage2/'+parseInt(urlParams.patient))
            }
        } else {
            alert('Please add reason for missing questions.')

            // if (!dataStore.globalMissing) {
            //
            //     if (showMissing === 'active') {
            //         await clinicalHistoryStore.updateClinicalHistory(
            //             {
            //                 q1: clinicalHistoryStore.q1.main ? JSON.stringify(clinicalHistoryStore.q1) : dataStore.returnOtherObjectMissing,
            //                 q2: clinicalHistoryStore.q2 ? clinicalHistoryStore.q2 : dataStore.returnOtherTextMissing,
            //             },parseInt(urlParams.patient))
            //         navigate('/clinicalHistoryPage2/'+parseInt(urlParams.patient))
            //         setShowMissing('not active')
            //     } else {
            //         setShowMissing('active')
            //     }
            // } else {
            //     await clinicalHistoryStore.updateClinicalHistory(
            //         {
            //             q1: clinicalHistoryStore.q1.main ? JSON.stringify(clinicalHistoryStore.q1) : dataStore.returnOtherObjectMissing,
            //             q2: clinicalHistoryStore.q2 ? clinicalHistoryStore.q2 : dataStore.returnOtherTextMissing,
            //         },parseInt(urlParams.patient))
            //     navigate('/clinicalHistoryPage2/'+parseInt(urlParams.patient))
            // }
        }
    }
    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={clinicalHistoryStore.loading}>
            <Box sx={{width: '100%', px: 4, mb: 0, mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>

                    <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32, lineHeight: 1.2}}>
                        Clinical History at diagnosis
                    </Typography>
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>
                    <QuestionBox>
                        <Stack direction={'row'} sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <QuestionText>
                                Previous pulmonary disease

                            </QuestionText>
                            {dataStore.questionsMissing.includes('q1')
                                ? <MissingReason
                                    style={{left:340,marginTop:3}}
                                    value={clinicalHistoryStore.q1.main}
                                    onSubmitMissing={async ()=>{
                                        clinicalHistoryStore.setQ1(
                                            {...clinicalHistoryStore.q1, main: dataStore.returnOtherTextMissing,}
                                        )
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q1'), 1)
                                    }}/> : <></>}
                            <YesNo
                                value={clinicalHistoryStore.q1.main}
                                handleChange={(event) => {
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q1'), 1)

                                    if (event.target.value === 'true') {
                                        clinicalHistoryStore.setQ1(
                                            {...clinicalHistoryStore.q1, main: event.target.value,}
                                        )
                                    } else {
                                        clinicalHistoryStore.setQ1(
                                            {
                                                copd: null,
                                                pulmonaryFibrosis: null,
                                                other: {selected: null, value: []}, main: event.target.value,
                                            }
                                        )
                                    }
                                    console.log('dataStore.questionsMissing',dataStore.questionsMissing)

                                }}
                            />


                        </Stack>
                        {clinicalHistoryStore.q1.main === 'true' ? <QuestionText width={'100%'}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                                alignItems: 'center',
                                pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0

                            }}>
                                <li style={{fontWeight: "normal"}}>COPD
                                    {dataStore.questionsMissing.includes('copd')
                                        ? <MissingReason
                                            style={{marginTop:-9}}
                                            value={clinicalHistoryStore.q1?.copd}
                                            onSubmitMissing={async ()=>{
                                                clinicalHistoryStore.setQ1(
                                                    {...clinicalHistoryStore.q1, copd: dataStore.returnOtherTextMissing,}
                                                )
                                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('copd'), 1)
                                            }}/> : <></>}
                                </li>
                                <YesNo value={clinicalHistoryStore?.q1?.copd}
                                       handleChange={(event) => {
                                           dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('copd'), 1)

                                           clinicalHistoryStore.setQ1(
                                           {...clinicalHistoryStore.q1, copd: event.target.value,}
                                       )}}
                                />
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                                alignItems: 'center',
                                pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0

                            }}>
                                <li style={{fontWeight: "normal"}}>Pulmonary fibrosis
                                    {dataStore.questionsMissing.includes('pulmonaryFibrosis')
                                        ? <MissingReason
                                            value={clinicalHistoryStore.q1?.pulmonaryFibrosis}
                                            onSubmitMissing={async ()=>{
                                                clinicalHistoryStore.setQ1(
                                                    {...clinicalHistoryStore.q1,
                                                        pulmonaryFibrosis: dataStore.returnOtherTextMissing,}
                                                )
                                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('pulmonaryFibrosis'), 1)
                                            }}/> : <></>}
                                </li>
                                <YesNo value={clinicalHistoryStore?.q1?.pulmonaryFibrosis}
                                       handleChange={(event) => {
                                           dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('pulmonaryFibrosis'), 1)
                                           clinicalHistoryStore.setQ1({
                                               ...clinicalHistoryStore.q1,
                                               pulmonaryFibrosis: event.target.value,
                                           }
                                       )}}
                                />
                            </Box>
                            <div>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0

                                }}>
                                    <li style={{fontWeight: "normal"}}>Other
                                        {dataStore.questionsMissing.includes('other')
                                            ? <MissingReason
                                                value={clinicalHistoryStore.q1?.other?.selected}
                                                onSubmitMissing={()=>{
                                                    clinicalHistoryStore.setQ1({
                                                        ...clinicalHistoryStore.q1,
                                                        other: {
                                                            ...clinicalHistoryStore.q1.other,
                                                            selected: dataStore.returnOtherTextMissing,
                                                        }
                                                    })
                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('other'), 1)
                                                }}/> : <></>}
                                    </li>
                                    <Box sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-end",
                                        width: "70%",
                                        maxWidth: 500,
                                    }}>
                                        <YesNo value={clinicalHistoryStore?.q1?.other?.selected}
                                               handleChange={(event) => {
                                                   dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('other'), 1)

                                                   clinicalHistoryStore.setQ1({
                                                       ...clinicalHistoryStore.q1,
                                                       other: {
                                                           ...clinicalHistoryStore.q1.other,
                                                           selected: event.target.value,
                                                       }
                                                   }
                                               )
                                               if (event.target.value === "true") {
                                                   setOpenOther(true)
                                               } else {
                                                   setOpenOther(false)
                                                   setNewOther('')
                                                   clinicalHistoryStore.setQ1({
                                                           ...clinicalHistoryStore.q1,
                                                           other: {
                                                               ...clinicalHistoryStore.q1.other,
                                                               value: [],
                                                           }
                                                       }
                                                   )
                                               }
                                           }}/>
                                        {openOther || (clinicalHistoryStore.q1?.other?.selected && clinicalHistoryStore.q1.other.selected === "true") ?
                                            <Box sx={{display: "flex", flexDirection: "row"}}>
                                                {dataStore.questionsMissing.includes('q1othervalue')
                                                    ? <MissingReason
                                                        style={{marginTop:4,marginLeft:-100}}
                                                        value={typeof clinicalHistoryStore?.q1.other?.value === 'string' ? clinicalHistoryStore.q1.other.value : ''}
                                                        onSubmitMissing={async ()=>{
                                                            clinicalHistoryStore.setQ1({
                                                                ...clinicalHistoryStore.q1,
                                                                other: {
                                                                    ...clinicalHistoryStore.q1.other,
                                                                    value: dataStore.returnOtherTextMissing,
                                                                }
                                                            })
                                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q1othervalue'), 1)
                                                        }}/> : <></>}
                                                <TextField
                                                    size={'small'}
                                                    label="Specify Here"
                                                    margin="normal"
                                                    name="otherTumorPage1"
                                                    sx={{width: '100%', mt: 1}}
                                                    onChange={(event) => {
                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q1othervalue'), 1)
                                                        setNewOther(event.target.value)
                                                    }}
                                                    value={newOther}
                                                />
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{
                                                        ml: 1,
                                                        fontSize: 12,
                                                        color: "white",
                                                        height: 40,
                                                        mt: 1,
                                                        mb: 1,
                                                        p: 0
                                                    }}
                                                    onClick={() => {
                                                        let others = clinicalHistoryStore.q1.other.value
                                                        if(others.find((t)=>t == newOther)){
                                                            alert('You are trying to add duplicate value.')
                                                            setNewOther('');
                                                        }else{
                                                            if (newOther === '') {
                                                                return
                                                            }

                                                            if (others === null) {
                                                                others = []
                                                            }
                                                            others.push(newOther)
                                                            clinicalHistoryStore.setQ1({
                                                                ...clinicalHistoryStore.q1,
                                                                other: {
                                                                    ...clinicalHistoryStore.q1.other,
                                                                    value: others,
                                                                }
                                                            })
                                                            setNewOther('');
                                                        }

                                                    }}>
                                                    Add
                                                </Button>
                                            </Box>
                                            : <></>}
                                    </Box>
                                </Box>
                                <Box sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    flexWrap: "wrap",
                                    rowGap: 1,
                                    mt: 1
                                }}>
                                    {clinicalHistoryStore.q1?.other?.value &&  Array.isArray(clinicalHistoryStore.q1.other.value) &&
                                        clinicalHistoryStore.q1.other.value.map((item, index) =>
                                        <Box key={"OTHER__" + index}
                                             sx={{
                                                 minWidth: 150,
                                                 pl: 1,
                                             }}>
                                            <Paper sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                flex: 1,
                                                alignItems: "center",
                                                justifyContent: "flex-end",
                                                p: 1,
                                                pl: 2
                                            }}>
                                                <Typography sx={{mr: 1}}>
                                                    {item}
                                                </Typography>
                                                <IconButton onClick={() => {
                                                    let others = clinicalHistoryStore.q1.other.value
                                                    others.splice(index, 1)
                                                    clinicalHistoryStore.setQ1(
                                                        {
                                                            ...clinicalHistoryStore.q1,
                                                            other: {
                                                                ...clinicalHistoryStore.q1.other,
                                                                value: others
                                                            }
                                                        }
                                                    )
                                                }}>
                                                    <DeleteIcon color="error"/>
                                                </IconButton>
                                            </Paper>
                                        </Box>)}
                                </Box>
                            </div>
                        </QuestionText>: <></>}

                    </QuestionBox>

                    <QuestionBox allInRow={true}>
                        <QuestionText>
                            Family history of lung cancer
                            {dataStore.questionsMissing.includes('q2')
                                ? <MissingReason
                                    style={{marginTop:-8}}
                                    value={clinicalHistoryStore.q2}
                                    onSubmitMissing={async ()=>{
                                        clinicalHistoryStore.setQ2(
                                            dataStore.returnOtherTextMissing
                                        )
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q2'), 1)
                                    }}/> : <></>}
                        </QuestionText>
                        <YesNo value={clinicalHistoryStore.q2}
                               handleChange={(event) => {
                                   dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q2'), 1)
                                   clinicalHistoryStore.setQ2(
                                   event.target.value
                               )
                               }}
                        />

                    </QuestionBox>
                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(ClinicalHistoryPage1);
