import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {RadioGroup, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import RadioItem from "../../components/RadioItem";
import TextField from "@mui/material/TextField";
import QuestionsLayout from "../../layout/QuestionsLayout";
import MissingReason from "../../components/MissingReason";


const ConcomitantMedicationsPage3 = () => {
    const {
        treatmentPatternsStore, dataStore,menuStore
    } = useStores()
    const [showMissing, setShowMissing] = React.useState('not active');

    const navigate = useNavigate()
    const [otherOn, setOtherOn] = React.useState(null);
    const [numberOfItem, setNumberOfItem] = React.useState(2);
    let params = useParams();
    useEffect(() => {
        // treatmentPatternsStore.getTreatment(parseInt(params.patient),parseInt(params.number))
    }, [])

    const handleNext = async () => {
        let selected = treatmentPatternsStore.q16.disease.filter((q) => q.selected === 'on')
        console.log('selected', selected)
        if (selected.length > numberOfItem) {
            setNumberOfItem(numberOfItem + 2)
        } else {
            if (!treatmentPatternsStore.checkMissing6_2) {
                // treatmentPatternsStore.q16.main = 'true'
                await treatmentPatternsStore.updateTreatment(
                    {
                        q16: JSON.stringify(treatmentPatternsStore.q16),
                    }, parseInt(params.number),parseInt(params.patient))
                if(dataStore.forceUpdate == true){
                    dataStore.setShowUpdateModal('not active')
                }
                if(dataStore.showUpdateModal !== 'active'){
                    navigate(`/imagingAssessmentsPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
                }

            } else {
                alert('Please add reason for missing questions.')

                // if (!dataStore.globalMissing) {
                //     if (showMissing === 'active') {
                //         await treatmentPatternsStore.updateTreatment(
                //             {
                //                 q16: treatmentPatternsStore.q16?.main ? JSON.stringify(treatmentPatternsStore.q16) : dataStore.returnOtherObjectMissing,
                //             }, parseInt(params.number),parseInt(params.patient))
                //         navigate(`/imagingAssessmentsPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
                //         setShowMissing('not active')
                //     } else {
                //         setShowMissing('active')
                //     }
                // } else {
                //     await treatmentPatternsStore.updateTreatment(
                //         {
                //             q16: treatmentPatternsStore.q16?.main ? JSON.stringify(treatmentPatternsStore.q16) : dataStore.returnOtherObjectMissing,
                //         }, parseInt(params.number),parseInt(params.patient))
                //     navigate(`/imagingAssessmentsPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
                // }
            }
        }
    }

    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={treatmentPatternsStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>
                    {parseInt(params.number) === 0 ?
                        <div>
                            <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                                Treatment patterns and healthcare resource use (Only Initial Treatment)
                            </Typography>
                            <Typography color={'txtGrey'} sx={{fontSize: 20,mt:1}}>
                                (Please complete this section only for initial treatment.)
                            </Typography>

                        </div> :
                        <div>
                            <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                                {`Treatment patterns and healthcare resource use for subsequent treatment n = ${params.number}`}
                            </Typography>
                            <Typography color={'txtGrey'} sx={{fontSize: 20,mt:1}}>
                                [Please complete this section for “Subsequent treatment (n=1, 2, …)”]
                            </Typography>
                        </div>
                    }
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>
                    <Typography sx={{fontWeight: 'bold', fontSize: 30,}}>
                        Concomitant medications used for disease management
                    </Typography>
                    {treatmentPatternsStore.q16.disease.filter((q) => q.selected === 'on').map((item, index) => {
                        let disease = treatmentPatternsStore.q16.disease
                        const diseaseIndex = disease.findIndex(object => {
                            return object.name === item.name;
                        })
                        if (item.selected === 'on' && (index + 1 <= numberOfItem && index + 1 > numberOfItem - 2)) {
                            return (
                                <QuestionBox key={'item' + index}>
                                    <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 24, mb: 2}}>
                                        {item.name}

                                    </Typography>

                                    <Box spacing={1}
                                         sx={{
                                             width: '100%',
                                             display: 'flex',
                                             alignItems: 'center',
                                             gap: 6,
                                             rowGap: 0,
                                             pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0

                                         }}>
                                        <li style={{fontSize: 20}}>Duration in
                                            {dataStore.questionsMissing.includes('type'+item.name)
                                                ? <MissingReason
                                                    value={disease[diseaseIndex]?.duration?.type ? disease[diseaseIndex].duration.type : disease[diseaseIndex].duration.amount}
                                                    style={{right:60,marginTop:3}}
                                                    onSubmitMissing={async ()=>{
                                                        disease[diseaseIndex].duration = {
                                                            ...disease[diseaseIndex].duration,
                                                            type: dataStore.returnOtherTextMissing,
                                                            amount: dataStore.returnOtherTextMissing
                                                        }
                                                        treatmentPatternsStore.setQ16(
                                                            {
                                                                ...treatmentPatternsStore.q16,
                                                                disease: disease
                                                            }
                                                        )
                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('type'+item.name), 1)
                                                    }}/> : <></>}
                                            {dataStore.questionsMissing.includes('duration'+item.name)
                                                ? <MissingReason
                                                    value={disease[diseaseIndex]?.duration?.amount}
                                                    style={{right:60,marginTop:3}}
                                                    onSubmitMissing={async ()=>{
                                                        disease[diseaseIndex].duration = {
                                                            ...disease[diseaseIndex].duration,
                                                            amount: dataStore.returnOtherTextMissing
                                                        }
                                                        treatmentPatternsStore.setQ16(
                                                            {
                                                                ...treatmentPatternsStore.q16,
                                                                disease: disease
                                                            }
                                                        )
                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('duration'+item.name), 1)
                                                    }}/> : <></>}
                                        </li>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={(event) => {
                                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('type'+item.name), 1)

                                                disease[diseaseIndex].duration = {
                                                    ...disease[diseaseIndex].duration,
                                                    type: event.target.value
                                                }
                                                treatmentPatternsStore.setQ16(
                                                    {
                                                        ...treatmentPatternsStore.q16,
                                                        disease: disease
                                                    }
                                                )
                                            }}
                                            value={item?.duration?.type}
                                            sx={{display: 'flex', flexDirection: 'row', gap: 3}}
                                        >
                                            <RadioItem label={'Weeks'}/>
                                            <RadioItem label={'Days'}/>
                                        </RadioGroup>
                                        {item?.duration?.type && <Stack direction='row' sx={{alignItems: "center"}}>
                                            <Typography sx={{fontSize: 20, mr: 2}}>
                                                {item?.duration?.type && item.duration.type === 'Weeks' ? 'Number of Weeks' : 'Number of Days'}
                                            </Typography>
                                            <TextField
                                                size={'small'}
                                                type={"number"}
                                                onWheel={(e) => e.target.blur()}
                                                inputProps={{min: 0, style: {textAlign: 'center'}}}
                                                sx={{width: 100}}
                                                onChange={(event) => {
                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('duration'+item.name), 1)

                                                    disease[diseaseIndex].duration = {
                                                        ...disease[diseaseIndex].duration,
                                                        amount: event.target.value
                                                    }
                                                    treatmentPatternsStore.setQ16(
                                                        {
                                                            ...treatmentPatternsStore.q16,
                                                            disease: disease
                                                        }
                                                    )
                                                }}
                                                value={item?.duration?.amount && item.duration.amount.startsWith('missing|') ? '': item?.duration?.amount}

                                            />
                                        </Stack>}
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 6,
                                        rowGap: 0,
                                        mt: 2,
                                        pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0

                                    }}>
                                        <li style={{fontSize: 20}}>Frequency
                                            {dataStore.questionsMissing.includes('frequency'+item.name)
                                            ? <MissingReason
                                                    value={disease[diseaseIndex].frequency}
                                                style={{right:60,marginTop:3}}
                                                onSubmitMissing={async ()=>{
                                                    disease[diseaseIndex].frequency = dataStore.returnOtherTextMissing
                                                    treatmentPatternsStore.setQ16(
                                                        {
                                                            ...treatmentPatternsStore.q16,
                                                            disease: disease
                                                        }
                                                    )
                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('frequency'+item.name), 1)
                                                }}/> : <></>}
                                        </li>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={(event) => {
                                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('frequency'+item.name), 1)

                                                if (event.target.value === 'Other') {
                                                    setOtherOn(index)
                                                    item.frequency = ''
                                                } else {
                                                    setOtherOn(null)
                                                    disease[diseaseIndex].frequency = event.target.value
                                                    treatmentPatternsStore.setQ16(
                                                        {
                                                            ...treatmentPatternsStore.q16,
                                                            disease: disease
                                                        }
                                                    )
                                                }
                                            }}
                                            value={otherOn === index || (item?.frequency && item.frequency !== 'As needed' && item.frequency !== 'Every day'  && !item.frequency.startsWith('missing|'))
                                                ? 'Other' : item?.frequency}
                                            sx={{display: 'flex', flexDirection: 'row', gap: 3}}
                                        >
                                            <RadioItem label={'Every day'}/>
                                            <RadioItem label={'As needed'}/>
                                            <Stack direction={'row'} spacing={2}>
                                                <RadioItem label={'Other'}/>
                                                {otherOn === index ||
                                                (item?.frequency && item.frequency !== 'As needed' && item.frequency !== 'Every day' && !item.frequency.startsWith('missing|'))
                                                    ? <TextField
                                                        label="Specify Here"
                                                        name="otherTumorPage1"
                                                        sx={{width: '50%'}}
                                                        size={'small'}
                                                        onChange={(event) => {
                                                            setOtherOn(index)
                                                            disease[diseaseIndex].frequency = event.target.value
                                                            treatmentPatternsStore.setQ16(
                                                                {
                                                                    ...treatmentPatternsStore.q16,
                                                                    disease: disease
                                                                }
                                                            )
                                                        }}
                                                        value={item?.frequency && item.frequency.startsWith('missing|') ? '': item.frequency}

                                                    /> : <></>}
                                            </Stack>
                                        </RadioGroup>
                                    </Box>
                                </QuestionBox>
                            );
                        }

                    })
                    }
                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(ConcomitantMedicationsPage3);
