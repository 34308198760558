import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {RadioGroup, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import YesNo from "../../components/YesNo";
import QuestionText from "../../components/QuestionText";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import RadioItem from "../../components/RadioItem";
import QuestionsLayout from "../../layout/QuestionsLayout";
import MissingReason from "../../components/MissingReason";


const SubsequentTreatmentTumorBiologyPage3 = () => {
    const {
        treatmentPatternsStore, dataStore,menuStore
    } = useStores()
    const [showMissing, setShowMissing] = React.useState('not active');

    const navigate = useNavigate()
    let params = useParams();
    useEffect(() => {
        treatmentPatternsStore.getTreatment(parseInt(params.patient),parseInt(params.number))
    }, [])

    const handleNext = async () => {
        if (!treatmentPatternsStore.checkMissing15) {
            await treatmentPatternsStore.updateTreatment(
                {
                    s4: JSON.stringify(treatmentPatternsStore.s4),
                }, parseInt(params.number),parseInt(params.patient))
            if(dataStore.forceUpdate == true){
                dataStore.setShowUpdateModal('not active')
            }
            if(dataStore.showUpdateModal !== 'active'){
                navigate(`/timeDurationPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            }

        } else {
            alert('Please add reason for missing questions.')

            // if (!dataStore.globalMissing) {
            //     if (showMissing === 'active') {
            //         await treatmentPatternsStore.updateTreatment(
            //             {
            //                 s4: treatmentPatternsStore.s4?.main ? JSON.stringify(treatmentPatternsStore.s4) : dataStore.returnOtherObjectMissing,
            //             }, parseInt(params.number),parseInt(params.patient))
            //         navigate(`/timeDurationPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            //         setShowMissing('not active')
            //     } else {
            //         setShowMissing('active')
            //     }
            // } else {
            //     await treatmentPatternsStore.updateTreatment(
            //         {
            //             s4: treatmentPatternsStore.s4?.main ? JSON.stringify(treatmentPatternsStore.s4) : dataStore.returnOtherObjectMissing,
            //         }, parseInt(params.number),parseInt(params.patient))
            //     navigate(`/timeDurationPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            //     setShowMissing('not active')
            // }
        }
    }
    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={treatmentPatternsStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>

                    <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                        {`Subsequent Treatment (n = ${params.number})`}
                    </Typography>
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>
                    <Typography sx={{fontWeight: 'bold', fontSize: 30,}}>
                        Tumor biology at subsequent treatments
                    </Typography>
                    <QuestionBox>
                        <Stack direction={'row'} sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <QuestionText>
                                Surgical Specimen: Resection Grade

                            </QuestionText>
                            {dataStore.questionsMissing.includes('s4')
                                ? <MissingReason
                                    style={{marginLeft:360,marginTop:3}}
                                    onSubmitMissing={()=>{
                                        treatmentPatternsStore.setS4(
                                            {...treatmentPatternsStore.s4, main: dataStore.returnOtherTextMissing,})

                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('s4'), 1)
                                    }}/> : <></>}
                            <YesNo value={treatmentPatternsStore.s4.main}
                                   handleChange={(event) => {
                                       dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('s4'), 1)
                                       treatmentPatternsStore.setS4(
                                       {...treatmentPatternsStore.s4, main: event.target.value,})
                                   }}
                            />
                        </Stack>
                        {treatmentPatternsStore.s4.main === 'true' ? <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(event) => {
                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('s4type'), 1)

                                treatmentPatternsStore.setS4(
                                    {...treatmentPatternsStore.s4, type: event.target.value,}
                                )
                            }}
                            value={treatmentPatternsStore.s4.type}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: 3,
                                rowGap: 0
                            }}
                        >
                            <RadioItem label={'R0'}/>
                            <RadioItem label={'R1'}/>
                            <RadioItem label={'R2'}/>
                            {dataStore.questionsMissing.includes('s4type')
                                ? <MissingReason
                                    style={{marginLeft:270,marginTop:2}}
                                    onSubmitMissing={()=>{
                                        treatmentPatternsStore.setS4(
                                            {...treatmentPatternsStore.s4, type: dataStore.returnOtherTextMissing,}
                                        )
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('s4type'), 1)
                                    }}/> : <></>}
                        </RadioGroup> : <></>}

                    </QuestionBox>

                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(SubsequentTreatmentTumorBiologyPage3);
