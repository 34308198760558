import * as React from 'react';
import {Stack,} from "@mui/material";
import arrowRight from "../images/svgs/ArrowRight.svg";
import arrowLeft from "../images/svgs/ArrowLeft.svg";
import saveIcon from "../images/svgs/Save.svg";
import play from "../images/svgs/Play.svg";
import Box from "@mui/material/Box";
import theme from "../styles/theme";
import MyButton from "./MyButton";
import {useStores} from "../store";
import {observer} from "mobx-react";
import MissingQuestions from "../data/MissingQuestions";


const QuestionFooter = ({save, onClick, nextPress, backPress, sx,onSubmitMissing,showMissingForce}) => {
    const {
        dataStore
    } = useStores()

    return (
        <Stack direction={'row'}
               sx={{alignItems: 'center', justifyContent: 'space-between', display: 'flex', px: 8,py:2, ...sx}}>
            {backPress ? <Box onClick={backPress} style={{
                boxShadow: `3px 3px 15px ${theme.palette.primary.main}`,
                width: 50, height: 50,
                backgroundColor: theme.palette.primary.main, borderRadius: 25, display: 'flex',
                alignItems: 'center', justifyContent: 'center', cursor: 'pointer'
            }}>
                <img width={30} height={22} src={arrowLeft}/>
            </Box> : <div style={{width: 50, height: 50,}}/>}
            {showMissingForce === false ? <></> : dataStore.globalMissing === true ?
                <MyButton sx={{backgroundColor: '#333333', boxShadow: "none"}} label={'Missing Data'}
                          onClick={() => {
                              dataStore.setShowMissing(true)
                          }}/> : <></>}
            {onClick ? save ?
                    <MyButton iconStyle={{marginRight: 15}} icon={saveIcon} label={'Save'} onClick={onClick}/>
                    :
                    <MyButton iconStyle={{marginRight: 15}} icon={play} label={'Continue'} onClick={onClick}/>
                : <></>}

            {nextPress ? <Box onClick={nextPress} style={{
                boxShadow: `3px 3px 15px ${theme.palette.primary.main}`,
                width: 50,
                height: 50,
                cursor: 'pointer',
                backgroundColor: theme.palette.primary.main,
                borderRadius: 25,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <img width={30} height={22} src={arrowRight}/>
            </Box> : <div style={{width: 50, height: 50,}}/>}

            {dataStore.showMissing === true ? <MissingQuestions onClose={()=>{
                dataStore.setShowMissing(false)
            }} onSubmitMissing={onSubmitMissing}/> : <></>}
        </Stack>
    );
}

export default observer(QuestionFooter);
