import {action, makeObservable, observable,} from 'mobx'
import {CrfService} from "../../services/openapi";
import {Stores} from "../../store";


class SystemicTherapyStore {

    loading = false
    nNumber = 0
    q11 = {
        main: null,
        value: null,
    }

    q12 = {
        main: null,
        dose: '',
        numberOfFractions: '',
    }
    q13 = {
        main: null,
        type: '',
        dose: '',
        numberOfFractions: '',
    }
    q3CommonParams = {
        selected: null,
        dose: '',
        numberOfCyclesPlanned: '',
        numberOfCyclesActual: '',
        totalDurationInWeeks: '',
    }
    therapies = [
        {
            name: 'Cisplatin',
            params: this.q3CommonParams
        },
        {
            name: 'Etoposide',
            params: this.q3CommonParams
        },
        {
            name: 'Vinorelbine',
            params: this.q3CommonParams
        },
        {
            name: 'Pemetrexed',
            params: this.q3CommonParams
        },
        {
            name: 'Carboplatin',
            params: this.q3CommonParams
        },
        {
            name: 'Paclitaxel',
            params: this.q3CommonParams
        },
    ]
    q14 = {
        missingTherapiesReason:null,
        therapies: this.therapies,
    }
    q15 = {
        main: null,
        type:null,
        missingTherapiesReason: null,
        therapies: this.therapies,
    }
    q15a = {
        main: null,
        type:null,
        missingTherapiesReason: null,
        therapies: this.therapies,
    }
    q16 = null
    newNeoText = ''

    constructor() {
        makeObservable(this, {
            loading: observable,
            q11: observable,
            q15a: observable,
            q12: observable,
            q13: observable,
            q14: observable,
            q15: observable,
            q16: observable,
            nNumber: observable,
            newNeoText: observable,
            setQ11: action,
            setQ15a: action,
            setQ12: action,
            setQ13: action,
            setQ14: action,
            setQ15: action,
            setQ16: action,
            setLoading: action,
            updateTreatment: action,
            getTreatment: action,
            setNewNeoText: action,
            addNeo: action,
            reset: action,
            addNeo2: action,
            setNNumber: action,
        },)
    }

    setNNumber = (value)=>{
        this.nNumber = value
    }
    reset = () => {
        this.setQ15a({
            main: null,
            value: null,
        })
        this.setQ11({
            main: null,
            value: null,
        })
        this.setQ12({
            main: null,
            dose: '',
            numberOfFractions: '',
        })
        this.setQ13({
            main: null,
            type: '',
            dose: '',
            numberOfFractions: '',
        })
        this.setQ14({
            therapies: this.therapies,
        })
        this.setQ15({
            main: null,
            therapies: this.therapies,

        })
        this.setQ16(null)
    }


    get checkMissing1() {
        console.log('this.q11',this.q11)
        if(this.q11 == null || this.q11.main == null){
            Stores.dataStore.setQuestionsMissing('q11')
        }
        if(this.q11.main === 'true' &&  Stores.treatmentPatternsStore.q4.main === 'true' && this.q11.value == null){
            Stores.dataStore.setQuestionsMissing('q11value')
        }


         if(this.q11 == null || this.q11.main == null){
             return true
        }else if(this.q11.main === 'true' &&  Stores.treatmentPatternsStore.q4.main === 'true' && this.q11.value == null){
             return true
         }
    }

    get checkMissing2() {
        if(this.q13.main === null && this.q12.main === null){
            Stores.dataStore.setQuestionsMissing('q12')
        }
        if(this.q12.main === null && this.q13.main == null){
            Stores.dataStore.setQuestionsMissing('q13')
        }

        if(this.q12.main != null && !this.q12.dose){
            Stores.dataStore.setQuestionsMissing('q12dose')
        }

        if(this.q12.main != null && !this.q12.numberOfFractions){
            Stores.dataStore.setQuestionsMissing('q12numberOfFractions')
        }
        if(this.q13.main != null && (this.q13.type && !this.q13.type.startsWith('missing|')) && !this.q13.dose){
            Stores.dataStore.setQuestionsMissing('q13dose')
        }
        if(this.q13.main != null && !this.q13.type){
            Stores.dataStore.setQuestionsMissing('q13type')
        }
        if(this.q13.main != null && (this.q13.type && !this.q13.type.startsWith('missing|')) && !this.q13.numberOfFractions){
            Stores.dataStore.setQuestionsMissing('q13numberOfFractions')
        }


        if ((this.q12?.main == null && this.q13?.main == null) ||
            ((this.q12.main != null && !this.q12.main.startsWith('missing|')) && (!this.q12.dose || !this.q12.numberOfFractions))
            || (this.q13.main != null && (this.q13.type && !this.q13.type.startsWith('missing|')) && !this.q13.dose)
            || (this.q13.main != null && !this.q13.type)
            || (this.q13.main != null && (this.q13.type && !this.q13.type.startsWith('missing|')) && !this.q13.numberOfFractions)
            ){
            return true
        }
    }

    get checkMissing3() {
        console.log('this.q14',this.q14)
        if (this.q14 == null) {
            Stores.dataStore.setQuestionsMissing('q14')
        }
        if((!this.q14?.missingTherapiesReason || this.q14.missingTherapiesReason === null) &&  !this.q14.therapies.find((t) => t.params.selected != null)){
            Stores.dataStore.setQuestionsMissing('SystemTherapies')
        }
        for(const therapy of this.q14.therapies){
            if(therapy.params.selected != null){
                Stores.dataStore.setQuestionsMissing(therapy.name)

            }
            if(therapy.params.selected != null && therapy.params.dose == ''){
                Stores.dataStore.setQuestionsMissing(therapy.name + 'dose')
            }
            if(therapy.params.selected != null && therapy.params.numberOfCyclesPlanned == ''){
                Stores.dataStore.setQuestionsMissing(therapy.name + 'numberOfCyclesPlanned')
            }
            if(therapy.params.selected != null && therapy.params.numberOfCyclesActual == ''){
                Stores.dataStore.setQuestionsMissing(therapy.name + 'numberOfCyclesActual')
            }
            if(therapy.params.selected != null && therapy.params.totalDurationInWeeks == ''){
                Stores.dataStore.setQuestionsMissing(therapy.name + 'totalDurationInWeeks')
            }
        }
        if(
            ((!this.q14?.missingTherapiesReason || this.q14.missingTherapiesReason === null) &&
                !this.q14.therapies.find((t) => t.params.selected != null)) ||
            (this.q14.therapies.find((t) => t.params.selected != null &&
                (t.params.dose == '' || t.params.numberOfCyclesPlanned == '' || t.params.numberOfCyclesActual == '' || t.params.totalDurationInWeeks == '')))){
            return true
        }
    }

    get checkMissing4() {
        console.log('this.q15',this.q15)
        if (this.q15 == null || this.q15.main == null) {
            Stores.dataStore.setQuestionsMissing('q15')
        }

        if(this.q15.main == 'true' && Stores.treatmentPatternsStore.q4.main === 'true' && this.q15.type == null ){
            Stores.dataStore.setQuestionsMissing('q15type')
        }

        if(this.q15.main == 'true' && (!this.q15?.missingTherapiesReason || this.q15.missingTherapiesReason === null)  &&
            !this.q15.therapies.find((t) => t.params.selected != null)){
            Stores.dataStore.setQuestionsMissing('therapies')
        }

        for(const therapy of this.q15.therapies){
            if(therapy.params.selected != null){
                Stores.dataStore.setQuestionsMissing(therapy.name)
            }
            if(therapy.params.selected != null && therapy.params.dose == ''){
                Stores.dataStore.setQuestionsMissing(therapy.name + 'dose')
            }
            if(therapy.params.selected != null && therapy.params.numberOfCyclesPlanned == ''){
                Stores.dataStore.setQuestionsMissing(therapy.name + 'numberOfCyclesPlanned')
            }
            if(therapy.params.selected != null && therapy.params.numberOfCyclesActual == ''){
                Stores.dataStore.setQuestionsMissing(therapy.name + 'numberOfCyclesActual')
            }
            if(therapy.params.selected != null && therapy.params.totalDurationInWeeks == ''){
                Stores.dataStore.setQuestionsMissing(therapy.name + 'totalDurationInWeeks')
            }
        }

        if((this.q15 == null || this.q15.main == null)
            ||
            (this.q15.main == 'true' && (!this.q15?.missingTherapiesReason || this.q15.missingTherapiesReason === null) &&
                !this.q15.therapies.find((t) => t.params.selected != null)) ||
            (this.q15.main == 'true' && this.nNumber > 0 && Stores.treatmentPatternsStore.q4.main === 'true' && this.q15.type == null ) ||
            (this.q15.therapies.find((t) => t.params.selected != null &&
                (t.params.dose == '' || t.params.numberOfCyclesPlanned == '' || t.params.numberOfCyclesActual == '' || t.params.totalDurationInWeeks == '')))){
            return true
        }
    }
    get checkMissing4a() {
        console.log('this.q15',this.q15a)
        if (this.q15a == null || this.q15a.main == null) {
            Stores.dataStore.setQuestionsMissing('q15a')
        }

        if(this.q15a.main == 'true' && Stores.treatmentPatternsStore.q4.main === 'true' && this.q15a.type == null ){
            Stores.dataStore.setQuestionsMissing('q15atype')
        }

        if(this.q15a.main == 'true' && (!this.q15a?.missingTherapiesReason || this.q15a.missingTherapiesReason === null)  &&
            !this.q15a.therapies.find((t) => t.params.selected != null)){
            Stores.dataStore.setQuestionsMissing('therapies')
        }

        for(const therapy of this.q15a.therapies){
            if(therapy.params.selected != null){
                Stores.dataStore.setQuestionsMissing(therapy.name)
            }
            if(therapy.params.selected != null && therapy.params.dose == ''){
                Stores.dataStore.setQuestionsMissing(therapy.name + 'dose')
            }
            if(therapy.params.selected != null && therapy.params.numberOfCyclesPlanned == ''){
                Stores.dataStore.setQuestionsMissing(therapy.name + 'numberOfCyclesPlanned')
            }
            if(therapy.params.selected != null && therapy.params.numberOfCyclesActual == ''){
                Stores.dataStore.setQuestionsMissing(therapy.name + 'numberOfCyclesActual')
            }
            if(therapy.params.selected != null && therapy.params.totalDurationInWeeks == ''){
                Stores.dataStore.setQuestionsMissing(therapy.name + 'totalDurationInWeeks')
            }
        }

        if((this.q15a == null || this.q15a.main == null)
            ||
            (this.q15a.main == 'true' && (!this.q15a?.missingTherapiesReason || this.q15a.missingTherapiesReason === null) &&
                !this.q15a.therapies.find((t) => t.params.selected != null)) ||
            (this.q15a.main == 'true' && this.nNumber > 0 && Stores.treatmentPatternsStore.q4.main === 'true' && this.q15a.type == null ) ||
            (this.q15a.therapies.find((t) => t.params.selected != null &&
                (t.params.dose == '' || t.params.numberOfCyclesPlanned == '' || t.params.numberOfCyclesActual == '' || t.params.totalDurationInWeeks == '')))){
            return true
        }
    }

    setNewNeoText = (value) => {
        this.newNeoText = value
    }
    setQ15a = (value) => {
        this.q15a = value
    }
    setQ11 = (value) => {
        this.q11 = value
    }

    setQ12 = (value) => {
        this.q12 = value
    }
    setQ13 = (value) => {
        this.q13 = value
    }
    setQ14 = (value) => {
        this.q14 = value
    }
    setQ15 = (value) => {
        this.q15 = value
    }
    setQ16 = (value) => {
        this.q16 = value
    }


    setLoading = (value) => {
        this.loading = value
    }

    updateTreatment = async (requestBody, number,patient) => {
        try {
            this.setLoading(true)
            console.log('requestBody', requestBody)
            let params = {...requestBody,force:Stores.dataStore.forceUpdate}

            if(Stores.dataStore.updateReason !== ''){
                params = {...params, reason:Stores.dataStore.updateReason}
            }
            console.log('updateSocioDemographic params', params)
            const response = await CrfService.updateTreatment(patient, number, params)
            console.log('updateTreatment response', response)

            if (response.q11 != null) {
                this.setQ11(response.q11)
            }
            if (response.q12 != null) {
                this.setQ12(response.q12)
            }
            if (response.q13 != null) {
                this.setQ13(response.q13)
            }
            if (response.q14 != null) {
                this.setQ14(response.q14)
            }
            if (response.q15 != null) {
                this.setQ15(response.q15)
            }
            if (response.q15a != null) {
                this.setQ15a(response.q15a)
            }
            this.setLoading(false)

        } catch (err) {
            this.setLoading(false)
            console.log('updateTreatment err', err)
            if(err?.body?.data?.updated){
                Stores.dataStore.setShowUpdateModal('active')
            }else {
                Stores.dataStore.setShowUpdateModal('not active')
            }
        }
    }


    getTreatment = async (patient,number) => {
        try {
            this.setLoading(true)

            const response = await CrfService.getTreatment(patient, number)
            console.log('getTreatment response', response)

            if (response.q11 != null) {
                this.setQ11(response.q11)
                if(response.q11?.main && response.q11.main.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q11')
                }
                if(response.q11?.value && response.q11.value.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q11value')
                }
            }
            if (response.q12 != null) {
                this.setQ12(response.q12)
                if(response.q12?.main && response.q12.main.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q12')
                }
                if(response.q12?.dose && response.q12.dose.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q12dose')
                }
                if(response.q12?.numberOfFractions && response.q12.numberOfFractions.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q12numberOfFractions')
                }

            }
            if (response.q13 != null) {
                this.setQ13(response.q13)
                if(response.q13?.main && response.q13.main.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q13')
                }
                if(response.q13?.dose && response.q13.dose.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q13dose')
                }
                if(response.q13?.type && response.q13.type.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q13type')
                }
                if(response.q13?.numberOfFractions && response.q13.numberOfFractions.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q13numberOfFractions')
                }
            }
            if (response.q14 != null) {
                this.setQ14(response.q14)
                if(response.q14?.missingTherapiesReason && response.q14.missingTherapiesReason.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('SystemTherapies')
                }
                if(response.q14?.therapies && response.q14.therapies.length > 0){
                    for(const therapy of response.q14.therapies){
                        if(therapy.params?.dose && therapy.params.dose.startsWith('missing|')){
                            Stores.dataStore.setQuestionsMissing(therapy.name + 'dose')
                        }
                        if(therapy.params?.numberOfCyclesPlanned && therapy.params.numberOfCyclesPlanned.startsWith('missing|')){
                            Stores.dataStore.setQuestionsMissing(therapy.name + 'numberOfCyclesPlanned')
                        }
                        if(therapy.params?.numberOfCyclesActual && therapy.params.numberOfCyclesActual.startsWith('missing|')){
                            Stores.dataStore.setQuestionsMissing(therapy.name + 'numberOfCyclesActual')
                        }
                        if(therapy.params?.totalDurationInWeeks && therapy.params.totalDurationInWeeks.startsWith('missing|')){
                            Stores.dataStore.setQuestionsMissing(therapy.name + 'totalDurationInWeeks')
                        }
                    }
                }
            }
            if (response.q15 != null) {
                this.setQ15(response.q15)
                if (response.q15?.main && response.q15.main.startsWith('missing|')) {
                    Stores.dataStore.setQuestionsMissing('q15')
                }

                if(response.q15?.type && response.q15.type.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q15type')
                }

                if(response.q15?.missingTherapiesReason && response.q15.missingTherapiesReason.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('therapies')
                }
                if (response.q15.therapies && response.q15.therapies.length > 0) {
                    for (const therapy of response.q15.therapies) {
                        if (therapy.params?.dose && therapy.params.dose.startsWith('missing|')) {
                            Stores.dataStore.setQuestionsMissing(therapy.name + 'dose')
                        }
                        if (therapy.params?.numberOfCyclesPlanned && therapy.params.numberOfCyclesPlanned.startsWith('missing|')) {
                            Stores.dataStore.setQuestionsMissing(therapy.name + 'numberOfCyclesPlanned')
                        }
                        if (therapy.params?.numberOfCyclesActual && therapy.params.numberOfCyclesActual.startsWith('missing|')) {
                            Stores.dataStore.setQuestionsMissing(therapy.name + 'numberOfCyclesActual')
                        }
                        if (therapy.params?.totalDurationInWeeks && therapy.params.totalDurationInWeeks.startsWith('missing|')) {
                            Stores.dataStore.setQuestionsMissing(therapy.name + 'totalDurationInWeeks')
                        }
                    }
                }

            }

            if (response.q15a != null) {
                this.setQ15(response.q15a)
                if (response.q15a?.main && response.q15a.main.startsWith('missing|')) {
                    Stores.dataStore.setQuestionsMissing('q15a')
                }

                if(response.q15a?.type && response.q15a.type.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q15atype')
                }

                if(response.q15a?.missingTherapiesReason && response.q15a.missingTherapiesReason.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('therapies')
                }
                if (response.q15a.therapies && response.q15a.therapies.length > 0) {
                    for (const therapy of response.q15a.therapies) {
                        if (therapy.params?.dose && therapy.params.dose.startsWith('missing|')) {
                            Stores.dataStore.setQuestionsMissing(therapy.name + 'dose')
                        }
                        if (therapy.params?.numberOfCyclesPlanned && therapy.params.numberOfCyclesPlanned.startsWith('missing|')) {
                            Stores.dataStore.setQuestionsMissing(therapy.name + 'numberOfCyclesPlanned')
                        }
                        if (therapy.params?.numberOfCyclesActual && therapy.params.numberOfCyclesActual.startsWith('missing|')) {
                            Stores.dataStore.setQuestionsMissing(therapy.name + 'numberOfCyclesActual')
                        }
                        if (therapy.params?.totalDurationInWeeks && therapy.params.totalDurationInWeeks.startsWith('missing|')) {
                            Stores.dataStore.setQuestionsMissing(therapy.name + 'totalDurationInWeeks')
                        }
                    }
                }

            }

            Stores.dataStore.setForceUpdate(false)


            this.setLoading(false)

        } catch (err) {
            this.setLoading(false)
            console.log('getTreatment err', err)
        }
    }

    addNeo = async () => {

        if(this.q14.therapies.find((t)=>t.name == this.newNeoText)){
            alert('You are trying to add duplicate value.')
            this.setNewNeoText('')
        }else{
            this.q14.therapies.push({name: this.newNeoText, params: {...this.q3CommonParams, selected: 'on'}})
            this.setNewNeoText('')
        }
    }
    addNeo2 = async () => {

        if(this.q15.therapies.find((t)=>t.name == this.newNeoText)){
            alert('You are trying to add duplicate value.')
            this.setNewNeoText('')
        }else{
            this.q15.therapies.push({name: this.newNeoText, params: {...this.q3CommonParams, selected: 'on'}})
            this.setNewNeoText('')
        }
    }
    addNeo2a = async () => {

        if(this.q15a.therapies.find((t)=>t.name == this.newNeoText)){
            alert('You are trying to add duplicate value.')
            this.setNewNeoText('')
        }else{
            this.q15a.therapies.push({name: this.newNeoText, params: {...this.q3CommonParams, selected: 'on'}})
            this.setNewNeoText('')
        }
    }

}


export default SystemicTherapyStore
