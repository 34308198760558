import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import backImage from "../images/backImage.png";

const Error404 = () => {
    return (
        <Box sx={{
            backgroundImage: `url(${backImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100vh',
        }}
        >
            <Typography component="h2" variant="h2"
                        sx={{fontWeight: 'bold', color: 'white', px: 12}}>
                404
            </Typography>
            <Typography component="h4" variant="h4" sx={{fontWeight: 'bold', color: 'white', px: 12}}>
                Oops! Page not found
            </Typography>
        </Box>
    );
}

export default Error404;
