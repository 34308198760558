import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const RenderTableHeader = ({colDef}) => {

    return (
        <Box>
            <Typography color={'primary'} sx={{fontSize: 23, fontWeight: 'bold'}}>{colDef.headerName}</Typography>
        </Box>
    );
}

export default RenderTableHeader;
