import {action, makeObservable, observable,} from 'mobx'
import {OpenAPI, UserService} from "../services/openapi";


class MyAccountStore {

    name = ''
    email = ''
    phone = ''
    firstname = ''
    lastname = ''
    oldPassword = ''
    newPassword = ''
    newEmail = ''
    confirmPassword = ''
    loading = false
    passwordResetAlert = false

    constructor() {

        makeObservable(this, {
            name: observable,
            email: observable,
            phone: observable,
            firstname: observable,
            lastname: observable,
            oldPassword: observable,
            newPassword: observable,
            newEmail: observable,
            confirmPassword: observable,
            loading: observable,
            passwordResetAlert: observable,


            setName: action,
            setEmail: action,
            setPhone: action,
            setFirstname: action,
            setLastname: action,
            setOldPassword: action,
            setNewPassword: action,
            setNewEmail: action,
            setConfirmPassword: action,
            getMyAccount: action,
            setLoading: action,
            changePasswordRequest: action,
            setPasswordResetAlert: action,

        },)
    }

    setPasswordResetAlert = (value) => {
        this.passwordResetAlert = value
    }
    setLoading = (value) => {
        this.loading = value
    }
    setConfirmPassword = (value) => {
        this.confirmPassword = value
    }
    setNewPassword = (value) => {
        this.newPassword = value
    }
    setNewEmail = (value) => {
        this.newEmail = value
    }
    setOldPassword = (value) => {
        this.oldPassword = value
    }
    setName = (value) => {
        this.name = value
    }

    setEmail = (value) => {
        this.email = value
    }

    setPhone = (value) => {
        this.phone = value
    }

    setFirstname(value) {
        this.firstname = value;
    }

    setLastname(value) {
        this.lastname = value;
    }

    getMyAccount = async () => {
        try {
            this.setLoading(true)
            const response = await UserService.getMe()
            console.log('getMyAccount response', response)

            if (response) {
                this.setName(`${response.first_name} ${response.last_name}`)
                this.setFirstname(response.first_name)
                this.setLastname(response.last_name)
                this.setEmail(response.email)
                this.setPhone(response.phone)
            }

            this.setLoading(false)

        } catch (err) {
            this.setLoading(false)
            console.log('getMyAccount err', err)
            //todo handle error Show Error @aidin
        }
    }

    changePasswordRequest = async () => {
        try {
            this.setLoading(true)
            let params = {
                token: OpenAPI.TOKEN,
                password: this.newPassword,
            }
            const response = await UserService.changePassword(params)
            console.log('changePasswordRequest response', response)
            this.setLoading(false)

        } catch (err) {
            console.log('changePasswordRequest err', err)
            this.setLoading(false)
        }
    }

    changeEmailRequest = async () => {
        try {
            this.setLoading(true)
            const response = await UserService.updateMe({email: this.newEmail})
            console.log('changeEmailRequest response', response)
            this.setLoading(false)
            this.setEmail(response.email)
        } catch (err) {
            console.log('changeEmailRequest err', err)
            this.setLoading(false)
        }
    }

    changeNameRequest = async () => {
        try {
            this.setLoading(true)
            const response = await UserService.updateMe({first_name: this.firstname, last_name: this.lastname})
            console.log('changeNameRequest response', response)
            this.setLoading(false)
            this.setName(`${response.first_name} ${response.last_name}`)
        } catch (err) {
            console.log('changeNameRequest err', err)
            this.setLoading(false)
        }
    }
}


export default MyAccountStore
