import {createTheme, responsiveFontSizes} from "@mui/material/styles";

// Create a theme instance.
let theme = createTheme({
    palette: {
        primary: {main: "#22B570"},
        secondary: {main: "#9AD3B4"},
        white: "#ffffff",
        txtGrey: "#737373",
        disable: "#737373",
        action: {
            disabledBackground: '#d7d7d7',
            disabled: '#99d5b3',
        }
    },
    typography: {
        fontFamily: [
            "\"IBM Plex Sans\"",
            "sans-serif"
        ].join(",")
    },
    action: {
        disabledBackground: '#B9B9B9',
        disabled: '#ffffff'
    },
    components: {
        // Name of the component
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    fontSize: '1.5rem',
                },
            },
        },
    },
});
theme = responsiveFontSizes(theme);
export default theme;
