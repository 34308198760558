import {useStores,} from '../store'
import * as React from 'react';
import {observer} from "mobx-react";
import MyButton from "../components/MyButton";
import {MenuItem, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import AlertModal from "../components/AlertModal";
import Box from "@mui/material/Box";
import Layout from "../layout/Layout";


const CreateX2Delegate = () => {
    const {
        authStore
    } = useStores()


    const navigate = useNavigate()


    return (
        <Layout activeItem={'delegates'} loading={authStore.loading}>
            <Box sx={{
                p: 8,
                display: "flex",
                flexDirection: "column",
                maxWidth: 600,
                margin: "auto",
                alignItems: "center",
                mt: 12
            }}>
                <Typography component={'h1'} variant={'h3'} color={'primary'} sx={{fontWeight: 'bold', mb: 4}}>
                    Create X2 Delegate
                </Typography>
                <TextField
                    autoFocus
                    margin="normal"
                    id="firstName"
                    label="First Name"
                    fullWidth
                    value={authStore.delegateFirstName}
                    onChange={(event) => authStore.setDelegateFirstName(event.currentTarget.value)}

                />
                <TextField
                    margin="normal"
                    id="lastName"
                    label="Last Name"
                    fullWidth
                    value={authStore.delegateLastName}
                    onChange={(event) => authStore.setDelegateLastName(event.currentTarget.value)}

                />
                <TextField
                    margin="normal"
                    id="email"
                    label="Email"
                    fullWidth
                    value={authStore.delegateEmail}
                    onChange={(event) => authStore.setDelegateEmail(event.currentTarget.value)}/>
                <TextField
                    margin="normal"
                    id="outlined-select-currency"
                    select
                    label="Select"
                    onChange={(event) => authStore.setCenter(event.target.value)}
                    fullWidth
                >
                    {authStore.centers.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    ))}
                </TextField>
                <MyButton sx={{mt: 4, px: 12}} label={'Invite'}
                          onClick={async () => await authStore.createX2Delegate()}/>
            </Box>
            <AlertModal open={authStore.openSuccessInvitationAlert}
                        text={'The Invitation Sent to user email with registration link.'}
                        onClose={() => {
                            authStore.setOpenSuccessInvitationAlert(false)
                            navigate(-1)
                        }}
                        title={'Succeed!'}/>
            <AlertModal
                open={authStore.showErrMessage}
                onClose={() => {
                    authStore.setShowErrMessage(false)
                    authStore.setErrorMessage('')
                }}
                text={authStore.errorMessage}
                title={'Error!'}/>
        </Layout>
    );
}

export default observer(CreateX2Delegate);
