import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {RadioGroup, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import YesNo from "../../components/YesNo";
import QuestionText from "../../components/QuestionText";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import RadioItem from "../../components/RadioItem";
import TextField from "@mui/material/TextField";
import QuestionsLayout from "../../layout/QuestionsLayout";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Required from "../../components/Required";

const TumorBiologyPage1 = () => {
    const {
        tumorBiologyStore, dataStore, menuStore
    } = useStores()
    const [openOther, setOpenOther] = React.useState(false);
    const [newOther, setNewOther] = React.useState('');

    let urlParams = useParams();

    const navigate = useNavigate()

    useEffect(() => {
        tumorBiologyStore.getTumorBiology(parseInt(urlParams.patient))
    }, [])
    const [showMissing, setShowMissing] = React.useState('not active');

    const handleNext = async () => {
        // if (!tumorBiologyStore.checkMissing1) {
            if(tumorBiologyStore.checkTumorS1) {
                alert('Please answer all required questions.')
            }else{
                await tumorBiologyStore.updateTumorBiology(
                    {
                        q1: tumorBiologyStore.q1,
                        q2: JSON.stringify(tumorBiologyStore.q2),
                    },parseInt(urlParams.patient))
                if(dataStore.forceUpdate == true){
                    dataStore.setShowUpdateModal('not active')
                }
                if(dataStore.showUpdateModal !== 'active'){
                    navigate('/tumorBiologyPage2/'+parseInt(urlParams.patient))
                }
            }
        // } else {
        //     if (!dataStore.globalMissing) {
        //         if (showMissing === 'active') {
        //             await tumorBiologyStore.updateTumorBiology(
        //                 {
        //                     q1: tumorBiologyStore.q1 ? tumorBiologyStore.q1 : dataStore.returnOtherTextMissing,
        //                     q2: tumorBiologyStore.q2?.main ? JSON.stringify(tumorBiologyStore.q2) : dataStore.returnOtherObjectMissing,
        //                 },parseInt(urlParams.patient))
        //             navigate('/tumorBiologyPage2/'+parseInt(urlParams.patient))
        //             setShowMissing('not active')
        //         } else {
        //             setShowMissing('active')
        //         }
        //     } else {
        //         await tumorBiologyStore.updateTumorBiology(
        //             {
        //                 q1: tumorBiologyStore.q1 ? tumorBiologyStore.q1 : dataStore.returnOtherTextMissing,
        //                 q2: tumorBiologyStore.q2?.main ? JSON.stringify(tumorBiologyStore.q2) : dataStore.returnOtherObjectMissing,
        //             }, parseInt(urlParams.patient))
        //         navigate('/tumorBiologyPage2/' + parseInt(urlParams.patient))
        //     }
        // }
    }
    let splited = tumorBiologyStore.q1.split("|");
    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={tumorBiologyStore.loading}>
            <Box sx={{width: '100%', px: 4, mb: 0, mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>

                    <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32, lineHeight: 1.2}}>
                        Tumor biology at diagnosis
                    </Typography>
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>

                    <QuestionBox>
                        <QuestionText  required={true}>
                            Histology <Required/>
                        </QuestionText>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(event) => {
                                tumorBiologyStore.setQ1(
                                    event.target.value
                                )
                                if (event.target.value === 'other') {
                                    setOpenOther(true)
                                } else {
                                    setOpenOther(false)
                                }
                                setNewOther('')
                            }}
                            value={splited[0]}
                            sx={{display: 'flex', flexDirection: 'column'}}
                        >
                            <Stack direction={'row'} spacing={5} sx={{width: '100%',}}>
                                <RadioItem label={'squamous cell carcinoma'}/>
                                <RadioItem label={'adenocarcinoma in situ (AIS)'}/>
                            </Stack>
                            <RadioItem label={'minimally invasive adenocarcinoma (MIA)'}/>
                            <Stack direction={'row'} spacing={2}>
                                <RadioItem label={'other'}/>
                                {openOther || splited[0] === 'other' ?
                                    <Box sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "flex-end",
                                        alignSelf: "flex-end",
                                        width: "70%",
                                        maxWidth: 500,
                                    }}>
                                        <TextField
                                            size={'small'}
                                            label="Specify Here"
                                            margin="normal"
                                            name="otherTumorPage1"
                                            sx={{width: '100%', mt: 1}}
                                            onChange={(event) => {
                                                setNewOther(event.target.value)
                                            }}
                                            value={newOther}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{
                                                ml: 1,
                                                fontSize: 12,
                                                color: "white",
                                                height: 40,
                                                mt: 1,
                                                mb: 1,
                                                p: 0
                                            }}
                                            onClick={() => {
                                                if (newOther === '') {
                                                    return
                                                }
                                                let others = [];
                                                if (splited.length > 1 && splited[1] !== '') {
                                                    others = splited[1].split(",");
                                                }

                                                if(others.find((t)=>t == newOther)){
                                                    alert('You are trying to add duplicate value.')
                                                    setNewOther('');
                                                }else {
                                                    others.push(newOther)
                                                    tumorBiologyStore.setQ1(`other|${others.join(",")}`)
                                                    setNewOther('');
                                                }
                                            }}>
                                            Add
                                        </Button>
                                    </Box>
                                    : <></>}
                            </Stack>
                        </RadioGroup>

                        <Box sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row-reverse",
                            flexWrap: "wrap",
                            rowGap: 1,
                            mt: 1
                        }}>
                            {splited.length > 1 && splited[1] !== '' && splited[1].split(",").map((item, index) =>
                                <Box key={"OTHER__" + index}
                                     sx={{
                                         minWidth: 150,
                                         pl: 1,
                                     }}>
                                    <Paper sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flex: 1,
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        p: 1,
                                        pl: 2
                                    }}>
                                        <Typography sx={{mr: 1}}>
                                            {item}
                                        </Typography>
                                        <IconButton onClick={() => {
                                            let others = splited[1].split(",");
                                            others.splice(index, 1)
                                            tumorBiologyStore.setQ1(`other|${others.join(",")}`)
                                        }}>
                                            <DeleteIcon color="error"/>
                                        </IconButton>
                                    </Paper>
                                </Box>)}
                        </Box>
                    </QuestionBox>
                    <QuestionBox>
                        <QuestionText>
                            Biomarkers Testing <Required/>
                        </QuestionText>
                        <Stack direction={'row'} sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <Typography sx={{fontSize: 20}}>
                                PD-L1 expression levels test performed?
                            </Typography>
                            <YesNo value={tumorBiologyStore.q2.main}
                                   handleChange={(event) => {
                                       if (event.target.value === 'true') {
                                           tumorBiologyStore.setQ2(
                                               {...tumorBiologyStore.q2, main: event.target.value,}
                                           )
                                       }else{
                                           tumorBiologyStore.setQ2(
                                               {...tumorBiologyStore.q2, main: event.target.value,method: null,
                                                   levels: null}
                                           )
                                       }

                                   }}
                            />
                        </Stack>
                        {tumorBiologyStore.q2.main === 'true' ? <QuestionText width={'100%'}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                width: '100%',
                                alignItems: 'center',
                                pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0
                            }}>
                                <li>Method</li>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={tumorBiologyStore.q2.method}
                                    onChange={(event) => tumorBiologyStore.setQ2(
                                        {...tumorBiologyStore.q2, method: event.target.value,}
                                    )}
                                    sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around',ml:5,width:'80%'}}
                                >
                                    <RadioItem label={'TPS using 22C3 PharmDx'}/>
                                    <RadioItem label={'Other'}/>
                                    <RadioItem label={'Unknown'}/>
                                </RadioGroup>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                width: '100%',
                                alignItems: 'center',
                                pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0

                            }}>
                                <li>Levels</li>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={tumorBiologyStore.q2.levels}
                                    onChange={(event) => tumorBiologyStore.setQ2(
                                        {...tumorBiologyStore.q2, levels: event.target.value,}
                                    )}
                                    sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around',ml:5,width:'80%',pr:19}}
                                >
                                    <RadioItem label={'< 1%'}/>
                                    <RadioItem label={'1-49%'}/>
                                    <RadioItem label={'≥ 50%'}/>
                                </RadioGroup>
                            </Box>
                        </QuestionText> : <div/>}
                    </QuestionBox>
                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(TumorBiologyPage1);
