import {Backdrop, Stack} from "@mui/material";

import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import MyButton from "./MyButton";
import {useStores} from "../store";
import Countdown, {zeroPad} from "react-countdown";

const IdleConfirmation = ({idle, setIdle}) => {
    const navigate = useNavigate()
    const {authStore} = useStores()


    useEffect(() => {
        console.log("idle effect");
        if (idle === true) {
            const interval = setInterval(() => {
                console.log("idle redirect after 60 seconds");
                handleCancel();
            }, [60000]);
            return () => {
                clearInterval(interval);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idle]);

    const handleCancel = async () => {
        setIdle(false);
        await authStore.logout()
        navigate('/')
    };

    return (<Backdrop open={true}>
        <Paper sx={{p: 4}}>
            <Typography align="center" sx={{mb: 1}}>
                System has been Idle for 15 minutes
            </Typography>
            <Typography variant="h6" align="center" sx={{fontWeight: "bold", mb: 1,}}>
                Do you want to continue?
            </Typography>
            <Countdown date={Date.now() + 60000} renderer={({minutes, seconds}) => <Typography
                align="center" sx={{mb: 2, fontWeight: "bold",}}>{zeroPad(minutes)}:{zeroPad(seconds)}</Typography>}/>
            <Stack direction="row" spacing={2} sx={{justifyContent: "center"}}>
                <MyButton sx={{backgroundColor: '#333333', boxShadow: "none", '&:hover': {backgroundColor: '#666'}}}
                          disabled={false}
                          label="No"
                          onClick={handleCancel}/>
                <MyButton
                    disabled={false}
                    label="Yes"
                    onClick={() => setIdle(false)}
                />
            </Stack>
        </Paper>
    </Backdrop>);
};

export default IdleConfirmation;
