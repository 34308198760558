import {useStores} from "../store";
import {Navigate, useParams} from "react-router-dom";
import {OpenAPI} from "../services/openapi";
import * as React from "react";
import {useIdleTimer} from "react-idle-timer";
import IdleConfirmation from "./IdleConfirmation";

export const ProtectedRoute = ({children}) => {
    const {
        authStore, dataStore
    } = useStores()
    const [idle, setIdle] = React.useState(false);
    const onIdle = () => {
        setIdle(true);
    };

    useIdleTimer({
        onIdle,
        timeout: 1000 * 60 * 15,
        promptTimeout: 0,
        events: [
            "mousemove",
            "keydown",
            "wheel",
            "DOMMouseScroll",
            "mousewheel",
            "mousedown",
            "touchstart",
            "touchmove",
            "MSPointerDown",
            "MSPointerMove",
            "visibilitychange",
        ],
        immediateEvents: [],
        debounce: 0,
        throttle: 0,
        eventsThrottle: 200,
        element: document,
        startOnMount: true,
        startManually: false,
        stopOnIdle: false,
        crossTab: false,
        name: "idle-timer",
        syncTimers: 0,
        leaderElection: false,
    });

    let urlParams = useParams();

    let type = sessionStorage.getItem('role')
    let token = sessionStorage.getItem('token',)
    console.log('authStore.userType==>', authStore.userType)
    console.log('type==>', type)
    console.log('token==>', token)

    if (authStore.userType == null) {
        if (token && type) {
            console.log('type==>', type)
            authStore.setUerTypeText(type)
            OpenAPI.TOKEN = token
            console.log('ataStore.selectedPatient', dataStore.selectedPatient)
            if (dataStore.selectedPatient === null || dataStore.selectedPatient === undefined) {
                dataStore.getPatient(parseInt(urlParams.patient))
            }
        } else {
            return <Navigate to="/"/>;
        }
    }
    return <>
        {children}
        {idle && (
            <IdleConfirmation key={idle} idle={idle} setIdle={setIdle}/>
        )}
    </>;
};
