import {action, makeObservable, observable, } from 'mobx'
import {CrfService} from "../../services/openapi";
import {Stores} from "../../store";


class TumorBiologyStore {

    loading = false
    q1 = ''
    q2 = {
        main: null,
        method: null,
        levels: null
    }
    q3 = {
        main: null,
        method: [],
        missingGenomicReason:null,
        missingEGFRReason:null,
        genomic: {
            ALK: null,
            BRAF: null,
            EGFR: {
                selected: null,
                values: []
            },
            MET: null,
            NTRK: null,
            ROS: null,
            HER2: null,
            KRAS: null,
            RET: null,
            Other: null,
            NoMutation: null,
            Inconclusive: null
        }
    }
    q4 = null
    q5 = ''
    q6 = null

    constructor() {

        makeObservable(this, {
            loading: observable,
            q1: observable,
            q2: observable,
            q3: observable,
            q4: observable,
            q5: observable,
            q6: observable,
            setQ1: action,
            setQ2: action,
            setQ3: action,
            setQ4: action,
            setQ5: action,
            setQ6: action,
            setLoading: action,
            updateTumorBiology: action,
            getTumorBiology: action,
            reset: action,
            showInconclusive: action,
        },)
    }

    showInconclusive = () =>{
        if(this.q3.genomic?.ALK && this.q3.genomic.ALK == 'on'){
            return false
        }
        if(this.q3.genomic?.EGFR?.selected && this.q3.genomic.EGFR.selected == 'on' ||
            this.q3.genomic.EGFR?.values && this.q3.genomic.EGFR.values.length>0){
            return false
        }
        if(this.q3.genomic?.BRAF && this.q3.genomic.BRAF == 'on'){
            return false
        }
        if(this.q3.genomic?.MET && this.q3.genomic.MET == 'on'){
            return false
        }
        if(this.q3.genomic?.NTRK && this.q3.genomic.NTRK == 'on'){
            return false
        }
        if(this.q3.genomic?.ROS && this.q3.genomic.ROS == 'on'){
            return false
        }
        if(this.q3.genomic?.HER2 && this.q3.genomic.HER2 == 'on'){
            return false
        }
        if(this.q3.genomic?.KRAS && this.q3.genomic.KRAS == 'on'){
            return false
        }
        if(this.q3.genomic?.RET && this.q3.genomic.RET == 'on'){
            return false
        }
        if(this.q3.genomic?.RET && this.q3.genomic.RET == 'on'){
            return false
        }
        if(this.q3.genomic?.Other && this.q3.genomic.Other == 'on'){
            return false
        }
        if(this.q3.genomic?.NoMutation && this.q3.genomic.NoMutation == 'on'){
            return false
        }

        return true
    }

    reset = () => {
        this.setQ1('')
        this.setQ2({
            main: null,
            method: null,
            levels: null
        })
        this.setQ3({
            main: null,
            method: [],
            genomic: {
                ALK: null,
                BRAF: null,
                EGFR: {
                    selected: null,
                    values: []
                },
                MET: null,
                NTRK: null,
                ROS: null,
                HER2: null,
                KRAS: null,
                RET: null,
                Other: null,
                NoMutation: null,
                Inconclusive: null
            }
        })

        this.setQ4(null)
        this.setQ5('')
        this.setQ6(null)
    }

    get checkMissing1 () {
        if(this.q2 == null || this.q2.main == null){
            return true
        }else if(this.q2.main === 'true'  && this.q2.levels == null){
            return true
        }
    }
    get checkForceQuestions () {
        let force = false
        force = this.q1 === '';
        force = this.q2.main === 'true' && this.q2.method === null;
        force = this.q2.main !== 'false'

        return force
    }

    get checkMissing2() {

         if (this.q3.main == 'true' && this.q3.missingGenomicReason == null &&
             (this.q3.genomic.ALK == null || this.q3.genomic.ALK == false) &&
             (this.q3.genomic.BRAF == null || this.q3.genomic.BRAF == false) &&
             (this.q3.genomic.EGFR.selected == null || this.q3.genomic.EGFR.selected == false) &&
             (this.q3.genomic.MET == null || this.q3.genomic.MET == false) &&
             (this.q3.genomic.NTRK == null || this.q3.genomic.NTRK == false) &&
             (this.q3.genomic.ROS == null || this.q3.genomic.ROS == false) &&
             (this.q3.genomic.HER2 == null || this.q3.genomic.HER2 == false) &&
             (this.q3.genomic.KRAS == null || this.q3.genomic.KRAS == false) &&
             (this.q3.genomic.RET == null || this.q3.genomic.RET == false) &&
             (this.q3.genomic.Other == null || this.q3.genomic.Other == false) &&
             (this.q3.genomic.NoMutation == null || this.q3.genomic.NoMutation == false) &&
             (this.q3.genomic.Inconclusive == null || this.q3.genomic.Inconclusive == false)
         ) {
             Stores.dataStore.setQuestionsMissing('q3value')
         }
         if(this.q3.genomic.EGFR.selected != null && this.q3.genomic.EGFR.values.length == 0 && !this.q3?.missingEGFRReason){
             Stores.dataStore.setQuestionsMissing('EGFR')
         }

         if((this.q3 == null || this.q3.main == null) ||
             (this.q3.main == 'true' && this.q3.missingGenomicReason == null &&
                 (this.q3.genomic.ALK == null || this.q3.genomic.ALK == false) &&
                 (this.q3.genomic.BRAF == null || this.q3.genomic.BRAF == false) &&
                 (this.q3.genomic.EGFR.selected == null || this.q3.genomic.EGFR.selected == false) &&
                 (this.q3.genomic.MET == null || this.q3.genomic.MET == false) &&
                 (this.q3.genomic.NTRK == null || this.q3.genomic.NTRK == false) &&
                 (this.q3.genomic.ROS == null || this.q3.genomic.ROS == false) &&
                 (this.q3.genomic.HER2 == null || this.q3.genomic.HER2 == false) &&
                 (this.q3.genomic.KRAS == null || this.q3.genomic.KRAS == false) &&
                 (this.q3.genomic.RET == null || this.q3.genomic.RET == false) &&
                 (this.q3.genomic.Other == null || this.q3.genomic.Other == false) &&
                 (this.q3.genomic.NoMutation == null || this.q3.genomic.NoMutation == false) &&
                 (this.q3.genomic.Inconclusive == null || this.q3.genomic.Inconclusive == false)
             ) || (this.q3.genomic.EGFR.selected != null &&  !this.q3?.missingEGFRReason &&   this.q3.genomic.EGFR.values.length == 0)
         ){
             return true
         }
    }
    get checkMissing3 () {
        if(this.q5 === ''){
            Stores.dataStore.setQuestionsMissing('q5')
            return true
        }
    }

    setQ1 = (value) => {
        this.q1 = value
    }

    setQ2 = (value) => {
        this.q2 = value
    }
    setQ3 = (value) => {
        this.q3 = value
    }
    setQ4 = (value) => {
        this.q4 = value
    }
    setQ5 = (value) => {
        this.q5 = value
    }
    setQ6 = (value) => {
        this.q6 = value
    }

    setLoading = (value) => {
        this.loading = value
    }

    updateTumorBiology = async (requestBody,patient) => {
        try {
            this.setLoading(true)
            console.log('requestBody', requestBody)
            let params = {...requestBody,force:Stores.dataStore.forceUpdate}

            if(Stores.dataStore.updateReason !== ''){
                params = {...params, reason:Stores.dataStore.updateReason}
            }
            console.log('updateTumorBiology params', params)
            const response = await CrfService.updateTumorBiology(patient, params)
            console.log('updateTumorBiology response', response)
            if (response.q1 != null) {
                this.setQ1(response.q1)
            }
            if (response.q2 != null) {
                this.setQ2(response.q2)
            }
            if (response.q3 != null) {
                this.setQ3(response.q3)
            }
            if (response.q4 != null) {
                this.setQ4(response.q4)
            }
            if (response.q5 != null) {
                this.setQ5(response.q5)
            }
            Stores.dataStore.resetUpdateModalData()

            this.setLoading(false)

        } catch (err) {
            this.setLoading(false)
            console.log('updateTumorBiology err', err)
            if(err?.body?.data?.updated){
                Stores.dataStore.setShowUpdateModal('active')
            }else {
                Stores.dataStore.setShowUpdateModal('not active')
            }
        }
    }


    getTumorBiology = async (patient) => {
        try {
            this.setLoading(true)

            const response = await CrfService.getTumorBiology(patient)
            console.log('getTumorBiology response', response)

            if (response.q1 != null) {
                this.setQ1(response.q1)
            }
            if (response.q2 != null) {
                this.setQ2(response.q2)
            }
            if (response.q3 != null) {
                if (response.q3.main != null) {
                    this.q3.main = response.q3.main
                }
                if (response.q3.method != null) {
                    this.q3.method = response.q3.method
                }
                if (response.q3.genomic != null) {
                    this.q3.genomic = response.q3.genomic
                }

                if (response.q3?.missingGenomicReason && response.q3.missingGenomicReason.startsWith('missing|')) {
                    Stores.dataStore.setQuestionsMissing('q3value')
                }

                if (response.q3?.missingEGFRReason && response.q3.missingEGFRReason.startsWith('missing|')) {
                    Stores.dataStore.setQuestionsMissing('EGFR')
                }


            }
            if (response.q4 != null) {
                this.setQ4(response.q4)
            }
            if (response.q5 != null) {
                this.setQ5(response.q5)
                if(response.q5.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q5')
                }
            }

            Stores.dataStore.setForceUpdate(false)

            this.setLoading(false)

        } catch (err) {
            this.setLoading(false)
            console.log('getTumorBiology err', err)
        }
    }

    get checkTumorS1(){
        if (this.q1 === null || this.q1 === '') {
            return true
        }
        if(this.q2.main === null){
            return true
        }
        if (this.q2.main === 'true' && this.q2.method === null) {
            return true
        }
        if (this.q2.main === 'true' && this.q2.levels === null) {
            return true
        }
    };



}


export default TumorBiologyStore
