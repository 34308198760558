import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const AuthFooter = () => {
    return (
        <Stack direction={'row'} spacing={2} sx={{
            position: 'absolute',
            bottom: 20,
            left: 0,
            right: 0,
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <Link href="/privacyPolicy" color={'txtGrey'} underline="none" sx={{fontWeight: 'bold', fontSize: 16}}>
                Privacy Policy
            </Link>
            <Typography color='txtGrey' sx={{fontSize: 6}}>⬤</Typography>
            <Link href="/terms" color={'txtGrey'} underline="none" sx={{fontWeight: 'bold', fontSize: 16}}>
                Terms & Conditions
            </Link>
        </Stack>
    );
}

export default AuthFooter;
