import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {Checkbox, ClickAwayListener, FormControlLabel, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import Paper from "@mui/material/Paper";
import YesNo from "../../components/YesNo";
import QuestionText from "../../components/QuestionText";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import arrowDown from "../../images/svgs/Arrow-down.svg";
import QuestionsLayout from "../../layout/QuestionsLayout";
import arrowUp from "../../images/svgs/Arrow-up.svg";
import Required from "../../components/Required";
import MissingReason from "../../components/MissingReason";


const TumorBiologyPage2 = () => {
    const {
        tumorBiologyStore, dataStore,menuStore
    } = useStores()
    const [showMissing, setShowMissing] = React.useState('not active');
    let urlParams = useParams();

    const navigate = useNavigate()
    const [openMenu, setOpenMenu] = React.useState(false);

    useEffect(() => {
        tumorBiologyStore.getTumorBiology(parseInt(urlParams.patient))
    }, [])
    const checkboxPress = (event) => {
        if (event.target.checked) {
            if (tumorBiologyStore.q3.method.length === 0) {
                tumorBiologyStore.setQ3(
                    {

                        ...tumorBiologyStore.q3,
                        method: [event.target.value],

                    }
                )
            } else {
                if (event.target.value === 'Unknown') {
                    tumorBiologyStore.setQ3(
                        {
                            ...tumorBiologyStore.q3,
                            method: [event.target.value],
                        }
                    )
                } else {
                    let method = tumorBiologyStore.q3.method
                    method = method.filter(e => e !== 'Unknown')
                    method.push(event.target.value)
                    tumorBiologyStore.setQ3(
                        {

                            ...tumorBiologyStore.q3,
                            method: method,
                        }
                    )
                }
            }

        } else {
            if (event.target.value === 'Unknown') {
                tumorBiologyStore.setQ3(
                    {
                        ...tumorBiologyStore.q3,
                        method: [],
                    }
                )
            } else {
                let methods = tumorBiologyStore.q3.method.filter(text => text !== event.target.value);
                tumorBiologyStore.setQ3(
                    {
                        ...tumorBiologyStore.q3,
                        method: methods
                    }
                )
            }
        }
    }

    const genomicPress = (enableParam, disableParam, event) => {
        if(tumorBiologyStore.q3.genomic.Inconclusive == null || tumorBiologyStore.q3.genomic.Inconclusive == false || enableParam.Inconclusive){
            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3value'), 1)

            if (event.target.checked) {
                tumorBiologyStore.setQ3(
                    {...tumorBiologyStore.q3, genomic: {...tumorBiologyStore.q3.genomic, ...enableParam,}, missingGenomicReason:null}
                )
            } else {
                tumorBiologyStore.setQ3(
                    {
                        ...tumorBiologyStore.q3,
                        genomic: {...tumorBiologyStore.q3.genomic, ...disableParam, missingGenomicReason:null}
                    }
                )
            }
        }
        console.log('tumorBiologyStore.q3.genomic.Inconclusive',tumorBiologyStore.q3.genomic.Inconclusive)

    }


    const EGFRPress = (event) => {
        if(tumorBiologyStore.q3.genomic.Inconclusive == null || tumorBiologyStore.q3.genomic.Inconclusive == false){
            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('EGFR'), 1)

            if (event.target.checked) {
                if (tumorBiologyStore.q3.genomic.EGFR?.values) {
                    let EGFR = tumorBiologyStore.q3.genomic.EGFR.values
                    EGFR.push(event.target.value)
                    tumorBiologyStore.setQ3(
                        {
                            ...tumorBiologyStore.q3,
                            genomic: {
                                ...tumorBiologyStore.q3.genomic,
                                EGFR: {...tumorBiologyStore.q3.genomic.EGFR, values: EGFR}
                            },
                        }
                    )
                } else {
                    tumorBiologyStore.setQ3(
                        {
                            ...tumorBiologyStore.q3,
                            genomic: {
                                ...tumorBiologyStore.q3.genomic,
                                EGFR: {...tumorBiologyStore.q3.genomic.EGFR, values: [event.target.value]}
                            },

                        }
                    )
                }
            } else {
                let EGFRs = tumorBiologyStore.q3.genomic.EGFR.values.filter(text => text !== event.target.value);
                tumorBiologyStore.setQ3(
                    {
                        ...tumorBiologyStore.q3,
                        genomic: {
                            ...tumorBiologyStore.q3.genomic,
                            EGFR: {
                                ...tumorBiologyStore.q3.genomic.EGFR,
                                values: EGFRs
                            }
                        },

                    }
                )
            }
        }
    }

    const handleNext = async () => {

            if(tumorBiologyStore.q3.main === null || (tumorBiologyStore.q3.main=== 'true' && tumorBiologyStore.q3.method.length === 0)) {
                alert('Please answer all required questions.')

            }else {
                if (!tumorBiologyStore.checkMissing2) {
                    await tumorBiologyStore.updateTumorBiology(
                        {
                            q3: JSON.stringify(tumorBiologyStore.q3),
                        }, parseInt(urlParams.patient))
                    if (dataStore.forceUpdate == true) {
                        dataStore.setShowUpdateModal('not active')
                    }
                    if (dataStore.showUpdateModal !== 'active') {
                        navigate('/tumorBiologyPage3/' + parseInt(urlParams.patient))
                    }

                }else {
                    alert('Please add reason for missing questions.')

                }
            }

    }

    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={tumorBiologyStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>

                    <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                        Tumor biology at diagnosis
                    </Typography>
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>

                    <QuestionBox>
                        <Stack direction={'row'}
                               sx={{width: '100%', display: 'flex', justifyContent: 'space-between',alignItems:'center'}}>
                            <QuestionText>
                                Genomic alterations test performed? <Required/>
                            </QuestionText>
                            <YesNo value={tumorBiologyStore.q3.main}
                                   handleChange={(event) => {
                                       if (event.target.value === 'true') {
                                           tumorBiologyStore.setQ3(
                                               {
                                                   ...tumorBiologyStore.q3,
                                                   main: event.target.value,
                                               })
                                       }else{
                                           tumorBiologyStore.setQ3(
                                               {
                                                   ...tumorBiologyStore.q3,
                                                   main: event.target.value,
                                                   method: [],
                                                   genomic: {
                                                       ALK: null,
                                                       BRAF: null,
                                                       EGFR: {
                                                           selected: null,
                                                           values: []
                                                       },
                                                       MET: null,
                                                       NTRK: null,
                                                       ROS: null,
                                                       HER2: null,
                                                       KRAS: null,
                                                       RET: null,
                                                       Other: null,
                                                       NoMutation: null,
                                                       Inconclusive: null
                                                   }
                                               })
                                       }
                                   }}
                            />
                        </Stack>
                        {tumorBiologyStore.q3?.main && tumorBiologyStore.q3.main === 'true' ?
                            <QuestionText width={'100%'}>
                                <Box sx={{
                                    display: 'flex',
                                    gap: "24px",
                                    rowGap: 0,
                                    width: '100%',
                                    alignItems: 'center',
                                    pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0
                                }}>
                                    <li>Method </li>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(event) => checkboxPress(event)}
                                                checked={tumorBiologyStore.q3?.method && tumorBiologyStore.q3.method.includes('NGS')}
                                                value="NGS"
                                            />
                                        }
                                        label="NGS"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(event) => checkboxPress(event)}
                                                checked={tumorBiologyStore.q3?.method && tumorBiologyStore.q3.method.includes('PCR')}
                                                value="PCR"
                                            />
                                        }
                                        label="PCR"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(event) => checkboxPress(event)}
                                                checked={tumorBiologyStore.q3?.method && tumorBiologyStore.q3.method.includes('Unknown')}
                                                disabled={tumorBiologyStore.q3?.method && tumorBiologyStore.q3.method.includes('PCR') && tumorBiologyStore.q3.method.includes('NGS')}

                                                value="Unknown"
                                            />
                                        }
                                        label="Unknown"
                                    />


                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    flexDirection: 'column',
                                    pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0

                                }}>
                                    <li>Genomic alterations described: <span style={{fontWeight:'normal'}}>(select all applicable)</span>
                                        {dataStore.questionsMissing.includes('q3value')
                                            ? <MissingReason
                                                value={tumorBiologyStore.q3.missingGenomicReason}
                                                onSubmitMissing={async ()=>{
                                                    tumorBiologyStore.setQ3(
                                                        {...tumorBiologyStore.q3, missingGenomicReason:dataStore.returnOtherTextMissing}
                                                    )
                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3value'), 1)
                                                }}/> : <></>}</li>
                                    <Box
                                        sx={{
                                            display: 'flex', flex: 1,
                                            flexDirection: 'column', width: '100%',
                                        }}
                                    >
                                        <Box sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => genomicPress(
                                                            {ALK: event.target.value}, {ALK: !event.target.value}, event)}
                                                        checked={tumorBiologyStore.q3.genomic.ALK}
                                                    />
                                                }
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {fontSize: 18},
                                                    flex: 1
                                                }}
                                                label="ALK translocation"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => genomicPress(
                                                            {BRAF: event.target.value}, {BRAF: !event.target.value}, event)}
                                                        checked={tumorBiologyStore.q3.genomic.BRAF}
                                                    />
                                                }
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {fontSize: 18},
                                                    flex: 1
                                                }}
                                                label="BRAF V600 mutation"
                                            />
                                        </Box>

                                        <Box sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            flex: 1
                                        }}>
                                            <Box sx={{width: '50%', display: "flex", alignItems: "center",}}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onChange={async (event) => {
                                                                if(tumorBiologyStore.q3.genomic.Inconclusive == null || tumorBiologyStore.q3.genomic.Inconclusive == false){

                                                                    if (tumorBiologyStore.q3.genomic.EGFR.selected === 'on') {
                                                                        tumorBiologyStore.setQ3(
                                                                            {
                                                                                ...tumorBiologyStore.q3,
                                                                                genomic: {
                                                                                    ...tumorBiologyStore.q3.genomic,
                                                                                    EGFR: {
                                                                                        selected: null,
                                                                                        values: []
                                                                                    }
                                                                                },
                                                                            }
                                                                        )
                                                                        setOpenMenu(false)
                                                                    } else {
                                                                        tumorBiologyStore.setQ3(
                                                                            {
                                                                                ...tumorBiologyStore.q3,
                                                                                genomic: {
                                                                                    ...tumorBiologyStore.q3.genomic,
                                                                                    EGFR: {
                                                                                        ...tumorBiologyStore.q3.genomic.EGFR,
                                                                                        selected: event.target.value
                                                                                    }
                                                                                },
                                                                            }
                                                                        )
                                                                        setOpenMenu(true)
                                                                    }
                                                                } }}
                                                            checked={tumorBiologyStore.q3.genomic.EGFR.selected}
                                                        />
                                                    }
                                                    sx={{
                                                        '& .MuiFormControlLabel-label': {fontSize: 18},
                                                    }}
                                                    label="EGFR activating mutations"
                                                />
                                                <img onClick={() => {
                                                    tumorBiologyStore.setQ3(
                                                        {
                                                            ...tumorBiologyStore.q3,
                                                            genomic: {
                                                                ...tumorBiologyStore.q3.genomic,
                                                                EGFR: {
                                                                    ...tumorBiologyStore.q3.genomic.EGFR,
                                                                    selected: 'on'
                                                                }
                                                            },
                                                        }
                                                    )
                                                    setOpenMenu(true)
                                                }}
                                                     style={{
                                                         cursor: 'pointer',
                                                         width: 18,
                                                         height: 18,
                                                     }}
                                                     src={arrowDown} alt=""/>
                                            </Box>

                                            {openMenu &&
                                                <ClickAwayListener onClickAway={() => {
                                                    setOpenMenu(false)
                                                }}>
                                                    <Paper sx={{
                                                        display: 'flex',
                                                        width: '50%',
                                                        backgroundColor: 'white',
                                                        mt: -1,
                                                        ml: -3,
                                                        position: 'absolute',
                                                        p: 3,
                                                        pt: 1,
                                                        borderRadius: 3,
                                                        flexDirection: 'column',
                                                        zIndex: 999,
                                                    }} elevation={5}>
                                                        <Stack sx={{
                                                            width: '60%',
                                                            display: "flex",
                                                            alignItems: "center"
                                                        }} direction={'row'}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        onChange={async () => {
                                                                            tumorBiologyStore.setQ3(
                                                                                {
                                                                                    ...tumorBiologyStore.q3,
                                                                                    genomic: {
                                                                                        ...tumorBiologyStore.q3.genomic,
                                                                                        EGFR: {
                                                                                            selected: null,
                                                                                            values: []
                                                                                        }
                                                                                    },
                                                                                }
                                                                            )
                                                                            setOpenMenu(false)
                                                                            console.log('openMenu', openMenu)
                                                                        }}
                                                                        checked={tumorBiologyStore.q3.genomic.EGFR.selected}
                                                                    />
                                                                }
                                                                sx={{
                                                                    '& .MuiFormControlLabel-label': {fontSize: 18},
                                                                }}
                                                                label="EGFR activating mutations"
                                                            />

                                                            <img onClick={() => {
                                                                if(tumorBiologyStore.q3?.genomic?.EGFR?.values &&
                                                                    tumorBiologyStore.q3.genomic.EGFR.values.length>0){
                                                                    setOpenMenu(false)

                                                                }
                                                            }}
                                                                 style={{
                                                                     cursor: 'pointer',
                                                                     width: 18,
                                                                     height: 18,
                                                                 }}
                                                                 src={arrowUp} alt=""/>

                                                            {dataStore.questionsMissing.includes('EGFR')
                                                                ? <MissingReason
                                                                    value={tumorBiologyStore.q3.missingEGFRReason}
                                                                    style={{right:10,marginTop:3}}
                                                                    onSubmitMissing={async ()=>{
                                                                        tumorBiologyStore.setQ3(
                                                                            {
                                                                                ...tumorBiologyStore.q3,
                                                                                missingEGFRReason: dataStore.returnOtherTextMissing,

                                                                            })
                                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('EGFR'), 1)
                                                                    }}/> : <></>}
                                                        </Stack>
                                                        <Stack direction={'row'} sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            width: '100%',
                                                            px: 3
                                                        }}>
                                                            <Box sx={{
                                                                width: '100%',
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                                flex: 1
                                                            }}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            onChange={(event) => EGFRPress(event)}
                                                                            checked={tumorBiologyStore.q3.genomic?.EGFR.values &&
                                                                                tumorBiologyStore.q3.genomic.EGFR.values.length > 0 &&
                                                                                tumorBiologyStore.q3.genomic.EGFR.values.includes('Exon 18 mutation')}
                                                                        />
                                                                    }
                                                                    sx={{
                                                                        '& .MuiFormControlLabel-label': {fontSize: 18},

                                                                    }}
                                                                    value={'Exon 18 mutation'}
                                                                    label="Exon 18 mutation"
                                                                />
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            onChange={(event) => EGFRPress(event)}
                                                                            checked={tumorBiologyStore.q3.genomic?.EGFR.values &&
                                                                                tumorBiologyStore.q3.genomic.EGFR.values.length > 0 &&
                                                                                tumorBiologyStore.q3.genomic.EGFR.values.includes('Exon 19 mutation')}
                                                                        />
                                                                    }
                                                                    sx={{
                                                                        '& .MuiFormControlLabel-label': {fontSize: 18},

                                                                    }}
                                                                    value={'Exon 19 mutation'}
                                                                    label="Exon 19 mutation"
                                                                />
                                                            </Box>

                                                            <Box sx={{
                                                                width: '100%',
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                                flex: 1
                                                            }}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            onChange={(event) => EGFRPress(event)}
                                                                            checked={tumorBiologyStore.q3.genomic?.EGFR.values &&
                                                                                tumorBiologyStore.q3.genomic.EGFR.values.length > 0 &&
                                                                                tumorBiologyStore.q3.genomic.EGFR.values.includes('Exon 20 mutation')}
                                                                        />
                                                                    }
                                                                    sx={{
                                                                        '& .MuiFormControlLabel-label': {fontSize: 18},

                                                                    }}
                                                                    value={'Exon 20 mutation'}
                                                                    label="Exon 20 mutation"
                                                                />
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            onChange={(event) => EGFRPress(event)}
                                                                            checked={tumorBiologyStore.q3.genomic?.EGFR.values &&
                                                                                tumorBiologyStore.q3.genomic.EGFR.values.length > 0 &&
                                                                                tumorBiologyStore.q3.genomic.EGFR.values.includes('Exon 21 mutation')}
                                                                        />
                                                                    }
                                                                    sx={{
                                                                        '& .MuiFormControlLabel-label': {fontSize: 18},

                                                                    }}
                                                                    value={'Exon 21 mutation'}
                                                                    label="Exon 21 mutation"
                                                                />
                                                            </Box>

                                                            <Box sx={{
                                                                width: '100%',
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                                flex: 1
                                                            }}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            onChange={(event) => EGFRPress(event)}
                                                                            checked={tumorBiologyStore.q3.genomic?.EGFR.values &&
                                                                                tumorBiologyStore.q3.genomic.EGFR.values.length > 0 &&
                                                                                tumorBiologyStore.q3.genomic.EGFR.values.includes('Other')}
                                                                        />
                                                                    }
                                                                    sx={{
                                                                        '& .MuiFormControlLabel-label': {fontSize: 18},

                                                                    }}
                                                                    value={'Other'}
                                                                    label="Other"
                                                                />

                                                            </Box>
                                                        </Stack>
                                                    </Paper>
                                                </ClickAwayListener>}

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => genomicPress(
                                                            {MET: event.target.value}, {MET: !event.target.value}, event)}
                                                        checked={tumorBiologyStore.q3.genomic.MET}
                                                    />

                                                }
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {fontSize: 18},
                                                    flex: 1
                                                }}
                                                label="MET mutation or amplification"
                                            />
                                        </Box>

                                        <Box sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => genomicPress(
                                                            {NTRK: event.target.value}, {NTRK: !event.target.value}, event)}
                                                        checked={tumorBiologyStore.q3.genomic.NTRK}
                                                    />
                                                }
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {fontSize: 18},
                                                    flex: 1
                                                }}
                                                label="NTRK fusions"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => genomicPress(
                                                            {ROS: event.target.value}, {ROS: !event.target.value}, event)}
                                                        checked={tumorBiologyStore.q3.genomic.ROS}
                                                    />
                                                }
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {fontSize: 18},
                                                    flex: 1
                                                }}
                                                label="ROS 1 translocation"
                                            />
                                        </Box>

                                        <Box sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => genomicPress(
                                                            {HER2: event.target.value}, {HER2: !event.target.value}, event)}
                                                        checked={tumorBiologyStore.q3.genomic.HER2}
                                                    />
                                                }
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {fontSize: 18},
                                                    flex: 1
                                                }}
                                                label="HER2 mutation"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => genomicPress(
                                                            {KRAS: event.target.value}, {KRAS: !event.target.value}, event)}
                                                        checked={tumorBiologyStore.q3.genomic.KRAS}
                                                    />
                                                }
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {fontSize: 18},
                                                    flex: 1
                                                }}
                                                label="KRAS mutation"
                                            />
                                        </Box>

                                        <Box sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => genomicPress(
                                                            {RET: event.target.value}, {RET: !event.target.value}, event)}
                                                        checked={tumorBiologyStore.q3.genomic.RET}
                                                    />
                                                }
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {fontSize: 18},
                                                    flex: 1
                                                }}
                                                label="RET translocation"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => genomicPress(
                                                            {Other: event.target.value}, {Other: !event.target.value}, event)}
                                                        checked={tumorBiologyStore.q3.genomic.Other}
                                                    />
                                                }
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {fontSize: 18},
                                                    flex: 1
                                                }}
                                                label="Other"
                                            />
                                        </Box>
                                        <Box sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}>
                                        {tumorBiologyStore.showInconclusive() ?
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => genomicPress(
                                                            {Inconclusive: event.target.value}, {Inconclusive: !event.target.value}, event)}
                                                        checked={tumorBiologyStore.q3.genomic.Inconclusive}
                                                    />
                                                }
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {fontSize: 18},
                                                    flex: 1
                                                }}
                                                label="Inconclusive"
                                            />: <></>}
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => genomicPress(
                                                            {NoMutation: event.target.value}, {NoMutation: !event.target.value}, event)}
                                                        checked={tumorBiologyStore.q3.genomic.NoMutation}
                                                    />
                                                }
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {fontSize: 18},
                                                    flex: 1
                                                }}
                                                label="No mutation"
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </QuestionText> : <></>}
                    </QuestionBox>
                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(TumorBiologyPage2);
