import * as React from 'react';
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";


const MaleFemale = ({handleChange, value}) => {

    return (
        <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={handleChange}
            sx={{display: 'flex', flexDirection: 'row', gap: "24px", rowGap: 0}}
            value={value}
        >
            <FormControlLabel componentsProps={{
                typography: {sx: {fontSize: 20, fontWeight: 'bold'}}
            }} value="male" control={<Radio/>} label="Male"/>
            <FormControlLabel sx={{ml: 2}} componentsProps={{
                typography: {sx: {fontSize: 20, fontWeight: 'bold'}}
            }} value="female" control={<Radio/>} label="Female"/>
        </RadioGroup>
    );
}

export default MaleFemale;
