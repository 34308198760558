import {makeObservable, observable,} from 'mobx'
import {Stores} from "../store";


class QuestionStore {

    loading = false

    constructor() {

        makeObservable(this, {
            loading: observable,

        },)
    }

    setLoading = (value) => {
        this.loading = value
    }

    resetAllQuestions = () => {
        Stores.inclusionCriteriaStore.reset()
        Stores.exclusionCriteriaStore.reset()
        Stores.socioDemographicCharacteristicsStore.reset()
        Stores.clinicalHistoryStore.reset()
        Stores.environmentalExposureStore.reset()
        Stores.clinicalCharacterizationStore.reset()
        Stores.tumorBiologyStore.reset()
        Stores.treatmentPatternsStore.reset()
        Stores.systemicTherapyStore.reset()
        Stores.afterInitialTreatmentStore.reset()
        Stores.dispositionStore.reset()
    }

}


export default QuestionStore
