import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import Box from "@mui/material/Box";
import YesNo from "../../components/YesNo";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionsLayout from "../../layout/QuestionsLayout";
import {observer} from "mobx-react";


const PatientCompleted = () => {
    const {
        dispositionStore, dataStore,menuStore
    } = useStores()
    let urlParams = useParams();

    const navigate = useNavigate()
    useEffect(() => {
        dispositionStore.getDisposition(parseInt(urlParams.patient))
        dataStore.getPatient(parseInt(urlParams.patient))

    }, [])
    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         hideFooter={true}>
            <Box sx={{
                width: '100%',
                px: 12,
                mb: 0,
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Box width={'100%'} sx={{
                    mt: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',

                }}>
                    <Typography color={'primary'} sx={{fontSize: 50, fontWeight: 'bold'}}>Patient
                        Completed</Typography>
                </Box>


                <Box width={'100%'} sx={{

                    mt: 3,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 3
                }}>
                    <Typography color={'primary'} sx={{fontSize: 20, textAlign: "center"}}>
                        Are you sure? Once a patient is completed no changes to this patient will be
                        possible <br/> unless duly authorized.</Typography>
                </Box>
                <YesNo fontSize={30} value={dataStore.patientCompleted} handleChange={async (event) => {
                    if (event.target.value === "false") {
                        navigate(-1)
                    } else {
                        await dataStore.updatePatient(parseInt(urlParams.patient), {completed: true})
                        navigate('/addData')
                    }
                }}/>

            </Box>
        </QuestionsLayout>
    );
}

export default observer(PatientCompleted);
