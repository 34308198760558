import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import YesNo from "../../components/YesNo";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import TextField from "@mui/material/TextField";
import QuestionsLayout from "../../layout/QuestionsLayout";
import MissingReason from "../../components/MissingReason";


const AfterInitialEmergencyConsultationsPage1 = () => {
    const {
        afterInitialTreatmentStore, dataStore,menuStore
    } = useStores()

    const navigate = useNavigate()
    const [showMissing, setShowMissing] = React.useState('not active');

    let params = useParams();
    useEffect(() => {
        afterInitialTreatmentStore.getAfterTreatment(parseInt(params.patient),parseInt(params.number))
    }, [])

    const handleNext = async () => {
        if (!afterInitialTreatmentStore.checkMissing6) {
            await afterInitialTreatmentStore.updateAfterTreatment(
                {
                    q7: JSON.stringify(afterInitialTreatmentStore.q7),
                }, parseInt(params.number),parseInt(params.patient))
            if(dataStore.forceUpdate == true){
                dataStore.setShowUpdateModal('not active')
            }
            if(dataStore.showUpdateModal !== 'active'){
                navigate(`/subsequentTreatmentPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            }

        } else {
            alert('Please add reason for missing questions.')

            // if (!dataStore.globalMissing) {
            //     if (showMissing === 'active') {
            //         await afterInitialTreatmentStore.updateAfterTreatment(
            //             {
            //                 q7: afterInitialTreatmentStore.q7?.main ? JSON.stringify(afterInitialTreatmentStore.q7) : dataStore.returnOtherObjectMissing,
            //             }, parseInt(params.number),parseInt(params.patient))
            //         if(dataStore.forceUpdate == true){
            //             dataStore.setShowUpdateModal('not active')
            //         }
            //         if(dataStore.showUpdateModal !== 'active'){
            //             navigate(`/subsequentTreatmentPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            //         }
            //         setShowMissing('not active')
            //
            //     } else {
            //         setShowMissing('active')
            //     }
            // } else {
            //     await afterInitialTreatmentStore.updateAfterTreatment(
            //         {
            //             q7: afterInitialTreatmentStore.q7?.main ? JSON.stringify(afterInitialTreatmentStore.q7) : dataStore.returnOtherObjectMissing,
            //         }, parseInt(params.number),parseInt(params.patient))
            //     navigate(`/subsequentTreatmentPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            // }
        }
    }

    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={afterInitialTreatmentStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>
                    {parseInt(params.number) === 0 ?
                        <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                            Health Care Resource utilization after treatment completion or discontinuation
                        </Typography> :
                        <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                            {`Health Care Resource utilization after completion or discontinuation of treatment n = ${params.number}`}
                        </Typography>
                    }
                    <Typography color={'txtGrey'} sx={{fontWeight: 'bold', fontSize: 18,}}>
                        [Please complete this section only during the time between treatments. Subsequent treatment data to be collected in section “Subsequent Treatment (n=2, 3, x ….)”.]
                    </Typography>
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>
                    <Typography sx={{fontWeight: 'bold', fontSize: 30,}}>
                        Emergency Consultations
                    </Typography>
                    <QuestionBox allInRow={true}>
                        <YesNo value={afterInitialTreatmentStore.q7?.main}
                               handleChange={(event) => {
                                   dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q7'), 1)
                                   if (event.target.value === 'true') {
                                       afterInitialTreatmentStore.setQ7(
                                           {...afterInitialTreatmentStore.q7, main: event.target.value,})
                                   }else{
                                       afterInitialTreatmentStore.setQ7(
                                           {...afterInitialTreatmentStore.q7, main: event.target.value,})
                                   }
                               }}
                        >
                            {dataStore.questionsMissing.includes('q7')
                                ? <MissingReason
                                    style={{right:60,marginTop:-3}}
                                    onSubmitMissing={async ()=>{
                                        afterInitialTreatmentStore.setQ7(
                                            {...afterInitialTreatmentStore.q7, main: dataStore.returnOtherTextMissing,})
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q7'), 1)
                                    }}/> : <></>}
                        </YesNo>
                        {afterInitialTreatmentStore.q7?.main && afterInitialTreatmentStore.q7.main === 'true' ?<Box sx={{display: 'flex', alignItems: 'center', mt: 1}}>
                            <Typography sx={{fontSize: 18, mr: 1}}>
                                {dataStore.questionsMissing.includes('q7amount')
                                    ? <MissingReason
                                        style={{marginLeft:-80,marginTop:-9}}
                                        onSubmitMissing={async ()=>{
                                            afterInitialTreatmentStore.setQ7(
                                                {...afterInitialTreatmentStore.q7, amount:  dataStore.returnOtherTextMissing,})
                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q7amount'), 1)
                                        }}/> : <></>}
                                Number
                            </Typography>
                            <TextField
                                type={"number"}
                                onWheel={(e) => e.target.blur()}
                                size={'small'}
                                sx={{width: 100, ml: 3, mr: 1}}
                                inputProps={{min: 0, style: {textAlign: 'center'}}}
                                placeholder={'00'}
                                value={afterInitialTreatmentStore.q7?.amount && afterInitialTreatmentStore.q7.amount.startsWith('missing|') ? '': afterInitialTreatmentStore.q7.amount}

                                onChange={(event) => {
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q7amount'), 1)
                                    afterInitialTreatmentStore.setQ7(
                                    {...afterInitialTreatmentStore.q7, amount: event.target.value,})}}/>
                        </Box>:<></>}
                    </QuestionBox>
                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(AfterInitialEmergencyConsultationsPage1);
