import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {RadioGroup, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import QuestionText from "../../components/QuestionText";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import RadioItem from "../../components/RadioItem";
import TextField from "@mui/material/TextField";
import QuestionsLayout from "../../layout/QuestionsLayout";
import Joi from "joi";
import Required from "../../components/Required";


const TreatmentPatternsDuration = () => {
    const {
        treatmentPatternsStore, dataStore, menuStore
    } = useStores()
    const [openDate, setOpenDate] = React.useState(false);
    const [showMissing, setShowMissing] = React.useState('not active');
    let params = useParams();
    useEffect(() => {
        treatmentPatternsStore.getTreatment(parseInt(params.patient), parseInt(params.number))
    }, [])
    const navigate = useNavigate()
    const NrWeekSchema = Joi.number().min(0).max(24).integer().required();
    const NrWeek2Schema = Joi.number().min(1).max(24).integer().required();
    const NrDaySchema = Joi.number().min(7).max(180).integer().required();

    const handleNext = async () => {
        if(treatmentPatternsStore.q1 !== '' && treatmentPatternsStore.q2.type !== null && treatmentPatternsStore.q2.value !== ''){
            if (NrWeekSchema.validate(treatmentPatternsStore.q1).error) {
                if (!window.confirm("Do you confirm this value?")) {
                    return;
                }
            }
            if (treatmentPatternsStore.q2.type === 'Weeks' ? NrWeek2Schema.validate(treatmentPatternsStore.q2.value).error : NrDaySchema.validate(treatmentPatternsStore.q2.value).error) {
                if (!window.confirm("Do you confirm this value?")) {
                    return;
                }
            }
            if (treatmentPatternsStore.q1 !== '' && treatmentPatternsStore.q2.type !== null && treatmentPatternsStore.q2.value !== '') {
                await treatmentPatternsStore.updateTreatment(
                    {
                        q1: treatmentPatternsStore.q1,
                        q2: JSON.stringify(treatmentPatternsStore.q2),
                    }, parseInt(params.number), parseInt(params.patient))
                if (dataStore.forceUpdate === true) {
                    dataStore.setShowUpdateModal('not active')
                }
                if (dataStore.showUpdateModal !== 'active') {
                    navigate(`/treatmentPatternsNeoadjuvant/${parseInt(params.patient)}/${parseInt(params.number)}`)
                }
            }
        }else {
            alert('Please answer all required questions.')
        }

    }

    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={treatmentPatternsStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>

                    <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                        Treatment patterns and healthcare resource use (Only Initial Treatment)
                    </Typography>
                    <Typography color={'txtGrey'} sx={{fontSize: 20,}}>
                        (Please complete this section only for initial treatment.)
                    </Typography>
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>

                    <QuestionBox allInRow={true}>
                        <QuestionText width={'50%'}>
                            Time from diagnosis to treatment initiation <Required/>
                        </QuestionText>
                        <Stack direction={'row'} sx={{display: 'flex', alignItems: 'center'}}>
                            {NrWeekSchema.validate(treatmentPatternsStore.q1).error && <Typography
                                variant="caption" color="error" sx={{mr: 2}}>Between 0 and 24</Typography>}
                            <Typography sx={{fontSize: 18, mr: 5}}>
                                Number of weeks
                            </Typography>
                            <TextField
                                size={'small'}
                                type={"number"}
                                onWheel={(e) => e.target.blur()}
                                sx={{width: 100}}
                                inputProps={{min: 0, style: {textAlign: 'center'}}}
                                error={NrWeekSchema.validate(treatmentPatternsStore.q1).error}
                                onChange={(event) => treatmentPatternsStore.setQ1(
                                    event.target.value
                                )}
                                value={treatmentPatternsStore.q1 && treatmentPatternsStore.q1.startsWith('missing|') ? '': treatmentPatternsStore.q1}
                            />
                        </Stack>
                    </QuestionBox>
                    <QuestionBox>
                        <QuestionText>
                            Total Duration of treatment <Required/>
                        </QuestionText>
                        <Stack direction={'row'} sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={(event) => {
                                    treatmentPatternsStore.setQ2(
                                        {...treatmentPatternsStore.q2, type: event.target.value,}
                                    )
                                    setOpenDate(true)
                                }}
                                value={treatmentPatternsStore.q2.type}
                                sx={{display: 'flex', flexDirection: 'row'}}
                            >
                                <RadioItem label={'Weeks'}/>
                                <RadioItem label={'Days'}/>
                            </RadioGroup>
                            {openDate || treatmentPatternsStore.q2?.type != null ?
                                <Stack direction={'row'} sx={{display: 'flex', alignItems: 'center'}}>
                                    <Typography sx={{fontSize: 18, mr: 5}}>
                                        {treatmentPatternsStore.q2.type === 'Weeks' ? 'Number of Weeks' : 'Number of Days'}
                                    </Typography>
                                    {treatmentPatternsStore.q2.type !== '' && <TextField
                                        size={"small"}
                                        type={"number"}
                                        onWheel={(e) => e.target.blur()}
                                        sx={{width: 100}}
                                        error={treatmentPatternsStore.q2.type === 'Weeks' ? NrWeek2Schema.validate(treatmentPatternsStore.q2.value).error : NrDaySchema.validate(treatmentPatternsStore.q2.value).error}
                                        inputProps={{min: 0, style: {textAlign: 'center'}}}
                                        onChange={(event) => treatmentPatternsStore.setQ2(
                                            {...treatmentPatternsStore.q2, value: event.target.value,}
                                        )}
                                        value={treatmentPatternsStore.q2?.value && treatmentPatternsStore.q2.value.startsWith('missing|') ? '': treatmentPatternsStore.q2.value}

                                    />}
                                    {treatmentPatternsStore.q2.type === 'Weeks' ? NrWeek2Schema.validate(treatmentPatternsStore.q2.value).error &&
                                        <Typography
                                            variant="caption" color="error" sx={{ml: 2}}>Between 1 and
                                            24</Typography> : NrDaySchema.validate(treatmentPatternsStore.q2.value).error &&
                                        <Typography
                                            variant="caption" color="error" sx={{ml: 2}}>Between 7 and 180</Typography>}
                                </Stack> : <></>}
                        </Stack>
                    </QuestionBox>
                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(TreatmentPatternsDuration);
