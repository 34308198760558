import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {RadioGroup, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import QuestionText from "../../components/QuestionText";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import RadioItem from "../../components/RadioItem";
import TextField from "@mui/material/TextField";
import QuestionsLayout from "../../layout/QuestionsLayout";
import Required from "../../components/Required";
import Joi from "joi";
import MissingReason from "../../components/MissingReason";


const TumorBiologyPage3 = () => {
    const {
        tumorBiologyStore, dataStore,menuStore
    } = useStores()
    let urlParams = useParams();

    useEffect(() => {
        tumorBiologyStore.getTumorBiology(parseInt(urlParams.patient))

    }, [])
    const navigate = useNavigate()
    const [showMissing, setShowMissing] = React.useState('not active');
    const NrMonthSchema = Joi.number().min(0.5).max(72).required();

    const handleNext = async () => {

        if (!tumorBiologyStore.checkMissing3) {
            if (tumorBiologyStore.q4 !== null) {
                await tumorBiologyStore.updateTumorBiology(
                    {
                        q4: tumorBiologyStore.q4,
                        q5: tumorBiologyStore.q5,
                    }, parseInt(urlParams.patient))
                if (dataStore.forceUpdate === true) {
                    dataStore.setShowUpdateModal('not active')
                }
                if (dataStore.showUpdateModal !== 'active') {
                    navigate(`/treatmentPatternsDuration/${parseInt(urlParams.patient)}/0`)
                }
            }else{
                alert('Please answer all required questions.')
            }

        } else {
            alert('Please add reason for missing questions.')

            // if (!dataStore.globalMissing) {
            //     if (showMissing === 'active') {
            //         await tumorBiologyStore.updateTumorBiology(
            //             {
            //                 q4: tumorBiologyStore.q4 ? tumorBiologyStore.q4 : dataStore.returnOtherTextMissing,
            //                 q5: tumorBiologyStore.q5 ? tumorBiologyStore.q5 : dataStore.returnOtherTextMissing,
            //             },parseInt(urlParams.patient))
            //         navigate(`/treatmentPatternsDuration/${parseInt(urlParams.patient)}/0`)
            //         setShowMissing('not active')
            //     } else {
            //         setShowMissing('active')
            //     }
            // } else {
            //     await tumorBiologyStore.updateTumorBiology(
            //         {
            //             q4: tumorBiologyStore.q4 ? tumorBiologyStore.q4 : dataStore.returnOtherTextMissing,
            //             q5: tumorBiologyStore.q5 ? tumorBiologyStore.q5 : dataStore.returnOtherTextMissing,
            //         }, parseInt(urlParams.patient))
            //     navigate(`/treatmentPatternsDuration/${parseInt(urlParams.patient)}/0`)
            // }
        }
        if (NrMonthSchema.validate(tumorBiologyStore.q5).error) {
            if (!window.confirm("Do you confirm this value?")) {
                return;
            }
        }
    }

    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={tumorBiologyStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>
                    <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                        Tumor biology at diagnosis
                    </Typography>
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>

                    <QuestionBox>
                        <QuestionText>
                            Surgical Specimen: Resection Grade <Required/>
                        </QuestionText>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(event) => tumorBiologyStore.setQ4(
                                event.target.value
                            )}
                            value={tumorBiologyStore.q4}
                            sx={{display: 'flex', width: '100%', gap: "24px", rowGap: 0}}
                        >
                            <RadioItem label={'R0'}/>
                            <RadioItem label={'R1'}/>
                            <RadioItem label={'R2'}/>
                            <RadioItem label={'Unknown'}/>
                        </RadioGroup>
                    </QuestionBox>
                    <QuestionBox>

                        <Typography sx={{fontSize:20}}>
                            <strong>
                                Nr of months since diagnosis until end of study as defined in the protocol
                            </strong>
                            &nbsp; (Death, last recorded clinical activity within the real-world database or end of
                            follow-up period):
                            {dataStore.questionsMissing.includes('q5')
                                ? <MissingReason
                                    value={tumorBiologyStore.q5}
                                    onSubmitMissing={async ()=>{
                                        tumorBiologyStore.setQ5(
                                            dataStore.returnOtherTextMissing
                                        )
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q5'), 1)
                                    }}/> : <></>}
                        </Typography>
                        <Stack direction={'row'} sx={{width: '100%', display: 'flex', alignItems: 'center',mt:2}}>
                            <Typography sx={{fontSize: 18, mr: 5}}>
                                Number of months
                            </Typography>
                            <TextField
                                size={'small'}
                                type={"number"}
                                onWheel={(e) => e.target.blur()}
                                error={NrMonthSchema.validate(tumorBiologyStore.q5).error}
                                sx={{width: 100}}
                                inputProps={{min: 0, style: {textAlign: 'center'}}}
                                onChange={(event) => {
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q5'), 1)
                                    tumorBiologyStore.setQ5(
                                    event.target.value
                                )}}
                                value={tumorBiologyStore.q5 && tumorBiologyStore.q5.startsWith('missing|') ? '': tumorBiologyStore.q5}
                            />
                            {NrMonthSchema.validate(tumorBiologyStore.q5).error && <Typography
                                variant="caption" color="error" sx={{ml: 2}}>Between 0.5 and 72</Typography>}
                        </Stack>
                        <Typography sx={{fontSize: 16, mt: 3, color: 'grey'}}>
                            End of follow-up period comprises cut-off date (individuals followed-up until June 2022) or evolution to clinical stages outside the scope of the study.
                        </Typography>
                    </QuestionBox>
                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(TumorBiologyPage3);
