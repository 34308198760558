import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {RadioGroup, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import YesNo from "../../components/YesNo";
import QuestionText from "../../components/QuestionText";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import RadioItem from "../../components/RadioItem";
import QuestionsLayout from "../../layout/QuestionsLayout";
import TextField from "@mui/material/TextField";
import Required from "../../components/Required";


const SubsequentTreatmentTumorBiologyPage1 = () => {
    const {
        treatmentPatternsStore, dataStore,menuStore
    } = useStores()
    const [openOther, setOpenOther] = React.useState(false);
    const [showMissing, setShowMissing] = React.useState('not active');

    const navigate = useNavigate()

    let params = useParams();
    useEffect(() => {
        treatmentPatternsStore.getTreatment(parseInt(params.patient),parseInt(params.number))
    }, [])

    const handleNext = async () => {
        // if (!treatmentPatternsStore.checkMissings1) {
            if (treatmentPatternsStore.checkTumorS1) {
                alert('Please answer all required questions.')
            } else {
                await treatmentPatternsStore.updateTreatment(
                    {
                        s1: treatmentPatternsStore.s1,
                        s2: JSON.stringify(treatmentPatternsStore.s2),
                    }, parseInt(params.number), parseInt(params.patient))

                if (dataStore.forceUpdate === true) {
                    dataStore.setShowUpdateModal('not active')
                }
                if (dataStore.showUpdateModal !== 'active') {
                    if(treatmentPatternsStore.s1 === 'false' || treatmentPatternsStore.s1 === false){
                        navigate(`/timeDurationPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
                    }else{
                        navigate(`/subsequentTreatmentTumorBiologyPage2/${parseInt(params.patient)}/${parseInt(params.number)}`)
                    }

                }
            }
        // }else {
        //     if (!dataStore.globalMissing) {
        //         if (showMissing === 'active') {
        //             await treatmentPatternsStore.updateTreatment(
        //                 {
        //                     s1: treatmentPatternsStore.s1,
        //                     s2: JSON.stringify(treatmentPatternsStore.s2),
        //                 }, parseInt(params.number), parseInt(params.patient))
        //             navigate(`/subsequentTreatmentTumorBiologyPage2/${parseInt(params.patient)}/${parseInt(params.number)}`)
        //             setShowMissing('not active')
        //         } else {
        //             setShowMissing('active')
        //         }
        //     } else {
        //         await treatmentPatternsStore.updateTreatment(
        //             {
        //                 s1: treatmentPatternsStore.s1,
        //                 s2: JSON.stringify(treatmentPatternsStore.s2),
        //             }, parseInt(params.number), parseInt(params.patient))
        //         navigate(`/subsequentTreatmentTumorBiologyPage2/${parseInt(params.patient)}/${parseInt(params.number)}`)
        //     }
        // }
    }
    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={treatmentPatternsStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>

                    <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                        {`Subsequent Treatment (n = ${params.number})`}
                    </Typography>
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>
                    <Typography sx={{fontWeight: 'bold', fontSize: 30,}}>
                        Tumor biology at subsequent treatments
                    </Typography>
                    <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>
                        <QuestionBox>
                            <Typography sx={{fontSize: 20, fontWeight: 'bold'}}>
                                Has any of the following parameters been re-evaluated? <Required/>
                            </Typography>
                            <Typography>
                                [PD-L1 expression levels, Genomic Alterations, Surgical Specimen: Resection
                                Grade]
                            </Typography>
                            <YesNo value={treatmentPatternsStore.s1}
                                   handleChange={(event) => treatmentPatternsStore.setS1(event.target.value)}
                            />
                        </QuestionBox>

                    </Stack>
                    {treatmentPatternsStore.s1 === 'true' || treatmentPatternsStore.s1 === true ? <QuestionBox>
                        <QuestionText>
                            Biomarkers Testing <Required/>
                        </QuestionText>
                        <Stack direction={'row'} sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <Typography sx={{fontSize: 20}}>
                                PD-L1 expression levels test performed?
                            </Typography>
                            <YesNo value={treatmentPatternsStore.s2.main}
                                   handleChange={(event) => {
                                       if (event.target.value === 'true') {
                                           treatmentPatternsStore.setS2(
                                               {...treatmentPatternsStore.s2, main: event.target.value,} )
                                       }else{
                                           treatmentPatternsStore.setS2(
                                               {...treatmentPatternsStore.s2, main: event.target.value,
                                                   method: null,
                                                   levels: null,
                                                   other: '',
                                               } )
                                       }

                                   }}
                            />
                        </Stack>
                        {treatmentPatternsStore.s2.main === 'true' ? <QuestionText width={'100%'}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0

                            }}>
                                <li>Method</li>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={treatmentPatternsStore.s2.method}
                                    onChange={(event) => treatmentPatternsStore.setS2(
                                        {...treatmentPatternsStore.s2, method: event.target.value,}
                                    )}
                                    sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around',ml:5,width:'80%'}}

                                >
                                    <RadioItem label={'TPS using 22C3 PharmDx'}/>
                                    <RadioItem label={'Other'}/>
                                    <RadioItem label={'Unknown'}/>
                                </RadioGroup>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: 2,
                                pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0

                            }}>
                                <li>Levels</li>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={treatmentPatternsStore.s2.levels}
                                    onChange={(event) => {
                                        treatmentPatternsStore.setS2(
                                            {...treatmentPatternsStore.s2, levels: event.target.value,}
                                        );
                                        if (event.target.value === 'other') {
                                            setOpenOther(true)
                                        } else {
                                            setOpenOther(false)
                                        }
                                    }}
                                    sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around',ml:5,width:'80%'}}

                                >
                                    <RadioItem label={'< 1%'}/>
                                    <RadioItem label={'1-49%'}/>
                                    <RadioItem label={'≥ 50%'}/>
                                    <RadioItem label={'other'}/>
                                    {openOther ? <TextField
                                        onWheel={(e) => e.target.blur()}
                                        size={'small'}
                                        label="Specify Here"
                                        name="otherTumorPage1"
                                        sx={{width: '200px', mr: 2}}
                                        onChange={(event) => treatmentPatternsStore.setS2(
                                            {...treatmentPatternsStore.s2, other: event.target.value}
                                        )}
                                        value={treatmentPatternsStore.s2.other}/> : <></>}
                                </RadioGroup>
                            </Box>
                        </QuestionText> : <div/>}

                    </QuestionBox> : <></>}

                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(SubsequentTreatmentTumorBiologyPage1);
