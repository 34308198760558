import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import YesNo from "../../components/YesNo";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import QuestionsLayout from "../../layout/QuestionsLayout";


const SubsequentTreatmentPage1 = () => {
    const {
        afterInitialTreatmentStore, questionStore,menuStore,dataStore
    } = useStores()
    let params = useParams();
    useEffect(() => {
        afterInitialTreatmentStore.getAfterTreatment(parseInt(params.patient),parseInt(params.number))
    }, [])

    const navigate = useNavigate()
    console.log('afterInitialTreatmentStore.q8Prev',afterInitialTreatmentStore.q8Prev)
    console.log('afterInitialTreatmentStore.q8',afterInitialTreatmentStore.q8)

    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         backPress={() => navigate(-1)}
                         nextPress={async () => {
                             if (afterInitialTreatmentStore.q8 === null) {
                                 alert('Answer the Question!')
                             } else {
                                 if (afterInitialTreatmentStore.q8Prev === null &&
                                     (afterInitialTreatmentStore.q8 === 'true' || afterInitialTreatmentStore.q8 === true)) {
                                     if (window.confirm('Are you sure you want to create a subsequent treatment?')) {
                                         await afterInitialTreatmentStore.updateAfterTreatment(
                                             {q8: afterInitialTreatmentStore.q8}, parseInt(params.number), parseInt(params.patient))
                                         if (dataStore.forceUpdate === true) {
                                             dataStore.setShowUpdateModal('not active')
                                         }
                                         if (dataStore.showUpdateModal !== 'active') {
                                             if (afterInitialTreatmentStore.q8 === 'false') {
                                                 navigate('/dispositionDataPage1/' + parseInt(params.patient))

                                             } else {
                                                 navigate(`/subsequentTreatmentTumorBiologyPage1/${parseInt(params.patient)}/${parseInt(params.number) + 1}`)
                                                 questionStore.resetAllQuestions()
                                             }
                                         }
                                     }
                                 }else if(afterInitialTreatmentStore.q8Prev === null &&
                                     (afterInitialTreatmentStore.q8 === 'false' || afterInitialTreatmentStore.q8 === false)){
                                     console.log('afterInitialTreatmentStore.q8',afterInitialTreatmentStore.q8)
                                     if (window.confirm('Are you sure you want to go for disposition data at cut-off data?')) {

                                         await afterInitialTreatmentStore.updateAfterTreatment(
                                             {q8: afterInitialTreatmentStore.q8}, parseInt(params.number), parseInt(params.patient))
                                         if (dataStore.forceUpdate === true) {
                                             dataStore.setShowUpdateModal('not active')
                                         }
                                         if (dataStore.showUpdateModal !== 'active') {
                                             if (afterInitialTreatmentStore.q8 === 'false' || afterInitialTreatmentStore.q8 === false) {
                                                 navigate('/dispositionDataPage1/' + parseInt(params.patient))

                                             } else {
                                                 navigate(`/subsequentTreatmentTumorBiologyPage1/${parseInt(params.patient)}/${parseInt(params.number) + 1}`)
                                                 questionStore.resetAllQuestions()
                                             }
                                         }
                                     }
                                 }else if((afterInitialTreatmentStore.q8Prev === 'true' || afterInitialTreatmentStore.q8Prev === true) &&
                                     (afterInitialTreatmentStore.q8 === 'false' || afterInitialTreatmentStore.q8 === false)){
                                     if (window.confirm('If you choose the "No" option, all the information you previously filled in will be deleted. This applies to all subsequent treatments following this one. Do you wish to continue?')) {

                                         await afterInitialTreatmentStore.updateAfterTreatment(
                                             {q8: afterInitialTreatmentStore.q8}, parseInt(params.number), parseInt(params.patient))
                                         if (dataStore.forceUpdate === true) {
                                             dataStore.setShowUpdateModal('not active')
                                         }
                                         if (dataStore.showUpdateModal !== 'active') {
                                             navigate('/dispositionDataPage1/' + parseInt(params.patient))
                                         }
                                     }
                                 }else if((afterInitialTreatmentStore.q8Prev === 'false' || afterInitialTreatmentStore.q8Prev === false) &&
                                     (afterInitialTreatmentStore.q8 === 'true' || afterInitialTreatmentStore.q8 === true)){
                                     if (window.confirm('Are you sure you want to delete the disposition data at cut-off data and create a new subsequent treatment?')) {

                                         await afterInitialTreatmentStore.updateAfterTreatment(
                                             {q8: afterInitialTreatmentStore.q8}, parseInt(params.number), parseInt(params.patient))
                                         if (dataStore.forceUpdate === true) {
                                             dataStore.setShowUpdateModal('not active')
                                         }
                                         if (dataStore.showUpdateModal !== 'active') {
                                             navigate(`/subsequentTreatmentTumorBiologyPage1/${parseInt(params.patient)}/${parseInt(params.number) + 1}`)
                                             questionStore.resetAllQuestions()
                                         }
                                     }
                                 }else if((afterInitialTreatmentStore.q8Prev === 'true' || afterInitialTreatmentStore.q8Prev === true) &&
                                     (afterInitialTreatmentStore.q8 === 'true' || afterInitialTreatmentStore.q8 === true)){
                                     navigate(`/subsequentTreatmentTumorBiologyPage1/${parseInt(params.patient)}/${parseInt(params.number) + 1}`)
                                 }else if((afterInitialTreatmentStore.q8Prev === 'false' || afterInitialTreatmentStore.q8Prev === false) &&
                                     (afterInitialTreatmentStore.q8 === 'false' || afterInitialTreatmentStore.q8 === false)){
                                     navigate('/dispositionDataPage1/' + parseInt(params.patient))
                                 }
                             }
                         }}
                         loading={afterInitialTreatmentStore.loading}>
            <Box sx={{width: '100%', px: 4, mb: 0, mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>

                    <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32, lineHeight: 1.2}}>
                        Health Care Resource utilization after treatment completion or discontinuation
                    </Typography>
                    <Typography color={'txtGrey'} sx={{fontWeight: 'bold', fontSize: 18,}}>
                        [Please complete this section only during the time between treatments. Subsequent treatment data to be collected in section “Subsequent Treatment (n=2, 3, x ….)”.]
                    </Typography>
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>
                    <Typography sx={{fontWeight: 'bold', fontSize: 30,}}>
                        Subsequent treatment
                    </Typography>
                    <QuestionBox allInRow={true}>
                        <YesNo value={afterInitialTreatmentStore.q8}
                               handleChange={async (event) => {
                                   console.log('params=>', params)
                                   afterInitialTreatmentStore.setQ8(event.target.value)

                               }}
                        />
                    </QuestionBox>
                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(SubsequentTreatmentPage1);
