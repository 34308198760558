import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import YesNo from "../../components/YesNo";
import QuestionText from "../../components/QuestionText";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import QuestionsLayout from "../../layout/QuestionsLayout";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MissingReason from "../../components/MissingReason";


const ClinicalHistoryPage2 = () => {
    const {
        clinicalHistoryStore, dataStore, menuStore
    } = useStores()
    let urlParams = useParams();

    const navigate = useNavigate()
    const [showMissing, setShowMissing] = React.useState('not active');
    const [openOther, setOpenOther] = React.useState(null);
    const [openOtherTumor, setOpenOtherTumor] = React.useState(null);
    const [newOther, setNewOther] = React.useState('');
    const [newOtherTumor, setNewOtherTumor] = React.useState('');


    useEffect(() => {
        clinicalHistoryStore.getClinicalHistory(parseInt(urlParams.patient))
    }, [])
    const handleNext = async () => {
        if (!clinicalHistoryStore.checkMissing2) {
            await clinicalHistoryStore.updateClinicalHistory(
                {
                    q3: JSON.stringify(clinicalHistoryStore.q3),
                },parseInt(urlParams.patient))
            if(dataStore.forceUpdate == true){
                dataStore.setShowUpdateModal('not active')
            }
            if(dataStore.showUpdateModal !== 'active'){
                navigate('/environmentalExposurePage1/'+parseInt(urlParams.patient))
            }
        } else {
            alert('Please add reason for missing questions.')
            // if (!dataStore.globalMissing) {
            //     if (showMissing === 'active') {
            //         await clinicalHistoryStore.updateClinicalHistory(
            //             {
            //                 q3: clinicalHistoryStore.q3.main ? JSON.stringify(clinicalHistoryStore.q3) : dataStore.returnOtherObjectMissing,
            //             },parseInt(urlParams.patient))
            //         navigate('/environmentalExposurePage1/'+parseInt(urlParams.patient))
            //         setShowMissing('not active')
            //     } else {
            //         setShowMissing('active')
            //     }
            // } else {
            //     await clinicalHistoryStore.updateClinicalHistory(
            //         {
            //             q3: clinicalHistoryStore.q3.main ? JSON.stringify(clinicalHistoryStore.q3) : dataStore.returnOtherObjectMissing,
            //         },parseInt(urlParams.patient))
            //     navigate('/environmentalExposurePage1/'+parseInt(urlParams.patient))
            // }
        }
    }

    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={clinicalHistoryStore.loading}
        overflow={true}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>
                    <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                        Clinical History at diagnosis
                    </Typography>
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 1}}>
                    <QuestionBox>
                        <Stack direction={'row'} sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <QuestionText>
                                Comorbidities

                            </QuestionText>
                            {dataStore.questionsMissing.includes('q3')
                                ? <MissingReason
                                    style={{left:210,marginTop:3}}
                                    value={clinicalHistoryStore.q3.main}
                                    onSubmitMissing={async ()=>{
                                        clinicalHistoryStore.setQ3(
                                            {...clinicalHistoryStore.q3, main: dataStore.returnOtherTextMissing,})
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3'), 1)
                                    }}/> : <></>}
                            <YesNo value={clinicalHistoryStore.q3.main}
                                   handleChange={async (event) => {
                                       dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3'), 1)
                                       if(event.target.value === 'false'){
                                           await clinicalHistoryStore.updateClinicalHistory(
                                               {
                                                   q3: JSON.stringify({
                                                       diabetes: null,
                                                       hypertension: null,
                                                       autoImmune: null,
                                                       cardiovascular: null,
                                                       renal: null,
                                                       cerebrovascular: null,
                                                       hepatic: null,
                                                       depression: null,
                                                       chronicInfectious: null,
                                                       otherLungTumor: null,
                                                       otherTumor: {selected: null, value: []},
                                                       other: {selected: null, value: []}, main: event.target.value,
                                                   }),
                                               },parseInt(urlParams.patient))
                                           navigate('/environmentalExposurePage1/'+parseInt(urlParams.patient))
                                       }else{
                                           await clinicalHistoryStore.setQ3(
                                               {...clinicalHistoryStore.q3, main: event.target.value,})
                                       }
                                   }}
                            />
                        </Stack>
                        {clinicalHistoryStore.q3?.main && clinicalHistoryStore.q3.main === "true" ?
                            <Box width={'100%'}>
                                <div style={{marginLeft: 20}}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0

                                    }}>
                                        <li>Diabetes
                                            {dataStore.questionsMissing.includes('q3diabetes')
                                                ? <MissingReason
                                                    value={clinicalHistoryStore.q3.diabetes}
                                                    onSubmitMissing={async ()=>{
                                                        clinicalHistoryStore.setQ3(
                                                            {...clinicalHistoryStore.q3, diabetes: dataStore.returnOtherTextMissing,}
                                                        )
                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3diabetes'), 1)
                                                    }}/> : <></>}
                                        </li>

                                        <YesNo value={clinicalHistoryStore.q3.diabetes}
                                               handleChange={(event) => {
                                                   dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3diabetes'), 1)

                                                   clinicalHistoryStore.setQ3(
                                                   {...clinicalHistoryStore.q3, diabetes: event.target.value,}
                                               )}}
                                        />
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        alignItems: 'center',
                                        pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0

                                    }}>
                                        <li>Hypertension
                                            {dataStore.questionsMissing.includes('q3hypertension')
                                                ? <MissingReason
                                                    value={clinicalHistoryStore.q3.hypertension}
                                                    onSubmitMissing={async ()=>{
                                                        clinicalHistoryStore.setQ3(
                                                            {
                                                                ...clinicalHistoryStore.q3,
                                                                hypertension: dataStore.returnOtherTextMissing,
                                                            }
                                                        )
                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3hypertension'), 1)
                                                    }}/> : <></>}
                                        </li>
                                        <YesNo value={clinicalHistoryStore.q3.hypertension}
                                               handleChange={(event) => {
                                                   dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3hypertension'), 1)

                                                   clinicalHistoryStore.setQ3(
                                                   {
                                                       ...clinicalHistoryStore.q3,
                                                       hypertension: event.target.value,
                                                   }
                                               )}}
                                        />
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        alignItems: 'center',
                                        pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0

                                    }}>
                                        <li>Auto-immune disease
                                            {dataStore.questionsMissing.includes('q3autoImmune')
                                                ? <MissingReason
                                                    value={clinicalHistoryStore.q3.autoImmune}
                                                    onSubmitMissing={async ()=>{
                                                        clinicalHistoryStore.setQ3(
                                                            {...clinicalHistoryStore.q3, autoImmune: dataStore.returnOtherTextMissing,}
                                                        )
                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3autoImmune'), 1)
                                                    }}/> : <></>}
                                        </li>
                                        <YesNo value={clinicalHistoryStore.q3.autoImmune}
                                               handleChange={(event) => {
                                                   dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3autoImmune'), 1)

                                                   clinicalHistoryStore.setQ3(
                                                   {...clinicalHistoryStore.q3, autoImmune: event.target.value,}
                                               )}}
                                        />
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        alignItems: 'center',height:35,
                                        pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0

                                    }}>
                                        <li>Cardiovascular disease
                                            {dataStore.questionsMissing.includes('q3cardiovascular')
                                                ? <MissingReason
                                                    value={clinicalHistoryStore.q3.cardiovascular}

                                                    onSubmitMissing={async ()=>{
                                                        clinicalHistoryStore.setQ3(
                                                            {
                                                                ...clinicalHistoryStore.q3,
                                                                cardiovascular: dataStore.returnOtherTextMissing,
                                                            }
                                                        )
                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3cardiovascular'), 1)
                                                    }}/> : <></>}
                                        </li>
                                        <YesNo value={clinicalHistoryStore.q3.cardiovascular}
                                               handleChange={(event) => {
                                                   dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3cardiovascular'), 1)

                                                   clinicalHistoryStore.setQ3(
                                                   {
                                                       ...clinicalHistoryStore.q3,
                                                       cardiovascular: event.target.value,
                                                   }
                                               )}}
                                        />
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        alignItems: 'center',
                                        pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0

                                    }}>
                                        <li>Renal disease
                                            {dataStore.questionsMissing.includes('q3renal')
                                                ? <MissingReason
                                                    value={clinicalHistoryStore.q3.renal}

                                                    onSubmitMissing={async ()=>{
                                                        clinicalHistoryStore.setQ3(
                                                            {...clinicalHistoryStore.q3, renal:  dataStore.returnOtherTextMissing,}
                                                        )
                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3renal'), 1)
                                                    }}/> : <></>}
                                        </li>
                                        <YesNo value={clinicalHistoryStore.q3.renal}
                                               handleChange={(event) => {
                                                   dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3renal'), 1)

                                                   clinicalHistoryStore.setQ3(
                                                   {...clinicalHistoryStore.q3, renal: event.target.value,}
                                               )}}
                                        />
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        alignItems: 'center',
                                        pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0

                                    }}>
                                        <li>Cerebrovascular disease
                                            {dataStore.questionsMissing.includes('q3cerebrovascular')
                                                ? <MissingReason
                                                    value={clinicalHistoryStore.q3.cerebrovascular}

                                                    onSubmitMissing={async ()=>{
                                                        clinicalHistoryStore.setQ3(
                                                            {
                                                                ...clinicalHistoryStore.q3,
                                                                cerebrovascular: dataStore.returnOtherTextMissing,
                                                            }
                                                        )
                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3cerebrovascular'), 1)
                                                    }}/> : <></>}
                                        </li>
                                        <YesNo value={clinicalHistoryStore.q3.cerebrovascular}
                                               handleChange={(event) => {
                                                   dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3cerebrovascular'), 1)

                                                   clinicalHistoryStore.setQ3(
                                                   {
                                                       ...clinicalHistoryStore.q3,
                                                       cerebrovascular: event.target.value,
                                                   }
                                               )}}
                                        />
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        alignItems: 'center',
                                        pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0

                                    }}>
                                        <li>Hepatic disease
                                            {dataStore.questionsMissing.includes('q3hepatic')
                                                ? <MissingReason
                                                    value={clinicalHistoryStore.q3.hepatic}

                                                    onSubmitMissing={async ()=>{
                                                        clinicalHistoryStore.setQ3(
                                                            {...clinicalHistoryStore.q3, hepatic: dataStore.returnOtherTextMissing,}
                                                        )
                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3hepatic'), 1)
                                                    }}/> : <></>}
                                        </li>
                                        <YesNo value={clinicalHistoryStore.q3.hepatic}
                                               handleChange={(event) => {
                                                   dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3hepatic'), 1)

                                                   clinicalHistoryStore.setQ3(
                                                   {...clinicalHistoryStore.q3, hepatic: event.target.value,}
                                               )}}
                                        />
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        alignItems: 'center',
                                        pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0

                                    }}>
                                        <li>Depression
                                            {dataStore.questionsMissing.includes('q3depression')
                                                ? <MissingReason
                                                    value={clinicalHistoryStore.q3.depression}

                                                    onSubmitMissing={async ()=>{
                                                        clinicalHistoryStore.setQ3(
                                                            {...clinicalHistoryStore.q3, depression: dataStore.returnOtherTextMissing,}
                                                        )
                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3depression'), 1)
                                                    }}/> : <></>}
                                        </li>
                                        <YesNo value={clinicalHistoryStore.q3.depression}
                                               handleChange={(event) => {
                                                   dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3depression'), 1)

                                                   clinicalHistoryStore.setQ3(
                                                   {...clinicalHistoryStore.q3, depression: event.target.value,}
                                               )}}
                                        />
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        alignItems: 'center',
                                        pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0

                                    }}>
                                        <li>Chronic infectious disease
                                            {dataStore.questionsMissing.includes('chronicInfectious')
                                                ? <MissingReason
                                                    value={clinicalHistoryStore.q3.chronicInfectious}

                                                    onSubmitMissing={async ()=>{
                                                        clinicalHistoryStore.setQ3(
                                                            {
                                                                ...clinicalHistoryStore.q3,
                                                                chronicInfectious: dataStore.returnOtherTextMissing,
                                                            }
                                                        )
                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('chronicInfectious'), 1)
                                                    }}/> : <></>}
                                        </li>
                                        <YesNo value={clinicalHistoryStore.q3.chronicInfectious}
                                               handleChange={(event) => {
                                                   dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('chronicInfectious'), 1)

                                                   clinicalHistoryStore.setQ3(
                                                   {
                                                       ...clinicalHistoryStore.q3,
                                                       chronicInfectious: event.target.value,
                                                   }
                                               )}}
                                        />
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        alignItems: 'center',
                                        pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0

                                    }}>
                                        <li>Other lung tumor
                                            {dataStore.questionsMissing.includes('otherLungTumor')
                                                ? <MissingReason
                                                    value={clinicalHistoryStore.q3.otherLungTumor}
                                                    onSubmitMissing={async ()=>{
                                                        clinicalHistoryStore.setQ3(
                                                            {
                                                                ...clinicalHistoryStore.q3,
                                                                otherLungTumor: dataStore.returnOtherTextMissing,
                                                            }
                                                        )
                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('otherLungTumor'), 1)
                                                    }}/> : <></>}
                                        </li>
                                        <YesNo value={clinicalHistoryStore.q3.otherLungTumor}
                                               handleChange={(event) => {
                                                   dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('otherLungTumor'), 1)
                                                   clinicalHistoryStore.setQ3(
                                                   {...clinicalHistoryStore.q3, otherLungTumor: event.target.value,
                                                   }
                                               )}}
                                        />
                                    </Box>

                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        alignItems: 'flex-start',
                                        mt:1,
                                        pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0

                                    }}>
                                        <li>Other tumor
                                            {dataStore.questionsMissing.includes('otherTumor')
                                                ? <MissingReason
                                                    value={clinicalHistoryStore.q3.otherTumor.selected}
                                                    onSubmitMissing={async ()=>{
                                                        clinicalHistoryStore.setQ3(
                                                            {
                                                                ...clinicalHistoryStore.q3,
                                                                otherTumor: {
                                                                    ...clinicalHistoryStore.q3.otherTumor,
                                                                    selected: dataStore.returnOtherTextMissing,
                                                                }
                                                            }
                                                        )
                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('otherTumor'), 1)
                                                    }}/> : <></>}
                                        </li>
                                        <Box sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-end",
                                            width: "70%",
                                            maxWidth: 600,
                                        }}>
                                            <YesNo value={clinicalHistoryStore.q3?.otherTumor?.selected}
                                                   handleChange={(event) => {
                                                       dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('otherTumor'), 1)

                                                       clinicalHistoryStore.setQ3(
                                                           {
                                                               ...clinicalHistoryStore.q3,
                                                               otherTumor: {
                                                                   ...clinicalHistoryStore.q3.otherTumor,
                                                                   selected: event.target.value,
                                                               }
                                                           }
                                                       )
                                                       if (event.target.value === "true") {
                                                           setOpenOtherTumor(true)
                                                       } else {
                                                           setOpenOtherTumor(false)
                                                           setNewOtherTumor("")
                                                           clinicalHistoryStore.setQ3(
                                                               {
                                                                   ...clinicalHistoryStore.q3,
                                                                   otherTumor: {
                                                                       ...clinicalHistoryStore.q3.otherTumor,
                                                                       value: []
                                                                   }
                                                               }
                                                           )
                                                       }
                                                   }}
                                            />
                                            {openOtherTumor || (clinicalHistoryStore.q3.otherTumor.selected && clinicalHistoryStore.q3.otherTumor.selected === "true") ?
                                                <Box sx={{display: "flex", flexDirection: "row"}}>
                                                    {dataStore.questionsMissing.includes('otherTumorvalue') ?
                                                        <MissingReason
                                                            style={{marginLeft:-90,marginTop:3}}
                                                            value={typeof clinicalHistoryStore.q3.otherTumor.value === 'string' ? clinicalHistoryStore.q3.otherTumor.value : ''}
                                                            onSubmitMissing={async ()=>{
                                                                clinicalHistoryStore.setQ3(
                                                                    {
                                                                        ...clinicalHistoryStore.q3,
                                                                        otherTumor: {
                                                                            ...clinicalHistoryStore.q3.otherTumor,
                                                                            value: dataStore.returnOtherTextMissing
                                                                        }
                                                                    }
                                                                )
                                                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('otherTumorvalue'), 1)
                                                            }}/>
                                                        : <></>}
                                                    <TextField
                                                        size={'small'}
                                                        label="Specify Here"
                                                        margin="normal"
                                                        name="otherTumorPage2"
                                                        sx={{width: '100%', mt: 1}}
                                                        onChange={(event) => {
                                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('otherTumorvalue'), 1)

                                                            setNewOtherTumor(event.target.value)
                                                        }}
                                                        value={newOtherTumor}
                                                    />
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        sx={{
                                                            ml: 1,
                                                            fontSize: 12,
                                                            color: "white",
                                                            height: 40,
                                                            mt: 1,
                                                            mb: 1,
                                                            p: 0,mr:3
                                                        }}
                                                        onClick={() => {
                                                            if (newOtherTumor === '') {
                                                                return
                                                            }
                                                            let otherTumor = typeof clinicalHistoryStore.q3.otherTumor.value === 'string' ? [] : clinicalHistoryStore.q3.otherTumor.value
                                                            if (otherTumor === null) {
                                                                otherTumor = []
                                                            }
                                                            if(otherTumor.includes(newOtherTumor)){
                                                                alert('You are trying to add duplicate value.')
                                                            }else{
                                                                otherTumor.push(newOtherTumor)

                                                            }

                                                            clinicalHistoryStore.setQ3({
                                                                ...clinicalHistoryStore.q3,
                                                                otherTumor: {
                                                                    ...clinicalHistoryStore.q3.otherTumor,
                                                                    value: otherTumor,
                                                                }
                                                            })
                                                            setNewOtherTumor('');
                                                        }}>
                                                        Add
                                                    </Button>
                                                </Box>
                                                : <></>}
                                        </Box>

                                    </Box>
                                    <Box sx={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row-reverse",
                                        flexWrap: "wrap",
                                        rowGap: 1,
                                    }}>
                                        {Array.isArray(clinicalHistoryStore.q3.otherTumor.value) && clinicalHistoryStore.q3.otherTumor.value.map((item, index) =>
                                            <Box key={"OTHERT__" + index}
                                                 sx={{
                                                     minWidth: 150,
                                                     pl: 1,
                                                 }}>
                                                <Paper sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    flex: 1,
                                                    alignItems: "center",
                                                    justifyContent: "flex-end",
                                                    p: 1,
                                                    pl: 2
                                                }}>
                                                    <Typography sx={{mr: 1}}>
                                                        {item}
                                                    </Typography>
                                                    <IconButton onClick={() => {
                                                        let others = clinicalHistoryStore.q3.otherTumor.value
                                                        others.splice(index, 1)
                                                        clinicalHistoryStore.setQ3(
                                                            {
                                                                ...clinicalHistoryStore.q3,
                                                                otherTumor: {
                                                                    ...clinicalHistoryStore.q3.otherTumor,
                                                                    value: others
                                                                }
                                                            }
                                                        )
                                                    }}>
                                                        <DeleteIcon color="error"/>
                                                    </IconButton>
                                                </Paper>
                                            </Box>)}
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        alignItems: 'flex-start',
                                        pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0

                                    }}>
                                        <li>Other
                                            {dataStore.questionsMissing.includes('other')
                                                ? <MissingReason
                                                    value={clinicalHistoryStore.q3.other.selected}
                                                    onSubmitMissing={async ()=>{
                                                        clinicalHistoryStore.setQ3(
                                                            {
                                                                ...clinicalHistoryStore.q3,
                                                                other: {
                                                                    ...clinicalHistoryStore.q3.other,
                                                                    selected: dataStore.returnOtherTextMissing,
                                                                }
                                                            }
                                                        )
                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('other'), 1)
                                                    }}/> : <></>}

                                        </li>
                                        <Box sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-end",
                                            width: "70%",
                                            maxWidth: 600,
                                        }}>
                                            <YesNo value={clinicalHistoryStore.q3.other.selected}
                                                   handleChange={(event) => {
                                                       dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('other'), 1)
                                                       clinicalHistoryStore.setQ3(
                                                           {
                                                               ...clinicalHistoryStore.q3,
                                                               other: {
                                                                   ...clinicalHistoryStore.q3.other,
                                                                   selected: event.target.value,
                                                               }
                                                           }
                                                       )
                                                       if (event.target.value === "true") {
                                                           setOpenOther(true)
                                                       } else {
                                                           setOpenOther(false)
                                                           setNewOther("")
                                                           clinicalHistoryStore.setQ3(
                                                               {
                                                                   ...clinicalHistoryStore.q3,
                                                                   other: {
                                                                       ...clinicalHistoryStore.q3.other,
                                                                       value: [],
                                                                   }
                                                               }
                                                           )
                                                       }

                                                   }}
                                          />
                                            {openOther || (clinicalHistoryStore.q3.other.selected && clinicalHistoryStore.q3.other.selected === "true") ?
                                                <Box sx={{display: "flex", flexDirection: "row"}}>
                                                    {dataStore.questionsMissing.includes('othervalue')
                                                        ? <MissingReason
                                                            style={{marginLeft:-90,marginTop:3}}
                                                            value={typeof clinicalHistoryStore.q3.other.value === 'string' ? clinicalHistoryStore.q3.other.value : ''}
                                                            onSubmitMissing={async ()=>{
                                                                clinicalHistoryStore.setQ3({
                                                                    ...clinicalHistoryStore.q3,
                                                                    other: {
                                                                        ...clinicalHistoryStore.q3.other,
                                                                        value: dataStore.returnOtherTextMissing,
                                                                    }
                                                                })
                                                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('othervalue'), 1)
                                                            }}/> : <></>}
                                                    <TextField
                                                        size={'small'}
                                                        label="Specify Here"
                                                        margin="normal"
                                                        name="otherPage2"
                                                        sx={{width: '100%', mt: 1}}
                                                        onChange={(event) => {
                                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('othervalue'), 1)
                                                            setNewOther(event.target.value)
                                                        }}
                                                        value={newOther}
                                                    />
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        sx={{
                                                            ml: 1,
                                                            fontSize: 12,
                                                            color: "white",
                                                            height: 40,
                                                            mt: 1,
                                                            mb: 1,
                                                            p: 0,mr:3
                                                        }}
                                                        onClick={() => {
                                                            if (newOther === '') {
                                                                return
                                                            }
                                                            let others = typeof clinicalHistoryStore.q3.other.value === 'string' ? [] : clinicalHistoryStore.q3.other.value
                                                            if (others === null) {
                                                                others = []
                                                            }
                                                            if(others.includes(newOther)){
                                                                alert('You are trying to add duplicate value.')
                                                            }else{
                                                                others.push(newOther)
                                                            }
                                                            clinicalHistoryStore.setQ3({
                                                                ...clinicalHistoryStore.q3,
                                                                other: {
                                                                    ...clinicalHistoryStore.q3.other,
                                                                    value: others,
                                                                }
                                                            })
                                                            setNewOther('');
                                                        }}>
                                                        Add
                                                    </Button>
                                                </Box>
                                                : <></>}
                                        </Box>

                                    </Box>
                                    <Box sx={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row-reverse",
                                        flexWrap: "wrap",
                                        rowGap: 1
                                    }}>
                                        {Array.isArray(clinicalHistoryStore.q3.other.value) && clinicalHistoryStore.q3.other.value.map((item, index) =>
                                            <Box key={"OTHER__" + index}
                                                 sx={{
                                                     minWidth: 150,
                                                     pl: 1,
                                                 }}>
                                                <Paper sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    flex: 1,
                                                    alignItems: "center",
                                                    justifyContent: "flex-end",
                                                    p: 1,
                                                    pl: 2
                                                }}>
                                                    <Typography sx={{mr: 1}}>
                                                        {item}
                                                    </Typography>
                                                    <IconButton onClick={() => {
                                                        let others = clinicalHistoryStore.q3.other.value
                                                        others.splice(index, 1)
                                                        clinicalHistoryStore.setQ3(
                                                            {
                                                                ...clinicalHistoryStore.q3,
                                                                other: {
                                                                    ...clinicalHistoryStore.q3.other,
                                                                    value: others
                                                                }
                                                            }
                                                        )
                                                    }}>
                                                        <DeleteIcon color="error"/>
                                                    </IconButton>
                                                </Paper>
                                            </Box>)}
                                    </Box>
                                </div>
                            </Box> : <></>}
                    </QuestionBox>
                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(ClinicalHistoryPage2);
