import * as React from 'react';
import Typography from '@mui/material/Typography';

import account from "../images/svgs/Account.svg";
import accountGreen from "../images/svgs/AccountGreen.svg";
import {Stack} from "@mui/material";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";

const Account = ({active}) => {
    const navigate = useNavigate()

    return (
        <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 4,}}>
            <Stack onClick={() => navigate('/myAccount')} spacing={2} direction={'row'} alignItems={'center'}
                   justifyContent={'center'}
                   sx={{
                       cursor: 'pointer',
                       backgroundColor: active ? 'white' : 'transparent',
                       py: 1,
                       px: 2,
                       borderRadius: 9,
                       ml:-2
                   }}>
                <img src={active ? accountGreen : account} alt="my_account" style={{width: 40, height: 40}}/>
                <Typography color={active ? 'primary' : 'white'} sx={{fontSize: 22, fontWeight: 'bold'}}>
                    Account
                </Typography>
            </Stack>
        </Box>
    );
}

export default Account;
