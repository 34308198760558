import {action, computed, makeObservable, observable} from 'mobx'
import {CrfService} from "../../services/openapi";
import {Stores} from "../../store";

class ClinicalHistoryStore {
    loading = false
    q1 = {
        main: null,
        copd: null,
        pulmonaryFibrosis: null,
        other: {selected: null, value: []},
    }
    q2 = null
    q3 = {
        main: null,
        diabetes: null,
        hypertension: null,
        autoImmune: null,
        cardiovascular: null,
        renal: null,
        cerebrovascular: null,
        hepatic: null,
        depression: null,
        chronicInfectious: null,
        otherLungTumor: null,
        otherTumor: {selected: null, value: []},
        other: {selected: null, value: []},
    }

    constructor() {

        makeObservable(this, {
            loading: observable,
            q1: observable,
            q2: observable,
            q3: observable,
            setQ1: action,
            setQ2: action,
            setQ3: action,
            setLoading: action,
            updateClinicalHistory: action,
            getClinicalHistory: action,
            reset: action,
            checkMissing1: computed,
            checkMissing2: computed,
            checkMissing3: computed,
        },)
    }

    reset = () => {
        this.setQ1({
            main: null,
            copd: null,
            pulmonaryFibrosis: null,
            other: {selected: null, value: []},
        })
        this.setQ2(null)
        this.setQ3({
            main: null,
            diabetes: null,
            hypertension: null,
            autoImmune: null,
            cardiovascular: null,
            renal: null,
            cerebrovascular: null,
            hepatic: null,
            depression: null,
            chronicInfectious: null,
            otherLungTumor: null,
            otherTumor: {selected: null, value: []},
            other: {selected: null, value: []},
        })
    }

    get checkMissing1() {
        console.log('this.q1',this.q1)
        if (this.q1 == null || this.q1.main == null) {
            Stores.dataStore.setQuestionsMissing('q1')
        }
        if(this.q2 == null){
            Stores.dataStore.setQuestionsMissing('q2')
        }
        if (this.q1.main === 'true') {
            if (this.q1.copd == null) {
                Stores.dataStore.setQuestionsMissing('copd')
            }
            if (this.q1.pulmonaryFibrosis == null) {
                Stores.dataStore.setQuestionsMissing('pulmonaryFibrosis')
            }
            if (this.q1.other.selected == null) {
                Stores.dataStore.setQuestionsMissing('other')
            }
            if((typeof this.q1.other.value === 'string' && !this.q1.other.value.startsWith('missing|')) || this.q1.other.value.length === 0){
                Stores.dataStore.setQuestionsMissing('q1othervalue')
            }

        }
        if((this.q1 == null || this.q1.main == null) || this.q2 == null ||
            (this.q1.main === 'true' && (this.q1.copd == null || this.q1.pulmonaryFibrosis == null || this.q1.other.selected == null))
        || (this.q1.other.selected === 'true' && ((typeof this.q1.other.value === 'string' && !this.q1.other.value.startsWith('missing|')) || this.q1.other.value.length === 0))){
            return true
        }
    }

    get checkMissing2() {
        console.log('this.q3',this.q3)
        if (this.q3 == null || this.q3.main == null) {
            Stores.dataStore.setQuestionsMissing('q3')
        }
        if (this.q3 == null || this.q3.main == null) {
           return true
        }
        if (this.q3.main == 'true' || this.q3.main == true) {
            if (this.q3.diabetes == null) {
                Stores.dataStore.setQuestionsMissing('q3diabetes')
            }

            if (this.q3.hypertension == null) {
                Stores.dataStore.setQuestionsMissing('q3hypertension')
            }

            if (this.q3.autoImmune == null) {
                Stores.dataStore.setQuestionsMissing('q3autoImmune')
            }

            if (this.q3.cardiovascular == null) {
                Stores.dataStore.setQuestionsMissing('q3cardiovascular')
            }

            if (this.q3.renal == null) {
                Stores.dataStore.setQuestionsMissing('q3renal')
            }

            if (this.q3.cerebrovascular == null) {
                Stores.dataStore.setQuestionsMissing('q3cerebrovascular')
            }

            if (this.q3.hepatic == null) {
                Stores.dataStore.setQuestionsMissing('q3hepatic')
            }

            if (this.q3.depression == null) {
                Stores.dataStore.setQuestionsMissing('q3depression')
            }
            if (this.q3.chronicInfectious == null) {
                Stores.dataStore.setQuestionsMissing('chronicInfectious')
            }
            if (this.q3.otherLungTumor == null) {
                Stores.dataStore.setQuestionsMissing('otherLungTumor')
            }
            if (this.q3.otherTumor.selected == null) {
                Stores.dataStore.setQuestionsMissing('otherTumor')
            }
            if (this.q3.otherTumor.selected === 'true' && this.q3.otherTumor.value.length === 0) {
                Stores.dataStore.setQuestionsMissing('otherTumorvalue')
            }
            if (this.q3.other.selected == null) {
                Stores.dataStore.setQuestionsMissing('other')
            }
            if (this.q3.other.selected === 'true' && this.q3.other.value.length === 0) {
                Stores.dataStore.setQuestionsMissing('othervalue')
            }
            if (this.q3.diabetes == null || this.q3.hypertension == null || this.q3.autoImmune == null
                || this.q3.cardiovascular == null || this.q3.renal == null ||
                this.q3.cerebrovascular == null || this.q3.hepatic == null || this.q3.depression == null || this.q3.chronicInfectious == null
                || this.q3.otherLungTumor == null|| this.q3.otherTumor.selected == null|| this.q3.other.selected == null
            || (this.q3.otherTumor.selected === 'true' && this.q3.otherTumor.value.length === 0) ||
                (this.q3.other.selected === 'true' && this.q3.other.value.length === 0)) {
                return true;
            }
        }
    }

    get checkMissing3() {
        if (this.q3 == null || this.q3.main == null || this.q3.main === 'false') {
            return true;
        } else {
            if (this.q3.chronicInfectious == null
                || this.q3.otherLungTumor == null || this.q3.otherTumor.selected == null || this.q3.other.selected == null) {
                return true;
            } else if ((this.q3.otherTumor.selected === 'true' && this.q3.otherTumor.value.length === 0) ||
                (this.q3.other.selected === 'true' && this.q3.other.value.length === 0)) {
                return true

            }
        }
        return false;
    }

    setQ1 = (value) => {
        this.q1 = value
    }

    setQ2 = (value) => {
        this.q2 = value
    }
    setQ3 = (value) => {
        this.q3 = value
    }

    setLoading = (value) => {
        this.loading = value
    }

    updateClinicalHistory = async (requestBody,patient) => {
        try {
            this.setLoading(true)
            console.log('requestBody', requestBody)
            let params = {...requestBody,force:Stores.dataStore.forceUpdate}

            if(Stores.dataStore.updateReason !== ''){
                params = {...params, reason:Stores.dataStore.updateReason}
            }
            console.log('updateClinicalHistory params', params)

            const response = await CrfService.updateClinicalHistory(patient, params)
            console.log('updateClinicalHistory response', response)
            if (response.q1 != null) {
                this.setQ1(response.q1)
            }
            if (response.q2 != null) {
                this.setQ2(response.q2)
            }
            if (response.q3 != null) {
                this.setQ3(response.q3)
            }
            Stores.dataStore.resetUpdateModalData()
            this.setLoading(false)
        } catch (err) {
            this.setLoading(false)
            console.log('updateClinicalHistory err', err)
            if(err?.body?.data?.updated){
                Stores.dataStore.setShowUpdateModal('active')
            }else {
                Stores.dataStore.setShowUpdateModal('not active')
            }
        }
    }


    getClinicalHistory = async (patient) => {
        try {
            this.setLoading(true)

            const response = await CrfService.getClinicalHistory(patient)
            console.log('getClinicalHistory response', response)

            if (response.q1 != null) {
                if(response.q1.main.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q1')
                }

                if(response.q1?.copd && response.q1.copd.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('copd')
                }
                if(response.q1?.pulmonaryFibrosis && response.q1.pulmonaryFibrosis.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('pulmonaryFibrosis')
                }
                if(response.q1?.other?.selected && response.q1.other.selected.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('other')
                }
                if(typeof response.q1.other.value === 'string' && response.q1.other.value.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q1othervalue')
                }
                this.setQ1(response.q1)
            }
            if (response.q2 != null) {
                if(response.q2.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q2')
                }
                this.setQ2(response.q2)
            }
            if (response.q3 != null) {
                this.setQ3(response.q3)
                if (response.q3?.main && response.q3.main.startsWith('missing|')) {
                    Stores.dataStore.setQuestionsMissing('q3')
                }
                    if (response.q3?.diabetes && response.q3.diabetes.startsWith('missing|')) {
                        Stores.dataStore.setQuestionsMissing('q3diabetes')
                    }

                    if (response.q3?.hypertension && response.q3.hypertension.startsWith('missing|')) {
                        Stores.dataStore.setQuestionsMissing('q3hypertension')
                    }

                    if (response.q3?.autoImmune && response.q3.autoImmune.startsWith('missing|')) {
                        Stores.dataStore.setQuestionsMissing('q3autoImmune')
                    }

                    if (response.q3?.cardiovascular && response.q3.cardiovascular.startsWith('missing|')) {
                        Stores.dataStore.setQuestionsMissing('q3cardiovascular')
                    }

                    if (response.q3?.renal && response.q3.renal.startsWith('missing|')) {
                        Stores.dataStore.setQuestionsMissing('q3renal')
                    }

                    if (response.q3?.cerebrovascular && response.q3.cerebrovascular.startsWith('missing|')) {
                        Stores.dataStore.setQuestionsMissing('q3cerebrovascular')
                    }

                    if (response.q3?.hepatic && response.q3.hepatic.startsWith('missing|')) {
                        Stores.dataStore.setQuestionsMissing('q3hepatic')
                    }

                    if (response.q3?.depression && response.q3.depression.startsWith('missing|')) {
                        Stores.dataStore.setQuestionsMissing('q3depression')
                    }
                    if (response.q3?.chronicInfectious && response.q3.chronicInfectious.startsWith('missing|')) {
                        Stores.dataStore.setQuestionsMissing('chronicInfectious')
                    }
                    if (response.q3?.otherLungTumor && response.q3.otherLungTumor.startsWith('missing|')) {
                        Stores.dataStore.setQuestionsMissing('otherLungTumor')
                    }
                    if (response.q3?.otherTumor?.selected && response.q3.otherTumor.selected.startsWith('missing|')) {
                        Stores.dataStore.setQuestionsMissing('otherTumor')
                    }
                    if (response.q3?.other?.selected && response.q3.other.selected.startsWith('missing|')) {
                        Stores.dataStore.setQuestionsMissing('other')
                    }
                    if (typeof response.q3.other.value === 'string' &&  response.q3.other.value.startsWith('missing|')) {
                        Stores.dataStore.setQuestionsMissing('otherTumorvalue')
                    }
                    if (typeof response.q3.other.value === 'string' &&  response.q3.other.value.startsWith('missing|')) {
                        Stores.dataStore.setQuestionsMissing('othervalue')
                    }
            }

            Stores.dataStore.setForceUpdate(false)

            this.setLoading(false)

        } catch (err) {
            this.setLoading(false)
            console.log('getClinicalHistory err', err)
        }
    }


}


export default ClinicalHistoryStore
