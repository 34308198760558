import {useStores,} from '../store'
import * as React from 'react';
import Typography from '@mui/material/Typography';
import theme from '../styles/theme'
import logo from "../images/svgs/Logo-white.svg";
import {Stack} from "@mui/material";
import plus from "../images/svgs/Plus.svg";
import plusWhite from "../images/svgs/Plus-white.svg";
import {observer} from "mobx-react";
import MenuItem from "./MenuItem";
import eyeWhite from "../images/svgs/Eye-white.svg";
import eyeGreen from "../images/svgs/Eye-green.svg";
import delegate from "../images/svgs/Delegate.svg";
import delegateGreen from "../images/svgs/Delegate-green.svg";
import exportIcon from "../images/svgs/export.svg";
import exportWhite from "../images/svgs/exportWhite.svg";
import Account from "../components/Account";
import {useNavigate} from "react-router-dom";
import auditGreen from "../images/svgs/Audit-green.svg";
import auditWhite from "../images/svgs/Audit-white.svg";
import Box from "@mui/material/Box";
import HelpDesk from "../components/HelpDesk";

const Menu = ({activeItem}) => {
    const {
        authStore,menuStore
    } = useStores()
    const navigate = useNavigate()
    return (
        <Box sx={{
            backgroundColor: theme.palette.primary.main,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100vh',
            width: '360px',
        }}>
            <Stack onClick={() => navigate('/')} sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                m: 8,
                cursor: 'pointer'
            }}>
                <img src={logo} alt="logo"/>
                <Typography component="h1" variant="h5" sx={{color: 'white', mt: 1}}>
                    Characterize
                </Typography>
            </Stack>
            <div style={{display: 'flex', flex: 1, width: '100%', flexDirection: 'column'}}>
                {
                    authStore.userType === 'X2' ?
                        <div>
                            <MenuItem onClick={() => {
                                navigate('/viewDataAudit')
                                menuStore.setSelectedItem('viewDataAudit')
                            }} icon={activeItem === 'viewData' ? eyeGreen : eyeWhite} label={'View Data'}
                                      active={activeItem === 'viewData'}/>
                            <MenuItem onClick={() => {
                                navigate('/pIDelegates')
                                menuStore.setSelectedItem('pIDelegates')

                            }} icon={activeItem === 'pIDelegates' ? delegateGreen : delegate} label={'PI/Delegates'}
                                      active={activeItem === 'pIDelegates'}/>
                            <MenuItem onClick={() => {
                                navigate('/auditTrail')
                                menuStore.setSelectedItem('auditTrail')

                            }} icon={activeItem === 'auditTrail' ? auditGreen : auditWhite} label={'Audit Trail'}
                                      active={activeItem === 'auditTrail'}/>

                            <MenuItem onClick={() => {
                                navigate('/exportDB')
                                menuStore.setSelectedItem('exportDB')
                            }} icon={activeItem === 'exportDB' ? exportIcon : exportWhite} label={'Export DB'}
                                      active={activeItem === 'exportDB'}/>
                        </div> : authStore.userType === 'SuperAdmin' ?
                            <div>
                                <MenuItem onClick={() => {
                                    navigate('/pIDelegates')
                                    menuStore.setSelectedItem('pIDelegates')

                                }} icon={activeItem === 'pIDelegates' ? delegateGreen : delegate} label={'PI/Delegates'}
                                          active={activeItem === 'pIDelegates'}/>

                                <MenuItem onClick={() => {
                                    navigate('/auditTrail')
                                    menuStore.setSelectedItem('auditTrail')

                                }} icon={activeItem === 'auditTrail' ? auditGreen : auditWhite} label={'Audit Trail'}
                                          active={activeItem === 'auditTrail'}/>

                            </div> :

                            <div>
                                <MenuItem onClick={() => {
                                    navigate('/addData')
                                    menuStore.setSelectedItem('addData')

                                }} icon={activeItem === 'addData' ? plus : plusWhite} label={'Add Data'}
                                          active={activeItem === 'addData'}/>
                                <MenuItem onClick={() => {
                                    navigate('/viewData')
                                    menuStore.setSelectedItem('viewData')

                                }} icon={activeItem === 'viewData' ? eyeGreen : eyeWhite} label={'View Data'}
                                          active={activeItem === 'viewData'}/>

                                {authStore.userType === 'PI' && <MenuItem onClick={() => {
                                    navigate('/delegate')
                                    menuStore.setSelectedItem('delegate')

                                }} icon={activeItem === 'delegates' ? delegateGreen : delegate} label={'Delegates'}
                                                                          active={activeItem === 'delegates'}/>}

                            </div>

                }
            </div>
            <HelpDesk/>
            <Account active={activeItem === 'myAccount'}/>

        </Box>
    )

}

export default observer(Menu)
