import * as React from 'react';
import Typography from '@mui/material/Typography';

import helpDeskGreen from "../images/svgs/HelpDeskGreen.svg";
import helpDeskWhite from "../images/svgs/HelpDeskWhite.svg";
import {RadioGroup, Stack} from "@mui/material";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import RadioItem from "./RadioItem";
import Paper from "@mui/material/Paper";
import theme from "../styles/theme";
import Button from "@mui/material/Button";

const HelpDesk = () => {
    const navigate = useNavigate()
    const [active, setActive] = React.useState(false);

    return (
        <Box sx={{width: '90%',alignSelf:'center',display:'flex',backgroundColor: active ? 'white' : 'transparent',borderRadius: 3}}>
            <Stack direction={'column'} sx={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
                borderRadius:7}}>
                {active ?<Stack direction={'column'} sx={{pt:2,pb:1,width: '100%',alignItems:'center'}} spacing={1}>
                    <Button sx={{
                        display: 'flex', alignItems: 'center',
                        backgroundColor:  theme.palette.primary.main,
                        width: '90%',
                        justifyContent: "center",
                        textTransform: 'none',
                        '&:hover': {
                            background: theme.palette.primary.main,
                        }
                    }} onClick={()=>{
                        window.open('mailto:technicalsupport@xrscience.pt', '_self')
                    }}>
                    <Typography sx={{fontSize: 21,color:'white'}}>
                        Technical Support
                    </Typography>
                    </Button>
                    <Button sx={{
                        display: 'flex', alignItems: 'center',
                        backgroundColor:  theme.palette.primary.main,
                        width: '90%',
                        justifyContent: "center",
                        textTransform: 'none',
                        '&:hover': {
                            background: theme.palette.primary.main,
                        }
                    }} onClick={()=>{
                        window.open('mailto:scientificsupport@xrscience.pt', '_self')
                    }}>
                    <Typography sx={{fontSize: 21,color:'white'}} >
                        Scientific Support
                    </Typography>
                    </Button>
                </Stack> : <></>}

            <Stack onClick={() => setActive(!active)} spacing={2} direction={'row'} alignItems={'center'}
                   justifyContent={'center'}
                   sx={{
                       cursor: 'pointer',
                       py: 1,
                       px: 2,

                   }}>
                <img src={active ? helpDeskGreen : helpDeskWhite} alt="my_account" style={{width: 40, height: 40}}/>
                <Typography color={active ? 'primary' : 'white'} sx={{fontSize: 22, fontWeight: 'bold'}}>
                    Help Desk
                </Typography>
            </Stack>
            </Stack>
        </Box>
    );
}

export default HelpDesk;
