import React, {lazy} from "react";
import {createBrowserRouter,} from "react-router-dom";
import App from "../app";
import {ProtectedRoute} from "../components/ProtectedRoute";
import ChooseArea from "../data/ChooseArea";
import ForgetPassword from "../auth/ForgetPassword";
import ResetPassword from "../auth/ResetPassword";
import CreateX2Delegate from "../auth/CreateX2Delegate";
import InclusionCriteriaPage1 from "../questions/inclusionCriteria/InclusionCriteriaPage1";
import ExclusionCriteriaPage1 from "../questions/exclusionCriteria/ExclusionCriteriaPage1";
import SocioDemographicCharacteristicsPage1
    from "../questions/socioDemographicCharacteristics/SocioDemographicCharacteristicsPage1";
import ClinicalHistoryPage1 from "../questions/clinicalHistory/ClinicalHistoryPage1";
import ClinicalHistoryPage2 from "../questions/clinicalHistory/ClinicalHistoryPage2";
import EnvironmentalExposurePage1 from "../questions/environmentalExposure/EnvironmentalExposurePage1";
import ClinicalCharacterizationPage1 from "../questions/clinicalCharacterization/ClinicalCharacterizationPage1";
import ClinicalCharacterizationPage2 from "../questions/clinicalCharacterization/ClinicalCharacterizationPage2";
import TumorBiologyPage1 from "../questions/tumorBiology/TumorBiologyPage1";
import TumorBiologyPage2 from "../questions/tumorBiology/TumorBiologyPage2";
import TumorBiologyPage3 from "../questions/tumorBiology/TumorBiologyPage3";
import TreatmentPatternsDuration from "../questions/treatmentPatterns/TreatmentPatternsDuration";
import TreatmentPatternsNeoadjuvant from "../questions/treatmentPatterns/TreatmentPatternsNeoadjuvant";
import TreatmentPatternsSurgery from "../questions/treatmentPatterns/TreatmentPatternsSurgery";
import TreatmentPatternsRTAlone from "../questions/treatmentPatterns/TreatmentPatternsRTAlone";
import TreatmentPatternsRadioFrequency from "../questions/treatmentPatterns/TreatmentPatternsRadioFrequency";
import SystemicTherapyPage1 from "../questions/systemicTherapy/SystemicTherapyPage1";
import SystemicTherapyPage2 from "../questions/systemicTherapy/SystemicTherapyPage2";
import SystemicTherapyPage3 from "../questions/systemicTherapy/SystemicTherapyPage3";
import SystemicTherapyPage4 from "../questions/systemicTherapy/SystemicTherapyPage4";
import ConcomitantMedicationsPage1 from "../questions/concomitantMedications/ConcomitantMedicationsPage1";
import ConcomitantMedicationsPage2 from "../questions/concomitantMedications/ConcomitantMedicationsPage2";
import ConcomitantMedicationsPage3 from "../questions/concomitantMedications/ConcomitantMedicationsPage3";
import ImagingAssessmentsPage1 from "../questions/imagingAssessments/ImagingAssessmentsPage1";
import ImagingAssessmentsPage2 from "../questions/imagingAssessments/ImagingAssessmentsPage2";
import LaboratoryTestsPage1 from "../questions/laboratoryTests/LaboratoryTestsPage1";
import LaboratoryTestsPage2 from "../questions/laboratoryTests/LaboratoryTestsPage2";
import OtherExamsPage1 from "../questions/otherExams/OtherExamsPage1";
import OtherExamsPage2 from "../questions/otherExams/OtherExamsPage2";
import HospitalizationsPage1 from "../questions/hospitalizations/HospitalizationsPage1";
import EmergencyConsultationsPage1 from "../questions/emergencyConsultations/EmergencyConsultationsPage1";
import DiseaseRelatedPage1 from "../questions/diseaseRelated/DiseaseRelatedPage1";
import SubsequentTreatmentPage1 from "../questions/subsequentTreatment/SubsequentTreatmentPage1";
import SubsequentTreatmentTumorBiologyPage1
    from "../questions/subsequentTreatment/SubsequentTreatmentTumorBiologyPage1";
import SubsequentTreatmentTumorBiologyPage2
    from "../questions/subsequentTreatment/SubsequentTreatmentTumorBiologyPage2";
import TimeDurationPage1 from "../questions/timeDuration/TimeDurationPage1";
import DispositionDataPage1 from "../questions/dispositionData/DispositionDataPage1";
import ImagingAssessmentsAfterInitialTreatmentPage2
    from "../questions/afterInitialTreatment/ImagingAssessmentsAfterInitialTreatmentPage2";
import ImagingAssessmentsAfterInitialTreatmentPage1
    from "../questions/afterInitialTreatment/ImagingAssessmentsAfterInitialTreatmentPage1";
import LaboratoryTestsAfterInitialTreatmentPage1
    from "../questions/afterInitialTreatment/LaboratoryTestsAfterInitialTreatmentPage1";
import LaboratoryTestsAfterInitialTreatmentPage2
    from "../questions/afterInitialTreatment/LaboratoryTestsAfterInitialTreatmentPage2";
import OtherExamsAfterInitialPage1 from "../questions/afterInitialTreatment/OtherExamsAfterInitialPage1";
import OtherExamsAfterInitialPage2 from "../questions/afterInitialTreatment/OtherExamsAfterInitialPage2";
import HospitalizationsAfterInitialTreatmentPage1
    from "../questions/afterInitialTreatment/HospitalizationsAfterInitialTreatmentPage1";
import DiseaseRelatedAfterInitialPage1 from "../questions/afterInitialTreatment/DiseaseRelatedAfterInitialPage1";
import SubsequentTreatmentTumorBiologyPage3
    from "../questions/subsequentTreatment/SubsequentTreatmentTumorBiologyPage3";
import PatientCompleted from "../questions/dispositionData/PatientCompleted";
import ChangeName from "../myAccount/ChangeName";
import LaboratoryTestsPage3 from "../questions/laboratoryTests/LaboratoryTestsPage3";
import LaboratoryTestsAfterInitialTreatmentPage3
    from "../questions/afterInitialTreatment/LaboratoryTestsAfterInitialTreatmentPage3";
import AfterInitialEmergencyConsultationsPage1
    from "../questions/afterInitialTreatment/AfterInitialEmergencyConsultationsPage1";
import Error404 from "../error404/Error404";
import SystemicTherapyPage5 from "../questions/systemicTherapy/SystemicTherapyPage5";

const AddData = lazy(() => import("../data/AddData"))
const MyAccount = lazy(() => import("../myAccount/MyAccount"))
const ChangeEmail = lazy(() => import("../myAccount/ChangeEmail"))
const ChangePhoneNumber = lazy(() => import("../myAccount/ChangePhoneNumber"))
const ChangePassword = lazy(() => import("../myAccount/ChangePassword"))
const ViewData = lazy(() => import("../data/ViewData"))
const CreateDelegate = lazy(() => import("../auth/CreateDelegate"))
const Delegate = lazy(() => import("../data/Delegate"))
const ViewDataAudit = lazy(() => import("../audit/ViewDataAudit"))
const AuditTrail = lazy(() => import("../audit/AuditTrail"))
const ExportDB = lazy(() => import("../audit/ExportDB"))
const PIDelegate = lazy(() => import("../partners/PIDelegate"))
const PrivacyPolicy = lazy(() => import("../terms/PrivacyPolicy"))
const Terms = lazy(() => import("../terms/Terms"))
const ConfirmPhone = lazy(() => import("../auth/ConfirmPhone"))
const DefinePassword = lazy(() => import("../auth/DefinePassword"))
const Login = lazy(() => import("../auth/Login"))
const CreatePi = lazy(() => import("../auth/CreatePi"))


const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
    },
    {
        path: "login",
        element: <Login/>,
    },
    {
        path: "confirmPhone",
        element: <ConfirmPhone/>,
    },
    {
        path: "define_password/:token",
        element: <DefinePassword/>,
    },
    {
        path: "reset_password/:token",
        element: <ResetPassword/>,
    },
    {
        path: "forgetPassword",
        element: <ForgetPassword/>,
    },
    {
        path: "terms",
        element: <Terms/>,
    },
    {
        path: "privacyPolicy",
        element: <PrivacyPolicy/>,
    },
    {
        path: "addData",
        element: <ProtectedRoute><AddData/></ProtectedRoute>,
    },
    {
        path: "myAccount",
        element: <ProtectedRoute><MyAccount/></ProtectedRoute>,
    },
    {
        path: "changeEmail",
        element: <ProtectedRoute><ChangeEmail/></ProtectedRoute>,
    },
    {
        path: "changePhoneNumber",
        element: <ProtectedRoute><ChangePhoneNumber/></ProtectedRoute>,
    },
    {
        path: "changePassword",
        element: <ProtectedRoute><ChangePassword/></ProtectedRoute>,
    },
    {
        path: "changeName",
        element: <ProtectedRoute><ChangeName/></ProtectedRoute>,
    },
    {
        path: "viewData",
        element: <ProtectedRoute><ViewData/></ProtectedRoute>,
    },
    {
        path: "createDelegate",
        element: <ProtectedRoute><CreateDelegate/></ProtectedRoute>,
    },
    {
        path: "createX2Delegate",
        element: <ProtectedRoute><CreateX2Delegate/></ProtectedRoute>,
    },
    {
        path: "delegate",
        element: <ProtectedRoute><Delegate/></ProtectedRoute>,
    },
    {
        path: "viewDataAudit",
        element: <ProtectedRoute><ViewDataAudit/></ProtectedRoute>,
    },
    {
        path: "auditTrail",
        element: <ProtectedRoute><AuditTrail/></ProtectedRoute>,
    },
    {
        path: "exportDB",
        element: <ProtectedRoute><ExportDB/></ProtectedRoute>,
    },
    {
        path: "pIDelegates",
        element: <ProtectedRoute><PIDelegate/></ProtectedRoute>,
    },
    {
        path: "createPi",
        element: <ProtectedRoute><CreatePi/></ProtectedRoute>,
    },
    {
        path: "chooseArea/:patient",
        element: <ProtectedRoute><ChooseArea/></ProtectedRoute>,
    },

    {
        path: "inclusionCriteriaPage1/:patient",
        element: <ProtectedRoute><InclusionCriteriaPage1/></ProtectedRoute>,
    },
    {
        path: "exclusionCriteriaPage1/:patient",
        element: <ProtectedRoute><ExclusionCriteriaPage1/></ProtectedRoute>,
    },
    {
        path: "socioDemographicCharacteristicsPage1/:patient",
        element: <ProtectedRoute><SocioDemographicCharacteristicsPage1/></ProtectedRoute>,
    },

    {
        path: "clinicalHistoryPage1/:patient",
        element: <ProtectedRoute><ClinicalHistoryPage1/></ProtectedRoute>,
    },

    {
        path: "clinicalHistoryPage2/:patient",
        element: <ProtectedRoute><ClinicalHistoryPage2/></ProtectedRoute>,
    },
    {
        path: "environmentalExposurePage1/:patient",
        element: <ProtectedRoute><EnvironmentalExposurePage1/></ProtectedRoute>,
    },
    {
        path: "clinicalCharacterizationPage1/:patient",
        element: <ProtectedRoute><ClinicalCharacterizationPage1/></ProtectedRoute>,
    },
    {
        path: "clinicalCharacterizationPage2/:patient",
        element: <ProtectedRoute><ClinicalCharacterizationPage2/></ProtectedRoute>,
    },
    {
        path: "tumorBiologyPage1/:patient",
        element: <ProtectedRoute><TumorBiologyPage1/></ProtectedRoute>,
    },
    {
        path: "tumorBiologyPage2/:patient",
        element: <ProtectedRoute><TumorBiologyPage2/></ProtectedRoute>,
    },
    {
        path: "tumorBiologyPage3/:patient",
        element: <ProtectedRoute><TumorBiologyPage3/></ProtectedRoute>,
    },
    {
        path: "treatmentPatternsDuration/:patient/:number",
        element: <ProtectedRoute><TreatmentPatternsDuration/></ProtectedRoute>,
    },
    {
        path: "treatmentPatternsNeoadjuvant/:patient/:number",
        element: <ProtectedRoute><TreatmentPatternsNeoadjuvant/></ProtectedRoute>,
    },
    {
        path: "treatmentPatternsSurgery/:patient/:number",
        element: <ProtectedRoute><TreatmentPatternsSurgery/></ProtectedRoute>,
    },
    {
        path: "treatmentPatternsRTAlone/:patient/:number",
        element: <ProtectedRoute><TreatmentPatternsRTAlone/></ProtectedRoute>,
    },
    {
        path: "treatmentPatternsRadioFrequency/:patient/:number",
        element: <ProtectedRoute><TreatmentPatternsRadioFrequency/></ProtectedRoute>,
    },
    {
        path: "systemicTherapyPage1/:patient/:number",
        element: <ProtectedRoute><SystemicTherapyPage1/></ProtectedRoute>,
    },
    {
        path: "systemicTherapyPage2/:patient/:number",
        element: <ProtectedRoute><SystemicTherapyPage2/></ProtectedRoute>,
    },
    {
        path: "systemicTherapyPage3/:patient/:number",
        element: <ProtectedRoute><SystemicTherapyPage3/></ProtectedRoute>,
    },
    {
        path: "systemicTherapyPage4/:patient/:number",
        element: <ProtectedRoute><SystemicTherapyPage4/></ProtectedRoute>,
    },
    {
        path: "systemicTherapyPage5/:patient/:number",
        element: <ProtectedRoute><SystemicTherapyPage5/></ProtectedRoute>,
    },
    {
        path: "concomitantMedicationsPage1/:patient/:number",
        element: <ProtectedRoute><ConcomitantMedicationsPage1/></ProtectedRoute>,
    },
    {
        path: "concomitantMedicationsPage2/:patient/:number",
        element: <ProtectedRoute><ConcomitantMedicationsPage2/></ProtectedRoute>,
    },

    {
        path: "concomitantMedicationsPage3/:patient/:number",
        element: <ProtectedRoute><ConcomitantMedicationsPage3/></ProtectedRoute>,
    },
    {
        path: "imagingAssessmentsPage1/:patient/:number",
        element: <ProtectedRoute><ImagingAssessmentsPage1/></ProtectedRoute>,
    },
    {
        path: "imagingAssessmentsPage2/:patient/:number",
        element: <ProtectedRoute><ImagingAssessmentsPage2/></ProtectedRoute>,
    },
    {
        path: "laboratoryTestsPage1/:patient/:number",
        element: <ProtectedRoute><LaboratoryTestsPage1/></ProtectedRoute>,
    },
    {
        path: "laboratoryTestsPage2/:patient/:number",
        element: <ProtectedRoute><LaboratoryTestsPage2/></ProtectedRoute>,
    },
    {
        path: "laboratoryTestsPage3/:patient/:number",
        element: <ProtectedRoute><LaboratoryTestsPage3/></ProtectedRoute>,
    },
    {
        path: "otherExamsPage1/:patient/:number",
        element: <ProtectedRoute><OtherExamsPage1/></ProtectedRoute>,
    },
    {
        path: "otherExamsPage2/:patient/:number",
        element: <ProtectedRoute><OtherExamsPage2/></ProtectedRoute>,
    },
    {
        path: "hospitalizationsPage1/:patient/:number",
        element: <ProtectedRoute><HospitalizationsPage1/></ProtectedRoute>,
    },
    {
        path: "diseaseRelatedPage1/:patient/:number",
        element: <ProtectedRoute><DiseaseRelatedPage1/></ProtectedRoute>,
    },
    {
        path: "emergencyConsultationsPage1/:patient/:number",
        element: <ProtectedRoute><EmergencyConsultationsPage1/></ProtectedRoute>,
    },
    {
        path: "subsequentTreatmentPage1/:patient/:number",
        element: <ProtectedRoute><SubsequentTreatmentPage1/></ProtectedRoute>,
    },
    {
        path: "subsequentTreatmentTumorBiologyPage1/:patient/:number",
        element: <ProtectedRoute><SubsequentTreatmentTumorBiologyPage1/></ProtectedRoute>,
    },
    {
        path: "subsequentTreatmentTumorBiologyPage2/:patient/:number",
        element: <ProtectedRoute><SubsequentTreatmentTumorBiologyPage2/></ProtectedRoute>,
    },
    {
        path: "subsequentTreatmentTumorBiologyPage3/:patient/:number",
        element: <ProtectedRoute><SubsequentTreatmentTumorBiologyPage3/></ProtectedRoute>,
    },
    {
        path: "timeDurationPage1/:patient/:number",
        element: <ProtectedRoute><TimeDurationPage1/></ProtectedRoute>,
    },
    {
        path: "dispositionDataPage1/:patient",
        element: <ProtectedRoute><DispositionDataPage1/></ProtectedRoute>,
    },
    {
        path: "imagingAssessmentsAfterInitialTreatmentPage1/:patient/:number",
        element: <ProtectedRoute><ImagingAssessmentsAfterInitialTreatmentPage1/></ProtectedRoute>,
    },
    {
        path: "imagingAssessmentsAfterInitialTreatmentPage2/:patient/:number",
        element: <ProtectedRoute><ImagingAssessmentsAfterInitialTreatmentPage2/></ProtectedRoute>,
    },
    {
        path: "afterInitialEmergencyConsultationsPage1/:patient/:number",
        element: <ProtectedRoute><AfterInitialEmergencyConsultationsPage1/></ProtectedRoute>,
    },
    {
        path: "laboratoryTestsAfterInitialTreatmentPage1/:patient/:number",
        element: <ProtectedRoute><LaboratoryTestsAfterInitialTreatmentPage1/></ProtectedRoute>,
    },
    {
        path: "laboratoryTestsAfterInitialTreatmentPage2/:patient/:number",
        element: <ProtectedRoute><LaboratoryTestsAfterInitialTreatmentPage2/></ProtectedRoute>,
    },
    {
        path: "laboratoryTestsAfterInitialTreatmentPage3/:patient/:number",
        element: <ProtectedRoute><LaboratoryTestsAfterInitialTreatmentPage3/></ProtectedRoute>,
    },
    {
        path: "otherExamsAfterInitialPage1/:patient/:number",
        element: <ProtectedRoute><OtherExamsAfterInitialPage1/></ProtectedRoute>,
    },
    {
        path: "otherExamsAfterInitialPage2/:patient/:number",
        element: <ProtectedRoute><OtherExamsAfterInitialPage2/></ProtectedRoute>,
    },
    {
        path: "hospitalizationsAfterInitialTreatmentPage1/:patient/:number",
        element: <ProtectedRoute><HospitalizationsAfterInitialTreatmentPage1/></ProtectedRoute>,
    },
    {
        path: "diseaseRelatedAfterInitialPage1/:patient/:number",
        element: <ProtectedRoute><DiseaseRelatedAfterInitialPage1/></ProtectedRoute>,
    },
    {
        path: "patientCompleted/:patient",
        element: <ProtectedRoute><PatientCompleted/></ProtectedRoute>,
    },
    {
        path: '*',
        element: <Error404/>
    }
]);

export default router
