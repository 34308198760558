import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';

import Grid from '@mui/material/Grid';
import {Checkbox, FormControlLabel, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import TextField from "@mui/material/TextField";
import arrowDown from "../../images/svgs/Arrow-down.svg";
import MyButton from "../../components/MyButton";
import OtherExamsMenu from "./OtherExamsMenu";
import QuestionsLayout from "../../layout/QuestionsLayout";
import Joi from "joi";
import {toJS} from "mobx";
import MissingReason from "../../components/MissingReason";


const OtherExamsPage2 = () => {
    const {
        treatmentPatternsStore, dataStore, menuStore
    } = useStores()
    let params = useParams();
    useEffect(() => {
        treatmentPatternsStore.getTreatment(parseInt(params.patient), parseInt(params.number))
    }, [])

    const [showMissing, setShowMissing] = React.useState('not active');

    const navigate = useNavigate()
    const [otherOn, setOtherOn] = React.useState(false);
    const [openMenu, setOpenMenu] = React.useState(null);

    const NrSchema = Joi.number().min(1).max(10).integer().required();
    const TypesSchema = Joi.array().items(NrSchema).optional();
    const handleNext = async () => {
        let jsTypes = toJS(treatmentPatternsStore.q19.types)
        let types = jsTypes.filter(t => t.selected === 'on' && t.name !== 'Biopsy').map((t) => t.number);
        let typesBiopsy1 = jsTypes.filter(t => t.selected === 'on' && t.name === 'Biopsy' && t.type1?.name && t.type1.name !== null).map((t) => t.type1.number);
        let typesBiopsy2 = jsTypes.filter(t => t.selected === 'on' && t.name === 'Biopsy' && t.type2?.name && t.type2.name !== null).map((t) => t.type2.number);

        if (!treatmentPatternsStore.checkMissing9_1) {
            if (types.length > 0 && TypesSchema.validate(types).error ||
                typesBiopsy1.length > 0 && TypesSchema.validate(typesBiopsy1).error ||
                typesBiopsy2.length > 0 && TypesSchema.validate(typesBiopsy2).error) {
                if (!window.confirm("Do you confirm this value?")) {
                    return;
                }
            }
            await treatmentPatternsStore.updateTreatment(
                {
                    q19: JSON.stringify(treatmentPatternsStore.q19),
                }, parseInt(params.number), parseInt(params.patient))

            if (dataStore.forceUpdate === true) {
                dataStore.setShowUpdateModal('not active')
            }
            if (dataStore.showUpdateModal !== 'active') {
                navigate(`/hospitalizationsPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            }

        } else {
            alert('Please add reason for missing questions.')

            // if (!dataStore.globalMissing) {
            //     if (showMissing === 'active') {
            //         await treatmentPatternsStore.updateTreatment(
            //             {
            //                 q19: treatmentPatternsStore.q19?.main ? JSON.stringify(treatmentPatternsStore.q19) : dataStore.returnOtherObjectMissing,
            //             }, parseInt(params.number),parseInt(params.patient))
            //         navigate(`/hospitalizationsPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            //         setShowMissing('not active')
            //     } else {
            //         setShowMissing('active')
            //     }
            // } else {
            //     await treatmentPatternsStore.updateTreatment(
            //         {
            //             q19: treatmentPatternsStore.q19?.main ? JSON.stringify(treatmentPatternsStore.q19) : dataStore.returnOtherObjectMissing,
            //         }, parseInt(params.number),parseInt(params.patient))
            //     navigate(`/hospitalizationsPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            // }
        }
    }

    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={treatmentPatternsStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>

                    {parseInt(params.number) === 0 ?
                        <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                            Treatment patterns and healthcare resource use (Only Initial Treatment)
                        </Typography> :
                        <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                            {`Treatment patterns and healthcare resource use for subsequent treatment n = ${params.number}`}
                        </Typography>
                    }
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>
                    <Typography sx={{fontWeight: 'bold', fontSize: 30,}}>
                        Other Exams
                        {dataStore.questionsMissing.includes('q19type')
                            ? <MissingReason
                                value={treatmentPatternsStore.q19.missingTypesReason}
                                onSubmitMissing={()=>{
                                    treatmentPatternsStore.setQ19({
                                        ...treatmentPatternsStore.q19,
                                        missingTypesReason: dataStore.returnOtherTextMissing,
                                    })
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q19type'), 1)
                                }}/> : <></>}
                    </Typography>
                    <QuestionBox>
                        {treatmentPatternsStore.q19.main ?
                            <Box
                                sx={{
                                    display: 'flex', flex: 1,
                                    flexDirection: 'column', width: '100%',
                                }}
                            >
                                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                                    {treatmentPatternsStore.q19.types.map((item, index) => {
                                        let types = treatmentPatternsStore.q19.types
                                        return (
                                            <Grid item xs={6}>
                                                <Box sx={{
                                                    maxWidth:  '420px',
                                                    width:  "100%",
                                                    display: "flex",
                                                    justifyContent:  "space-between",
                                                    alignItems: "center",
                                                }}>
                                                    <div>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={(event) => {
                                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q19type'), 1)

                                                                    console.log('item.selected', item.selected)

                                                                    if (event.target.value === 'on' && item.selected === 'on') {
                                                                        setOpenMenu(null)
                                                                        types[index].selected = null
                                                                        types[index].numner = ''
                                                                        treatmentPatternsStore.setQ19(
                                                                            {
                                                                                ...treatmentPatternsStore.q19,
                                                                                types: types,
                                                                                missingTypesReason:null
                                                                            }
                                                                        )

                                                                    } else {
                                                                        types[index] = {
                                                                            ...types[index],
                                                                            selected: event.target.value
                                                                        }
                                                                        treatmentPatternsStore.setQ19(
                                                                            {
                                                                                ...treatmentPatternsStore.q19,
                                                                                types: types,
                                                                                missingTypesReason:null
                                                                            }
                                                                        )
                                                                    }
                                                                    setOpenMenu(index)

                                                                }}
                                                                checked={item.selected}
                                                            />
                                                        }
                                                        sx={{
                                                            '& .MuiFormControlLabel-label': {fontSize: 18},

                                                        }}
                                                        label={item.name}
                                                    />
                                                    {item.name === 'Biopsy' && item.selected &&
                                                        <img onClick={() => setOpenMenu(index)}
                                                             style={{
                                                                 cursor: 'pointer',
                                                                 width: 18,
                                                                 height: 18,
                                                                 marginBottom:-4
                                                             }}
                                                             src={arrowDown}/>}
                                                    </div>
                                                    {item.selected &&
                                                        <Box sx={{display: "flex", flexDirection: "column"}}>
                                                            <Stack direction={'row'} spacing={2}
                                                                   sx={{display: 'flex', alignItems: 'center'}}>
                                                                <Typography>

                                                                    {dataStore.questionsMissing.includes(item.name)
                                                                        ? <MissingReason
                                                                            value={types[index].number}
                                                                            style={{marginLeft:-80,marginTop:-9}}
                                                                            onSubmitMissing={()=>{
                                                                                types[index] = {
                                                                                    ...item,
                                                                                    number: dataStore.returnOtherTextMissing
                                                                                }
                                                                                treatmentPatternsStore.setQ19(
                                                                                    {
                                                                                        ...treatmentPatternsStore.q19,
                                                                                        types: types
                                                                                    }
                                                                                )
                                                                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf(item.name), 1)
                                                                            }}/> : <></>}

                                                                    Number:</Typography>
                                                                <TextField
                                                                    size={'small'}
                                                                    placeholder={'000'}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    sx={{width: 100}}
                                                                    value={item?.number && item.number.startsWith('missing|') ? '': item.number}

                                                                    error={NrSchema.validate(item.number).error}
                                                                    onChange={(event) => {
                                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf(item.name), 1)

                                                                        types[index] = {
                                                                            ...item,
                                                                            number: event.target.value
                                                                        }
                                                                        treatmentPatternsStore.setQ19(
                                                                            {
                                                                                ...treatmentPatternsStore.q19,
                                                                                types: types
                                                                            }
                                                                        )
                                                                    }}
                                                                />
                                                            </Stack>
                                                            {item.number !== '' && NrSchema.validate(item.number).error &&
                                                                <Typography
                                                                    variant="caption" color="error"
                                                                    sx={{alignSelf: "flex-end"}}>Between 1
                                                                    and 10</Typography>}
                                                        </Box>
                                                    }
                                                </Box>
                                                {openMenu === 4 && item.selected && item.name === 'Biopsy' &&
                                                    <OtherExamsMenu
                                                        store={treatmentPatternsStore}
                                                        question={'q19'}
                                                        disease={types}
                                                        item={item}
                                                        index={index}
                                                        checked={item.selected}
                                                        setOpenMenu={() => setOpenMenu(null)}
                                                        text={item.name}/>
                                                }
                                            </Grid>
                                        )
                                    })}
                                </Grid>

                                <Stack sx={{
                                    display: 'flex',
                                }} direction={'row'} spacing={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(event) =>otherOn === 'on' ? setOtherOn(false) : setOtherOn(event.target.value)}
                                                checked={otherOn}
                                            />
                                        }
                                        sx={{
                                            '& .MuiFormControlLabel-label': {fontSize: 18},
                                        }}
                                        label="Other"
                                    />
                                    {otherOn ?
                                        <>
                                            <TextField
                                                size={'small'}
                                                label="Specify Here"
                                                name="otherTumorPage1"
                                                sx={{width: '200px', mr: 2}}
                                                onChange={(event) => treatmentPatternsStore.setNewExamText(event.target.value)}
                                                value={treatmentPatternsStore.newExamText}/>
                                            <MyButton sx={{px: 4, py: 0.5}} label={'Add'}
                                                      disabled={treatmentPatternsStore.newExamText === ''}
                                                      onClick={treatmentPatternsStore.addNewExams}/>
                                        </>
                                        : null}
                                </Stack>
                            </Box> : <div/>}
                    </QuestionBox>

                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(OtherExamsPage2);
