import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {RadioGroup, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import YesNo from "../../components/YesNo";
import QuestionText from "../../components/QuestionText";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import RadioItem from "../../components/RadioItem";
import TextField from "@mui/material/TextField";
import QuestionsLayout from "../../layout/QuestionsLayout";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Required from "../../components/Required";
import Joi from "joi";
import MissingReason from "../../components/MissingReason";

const EnvironmentalExposurePage1 = () => {
    const {
        environmentalExposureStore, dataStore, menuStore
    } = useStores()
    const [openOther, setOpenOther] = React.useState(null);
    const [newOther, setNewOther] = React.useState('');
    const [showMissing, setShowMissing] = React.useState('not active');
    let urlParams = useParams();

    const navigate = useNavigate()

    useEffect(() => {
        environmentalExposureStore.getEnvironmentalExposure(parseInt(urlParams.patient))
    }, [])

    const UMASchema = Joi.number().min(1).max(200).integer().required();

    const handleNext = async () => {
        if ((environmentalExposureStore.q1?.main && environmentalExposureStore.q1.main) === "Current smoker" || (environmentalExposureStore.q1?.main && environmentalExposureStore.q1.main === "Former smoker")) {
            if (UMASchema.validate(environmentalExposureStore.q1.uma).error) {
                if (!window.confirm("Do you confirm this value?")) {
                    return;
                }
            }
        }
        if (!environmentalExposureStore.checkMissing) {
            if (environmentalExposureStore.q1?.main && environmentalExposureStore.q2 !== null && (environmentalExposureStore.q1.exposure !== null || environmentalExposureStore.q1.uma !== '')) {
                await environmentalExposureStore.updateEnvironmentalExposure(
                    {
                        q1: JSON.stringify(environmentalExposureStore.q1),
                        q2: environmentalExposureStore.q2,
                        q3: JSON.stringify(environmentalExposureStore.q3),
                    }, parseInt(urlParams.patient))
                if (dataStore.forceUpdate === true) {
                    dataStore.setShowUpdateModal('not active')
                }
                if (dataStore.showUpdateModal !== 'active') {
                    navigate('/clinicalCharacterizationPage1/' + parseInt(urlParams.patient))
                }
            } else {
                alert('Please answer all required questions.')
            }
        } else {
            alert('Please add reason for missing questions.')

            // if (!dataStore.globalMissing) {
            //     if (showMissing === 'active') {
            //         await environmentalExposureStore.updateEnvironmentalExposure(
            //             {
            //                 q1: environmentalExposureStore.q1?.main ? JSON.stringify(environmentalExposureStore.q1) : dataStore.returnOtherObjectMissing,
            //                 q2: environmentalExposureStore.q2 ? environmentalExposureStore.q2 : dataStore.returnOtherTextMissing,
            //                 q3: environmentalExposureStore.q3?.main ? JSON.stringify(environmentalExposureStore.q3) : dataStore.returnOtherObjectMissing,
            //
            //             }, parseInt(urlParams.patient))
            //         navigate('/clinicalCharacterizationPage1/' + parseInt(urlParams.patient))
            //         setShowMissing('not active')
            //     } else {
            //         setShowMissing('active')
            //     }
            // } else {
            //     await environmentalExposureStore.updateEnvironmentalExposure(
            //         {
            //             q1: environmentalExposureStore.q1?.main ? JSON.stringify(environmentalExposureStore.q1) : dataStore.returnOtherObjectMissing,
            //             q2: environmentalExposureStore.q2 ? environmentalExposureStore.q2 : dataStore.returnOtherTextMissing,
            //             q3: environmentalExposureStore.q3?.main ? JSON.stringify(environmentalExposureStore.q3) : dataStore.returnOtherObjectMissing,
            //
            //         }, parseInt(urlParams.patient))
            //     navigate('/clinicalCharacterizationPage1/' + parseInt(urlParams.patient))
            // }
        }
    }

    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={environmentalExposureStore.loading}>
            <Box sx={{width: '100%', px: 4, mb: 0, mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>

                    <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32, lineHeight: 1.2}}>
                        Environmental exposure to carcinogenic agents at diagnosis
                    </Typography>
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>

                    <QuestionBox>
                        <QuestionText  required={true}>
                            Smoking status <Required/>
                        </QuestionText>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(event) => environmentalExposureStore.setQ1(
                                {
                                    ...environmentalExposureStore.q1,
                                    main: event.target.value,
                                    exposure: null,
                                    uma: ''

                                }
                            )}
                            value={environmentalExposureStore.q1?.main}
                        >
                            <RadioItem label={'Never smoker'}/>
                            <RadioItem label={'Current smoker'}/>
                            <RadioItem label={'Former smoker'}/>
                        </RadioGroup>
                        <ul style={{width: '100%'}}>
                            {environmentalExposureStore.q1?.main && environmentalExposureStore.q1.main === 'Never smoker' ?
                                <div>
                                    <li>Exposure to secondhand smoke</li>
                                    <YesNo unknown={true} value={environmentalExposureStore.q1.exposure}
                                           handleChange={(event) => environmentalExposureStore.setQ1(
                                               {

                                                   ...environmentalExposureStore.q1,
                                                   exposure: event.target.value,

                                               }
                                           )}
                                    />
                                </div>
                                : (environmentalExposureStore.q1?.main && environmentalExposureStore.q1.main) === "Current smoker" || (environmentalExposureStore.q1?.main && environmentalExposureStore.q1.main === "Former smoker") ?
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%',
                                        alignItems: 'center'
                                    }}>
                                        <li>UMA</li>
                                        <TextField
                                            size={'small'}
                                            onWheel={(e) => e.target.blur()}
                                            sx={{width: 100, ml: 5}}
                                            placeholder={'000'}
                                            value={environmentalExposureStore.q1?.uma && environmentalExposureStore.q1.uma.startsWith('missing|') ? '': environmentalExposureStore.q1.uma}

                                            error={UMASchema.validate(environmentalExposureStore.q1.uma).error}
                                            onChange={(event) => {
                                                environmentalExposureStore.setQ1(
                                                    {

                                                        ...environmentalExposureStore.q1,
                                                        uma: event.target.value,

                                                    }
                                                )
                                            }}
                                        />
                                        {UMASchema.validate(environmentalExposureStore.q1.uma).error && <Typography
                                            variant="caption" color="error" sx={{ml: 2}}>Between 1 and 200</Typography>}
                                    </div> : <></>
                            }
                        </ul>
                    </QuestionBox>
                    <QuestionBox allInRow={true}>
                        <QuestionText width={'40%'}>
                            Environment Radiation exposure <Required/>
                        </QuestionText>
                        <YesNo value={environmentalExposureStore.q2}
                               unknown={true}
                               handleChange={(event) => environmentalExposureStore.setQ2(event.target.value)}
                        />
                    </QuestionBox>
                    <QuestionBox>
                        <Stack direction={'row'} sx={{
                            width: '100%',
                            justifyContent: 'space-between',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <QuestionText>
                                Other exposure

                            </QuestionText>
                            {dataStore.questionsMissing.includes('q3')
                                ? <MissingReason
                                    style={{left:220,marginTop:3}}
                                    value={environmentalExposureStore.q3.selected}
                                    onSubmitMissing={async ()=>{
                                        environmentalExposureStore.setQ3(
                                            {...environmentalExposureStore.q3, selected: dataStore.returnOtherTextMissing}
                                        )
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3'), 1)
                                    }}/> : <></>}
                            <YesNo value={environmentalExposureStore.q3.selected}
                                   handleChange={(event) => {
                                       dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3'), 1)

                                       environmentalExposureStore.setQ3(
                                           {...environmentalExposureStore.q3, selected: event.target.value}
                                       )
                                       if (event.target.value === "true") {
                                           setOpenOther(true)
                                       } else {
                                           setOpenOther(false)
                                           setNewOther('')
                                           environmentalExposureStore.setQ3({
                                                   ...environmentalExposureStore.q3,
                                                   value: [],
                                               }
                                           )
                                       }
                                   }}/>
                        </Stack>

                        {openOther || (environmentalExposureStore.q3?.selected && environmentalExposureStore.q3.selected === "true") ?
                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "flex-end",
                                alignSelf: "flex-end",
                                width: "70%",
                                maxWidth: 500,
                            }}>
                                {dataStore.questionsMissing.includes('q3value')
                                    ? <MissingReason
                                        style={{marginLeft:-100,marginBottom:6}}
                                        value={environmentalExposureStore.q3.missingValueReason}
                                        onSubmitMissing={async ()=>{
                                            environmentalExposureStore.setQ3({
                                                ...environmentalExposureStore.q3,
                                                missingValueReason: dataStore.returnOtherTextMissing,
                                            })
                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3value'), 1)
                                        }}/> : <></>}
                                <TextField
                                    size={'small'}
                                    label="Specify Here"
                                    margin="normal"
                                    name="otherTumorPage1"
                                    sx={{width: '100%', mt: 1}}
                                    onChange={(event) => {
                                        setNewOther(event.target.value)
                                    }}
                                    value={newOther}
                                />

                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        ml: 1,
                                        fontSize: 12,
                                        color: "white",
                                        height: 40,
                                        mt: 1,
                                        mb: 1,
                                        p: 0
                                    }}
                                    onClick={() => {
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3value'), 1)
                                        let others = environmentalExposureStore.q3.value
                                        if(others.find((t)=>t == newOther)){
                                            alert('You are trying to add duplicate value.')
                                            setNewOther('');
                                        }else {
                                            if (newOther === '') {
                                                return
                                            }
                                            if (others === null || typeof others === 'string') {
                                                others = []
                                            }
                                            others.push(newOther)
                                            environmentalExposureStore.setQ3({
                                                ...environmentalExposureStore.q3,
                                                value: others,
                                            })
                                            setNewOther('');

                                        }



                                    }}>
                                    Add
                                </Button>

                            </Box>
                            : <></>}
                        <Box sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row-reverse",
                            flexWrap: "wrap",
                            rowGap: 1,
                            mt: 1
                        }}>
                            {environmentalExposureStore.q3?.value &&
                                typeof environmentalExposureStore.q3.value !== 'string'&&
                                environmentalExposureStore.q3.value.length>0 &&
                                environmentalExposureStore.q3.value.map((item, index) =>
                                <Box key={"OTHER__" + index}
                                     sx={{
                                         minWidth: 150,
                                         pl: 1,
                                     }}>
                                    <Paper sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flex: 1,
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        p: 1,
                                        pl: 2
                                    }}>
                                        <Typography sx={{mr: 1}}>
                                            {item}
                                        </Typography>
                                        <IconButton onClick={() => {
                                            let others = environmentalExposureStore.q3.value
                                            others.splice(index, 1)
                                            environmentalExposureStore.setQ3(
                                                {
                                                    ...environmentalExposureStore.q3,
                                                    value: others
                                                }
                                            )
                                        }}>
                                            <DeleteIcon color="error"/>
                                        </IconButton>
                                    </Paper>
                                </Box>)}
                        </Box>
                    </QuestionBox>
                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(EnvironmentalExposurePage1);
