import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {RadioGroup, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import YesNo from "../../components/YesNo";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import RadioItem from "../../components/RadioItem";
import QuestionsLayout from "../../layout/QuestionsLayout";
import MissingReason from "../../components/MissingReason";


const SystemicTherapyPage1 = () => {
    const {
        systemicTherapyStore, dataStore,menuStore,treatmentPatternsStore
    } = useStores()
    const [showMissing, setShowMissing] = React.useState('not active');

    const navigate = useNavigate()
    let params = useParams();
    useEffect(() => {
        systemicTherapyStore.getTreatment(parseInt(params.patient),parseInt(params.number))
        treatmentPatternsStore.getTreatment(parseInt(params.patient),parseInt(params.number))
    }, [])

    const handleNext = async () => {
        if(systemicTherapyStore.q11.main === 'true'){
            if (!systemicTherapyStore.checkMissing1) {
                await systemicTherapyStore.updateTreatment(
                    {
                        q11: JSON.stringify(systemicTherapyStore.q11),
                    }, parseInt(params.number),parseInt(params.patient))
                if(dataStore.forceUpdate == true){
                    dataStore.setShowUpdateModal('not active')
                }
                if(dataStore.showUpdateModal !== 'active'){
                    navigate(`/systemicTherapyPage2/${parseInt(params.patient)}/${parseInt(params.number)}`)
                }
            } else {
                alert('Please add reason for missing questions.')

                // if (!dataStore.globalMissing) {
                //     if (showMissing === 'active') {
                //         await systemicTherapyStore.updateTreatment(
                //             {
                //                 q11: systemicTherapyStore.q11?.main ? JSON.stringify(systemicTherapyStore.q11) : dataStore.returnOtherObjectMissing,
                //             }, parseInt(params.number),parseInt(params.patient))
                //         navigate(`/systemicTherapyPage2/${parseInt(params.patient)}/${parseInt(params.number)}`)
                //         setShowMissing('not active')
                //     } else {
                //         setShowMissing('active')
                //     }
                // } else {
                //     await systemicTherapyStore.updateTreatment(
                //         {
                //             q11: systemicTherapyStore.q11?.main ? JSON.stringify(systemicTherapyStore.q11) : dataStore.returnOtherObjectMissing,
                //         }, parseInt(params.number),parseInt(params.patient))
                //     navigate(`/systemicTherapyPage2/${parseInt(params.patient)}/${parseInt(params.number)}`)
                // }
            }
        }else{
            if (!systemicTherapyStore.checkMissing1) {
                await systemicTherapyStore.updateTreatment(
                    {
                        q11: JSON.stringify(systemicTherapyStore.q11),
                    }, parseInt(params.number),parseInt(params.patient))
                if(dataStore.forceUpdate == true){
                    dataStore.setShowUpdateModal('not active')
                }

                if(dataStore.showUpdateModal !== 'active'){
                    if(treatmentPatternsStore.q6.main === 'false' && systemicTherapyStore.q11.main === 'false'){
                        navigate(`/systemicTherapyPage5/${parseInt(params.patient)}/${parseInt(params.number)}`)
                    }else{
                        navigate(`/concomitantMedicationsPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
                    }
                }
            }else {
                alert('Please add reason for missing questions.')

                // if (!dataStore.globalMissing) {
                //     if (showMissing === 'active') {
                //         await systemicTherapyStore.updateTreatment(
                //             {
                //                 q11: systemicTherapyStore.q11?.main ? JSON.stringify(systemicTherapyStore.q11) : dataStore.returnOtherObjectMissing,
                //             }, parseInt(params.number),parseInt(params.patient))
                //         navigate(`/systemicTherapyPage2/${parseInt(params.patient)}/${parseInt(params.number)}`)
                //         setShowMissing('not active')
                //     } else {
                //         setShowMissing('active')
                //     }
                // } else {
                //     await systemicTherapyStore.updateTreatment(
                //         {
                //             q11: systemicTherapyStore.q11?.main ? JSON.stringify(systemicTherapyStore.q11) : dataStore.returnOtherObjectMissing,
                //         }, parseInt(params.number),parseInt(params.patient))
                //     navigate(`/systemicTherapyPage2/${parseInt(params.patient)}/${parseInt(params.number)}`)
                // }
            }

        }

    }

    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={systemicTherapyStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>
                    {parseInt(params.number) === 0 ?
                        <div>
                            <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                                Treatment patterns and healthcare resource use (Only Initial Treatment)
                            </Typography>
                            <Typography color={'txtGrey'} sx={{fontSize: 20,mt:1}}>
                                (Please complete this section only for initial treatment.)
                            </Typography>
                        </div> :
                        <div>
                            <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                                {`Treatment patterns and healthcare resource use for subsequent treatment n = ${params.number}`}
                            </Typography>
                            <Typography color={'txtGrey'} sx={{fontSize: 20,mt:1}}>
                                [Please complete this section for “Subsequent treatment (n=1, 2, …)”]
                            </Typography>
                        </div>
                    }
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>
                    <Typography sx={{fontWeight: 'bold', fontSize: 30,}}>
                        Systemic Therapy+RT
                    </Typography>
                    <QuestionBox>
                        <YesNo value={systemicTherapyStore.q11.main}
                               handleChange={(event) => {
                                   dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q11'), 1)

                                   systemicTherapyStore.setQ11(
                                   {
                                       ...systemicTherapyStore.q11,
                                       main: event.target.value,
                                       value: event.target.value === 'true' ? systemicTherapyStore.q11.value : null
                                   })
                               }}
                        />
                        {dataStore.questionsMissing.includes('q11')
                            ? <MissingReason
                                value={systemicTherapyStore?.q11?.main}
                                style={{right:60,marginTop:-1}}
                                onSubmitMissing={async ()=>{
                                    systemicTherapyStore.setQ11(
                                        {...systemicTherapyStore.q11, main: dataStore.returnOtherTextMissing,})
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q11'), 1)
                                }}/> : <></>}
                        {systemicTherapyStore.q11.main === 'true' && treatmentPatternsStore.q4.main === 'true' && <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(event) => {
                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q11value'), 1)

                                systemicTherapyStore.setQ11(
                                {...systemicTherapyStore.q11, value: event.target.value,}
                            )}}
                            value={systemicTherapyStore.q11.value}
                            sx={{display: 'flex', flexDirection: 'row', gap: "24px", rowGap: 0}}
                        >
                            <RadioItem label={'Before Surgery'}/>
                            <RadioItem label={'After surgery'}/>
                            <RadioItem label={'No surgery performed at this point'}/>
                        </RadioGroup>}
                        {dataStore.questionsMissing.includes('q11value')
                            ? <MissingReason
                                value={systemicTherapyStore?.q11?.value}
                                style={{right:60,marginTop:35}}
                                onSubmitMissing={async ()=>{
                                    systemicTherapyStore.setQ11(
                                        {...systemicTherapyStore.q11, value: dataStore.returnOtherTextMissing,})
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q11value'), 1)
                                }}/> : <></>}
                    </QuestionBox>
                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(SystemicTherapyPage1);
