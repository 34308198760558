import {action, makeObservable, observable,} from 'mobx'
import {CrfService} from "../../services/openapi";
import {Stores} from "../../store";

class TreatmentPatternsStore {
    loading = false
    q1 = ''
    q2 = {
        type: null,
        value: ''
    }
    s1 = null
    s2 = {
        main: null,
        method: null,
        levels: null,
        other: '',
    }
    s3 = {
        main: null,
        method: [],
        missingGenomicReason:null,
        genomic: {
            ALK: null,
            BRAF: null,
            EGFR: {
                selected: null,
                values: []
            },
            MET: null,
            NTRK: null,
            ROS: null,
            HER2: null,
            KRAS: null,
            RET: null,
            Other: null,
            NoMutation: null,
            Inconclusive: null
        }
    }
    s4 = {
        main: null,
        type: null
    }
    s5 = ''
    s6 = ''
    s7 = {
        type: null,
        value: ''
    }
    q3CommonParams = {
        selected: null,
        dose: '',
        numberOfCyclesPlanned: '',
        numberOfCyclesActual: '',
        totalDurationInWeeks: '',
    }
    q3 = {
        main: null,
        missingTherapiesReason:null,
        therapies: [
            {
                name: 'Cisplatin',
                params: this.q3CommonParams
            },
            {
                name: 'Etoposide',
                params: this.q3CommonParams
            },
            {
                name: 'Vinorelbine',
                params: this.q3CommonParams
            },
            {
                name: 'Pemetrexed',
                params: this.q3CommonParams
            },
            {
                name: 'Carboplatin',
                params: this.q3CommonParams
            },
            {
                name: 'Paclitaxel',
                params: this.q3CommonParams
            },
        ],

    }
    q4 = {
        main: null,
        numberOfSurgery: ''
    }
    q5 = [{
        surgicalProcedure: null,
        anatomicalResection: [],
        missingAnatomicalReason:null
    }]
    q6 = {
        main: null,
        value: null
    }
    q7 = {
        main: null,
        dose: '',
        numberOfFractions: '',
    }
    q8 = {
        main: null,
        dose: '',
        numberOfFractions: '',
    }
    q9 = {
        main: null,
        catheter: null
    }
    q10 = null
    /***
     *
     * q11 - q15 are inside the systemicTherapyStore
     */

    commonQ16Params = {
        duration: {type: null, amount: ''},
        frequency: '',
        selected: null,
    }
    q16 = {
        main: null,
        missingDiseaseReason:null,
        disease: [
            {
                name: 'Anti-emetics 5HT3/Setrons',
                extra: [],
                ...this.commonQ16Params
            },
            {
                name: 'Anti-emetics Aprepitant/Fosaprepitant',
                extra: [],
                ...this.commonQ16Params
            },
            {
                name: 'Other anti-emetics',
                extra: [],
                ...this.commonQ16Params
            },
            {
                name: 'GrowthFactors',
                extra: [],
                ...this.commonQ16Params
            },
            {
                name: 'Corticosteroids',
                extra: [],
                ...this.commonQ16Params
            },
            {
                name: 'Hydrocortisone',
                extra: [],
                ...this.commonQ16Params
            },
            {
                name: 'Antidepressant drug',
                extra: [],
                ...this.commonQ16Params
            },
            {
                name: 'Anti-anxiety drug',
                extra: [],
                ...this.commonQ16Params
            },
            {
                name: 'Iron doses (intravenous or oral)',
                extra: [],
                ...this.commonQ16Params
            },
            {
                name: 'Antihistamine',
                extra: [],
                ...this.commonQ16Params
            },
            {
                name: 'Blood component transfusion',
                extra: [],
                ...this.commonQ16Params
            },
            {
                name: 'EPO/ASE',
                extra: [],
                ...this.commonQ16Params
            },
            {
                name: 'Grade 1 or 2 (non-Opioid) Analgesics',
                extra: [],
                ...this.commonQ16Params
            },
            {
                name: 'Grade 3 (Opioid) Analgesics',
                extra: [],
                ...this.commonQ16Params
            },
            {
                name: 'Antibiotics',
                extra: [],
                ...this.commonQ16Params
            },
            {
                name: 'LMWH (Low molecular weight heparin)',
                extra: [],
                ...this.commonQ16Params
            },
            {
                name: 'Immunosuppressants (ex, infliximab/others)',
                extra: [],
                ...this.commonQ16Params
            },
        ]
    }
    commonQ17Params = {
        selected: null,
        number: '',
    }
    q17 = {
        main: null,
        missingTypesReason:null,
        types: [
            {
                name: 'X-Ray thorax',
                ...this.commonQ17Params
            },
            {
                name: 'CT thorax',
                ...this.commonQ17Params
            },
            {
                name: 'PET-CT thorax',
                ...this.commonQ17Params
            },
            {
                name: 'MRI brain',
                ...this.commonQ17Params
            },
            {
                name: 'Bone scintigraphy',
                ...this.commonQ17Params
            },
            {
                name: 'Contrast enhanced- CT brain',
                ...this.commonQ17Params
            },
        ]
    }
    q18 = {
        main: null,
        missingTypesReason: null,
        types: [
            {
                name: 'Complete Blood Count',
                ...this.commonQ17Params
            },
            {
                name: 'Albumin',
                ...this.commonQ17Params
            },
            {
                name: 'Alkaline phosphatase',
                ...this.commonQ17Params
            },
            {
                name: 'Glucose',
                ...this.commonQ17Params
            },
            {
                name: 'Lactate Dehydrogenase',
                ...this.commonQ17Params
            },
            {
                name: 'Phosphorus',
                ...this.commonQ17Params
            },
            {
                name: 'Potassium',
                ...this.commonQ17Params
            },
            {
                name: 'Sodium',
                ...this.commonQ17Params
            },
            {
                name: 'Calcium',
                ...this.commonQ17Params
            },
            {
                name: 'Total Bilirubin',
                ...this.commonQ17Params
            },
            {
                name: 'Direct Bilirubin',
                ...this.commonQ17Params
            },
            {
                name: 'Total Protein',
                ...this.commonQ17Params
            },
            {
                name: 'Uric acid',
                ...this.commonQ17Params
            },
            {
                name: 'Urea',
                ...this.commonQ17Params
            },
            {
                name: 'PT (INR)',
                ...this.commonQ17Params
            },
            {
                name: 'aPTT',
                ...this.commonQ17Params
            },
            {
                name: 'Thyroid stimulating hormone (TSH)',
                ...this.commonQ17Params
            },
            {
                name: ' Free thyroxine (FT4)',
                ...this.commonQ17Params
            },
            {
                name: 'Creatinine',
                ...this.commonQ17Params
            },
            {
                name: 'C-reactive Protein',
                ...this.commonQ17Params
            },
            {
                name: 'Hemoculture',
                ...this.commonQ17Params
            },
            {
                name: 'Urine Culture',
                ...this.commonQ17Params
            },
            {
                name: 'Urinalysis - Blood',
                ...this.commonQ17Params
            },
            {
                name: 'Urinalysis - Glucose',
                ...this.commonQ17Params
            },
            {
                name: 'Urinalysis - Protein',
                ...this.commonQ17Params
            },
            {
                name: 'Urinalysis - Specific Gravity',
                ...this.commonQ17Params
            },
        ]
    }
    q19 = {
        main: null,
        missingTypesReason:null,
        types: [
            {
                name: 'ECG',
                ...this.commonQ17Params
            },
            {
                name: 'Echocardiogram',
                ...this.commonQ17Params
            },
            {
                name: 'Spirometry',
                ...this.commonQ17Params
            },
            {
                name: 'Plethysmography',
                ...this.commonQ17Params
            },
            {
                name: 'Biopsy',
                selected: null,
                number:'',
                type1: {name: null, subName: null,number: ''},
                type2: {name: null, subName: null,number: ''}
            },
            {
                name: 'CT-guided biopsy',
                ...this.commonQ17Params
            },
            {
                name: 'Bronchoscopy',
                ...this.commonQ17Params
            },
            {
                name: 'EBUS',
                ...this.commonQ17Params
            },
            {
                name: 'EUS mediastinal',
                ...this.commonQ17Params
            },
        ]
    }
    q20 = {
        main: null,
        numberOfSurgery: ''
    }
    q21 = [{
        ward: {selected: null, amount: ''},
        intermediateCareUnit: {selected: null, amount: ''},
        intensiveCareUnit: {selected: null, amount: ''},
    }]
    q22 = {
        main: null,
        oncology: {selected: null, amount: '', unknown: null},
        other: {
            selected: null,
            unknown: null,
            specialities: [
                {
                    speciality: '',
                    amount: ''
                },

            ],
        },
        gp: {selected: null, amount: '', unknown: null},
    };
    q23 = {
        main: null,
        amount: ''
    }
    newNeoText = ''
    newDiseaseText = ''
    newLabText = ''
    newExamText = ''
    newImageAssessmentText = ''
    newAddedNeo = []
    newAddedDisease = []

    constructor() {

        makeObservable(this, {
            loading: observable,
            q1: observable,
            s1: observable,
            s2: observable,
            s3: observable,
            s4: observable,
            s5: observable,
            s6: observable,
            s7: observable,
            q2: observable,
            q3: observable,
            q4: observable,
            q5: observable,
            q6: observable,
            q7: observable,
            q8: observable,
            q9: observable,
            q10: observable,
            q16: observable,
            q3CommonParams: observable,
            newNeoText: observable,
            newAddedNeo: observable,
            commonQ16Params: observable,
            newDiseaseText: observable,
            newImageAssessmentText: observable,
            newAddedDisease: observable,
            commonQ17Params: observable,
            q17: observable,
            q18: observable,
            q19: observable,
            q20: observable,
            q21: observable,
            q22: observable,
            q23: observable,
            newLabText: observable,
            newExamText: observable,
            addNewExams: action,
            setNewExamText: action,
            addNewLabs: action,
            setNewLabText: action,
            setQ1: action,
            setS1: action,
            setS2: action,
            setS3: action,
            setS4: action,
            setS5: action,
            setS6: action,
            setS7: action,
            setQ2: action,
            setQ3: action,
            setQ4: action,
            setQ5: action,
            setQ6: action,
            setQ7: action,
            setQ8: action,
            setQ9: action,
            setQ10: action,
            setQ16: action,
            setQ17: action,
            setQ18: action,
            setQ19: action,
            setQ20: action,
            setQ21: action,
            setQ22: action,
            setQ23: action,
            setLoading: action,
            updateTreatment: action,
            getTreatment: action,
            addNeo: action,
            setNewNeoText: action,

            setNewDiseaseText: action,
            setNewImageAssessmentText: action,

            addImageAssessmentType: action,
            addNewSpeciality: action,
            reset: action,
        },)
    }

    reset = () => {
        this.setQ1('')
        this.setQ2({
            type: null,
            value: ''
        })
        this.setS1('')
        this.setS2({
            main: null,
            method: null,
            levels: null
        })
        this.setS3({
            main: null,
            method: [],
            genomic: {
                ALK: null,
                BRAF: null,
                EGFR: {
                    selected: null,
                    values: []
                },
                MET: null,
                NTRK: null,
                ROS: null,
                HER2: null,
                KRAS: null,
                RET: null,
                Other: null,
                NoMutation: null,
                Inconclusive: null
            }
        })
        this.setS4({
            main: null,
            type: null
        })
        this.setS5('')
        this.setS6('')
        this.setS7({
            type: null,
            value: ''
        })
        this.setQ3({
            main: null,
            therapies: [
                {
                    name: 'Cisplatin',
                    params: this.q3CommonParams
                },
                {
                    name: 'Etoposide',
                    params: this.q3CommonParams
                },
                {
                    name: 'Vinorelbine',
                    params: this.q3CommonParams
                },
                {
                    name: 'Pemetrexed',
                    params: this.q3CommonParams
                },
                {
                    name: 'Carboplatin',
                    params: this.q3CommonParams
                },
                {
                    name: 'Paclitaxel',
                    params: this.q3CommonParams
                },
            ],

        })
        this.setQ4({
            main: null,
            numberOfSurgery: ''
        })
        this.setQ5([{
            surgicalProcedure: null,
            anatomicalResection: null,
        }])
        this.setQ6({
            main: null,
            value: null
        })
        this.setQ7({
            main: null,
            dose: '',
            numberOfFractions: '',
        })
        this.setQ8({
            main: null,
            dose: '',
            numberOfFractions: '',
        })
        this.setQ9({
            main: null,
            catheter: null
        })
        this.setQ10(null)
        this.setQ16({
            main: null,
            disease: [
                {
                    name: 'Anti-emetics 5HT3/Setrons',
                    extra: [],
                    ...this.commonQ16Params
                },
                {
                    name: 'Anti-emetics Aprepitant/Fosaprepitant',
                    extra: [],
                    ...this.commonQ16Params
                },
                {
                    name: 'Other anti-emetics',
                    extra: [],
                    ...this.commonQ16Params
                },
                {
                    name: 'GrowthFactors',
                    extra: [],
                    ...this.commonQ16Params
                },
                {
                    name: 'Corticosteroids',
                    extra: [],
                    ...this.commonQ16Params
                },
                {
                    name: 'Hydrocortisone',
                    extra: [],
                    ...this.commonQ16Params
                },
                {
                    name: 'Antidepressant drug',
                    extra: [],
                    ...this.commonQ16Params
                },
                {
                    name: 'Anti-anxiety drug',
                    extra: [],
                    ...this.commonQ16Params
                },
                {
                    name: 'Iron doses (intravenous or oral)',
                    extra: [],
                    ...this.commonQ16Params
                },
                {
                    name: 'Antihistamine',
                    extra: [],
                    ...this.commonQ16Params
                },
                {
                    name: 'Blood component transfusion',
                    extra: [],
                    ...this.commonQ16Params
                },
                {
                    name: 'EPO/ASE',
                    extra: [],
                    ...this.commonQ16Params
                },
                {
                    name: 'Grade 1 or 2 (non-Opioid) Analgesics',
                    extra: [],
                    ...this.commonQ16Params
                },
                {
                    name: 'Grade 3 (Opioid) Analgesics',
                    extra: [],
                    ...this.commonQ16Params
                },
                {
                    name: 'Antibiotics',
                    extra: [],
                    ...this.commonQ16Params
                },
                {
                    name: 'LMWH (Low molecular weight heparin)',
                    extra: [],
                    ...this.commonQ16Params
                },
                {
                    name: 'Immunosuppressants (ex, infliximab/others)',
                    extra: [],
                    ...this.commonQ16Params
                },
            ]
        })
        this.setQ17({
            main: null,
            types: [
                {
                    name: 'X-Ray thorax',
                    ...this.commonQ17Params
                },
                {
                    name: 'CT thorax',
                    ...this.commonQ17Params
                },
                {
                    name: 'PET-CT thorax',
                    ...this.commonQ17Params
                },
                {
                    name: 'MRI brain',
                    ...this.commonQ17Params
                },
                {
                    name: 'Bone scintigraphy',
                    ...this.commonQ17Params
                },
                {
                    name: 'Contrast enhanced- CT brain',
                    ...this.commonQ17Params
                },
            ]
        })
        this.setQ18({
            main: null,
            types: [
                {
                    name: 'Complete Blood Count',
                    ...this.commonQ17Params
                },
                {
                    name: 'Albumin',
                    ...this.commonQ17Params
                },
                {
                    name: 'Alkaline phosphatase',
                    ...this.commonQ17Params
                },
                {
                    name: 'Glucose',
                    ...this.commonQ17Params
                },
                {
                    name: 'Lactate Dehydrogenase',
                    ...this.commonQ17Params
                },
                {
                    name: 'Phosphorus',
                    ...this.commonQ17Params
                },
                {
                    name: 'Potassium',
                    ...this.commonQ17Params
                },
                {
                    name: 'Sodium',
                    ...this.commonQ17Params
                },
                {
                    name: 'Calcium',
                    ...this.commonQ17Params
                },
                {
                    name: 'Total Bilirubin',
                    ...this.commonQ17Params
                },
                {
                    name: 'Direct Bilirubin',
                    ...this.commonQ17Params
                },
                {
                    name: 'Total Protein',
                    ...this.commonQ17Params
                },
                {
                    name: 'Uric acid',
                    ...this.commonQ17Params
                },
                {
                    name: 'Urea',
                    ...this.commonQ17Params
                },
                {
                    name: 'PT (INR)',
                    ...this.commonQ17Params
                },
                {
                    name: 'aPTT',
                    ...this.commonQ17Params
                },
                {
                    name: 'Thyroid stimulating hormone (TSH)',
                    ...this.commonQ17Params
                },
                {
                    name: ' Free thyroxine (FT4)',
                    ...this.commonQ17Params
                },
                {
                    name: 'Creatinine',
                    ...this.commonQ17Params
                },
                {
                    name: 'C-reactive Protein',
                    ...this.commonQ17Params
                },
                {
                    name: 'Hemoculture',
                    ...this.commonQ17Params
                },
                {
                    name: 'Urine Culture',
                    ...this.commonQ17Params
                },
                {
                    name: 'Urinalysis - Blood',
                    ...this.commonQ17Params
                },
                {
                    name: 'Urinalysis - Glucose',
                    ...this.commonQ17Params
                },
                {
                    name: 'Urinalysis - Protein',
                    ...this.commonQ17Params
                },
                {
                    name: 'Urinalysis - Specific Gravity',
                    ...this.commonQ17Params
                },
            ]
        })
        this.setQ19({
            main: null,
            types: [
                {
                    name: 'ECG',
                    ...this.commonQ17Params
                },
                {
                    name: 'Echocardiogram',
                    ...this.commonQ17Params
                },
                {
                    name: 'Spirometry',
                    ...this.commonQ17Params
                },
                {
                    name: 'Plethysmography',
                    ...this.commonQ17Params
                },
                {
                    name: 'Biopsy',
                    selected: null,
                    type1: {name: null, subName: null},
                    type2: {name: null, subName: null}
                },
                {
                    name: 'CT-guided biopsy',
                    ...this.commonQ17Params
                },
                {
                    name: 'Bronchoscopy',
                    ...this.commonQ17Params
                },
                {
                    name: 'EBUS',
                    ...this.commonQ17Params
                },
                {
                    name: 'EUS mediastinal',
                    ...this.commonQ17Params
                },
            ]
        })
        this.setQ20({
            main: null,
            numberOfSurgery: ''
        })
        this.setQ21([{
            ward: {selected: null, amount: ''},
            intermediateCareUnit: {selected: null, amount: ''},
            intensiveCareUnit: {selected: null, amount: ''},
        }])
        this.setQ22({
            main: null,
            oncology: {selected: null, amount: '', unknown: null},
            other: {
                selected: null,
                unknown: null,
                specialities: [
                    {
                        speciality: '',
                        amount: ''
                    },

                ],
            },
            gp: {selected: null, amount: '', unknown: null},
        });
        this.setQ23({
            main: null,
            amount: ''
        })
    }

    get checkMissing1() {
         if(this.q1 === '' || this.q2 == null || this.q2.type == null){
            return true
        }else if(this.q2.type != null && this.q2.value == ''){
             return true
        }
    };

    get checkMissing2() {
        console.log('this.q3',this.q3)
        if (this.q3 == null || this.q3.main == null) {
            Stores.dataStore.setQuestionsMissing('q3')
        }
        if((this.q3.main !== null || this.q3.main !== 'false' || this.q3.main !== false)
            && (!this.q3?.missingTherapiesReason || this.q3.missingTherapiesReason === null) &&  !this.q3.therapies.find((t) => t.params.selected != null)){
            Stores.dataStore.setQuestionsMissing('therapies')
        }
        for(const therapy of this.q3.therapies){
            if(therapy.params.selected != null){
                Stores.dataStore.setQuestionsMissing(therapy.name)

            }
            if(therapy.params.selected != null && therapy.params.dose == ''){
                Stores.dataStore.setQuestionsMissing(therapy.name + 'dose')
            }
            if(therapy.params.selected != null && therapy.params.numberOfCyclesPlanned == ''){
                Stores.dataStore.setQuestionsMissing(therapy.name + 'numberOfCyclesPlanned')
            }
            if(therapy.params.selected != null && therapy.params.numberOfCyclesActual == ''){
                Stores.dataStore.setQuestionsMissing(therapy.name + 'numberOfCyclesActual')
            }
            if(therapy.params.selected != null && therapy.params.totalDurationInWeeks == ''){
                Stores.dataStore.setQuestionsMissing(therapy.name + 'totalDurationInWeeks')
            }
        }
        if((this.q3 == null || this.q3.main == null) ||
            ((this.q3.main !== null && (this.q3.main === 'true' || this.q3.main === true))  &&
                 (!this.q3?.missingTherapiesReason || this.q3.missingTherapiesReason === null) &&
                !this.q3.therapies.find((t) => t.params.selected != null)) ||
            (this.q3.therapies.find((t) => t.params.selected != null &&
            (t.params.dose == '' || t.params.numberOfCyclesPlanned == '' || t.params.numberOfCyclesActual == '' || t.params.totalDurationInWeeks == '')))){
            return true
        }

    }

    get checkMissing3() {
        console.log('this.q5',this.q5)

        if(this.q4 == null || this.q4.main == null){
            Stores.dataStore.setQuestionsMissing('q4')
        }

        if((this.q4.main == 'true' || this.q4.main == true) && this.q4.numberOfSurgery == ''){
            Stores.dataStore.setQuestionsMissing('q4numberOfSurgery')
        }

        if(this.q5 == null || !Array.isArray(this.q5)){
            Stores.dataStore.setQuestionsMissing('q5')
        }

        if (this.q4.main == 'true') {
            for (let i=0;i<this.q5.length;i++) {
                if (this.q5[i].surgicalProcedure == null) {
                    Stores.dataStore.setQuestionsMissing('surgicalProcedure'+i)
                }
                if ((this.q5[i].anatomicalResection == [] || this.q5[i].anatomicalResection.length == 0) && !this.q5[i]?.missingAnatomicalReason) {
                    Stores.dataStore.setQuestionsMissing('anatomicalResection'+i)
                }
            }
        }

        if((this.q4 == null || this.q4.main == null) ||
            ((this.q4.main == 'true' || this.q4.main == true) && this.q4.numberOfSurgery == '') ||
            (this.q5 == null || !Array.isArray(this.q5))
        ){
            return true
        }

        console.log('this.q4',this.q4)
        if ((this.q4.main == 'true' || this.q4.main == true) && (this.q4.numberOfSurgery != '' && !this.q4.numberOfSurgery.includes('missing'))) {
            for (let surgery of this.q5) {
                if ((surgery.surgicalProcedure == null) || ((surgery.anatomicalResection == null || surgery.anatomicalResection.length == 0) && !surgery.missingAnatomicalReason)) {
                    return true
                }
            }
        }


    }


    get checkMissing4() {
        if(this.q6.main == null){
            Stores.dataStore.setQuestionsMissing('q6')
        }
        if(this.q6.main === 'true' && this.q4.main === 'true' &&this.q6.value == null){
            Stores.dataStore.setQuestionsMissing('q6value')

        }
        if(this.q6.main === 'true' && this.q7?.main == null && this.q8?.main == null){
            Stores.dataStore.setQuestionsMissing('q7q8')
        }
        if(this.q7.main != null && !this.q7.dose){
            Stores.dataStore.setQuestionsMissing('q7dose')
        }
        if(this.q7.main != null && !this.q7.numberOfFractions){
            Stores.dataStore.setQuestionsMissing('q7numberOfFractions')
        }
        if(this.q8.main != null && !this.q8.dose){
            Stores.dataStore.setQuestionsMissing('q8dose')
        }
        if(this.q8.main != null && !this.q8.numberOfFractions){
            Stores.dataStore.setQuestionsMissing('q8numberOfFractions')
        }
        if(this.q6.main == null || (this.q6.main === 'true' && this.q4.main === 'true' &&this.q6.value == null)  ||
            (this.q6.main === 'true' && this.q7?.main == null && this.q8?.main == null) ||
            (this.q7.main != null && !this.q7.dose) ||
            (this.q7.main != null && !this.q7.numberOfFractions) ||
            (this.q8.main != null && !this.q8.dose) ||
            (this.q8.main != null && !this.q8.numberOfFractions)
        ){
            return true
        }
        return false
    }

    get checkMissing5() {
        if(this.q9 == null || this.q9.main == null){
            Stores.dataStore.setQuestionsMissing('q9')
        }
        if(this.q9.main === 'true' && this.q9.catheter == null){
            Stores.dataStore.setQuestionsMissing('q9catheter')
        }
        if(this.q9.main === 'true' && this.q4.main === 'true' && this.q10 == null){
            Stores.dataStore.setQuestionsMissing('q10')
        }
        if(this.q9.main === 'true' && this.q4.main === 'true' && this.q10 === ''){
            Stores.dataStore.setQuestionsMissing('q10')
        }
        if(this.q9 == null || this.q9.main == null ||
            (this.q9.main === 'true' && this.q9.catheter == null) ||
            (this.q9.main === 'true' && !this.q9.catheter.includes('missing') && this.q4.main === 'true' && this.q10 == null) ||
            (this.q9.main === 'true' && !this.q9.catheter.includes('missing') && this.q4.main === 'true' && this.q10 === '')){
            return true
        }
        return false
    }

    get checkMissing6() {
         if(this.q16 == null || this.q16.main == null){
             Stores.dataStore.setQuestionsMissing('q16')
             return true
        }
    }
    get checkMissing6_1() {
        if(this.q16.main == 'true' && (!this.q16?.missingDiseaseReason || this.q16.missingDiseaseReason== null)&& !this.q16.disease.find((d)=>d.selected != null)) {
            Stores.dataStore.setQuestionsMissing('q16disease')
        }

        if(this.q16.main == 'true'  && (!this.q16?.missingDiseaseReason || this.q16.missingDiseaseReason== null)&& !this.q16.disease.find((d)=>d.selected != null)) {
            return true
        }
    }
    get checkMissing6_2() {
        for(const d of this.q16.disease){
            if(d.selected != null && d.duration.type == null){
                Stores.dataStore.setQuestionsMissing('type'+d.name)
            }
            if(d.selected != null && (d.duration.type != null && d.duration.amount == '')){
                Stores.dataStore.setQuestionsMissing('duration'+d.name)
            }
            if(d.selected != null && (d.duration.type == null || d.frequency == '')){
                Stores.dataStore.setQuestionsMissing('frequency'+d.name)
            }
        }



         if(this.q16.disease.find((d)=>d.selected != null && (d.duration.type != null && d.duration.amount == ''))) {
            return true
        }else {
            let missingDisease = this.q16.disease.find((d)=>d.selected != null && (d.duration.type == null
                || d.frequency == ''))
            console.log('missingDisease',missingDisease)
            if(missingDisease){
                return true
            }
        }
    }

    get checkMissing7() {
        if(this.q17 == null || this.q17.main == null){
            Stores.dataStore.setQuestionsMissing('q17')
            return true
        }
    }
    get checkMissing7_1() {
        if(this.q17 == null || this.q17.main == null){
            Stores.dataStore.setQuestionsMissing('q17')
        }
       if(this.q17.main == 'true' && !this.q17.types.find((t)=>t.selected != null) && !this.q17.missingTypesReason){
           Stores.dataStore.setQuestionsMissing('q17types')
        }
       for(const type of this.q17.types){
           if(type.selected === 'on' && type.number == ''){
               Stores.dataStore.setQuestionsMissing(type.name)
           }
       }

       if((this.q17 == null || this.q17.main == null) ||
           (this.q17.main == 'true' && !this.q17.types.find((t)=>t.selected != null) && !this.q17.missingTypesReason) ||
           this.q17.types.find((t)=>t.selected === 'on' && t.number == '')){
           return true
       }
    }

    get checkMissing8() {
        if(this.q18 == null || this.q18.main == null){
            Stores.dataStore.setQuestionsMissing('q18')
            return true
        }
    }
    get checkMissing8_1() {
console.log('this.q18',this.q18)
        if(this.q18.main == 'true' && !this.q18.types.find((t)=>t.selected != null) && !this.q18.missingTypesReason){
            Stores.dataStore.setQuestionsMissing('q18type')
        }
        for(const t of this.q18.types){
            if(t.selected != null && t.number == ''){
                Stores.dataStore.setQuestionsMissing(t.name)
            }
        }

        if((this.q18 == null || this.q18.main == null) ||
            (this.q18.main == 'true' && !this.q18.types.find((t)=>t.selected != null)  && !this.q18.missingTypesReason) ||
            (this.q18.types.find((t)=>t.selected != null && t.number == ''))){
            return true
        }
    }

    get checkMissing9() {
        if(this.q19 == null || this.q19.main == null){
            Stores.dataStore.setQuestionsMissing('q19')
            return true
        }
    }
    get checkMissing9_1() {
        console.log('this.q19',this.q19)
        if(this.q19 == null || this.q19.main == null){
            Stores.dataStore.setQuestionsMissing('q19')
        }
        if(this.q19.main == 'true' && !this.q19.types.find((t)=>t.selected != null) && !this.q19.missingTypesReason){
            Stores.dataStore.setQuestionsMissing('q19type')
        }
        if((this.q19.types.find((t)=>t.name === 'Biopsy' && t.selected === 'on' && t.type1.name === null)) &&
            (this.q19.types.find((t)=>t.name === 'Biopsy' && t.selected === 'on' && t.type2.name === null))){
            Stores.dataStore.setQuestionsMissing('BiopsyAll')

        }
        // if(this.q19.types.find((t)=>t.name === 'Biopsy' && t.selected === 'on' && t.type1.name === null)){
        //     Stores.dataStore.setQuestionsMissing('Metastasis Biopsy')
        // }
        // if(this.q19.types.find((t)=>t.name === 'Biopsy' && t.selected === 'on' && t.type2.name === null)){
        //     Stores.dataStore.setQuestionsMissing('Tumor Biopsy')
        // }
        if (this.q19.types.find((t)=>t.type2?.name && t.type2.name != null && (!t.type2?.subName || t.type2.subName === null))){
            Stores.dataStore.setQuestionsMissing('q19type2number')
        }
        for(const t of this.q19.types){
            if(t.selected === 'on' && t.number == ''){
                Stores.dataStore.setQuestionsMissing(t.name)
            }
        }

        if((this.q19 == null || this.q19.main == null) ||
            (this.q19.main == 'true' && !this.q19.types.find((t)=>t.selected != null) && !this.q19.missingTypesReason)||
            ((this.q19.types.find((t)=>t.name === 'Biopsy' && t.selected === 'on' && t.type1.name === null)) &&
                (this.q19.types.find((t)=>t.name === 'Biopsy' && t.selected === 'on' && t.type2.name === null)))
            ||
            // (this.q19.types.find((t)=>(t.name === 'Biopsy' && t.selected === 'on' && t.type1.name === null) && (t.type1?.name && !t.type1.name.startsWith('missing|')))) ||
            // (this.q19.types.find((t)=>(t.name === 'Biopsy' && t.selected === 'on' && t.type2.name === null) && (t.type2?.name && !t.type2.name.startsWith('missing|')))) ||
            (this.q19.types.find((t)=>(t.name === 'Biopsy' && t.type2.name != null && t.type2.subName === null)))
            ||
            (this.q19.types.find((t)=>(t.selected != null && t.number == '')))){
            return true
        }

    }

    get checkMissing10() {
        if(this.q20 == null || this.q20.main == null){
            Stores.dataStore.setQuestionsMissing('q20')
        }

        if((this.q20.main == 'true' || this.q20.main == true) && this.q20.numberOfSurgery == ''){
            Stores.dataStore.setQuestionsMissing('q20numberOfSurgery')
        }


        if (this.q20.main == 'true') {
            for (let i=0;i<this.q21.length;i++) {
                if (this.q21[i].ward.selected == null && this.q21[i].intensiveCareUnit.selected == null && this.q21[i].intermediateCareUnit.selected == null) {
                    Stores.dataStore.setQuestionsMissing('hosp'+i)
                }
            }
        }

            for (let i=0;i<this.q21.length;i++) {
            if (this.q21[i].ward.selected  === 'on' && this.q21[i].ward.amount == ''){
                Stores.dataStore.setQuestionsMissing('hosp'+i+'ward')
            }
            if (this.q21[i].intensiveCareUnit.selected  === 'on' && this.q21[i].intensiveCareUnit.amount == ''){
                Stores.dataStore.setQuestionsMissing('hosp'+i+'intensiveCareUnit')
            }
            if (this.q21[i].intermediateCareUnit.selected  === 'on' && this.q21[i].intermediateCareUnit.amount == ''){
                Stores.dataStore.setQuestionsMissing('hosp'+i+'intermediateCareUnit')
            }
            }

        console.log('this.q21',this.q21)

        if (this.q20.main == 'true' && (this.q20.numberOfSurgery != '' && !this.q20.numberOfSurgery.includes('missing'))) {
            for (let i=0;i<this.q21.length;i++) {
                if ((this.q21[i].ward.selected == null || (this.q21[i].ward.selected !== 'on' && !this.q21[i].ward.selected.startsWith('missing|'))) &&
                    (this.q21[i].intensiveCareUnit.selected == null  || (this.q21[i].intensiveCareUnit.selected !== 'on'  && !this.q21[i].intensiveCareUnit.selected.startsWith('missing|'))) &&
                    (this.q21[i].intermediateCareUnit.selected == null || (this.q21[i].intermediateCareUnit.selected !== 'on' && !this.q21[i].intermediateCareUnit.selected.startsWith('missing|')))) {
                    return true
                }
            }
        }

        if((this.q20 == null || this.q20.main == null) ||
            ((this.q20.main == 'true' || this.q20.main == true) && this.q20.numberOfSurgery == '') ||
            (this.q20.main == 'true' || this.q20.main == true) && this.q21.length == 0 ||
            (this.q21 == null || !Array.isArray(this.q21))
        ) {

            return true
        }

        if ((this.q20.main == 'true' || this.q20.main == true) && (this.q20.numberOfSurgery != '' && !this.q20.numberOfSurgery.includes('missing'))) {
                for (let q of this.q21) {
                    if ((q.ward.selected === 'on' && (q.ward.amount == '' && !q.ward.amount.startsWith('missing|'))) ||
                        (q.intensiveCareUnit.selected  === 'on' && (q.intensiveCareUnit.amount == '' && q.intensiveCareUnit.amount.startsWith('missing|'))) ||
                        (q.intermediateCareUnit.selected  === 'on' && (q.intermediateCareUnit.amount == '' && q.intermediateCareUnit.amount.startsWith('missing|')))) {
                        return true
                    }
                }
        }


    }


    get checkMissing11() {
        console.log('this.q22',this.q22)
        if (this.q22.main === null) {

            Stores.dataStore.setQuestionsMissing('q22');
        }
        if(this.q22.main === 'true' &&
            ((this.q22.oncology.selected === null || (this.q22.oncology.selected !== 'on' && !this.q22.oncology.selected.startsWith('missing|'))) &&
                (this.q22.other.selected === null || (this.q22.other.selected !== 'on' && !this.q22.other.selected.startsWith('missing|'))) &&
                (this.q22.gp.selected === null || (this.q22.gp.selected !== 'on' && !this.q22.gp.selected.startsWith('missing|'))))){
            Stores.dataStore.setQuestionsMissing('q22value')
        }
        if(this.q22.oncology.selected === 'on' && (this.q22.oncology.amount === '' && !this.q22.oncology.amount.startsWith('missing|') && this.q22.oncology.unknown === null)){
            Stores.dataStore.setQuestionsMissing('oncology')
        }
        // if(this.q22.other.selected === 'on' && (this.q22.other.specialities[0].speciality === '' && this.q22.other.unknown === null)){
        //     Stores.dataStore.setQuestionsMissing('other')
        // }
        if(this.q22.other.selected === 'on' && this.q22.other.specialities.length > 0  && this.q22.other.unknown === null){
            for(let i=0;i<this.q22.other.specialities.length;i++){
                let spec = this.q22.other.specialities[i]
                console.log('spec',spec)
                if(spec.speciality === ''){
                    Stores.dataStore.setQuestionsMissing('spec' + i + 'speciality')
                }
                if(spec.amount === ''){
                    Stores.dataStore.setQuestionsMissing('spec' + i + 'amount')
                }
            }
        }
        if(this.q22.gp.selected === 'on' && (this.q22.gp.amount === '' && this.q22.gp.unknown === null)){
            Stores.dataStore.setQuestionsMissing('gp')
        }
        if(this.q22.main === null){
            return true
        }
        if(this.q22.main === 'true' &&
            (this.q22.oncology.selected === null &&
                this.q22.other.selected === null && this.q22.gp.selected === null)){
                return true
        }
        if(this.q22.oncology.selected === 'on' && (this.q22.oncology.amount === '' && !this.q22.oncology.amount.startsWith('missing|') && this.q22.oncology.unknown === null)){
            return true
        }
        if(this.q22.other.selected === 'on' && (this.q22.other.specialities[0].speciality === '' && this.q22.other.unknown === null)){
            return true
        }
        if(this.q22.gp.selected === 'on' && (this.q22.gp.amount === '' && this.q22.gp.unknown === null)){
        return true
        }
        if(this.q22.other.selected === 'on' && this.q22.other.specialities.length > 0 && this.q22.other.unknown === null){
            for(let i=0;i<this.q22.other.specialities.length;i++){
                let spec = this.q22.other.specialities[i]
                if(spec.speciality === '' || spec.amount ===  ''){
                    return true
                }

            }
        }

    }

    get checkMissing12() {
         if(this.q23 == null || this.q23.main == null){
             Stores.dataStore.setQuestionsMissing('q23')
             return true
        }else if(this.q23.main == 'true' && this.q23.amount == ''){
             Stores.dataStore.setQuestionsMissing('q23amount')
             return true
         }
    }

    get checkMissing13() {
        console.log('this.s1',this.s1)
         if(this.s1 == null || this.s1 == ''){
            return true
        }
    }


    get checkMissing14() {
        if (this.s3.main === 'true' &&  this.s3.missingGenomicReason == null &&
            (this.s3.genomic.ALK == null || this.s3.genomic.ALK === false) &&
            (this.s3.genomic.BRAF == null || this.s3.genomic.BRAF === false) &&
            (this.s3.genomic.EGFR.selected == null || this.s3.genomic.EGFR.selected === false) &&
            (this.s3.genomic.MET == null || this.s3.genomic.MET === false) &&
            (this.s3.genomic.NTRK == null || this.s3.genomic.NTRK === false) &&
            (this.s3.genomic.ROS == null || this.s3.genomic.ROS === false) &&
            (this.s3.genomic.HER2 == null || this.s3.genomic.HER2 === false) &&
            (this.s3.genomic.KRAS == null || this.s3.genomic.KRAS === false) &&
            (this.s3.genomic.RET == null || this.s3.genomic.RET === false) &&
            (this.s3.genomic.Other == null || this.s3.genomic.Other === false) &&
            (this.s3.genomic.NoMutation == null || this.s3.genomic.NoMutation === false) &&
            (this.s3.genomic.Inconclusive == null || this.s3.genomic.Inconclusive === false)
        ) {
            Stores.dataStore.setQuestionsMissing('s3value')
        }
        if(this.s3.genomic.EGFR.selected != null && this.s3.genomic.EGFR.values.length === 0){
            Stores.dataStore.setQuestionsMissing('EGFR')
        }

        if((this.s3 == null || this.s3.main == null) ||
            (this.s3.main === 'true' && this.s3.missingGenomicReason == null &&
                (this.s3.genomic.ALK == null || this.s3.genomic.ALK === false) &&
                (this.s3.genomic.BRAF == null || this.s3.genomic.BRAF === false) &&
                (this.s3.genomic.EGFR.selected == null || this.s3.genomic.EGFR.selected === false) &&
                (this.s3.genomic.MET == null || this.s3.genomic.MET === false) &&
                (this.s3.genomic.NTRK == null || this.s3.genomic.NTRK === false) &&
                (this.s3.genomic.ROS == null || this.s3.genomic.ROS === false) &&
                (this.s3.genomic.HER2 == null || this.s3.genomic.HER2 === false) &&
                (this.s3.genomic.KRAS == null || this.s3.genomic.KRAS === false) &&
                (this.s3.genomic.RET == null || this.s3.genomic.RET === false) &&
                (this.s3.genomic.Other == null || this.s3.genomic.Other === false) &&
                (this.s3.genomic.Inconclusive == null || this.s3.genomic.Inconclusive === false) &&
                (this.s3.genomic.NoMutation == null || this.s3.genomic.NoMutation === false)
            ) || (this.s3.genomic.EGFR.selected != null && this.s3.genomic.EGFR.values.length === 0)
        ){
            return true
        }

    }
    get checkMissing15() {
        if( this.s4 == null || this.s4.main == null){
            Stores.dataStore.setQuestionsMissing('s4')
            return true
        }else if(this.s4.main === 'true' && this.s4.type == null){
            Stores.dataStore.setQuestionsMissing('s4type')
            return true
        }
    }

    get checkMissing16() {
        if(this.s5 == ''){
            Stores.dataStore.setQuestionsMissing('s5')
        }
        if(this.s6 == '') {
            Stores.dataStore.setQuestionsMissing('s6')
        }
        if(this.s7 == null || this.s7.type == null) {
            Stores.dataStore.setQuestionsMissing('s7')
        }
        if(this.s7.type != null && this.s7.value == ''){
            Stores.dataStore.setQuestionsMissing('s7value')
        }
        if(this.s5 == '' || this.s6 == '' || (this.s7 == null || this.s7.type == null) || (this.s7.type != null && this.s7.value == '')){
            return true

        }
    }

    setNewExamText = (value) => {
        this.newExamText = value
    }
    setNewLabText = (value) => {
        this.newLabText = value
    }

    setNewNeoText = (value) => {
        this.newNeoText = value
    }
    setNewDiseaseText = (value) => {
        this.newDiseaseText = value
    }
    setNewImageAssessmentText = (value) => {
        this.newImageAssessmentText = value
    }
    setQ1 = (value) => {
        this.q1 = value
    }
    setS1 = (value) => {
        this.s1 = value
    }

    setS2 = (value) => {
        this.s2 = value
    }
    setS3 = (value) => {
        this.s3 = value
    }
    setS4 = (value) => {
        this.s4 = value
    }
    setS5 = (value) => {
        this.s5 = value
    }
    setS6 = (value) => {
        this.s6 = value
    }
    setS7 = (value) => {
        this.s7 = value
    }
    setQ2 = (value) => {
        this.q2 = value
    }
    setQ3 = (value) => {
        this.q3 = value
    }
    setQ4 = (value) => {
        this.q4 = value
    }
    setQ5 = (value) => {
        this.q5 = value
    }
    setQ6 = (value) => {
        this.q6 = value
    }
    setQ7 = (value) => {
        this.q7 = value
    }
    setQ8 = (value) => {
        this.q8 = value
    }
    setQ9 = (value) => {
        this.q9 = value
    }
    setQ10 = (value) => {
        this.q10 = value
    }
    setQ16 = (value) => {
        this.q16 = value
    }
    setQ17 = (value) => {
        this.q17 = value
    }
    setQ18 = (value) => {
        this.q18 = value
    }
    setQ19 = (value) => {
        this.q19 = value
    }
    setQ20 = (value) => {
        this.q20 = value
    }
    setQ21 = (value) => {
        this.q21 = value
    }
    setQ22 = (value) => {
        this.q22 = value
    }
    setQ23 = (value) => {
        this.q23 = value
    }


    setLoading = (value) => {
        this.loading = value
    }

    updateTreatment = async (requestBody, number, patient) => {
        try {
            this.setLoading(true)
            console.log('requestBody', requestBody)
            let params = {...requestBody,force:Stores.dataStore.forceUpdate}

            if(Stores.dataStore.updateReason !== ''){
                params = {...params, reason:Stores.dataStore.updateReason}
            }
            console.log('updateTreatment params', params)
            const response = await CrfService.updateTreatment(patient, number, params)
            console.log('updateTreatment response', response)
            if (response.q1 != null) {
                this.setQ1(response.q1)
            }
            if (response.q2 != null) {
                this.setQ2(response.q2)
            }
            if (response.q3 != null) {
                this.setQ3(response.q3)
            }
            if (response.q4 != null) {
                this.setQ4(response.q4)
            }
            if (response.q5 != null) {
                this.q5 = [{
                    surgicalProcedure: null,
                    anatomicalResection: null,
                }]
                for (let i = 0; i < response.q5.length; i++) {
                    this.q5[i] = response.q5[i]
                }
            }
            if (response.q6 != null) {
                this.setQ6(response.q6)
            }
            if (response.q7 != null) {
                this.setQ7(response.q7)
            }
            if (response.q8 != null) {
                this.setQ8(response.q8)
            }
            if (response.q9 != null) {
                this.setQ9(response.q9)
            }
            if (response.q10 != null) {
                this.setQ10(response.q10)
            }
            if (response.q16 != null) {
                this.setQ16(response.q16)
            }
            if (response.q17 != null) {
                this.setQ17(response.q17)
            }
            if (response.q18 != null) {
                this.setQ18(response.q18)
            }
            if (response.q19 != null) {
                this.setQ19(response.q19)
            }
            if (response.q20 != null) {
                this.setQ20(response.q20)
            }
            if (response.q21 != null) {
                this.q21 = [{
                    ward: {selected: null, amount: ''},
                    intermediateCareUnit: {selected: null, amount: ''},
                    intensiveCareUnit: {selected: null, amount: ''},
                }]
                for (let i = 0; i < response.q21.length; i++) {
                    this.q21[i] = response.q21[i]

                }
                console.log('this.q21.length', this.q21.length)
            }
            if (response.q22 != null) {
                // for (let i=0; i<response.q21.length; i++){
                //     this.q21[i] = response.q21[i]
                // }
                this.setQ22(response.q22)

            }
            if (response.q23 != null) {
                this.setQ23(response.q23)
            }
            if (response.s1 != null) {
                this.setS1(response.s1)
            }
            if (response.s2 != null) {
                this.setS2(response.s2)
            }
            if (response.s3 != null) {
                this.setS3(response.s3)
            }
            if (response.s4 != null) {
                this.setS4(response.s4)
            }
            if (response.s5 != null) {
                this.setS5(response.s5)
            }
            if (response.s6 != null) {
                this.setS6(response.s6)
            }
            if (response.s7 != null) {
                this.setS7(response.s7)
            }
            Stores.dataStore.resetUpdateModalData()

            this.setLoading(false)

        } catch (err) {
            this.setLoading(false)
            console.log('updateTreatment err', err)
            if(err?.body?.data?.updated){
                Stores.dataStore.setShowUpdateModal('active')
            }else {
                Stores.dataStore.setShowUpdateModal('not active')
            }
        }
    }


    getTreatment = async (patient,number) => {
        try {
            this.setLoading(true)
            const response = await CrfService.getTreatment(patient, number)
            console.log('getTreatment response', response)

            if (response.q1 != null) {
                this.setQ1(response.q1)
            }
            if (response.q2 != null) {
                this.setQ2(response.q2)
            }
            if (response.q3 != null) {
                this.setQ3(response.q3)
                if (response.q3.main.startsWith('missing|')) {
                    Stores.dataStore.setQuestionsMissing('q3')
                }
                if(response.q3?.missingTherapiesReason && response.q3.missingTherapiesReason.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('therapies')
                }
                if(response.q3?.therapies && response.q3.therapies.length > 0){
                    for(const therapy of response.q3.therapies){
                        if(therapy.params?.dose && therapy.params.dose.startsWith('missing|')){
                            Stores.dataStore.setQuestionsMissing(therapy.name + 'dose')
                        }
                        if(therapy.params?.numberOfCyclesPlanned && therapy.params.numberOfCyclesPlanned.startsWith('missing|')){
                            Stores.dataStore.setQuestionsMissing(therapy.name + 'numberOfCyclesPlanned')
                        }
                        if(therapy.params?.numberOfCyclesActual && therapy.params.numberOfCyclesActual.startsWith('missing|')){
                            Stores.dataStore.setQuestionsMissing(therapy.name + 'numberOfCyclesActual')
                        }
                        if(therapy.params?.totalDurationInWeeks && therapy.params.totalDurationInWeeks.startsWith('missing|')){
                            Stores.dataStore.setQuestionsMissing(therapy.name + 'totalDurationInWeeks')
                        }
                    }
                }
            }
            if (response.q4 != null) {
                this.setQ4(response.q4)

                if(response.q4.main.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q4')
                }

                if(response.q4?.numberOfSurgery && response.q4.numberOfSurgery.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q4numberOfSurgery')
                }




            }
            if (response.q5 != null) {
                this.q5 = [{
                    surgicalProcedure: null,
                    anatomicalResection: null,
                }]
                for (let i = 0; i < response.q5.length; i++) {
                    this.q5[i] = response.q5[i]
                }
                if (response.q5.length > 0) {
                    for (let i=0;i<response.q5.length;i++) {
                        if (response.q5[i]?.surgicalProcedure && response.q5[i].surgicalProcedure.startsWith('missing|')) {
                            Stores.dataStore.setQuestionsMissing('surgicalProcedure'+i)
                        }
                        if (response.q5[i]?.missingAnatomicalReason && response.q5[i].missingAnatomicalReason.startsWith('missing|')) {
                            Stores.dataStore.setQuestionsMissing('anatomicalResection'+i)
                        }
                    }
                }
            }

            if (response.q6 != null) {
                this.setQ6(response.q6)
                if(response.q6.main.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q6')
                }
                if(response.q6?.value && response.q6.value.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q6value')

                }
            }
            if (response.q7 != null) {
                this.setQ7(response.q7)
                if(response.q7?.main && response.q7.main.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q7q8')
                }
                if(response.q7?.dose && response.q7.dose.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q7dose')
                }
                if(response.q7?.numberOfFractions && !response.q7.numberOfFractions.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q7numberOfFractions')
                }



            }
            if (response.q8 != null) {
                this.setQ8(response.q8)
                if(response.q8?.main && response.q8.main.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q7q8')
                }
                if(response.q8?.dose && response.q8.dose.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q8dose')
                }
                if(response.q8?.numberOfFractions && response.q8.numberOfFractions.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q8numberOfFractions')
                }
            }

            if (response.q9 != null) {
                this.setQ9(response.q9)
                if(response.q9.main.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q9')
                }
                if(response.q9?.catheter && response.q9.catheter.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q9catheter')
                }
            }

            if (response.q10 != null) {
                this.setQ10(response.q10)
                if(response.q10.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q10')
                }
            }
            if (response.q16?.main && response.q16.main != null) {
                this.setQ16(response.q16)
                if(response.q16.main.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q16')
                }
                if(response.q16?.missingDiseaseReason && response.q16.missingDiseaseReason.startsWith('missing|')) {
                    Stores.dataStore.setQuestionsMissing('q16disease')
                }
                if(response.q16.disease.length > 0){
                    for(const d of response.q16.disease){
                        if(d.duration?.type && d.duration.type.startsWith('missing|')){
                            Stores.dataStore.setQuestionsMissing('type'+d.name)
                        }
                        if(d.duration?.amount && d.duration.amount.startsWith('missing|')){
                            Stores.dataStore.setQuestionsMissing('duration'+d.name)
                        }
                        if(d.frequency && d.frequency.startsWith('missing|')){
                            Stores.dataStore.setQuestionsMissing('frequency'+d.name)
                        }
                    }
                }

            }
            if (response.q17 != null) {
                    this.setQ17(response.q17)
                if(response.q17.main && response.q17.main.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q17')
                }
                if(response.q17.missingTypesReason && response.q17.missingTypesReason !== null && response.q17.missingTypesReason.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q17types')
                }
                for(const type of response.q17.types){
                    if(type.selected != null && (type.number == '' || type.number.startsWith('missing|'))){
                        Stores.dataStore.setQuestionsMissing(type.name)
                    }
                }
            }
            if (response.q18 != null) {
                this.setQ18(response.q18)
                if(response.q18?.main && response.q18.main.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q18')
                }

                if(response.q18?.missingTypesReason && response.q18.missingTypesReason.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q18type')
                }
                if(response.q18?.types && response.q18.types.length > 0){
                    for(const t of response.q18.types){
                        if(t.selected === 'on' && (t.number == '' || t.number.startsWith('missing|'))){
                            Stores.dataStore.setQuestionsMissing(t.name)
                        }
                    }
                }


            }
            if (response.q19 != null) {
                    this.setQ19(response.q19)
                if(response.q19?.main && response.q19.main.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q19')
                }

                if(response.q19?.missingTypesReason && response.q19.missingTypesReason.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q19type')
                }
                if((this.q19.types.find((t)=>t.name === 'Biopsy' && t.selected === 'on' && t.type1?.name &&t.type1.name.startsWith('missing|'))) &&
                    (this.q19.types.find((t)=>t.name === 'Biopsy' && t.selected === 'on' && t.type2.name.startsWith('missing|')))){
                    Stores.dataStore.setQuestionsMissing('BiopsyAll')

                }
                // if(response.q19.types.find((t)=>t.name === 'Biopsy' && t.selected === 'on' && t.type1?.name &&t.type1.name.startsWith('missing|')) ){
                //     Stores.dataStore.setQuestionsMissing('Metastasis Biopsy')
                // }
                // if(response.q19.types.find((t)=>t.name === 'Biopsy' && t.selected === 'on' && t.type2.name.startsWith('missing|'))){
                //     Stores.dataStore.setQuestionsMissing('Tumor Biopsy')
                // }
                if (response.q19.types.find((t)=>t.type2?.name && t.type2.name != null && (!t.type2?.subName || t.type2.subName.startsWith('missing|')))){
                    Stores.dataStore.setQuestionsMissing('q19type2number')
                }
                if(response.q19.types.length > 0){
                    for(const t of response.q19.types){
                        if((t.selected === 'on' && t.number.startsWith('missing|'))){
                            Stores.dataStore.setQuestionsMissing(t.name)
                        }
                    }
                }

            }
            if (response.q20 != null) {
                this.setQ20(response.q20)
                if(response.q20.main && response.q20.main.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q20')
                }

                if(response.q20?.numberOfSurgery && response.q20.numberOfSurgery.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q20numberOfSurgery')
                }



            }
            if (response.q21 != null) {
                this.q21 = [{
                    ward: {selected: null, amount: ''},
                    intermediateCareUnit: {selected: null, amount: ''},
                    intensiveCareUnit: {selected: null, amount: ''},
                }]
                for (let i = 0; i < response.q21.length; i++) {
                    this.q21[i] = response.q21[i]
                }
                if (response.q21.length >0) {
                    for (let i=0;i<response.q21.length;i++) {
                        if ((typeof response.q21[i].ward.selected === 'string' && response.q21[i].ward.selected.startsWith('missing|')) &&
                            (typeof response.q21[i].intensiveCareUnit.selected === 'string' &&response.q21[i].intensiveCareUnit.selected.startsWith('missing|')) &&
                            (typeof response.q21[i].intermediateCareUnit.selected === 'string' &&response.q21[i].intermediateCareUnit.selected.startsWith('missing|'))) {
                            Stores.dataStore.setQuestionsMissing('hosp'+i)
                        }
                        if (response.q21[i]?.ward?.amount && response.q21[i].ward.amount.startsWith('missing|')){
                            Stores.dataStore.setQuestionsMissing('hosp'+i+'ward')
                        }
                        if (response.q21[i]?.intensiveCareUnit?.amount && response.q21[i].intensiveCareUnit.amount.startsWith('missing|')){
                            Stores.dataStore.setQuestionsMissing('hosp'+i+'intensiveCareUnit')
                        }
                        if (response.q21[i]?.intermediateCareUnit?.amount && response.q21[i].intermediateCareUnit.amount.startsWith('missing|')){
                            Stores.dataStore.setQuestionsMissing('hosp'+i+'intermediateCareUnit')
                        }
                    }

                }
            }
            if (response.q22 != null) {
                // for (let i=0; i<response.q21.length; i++){
                //     this.q21[i] = response.q21[i]
                // }
                this.setQ22(response.q22)
                if(response.q22.main && response.q22.main.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q22')
                }
                if(response.q22.oncology?.amount && response.q22.oncology.amount.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('oncology')
                }
                if(response.q22.oncology.selected && response.q22.oncology.selected.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q22value')
                }
                // if(response.q22.other.selected && response.q22.other.selected.startsWith('missing|')){
                //     Stores.dataStore.setQuestionsMissing('other')
                // }
                if(response.q22.gp?.amount && response.q22.gp.amount.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('gp')
                }
                if(response.q22.other.selected === 'on' && response.q22.other.specialities.length > 0){
                    for(let i=0;i<response.q22.other.specialities.length;i++){
                        let spec = response.q22.other.specialities[i]
                        console.log('spec',spec)
                        if(spec.speciality === '' || spec.speciality.startsWith('missing|')){
                            Stores.dataStore.setQuestionsMissing('spec' + i + 'speciality')

                        }
                        if(spec.amount === '' || spec.amount.startsWith('missing|')){
                            Stores.dataStore.setQuestionsMissing('spec' + i + 'amount')

                        }
                    }
                }

            }
            if (response.q23 != null) {
                this.setQ23(response.q23)
                if(response.q23.main && response.q23.main.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q23')
                }
                if(response.q23.amount && response.q23.amount.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q23amount')
                }
            }
            if (response.s1 != null) {
                this.setS1(response.s1)
            }
            if (response.s2 != null) {
                this.setS2(response.s2)
            }
            if (response.s3 != null) {
                this.setS3(response.s3)

                if (response.s3?.missingGenomicReason && response.s3.missingGenomicReason.startsWith('missing|')) {
                    Stores.dataStore.setQuestionsMissing('s3value')
                }

                if (response.s3?.missingEGFRReason && response.s3.missingEGFRReason.startsWith('missing|')) {
                    Stores.dataStore.setQuestionsMissing('EGFR')
                }
            }
            if (response.s4 != null) {
                this.setS4(response.s4)
            }
            if (response.s5 != null) {
                this.setS5(response.s5)
                if(response.s5.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('s5')
                }
            }
            if (response.s6 != null) {
                this.setS6(response.s6)
                if(response.s6.startsWith('missing|')) {
                    Stores.dataStore.setQuestionsMissing('s6')
                }
            }
            if (response.s7 != null) {
                this.setS7(response.s7)
                if(response.s7?.type && response.s7.type.startsWith('missing|')) {
                    Stores.dataStore.setQuestionsMissing('s7')
                }
                if((this.s7.type != null && !response.s7.type.startsWith('missing|')) && (this.s7?.value && this.s7.value.startsWith('missing|'))){
                    Stores.dataStore.setQuestionsMissing('s7value')
                }
            }
            Stores.dataStore.setForceUpdate(false)


            this.setLoading(false)

        } catch (err) {
            this.setLoading(false)
            console.log('getTreatment err', err)
        }
    }

    addNeo = async () => {
        if(this.q3.therapies.find((t)=>t.name == this.newNeoText)){
            alert('You are trying to add duplicate value.')
            this.setNewNeoText('')
        }else{
            this.q3.therapies.push({name: this.newNeoText, params: {...this.q3CommonParams, selected: 'on'}})
            this.setNewNeoText('')
        }

    }

    addDisease = async () => {
        if(this.q16.disease.find((d)=>d.name == this.newDiseaseText)){
            alert('You are trying to add duplicate value.')
            this.setNewDiseaseText('')
        }else{
            this.q16.disease.push({name: this.newDiseaseText, extra: [], selected: 'on', ...this.commonQ16Params})
            this.setNewDiseaseText('')
        }
    }

    addImageAssessmentType = async () => {

        if(this.q17.types.find((t)=>t.name == this.newImageAssessmentText)){
            alert('You are trying to add duplicate value.')
            this.setNewImageAssessmentText('')
        }else{
            this.q17.types.push({name: this.newImageAssessmentText, number: '', selected: 'on'})
            this.setNewImageAssessmentText('')
        }
    }


    addNewLabs = async () => {

        if(this.q18.types.find((t)=>t.name == this.newLabText)){
            alert('You are trying to add duplicate value.')
            this.setNewLabText('')
        }else{
            this.q18.types.push({name: this.newLabText, number: '', selected: 'on'})
            this.setNewLabText('')
        }
    }

    addNewExams = async () => {

        if(this.q19.types.find((t)=>t.name == this.newExamText)){
            alert('You are trying to add duplicate value.')
            this.setNewLabText('')
        }else{
            this.q19.types.push({name: this.newExamText, number: '', selected: 'on'})
            this.setNewExamText('')
        }
    }


    addNewSpeciality = () => {

        this.setLoading(true)

        let speciality = this.q22.other.specialities
        if(speciality.length < 6 && speciality[speciality.length - 1].speciality !== ''){
            speciality.push({
                speciality: '',
                amount: ''
            })
        }

        this.q22.other.specialities = speciality
        this.q22.other.unknown = null
        console.log('     this.q22.other.specialities', this.q22.other.specialities)
        this.setLoading(false)

    }
    removeNewSpeciality = (index) => {
        this.setLoading(true)
        let speciality = this.q22.other.specialities
        speciality.splice(index, 1)

        this.q22.other.specialities = speciality
        console.log('this.q22.other.specialities', this.q22.other.specialities)
        this.setLoading(false)

    }

    showInconclusive = () =>{
        if(this.s3.genomic?.ALK && this.s3.genomic.ALK == 'on'){
            return false
        }
        if(this.s3.genomic?.EGFR?.selected && this.s3.genomic.EGFR.selected == 'on' ||
            this.s3.genomic.EGFR?.values && this.s3.genomic.EGFR.values.length>0){
            return false
        }
        if(this.s3.genomic?.BRAF && this.s3.genomic.BRAF == 'on'){
            return false
        }
        if(this.s3.genomic?.MET && this.s3.genomic.MET == 'on'){
            return false
        }
        if(this.s3.genomic?.NTRK && this.s3.genomic.NTRK == 'on'){
            return false
        }
        if(this.s3.genomic?.ROS && this.s3.genomic.ROS == 'on'){
            return false
        }
        if(this.s3.genomic?.HER2 && this.s3.genomic.HER2 == 'on'){
            return false
        }
        if(this.s3.genomic?.KRAS && this.s3.genomic.KRAS == 'on'){
            return false
        }
        if(this.s3.genomic?.RET && this.s3.genomic.RET == 'on'){
            return false
        }
        if(this.s3.genomic?.RET && this.s3.genomic.RET == 'on'){
            return false
        }
        if(this.s3.genomic?.NoMutation && this.s3.genomic.NoMutation == 'on'){
            return false
        }
        if(this.s3.genomic?.Other && this.s3.genomic.Other == 'on'){
            return false
        }

        return true
    }

    get checkTumorS1(){
        if ((this.s1 === null || this.s1 === '') || (this.s1 === 'true' && this.s2.main === null)) {
            return true
        }
        if ((this.s1 === 'true' || this.s1 === true) && this.s2.main === 'true' && (this.s2.method === null || this.s2.levels === null)) {
            return true
        }
    };

    get checkMissings1 () {
        if(this.s2 == null || this.s2.main == null){
            return true
        }else if(this.s2.main === 'true'  && this.s2.levels == null){
            return true
        }
    }

}


export default TreatmentPatternsStore
