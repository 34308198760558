import {useStores,} from './store'
import * as React from 'react';
import {useEffect} from 'react';
import {useNavigate,} from "react-router-dom";
import {observer} from "mobx-react";
import {OpenAPI} from "./services/openapi";
import Loading from "./components/Loading";

OpenAPI.BASE = 'https://characterize.pt';

const App = () => {
    const {
        authStore, menuStore
    } = useStores()
    const navigate = useNavigate()

    const checkUser = async () => {
        await authStore.checkUser()

        if (authStore.userType) {
            authStore.setUserTypeByName(authStore.userType)
            let selectedItem = menuStore.getSelectedItem
            console.log('selectedItem', selectedItem)
            if (authStore.userType === 'X2') {
                if (selectedItem && selectedItem === 'pIDelegates') {
                    navigate('/pIDelegates')
                }else if(selectedItem && selectedItem === 'auditTrail'){
                    navigate('/auditTrail')
                }else if(selectedItem && selectedItem === 'exportDB'){
                    navigate('/exportDB')
                }else{
                    navigate('/viewDataAudit')
                }
            } else if (authStore.userType === 'SuperAdmin') {
                if(selectedItem && selectedItem === 'auditTrail'){
                    navigate('/auditTrail')
                }else{
                    navigate('/pIDelegates')
                }
            } else if (authStore.userType === 'PI' || authStore.userType === 'Delegate') {
                if(selectedItem && selectedItem === 'viewData'){
                    navigate('/viewData')
                }else if(selectedItem && selectedItem === 'delegate'){
                    navigate('/delegate')
                }else{
                    navigate('/addData')
                }
            }else{
                navigate('./')
            }
        } else {
            navigate('/login')
        }
    }

    useEffect(() => {
        checkUser()

    }, [])

    return (
        <Loading/>
    );
}

export default observer(App);
