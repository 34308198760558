import {useStores,} from '../store'
import {useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import backImage from "../images/backImage.png";
import {observer} from "mobx-react";
import AuthFooter from "./AuthFooter";
import Logo from "../components/Logo";
import MyButton from "../components/MyButton";
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import {ArrowBack} from "@mui/icons-material";
import theme from "../styles/theme";
import * as React from "react";
import AlertModal from "../components/AlertModal";

const ForgetPassword = () => {
    const {
        authStore, myAccountStore
    } = useStores()

    const navigate = useNavigate()

    useEffect(() => {
        myAccountStore.getMyAccount()
    }, [])

    return (
        <Grid container component="main" sx={{height: '100vh'}}>
            <Grid item md={6} sm={12} sx={{
                position: "relative",
                backgroundPosition: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: "center"
            }}>
                <Box onClick={() => navigate('/')} sx={{cursor:'pointer'}}>
                    <Logo type={'green'}/>

                </Box>
                <Box sx={{
                    my: 8,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <Button onClick={() => navigate(-1)} sx={{color: "txtGrey", mb: 4, alignSelf: "flex-start"}}>
                        <ArrowBack sx={{
                            mr: 2,
                            background: theme.palette.primary.main,
                            color: "white",
                            borderRadius: "50%",
                            width: 36,
                            height: 36,
                            p: 1
                        }}/>
                        Back to login
                    </Button>
                    <Typography component="h1" variant="h3" color={'primary'} sx={{fontWeight: 'bold', mb: 2}}>
                        Forget Password
                    </Typography>
                    <Box component="form" noValidate sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            sx={{width: '100%', minWidth: "400px"}}
                            value={authStore.email}
                            onChange={(event) => authStore.setEmail(event.currentTarget.value)}
                        />
                    </Box>
                    <MyButton sx={{mt: 4, px: 8}}
                              label={'Send Reset Link'}
                              onClick={authStore.resetPasswordRequest}/>
                </Box>
                <AuthFooter/>
            </Grid>
            <Grid
                item
                md={6}
                sm={12}
                sx={{
                    backgroundImage: `url(${backImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Typography component="h2" variant="h2"
                            sx={{fontWeight: 'bold', color: 'white', px: 12, maxWidth: 600}}>
                    Forgot Your Password? Reset it!
                </Typography>
            </Grid>
            <AlertModal
                open={authStore.showErrMessage}
                onClose={() => {
                    authStore.setShowErrMessage(false)
                    authStore.setErrorMessage('')
                }}
                text={authStore.errorMessage}
                title={'Error!'}/>
        </Grid>
    );
}

export default observer(ForgetPassword)
