import {action, makeObservable, observable,} from 'mobx'
import {CrfService} from "../../services/openapi";

class ExclusionCriteriaStore {

    loading = false
    completed = false
    q1 = null
    q2 = null
    q3 = null
    q4 = null
    q5 = null
    q6 = null

    constructor() {

        makeObservable(this, {
            loading: observable,
            completed: observable,
            q1: observable,
            q2: observable,
            q3: observable,
            q4: observable,
            q5: observable,
            q6: observable,

            setQ1: action,
            setQ2: action,
            setQ3: action,
            setQ4: action,
            setQ5: action,
            setQ6: action,
            updateInclusionExclusion: action,
            getInclusionExclusion: action,
            setLoading: action,
            reset: action,

        },)
    }

    reset = () => {
        this.q1 = null
        this.q2 = null
        this.q3 = null
        this.q4 = null
        this.q5 = null
        this.q6 = null
    }


    setQ1 = (value) => {
        this.q1 = value
    }
    setQ2 = (value) => {
        this.q2 = value

    }
    setQ3 = (value) => {
        this.q3 = value

    }
    setQ4 = (value) => {
        this.q4 = value

    }
    setQ5 = (value) => {
        this.q5 = value

    }
    setQ6 = (value) => {
        this.q6 = value

    }


    setLoading = (value) => {
        this.loading = value
    }


    updateInclusionExclusion = async (requestBody,patient) => {
        try {
            this.setLoading(true)

            const response = await CrfService.updateInclusionExclusion(patient, requestBody)
            console.log('updateInclusionExclusion response', response)
            this.setQ1(response.q3)
            this.setQ2(response.q4)
            this.setQ3(response.q5)
            this.setQ4(response.q6)
            this.setQ5(response.q7)
            this.setQ6(response.q8)
            this.setLoading(false)

        } catch (err) {
            this.setLoading(false)
            console.log('updateInclusionExclusion err', err)
        }
    }


    getInclusionExclusion = async (patient) => {
        try {
            this.setLoading(true)

            const response = await CrfService.getInclusionExclusion(patient)
            console.log('updateInclusionExclusion response', response)
            this.completed = response.should_include
            this.setQ1(response.q3)
            this.setQ2(response.q4)
            this.setQ3(response.q5)
            this.setQ4(response.q6)
            this.setQ5(response.q7)
            this.setQ6(response.q8)
            this.setLoading(false)
        } catch (err) {
            this.setLoading(false)
            console.log('getExclusionExclusion err', err)
        }
    }
}


export default ExclusionCriteriaStore
