import * as React from 'react';
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";


const YesNo = ({handleChange, sx, unknown, value, fontSize, disabled, children}) => {

    return (
        <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={handleChange}
            value={value}
            sx={{display: 'flex', flexDirection: 'row', gap: "24px", rowGap: 0, ...sx}}
        >
            {children}
            <FormControlLabel disabled={disabled} componentsProps={{
                typography: {sx: {fontSize: fontSize ? fontSize : 20,}}
            }} value={true} control={<Radio/>} label="Yes"/>
            <FormControlLabel disabled={disabled} sx={{ml: 2}} componentsProps={{
                typography: {sx: {fontSize: fontSize ? fontSize : 20, }}
            }} value={false} control={<Radio/>} label="No"/>
            {unknown && <FormControlLabel disabled={disabled} sx={{ml: 2}} componentsProps={{
                typography: {sx: {fontSize: fontSize ? fontSize : 20,}}
            }} value="unknown" control={<Radio disabled={disabled}/>} label="Unknown"/>}

            {children}
        </RadioGroup>
    );
}

export default YesNo;
