import * as React from 'react';
import theme from '../styles/theme'
import {Modal} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MyButton from "./MyButton";


const AlertModal = ({open, onClose, text, title}) => {
    return (
        <Modal
            open={open}
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: `4px solid ${theme.palette.primary.main}`,
                boxShadow: 24,
                p: 4,
            }}>
                <Typography id="modal-modal-title" variant="h4" component="h3" fontWeight="bold">
                    {title}
                </Typography>
                <Typography id="modal-modal-title" sx={{mt: 2}}>
                    {text}
                </Typography>
                <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 5}}>
                    <MyButton sx={{px: 6}} label={'OK'} onClick={onClose}/>
                </Box>
            </Box>
        </Modal>
    );
}

export default AlertModal;
