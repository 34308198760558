import * as React from 'react';
import theme from '../styles/theme'
import Box from "@mui/material/Box";


const QuestionText = (props) => {

    return (
        <Box sx={props.allInRow ? {
                border: `2px solid ${theme.palette.primary.main}`,
                p: 2, display: 'flex', alignSelf: 'center', borderRadius: 6, flexDirection: 'row',
                width: '100%', alignItems: 'center', justifyContent: 'space-between', py: 1,                minHeight:60

            }
            :
            {
                border: `2px solid ${theme.palette.primary.main}`,
                p: 2,
                display: 'flex',
                alignSelf: 'center',
                borderRadius: 6,
                flexDirection: 'column',
                width: '100%',
                py: 1,
                minHeight:60
            }}>
            {props.children}
        </Box>
    );
}

export default QuestionText;
