import {action, makeObservable, observable,} from 'mobx'
import {AuditService, ExportService} from "../services/openapi";
import moment from "moment";

import {downloadFile} from "../utils";

class AuditStore {
    loading = false
    logs = []
    count = 0
    page = 1
    selectedFilter = 0
    actionDescriptionFilter = ''
    dateMenuFilter = null
    auditSearchTxt = ''
    exportSearchTxt = ''
    timeFilter = null
    exportAreas = []
    datePickerAudit =null
    datePickerExport =null
    exportSelectionItems =[]

    constructor() {
        makeObservable(this, {
            loading: observable,
            logs: observable,
            count: observable,
            page: observable,
            selectedFilter: observable,
            auditSearchTxt: observable,
            exportSearchTxt: observable,
            dateMenuFilter: observable,
            actionDescriptionFilter: observable,
            exportAreas: observable,
            timeFilter: observable,
            datePickerAudit: observable,
            datePickerExport: observable,
            exportSelectionItems: observable,
            setLogs: action,
            setCount: action,
            setPage: action,
            getAudits: action,
            setSelectedFilter: action,
            setExportAreas: action,
            setDateMenuFilter: action,
            setAuditSearchTxt: action,
            setTimeFilter: action,
            exportAuditData: action,
            setDatePickerAudit: action,
            setDatePickerExport: action,
            setExportSearchTxt: action,
            exportData: action,
            setExportSelectionItems:action
        },)
    }

    setTimeFilter = (value) => {
        this.timeFilter = value
        this.getAudits()
    }
    setAuditSearchTxt = (value) => {
        this.auditSearchTxt = value
    }
    setExportSelectionItems = (value) => {
        this.exportSelectionItems = value
    }
    setExportSearchTxt = (value) => {
        this.exportSearchTxt = value
    }
    setDatePickerAudit = (value) => {
        this.datePickerAudit = value
    }
    setDatePickerExport = (value) => {
        this.datePickerExport = value
    }

    setDateMenuFilter = (value) => {
        this.dateMenuFilter = value
        this.getAudits()
    }

    setActionDescriptionFilter = (value) => {
        this.actionDescriptionFilter = value
        this.getAudits()
    }
    setSelectedFilter = (value) => {
        this.selectedFilter = value
        this.getAudits()
    }
    setLogs = (value) => {
        this.logs = value
    }
    setExportAreas = (value) => {
        this.setLoading(true)
        if(this.exportAreas.length > 0){
            if (this.exportAreas.find((exportArea) => exportArea.id !== value.id)) {
                this.exportAreas.push(value)
            }
        }else{
            this.exportAreas.push(value)
        }
        this.setLoading(false)

    }

    setLoading = (value) => {
        this.loading = value
    }

    setCount = (value) => {
        this.count = value
    }

    setPage = (value) => {
        this.page = value
        this.getAudits()
    }

    getAudits = async () => {
        try {
            this.setLoading(true)
            let filter = {
                action: this.actionDescriptionFilter,
                search: this.auditSearchTxt,
                type: this.selectedFilter
            };
            if(this.dateMenuFilter !== null){
                filter = {...filter, date_type: this.dateMenuFilter,}
            }
            if(this.timeFilter !== null){
                filter = {...filter, time: this.timeFilter,}
            }
            if(this.timeFilter !== null){
                filter = {...filter, time: this.timeFilter,}
            }
            if(this.datePickerAudit !== null){
                let date = moment(this.datePickerAudit.$d)
                filter = {...filter, date: date.format('YYYY-MM-DD'),}
            }

            console.log('filter',filter)
            const response = await AuditService.getLogs({}, this.page,filter)
            console.log('getAudits response', response)
            this.setLogs(response.items)
            this.setCount(response.count)
            this.setLoading(false)
        } catch (err) {
            console.log('getAudits err', err)
            this.setLoading(false)
        }
    }

    getExportAreas = async () => {
        try {
            this.setLoading(true)
            const response = await ExportService.getExportAreas()
            console.log('getExportAreas response', response)
            if(response.inclusion_exclusion){
                this.setExportAreas({
                    id:'Inclusion and Exclusion Criteria',
                    lastModification: response.inclusion_exclusion.last_update,
                    lastExport: response.inclusion_exclusion.last_export
                })
            }
            if(response.sociodemographic){
                this.setExportAreas({
                    id:'Socio-Demographic Characteristics at diagnosis',
                    lastModification: response.sociodemographic.last_update,
                    lastExport: response.sociodemographic.last_export
                })
            }
            if(response.clinical_history){
                this.setExportAreas({
                    id:'Clinical History at diagnosis',
                    lastModification: response.clinical_history.last_update,
                    lastExport: response.clinical_history.last_export
                })
            }
            if(response.environmental_exposure){
                this.setExportAreas({
                    id:'Environmental Exposure to Carcinogenic Agents at diagnosis',
                    lastModification: response.environmental_exposure.last_update,
                    lastExport: response.environmental_exposure.last_export
                })
            }
            if(response.clinical_characterization){
                this.setExportAreas({
                    id:'Clinical Characterization',
                    lastModification: response.clinical_characterization.last_update,
                    lastExport: response.clinical_characterization.last_export
                })
            }
            if(response.tumor_biology){
                this.setExportAreas({
                    id:'Tumor biology at diagnosis',
                    lastModification: response.tumor_biology.last_update,
                    lastExport: response.tumor_biology.last_export
                })
            }
            if(response.initial_treatment){
                this.setExportAreas({
                    id:'Initial Treatment',
                    lastModification: response.initial_treatment.last_update,
                    lastExport: response.initial_treatment.last_export
                })
            }
            if(response.after_initial_treatment){
                this.setExportAreas({
                    id:'After Initial Treatment',
                    lastModification: response.after_initial_treatment.last_update,
                    lastExport: response.after_initial_treatment.last_export
                })
            }
            if (response.after_initial_treatment) {
                for (let i = 0; i < response.sub_treatments.length; i++) {
                    this.setExportAreas({
                        id: `Subsequent Treatment n = ${i + 1}`,
                        lastModification: response.sub_treatments[i].last_update_sub_treatment,
                        lastExport: response.sub_treatments[i].last_export_sub_treatment
                    })
                    this.setExportAreas({
                        id: `After Subsequent Treatment n = ${i + 1}`,
                        lastModification: response.sub_treatments[i].last_update_sub_after,
                        lastExport: response.sub_treatments[i].last_export_sub_after
                    })
                }
                if (response.disposition) {
                    this.setExportAreas({
                        id: 'Disposition Data at Cut-Off Date',
                        lastModification: response.disposition.last_update,
                        lastExport: response.disposition.last_export
                    })
                }
                if (response.completed) {
                    this.setExportAreas({
                        id: 'Patient Complete',
                        lastModification: response.completed.last_update,
                        lastExport: response.completed.last_export
                    })
                }
            }
            console.log('this.exportAreas',this.exportAreas)

            this.setLoading(false)
        } catch (err) {
            console.log('getExportAreas err', err)
            this.setLoading(false)
        }
    }


    exportAuditData = async () => {
        try {
            this.setLoading(true)
            let filter = {
                action: this.actionDescriptionFilter,
                search: this.auditSearchTxt,
                type: this.selectedFilter
            };
            if(this.dateMenuFilter !== null){
                filter = {...filter, date_type: this.dateMenuFilter,}
            }
            if(this.timeFilter !== null){
                filter = {...filter, time: this.timeFilter,}
            }
            if(this.timeFilter !== null){
                filter = {...filter, time: this.timeFilter,}
            }
            if(this.datePickerAudit !== null){
                let date = moment(this.datePickerAudit.$d)
                filter = {...filter, date: date.format('YYYY-MM-DD'),}
            }
            const response = await AuditService.downloadLogs({},filter)
            console.log('exportData response', response)
            downloadFile(response)
            this.setLoading(false)
        } catch (err) {
            console.log('exportData err', err)
            this.setLoading(false)
        }
    }

    exportData = async () => {
        try {
            this.setLoading(true)
            let exportItems = {
                inclusion_exclusion: false,
                sociodemographic: false,
                clinical_history: false,
                clinical_characterization: false,
                environmental_exposure: false,
                tumor_biology: false,
                initial_treatment: false,
                after_initial_treatment: false,
                disposition: false,
                completed: false,
                sub_treatments: [],
                after_sub_treatments:[],
            }


            if(this.exportSelectionItems.length >0){

                for(const exportSelectionItem of this.exportSelectionItems){
                    if (exportSelectionItem === 'Inclusion and Exclusion Criteria') {
                        exportItems.inclusion_exclusion = true
                    }
                    if (exportSelectionItem === 'Socio-Demographic Characteristics at diagnosis') {
                        exportItems.sociodemographic = true
                    }
                    if (exportSelectionItem === 'Clinical History at diagnosis') {
                        exportItems.clinical_history = true
                    }
                    if (exportSelectionItem === 'Environmental Exposure to Carcinogenic Agents at diagnosis') {
                        exportItems.environmental_exposure = true
                    }
                    if (exportSelectionItem === 'Clinical Characterization') {
                        exportItems.clinical_characterization = true
                    }
                    if (exportSelectionItem === 'Tumor biology at diagnosis') {
                        exportItems.tumor_biology = true
                    }
                    if (exportSelectionItem === 'Initial Treatment') {
                        exportItems.initial_treatment = true
                    }
                    if (exportSelectionItem === 'After Initial Treatment') {
                        exportItems.after_initial_treatment = true
                    }
                    if (exportSelectionItem === 'Disposition Data at Cut-Off Date') {
                        exportItems.disposition = true
                    }
                    if (exportSelectionItem === 'Patient Complete') {
                        exportItems.completed = true
                    }

                    if(exportSelectionItem.includes('Subsequent Treatment') && !exportSelectionItem.includes('After')){
                        const number = exportSelectionItem.substring(exportSelectionItem.indexOf('=') + 2);
                        console.log('number',number)
                        exportItems.sub_treatments.push(parseInt(number))
                    }

                    if(exportSelectionItem.includes('After Subsequent Treatment')){
                        const number = exportSelectionItem.substring(exportSelectionItem.indexOf('=') + 2);
                        console.log('number',number)
                        exportItems.after_sub_treatments.push(parseInt(number))
                    }
                }
            }else{
                alert('You need to select at least one item')
            }

            console.log('exportItems',exportItems)
            const response = await ExportService.export(exportItems)
            console.log('exportData response', response)
            downloadFile(response)
            this.setLoading(false)
        } catch (err) {
            console.log('exportData err', err)
            this.setLoading(false)
        }
    }
}


export default AuditStore
