import * as React from 'react';
import {DataGrid} from "@mui/x-data-grid";
import filterGreen from "../images/svgs/FilterGreen.svg";
import MyPagination from "./MyPagination";

const MyDataGrid = ({
                        rows,
                        columns,
                        initialState,
                        sx,
                        getRowId,
                        onRowClick,
                        selectionModel,
                        onSelectionModelChange,
                        toolbar,
                        ...otherProps
                    }) => {
    return (<DataGrid
        rows={rows}
        columns={columns}
        pageSize={9}
        rowsPerPageOptions={[5, 10,]}
        getRowId={getRowId}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
        disableMultipleSelection={true}
        disableColumnMenu={true}
        onRowClick={onRowClick}
        sx={{
            mt: 4,
            width: "100%",
            px: 4,
            border: '0px',
            height: "627px",
            '& .MuiDataGrid-columnSeparator': {display: 'none'},
            '& .MuiDataGrid-columnHeaderTitleContainer': {
                'border-bottom-width': 3,
                // cursor:'default',
            },
            // '& .MuiDataGrid-columnHeaderTitleContainerContent': {
            //     // pointerEvents: 'none',
            // },
            // '& .MuiDataGrid-columnHeader': {
            //     // pointerEvents: 'none',
            //
            // },
            // '& .MuiDataGrid-menuIcon': {
            //     // pointerEvents: 'auto',
            //     visibility: 'visible',
            //     position:'absolute',
            //     right:60,
            //     top:10,
            //     display:'flex',
            //     zIndex:999,
            //     cursor: 'pointer'
            //
            // },
            "& .MuiDataGrid-virtualScrollerRenderZone": {
                "& .MuiDataGrid-row": {
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                    "&:nth-child(2n+1)": {backgroundColor: "rgba(235, 235, 235, .7)"}
                }
            },
            '& .MuiDataGrid-cellContent': {
                color: 'txtGrey',
                fontSize: 18,
            },
            '& .MuiDataGrid-footerContainer': {
                borderTop: '0px',
            },

            // '& .MuiDataGrid-menuIcon': {visibility:'visible'},
            // '& .MuiDataGrid-iconButtonContainer': {visibility:'hide'},
            ...sx
        }}
        // onColumnHeaderEnter={()=>alert('s')}
        autoHeight={false}
        // disableColumnSelector={true}
        // disableColumnFilter={true}
        // disableColumnMenu={true}
        // disableVirtualization={true}
        components={{
            // ColumnSortedDescendingIcon: () => <img style={{marginLeft:5,width:20,height:20}} src={arrowDown}/>,
            // ColumnSortedAscendingIcon: () => <img style={{marginLeft:5,width:20,height:20}} src={arrowUp}/>,
            // ColumnSortedDescendingIcon: () => <div/>,
            // ColumnSortedAscendingIcon: () => <div/>,
            ColumnMenuIcon: () => <img style={{marginLeft: 5, width: 20, height: 20}} src={filterGreen}/>,
            // ColumnMenu: CustomColumnMenu,
            // Toolbar: toolbar === true ? CustomToolbar : null,
            Pagination: MyPagination, // ColumnMenu: () => <CustomMenu/>
        }}

        hideFooterSelectedRowCount={true}
        initialState={initialState}
        selectionModel={selectionModel}
        onSelectionModelChange={onSelectionModelChange}
        {...otherProps}
    />);
}

export default MyDataGrid;
