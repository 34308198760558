import * as React from 'react';
import {Pagination} from "@mui/material";
import {gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector} from "@mui/x-data-grid";
import arrowCircleLeft from "../images/svgs/ArrowCircleLeft.svg";
import arrowCircleRight from "../images/svgs/ArrowCircleRight.svg";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import theme from "../styles/theme";
import arrowRight from "../images/svgs/ArrowRight.svg";
import arrowLeft from "../images/svgs/ArrowLeft.svg";

const MyPagination = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Pagination
            color="standard"
            count={pageCount}
            page={page + 1}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
            shape={'rounded'}
            size={'large'}
            siblingCount={0}
            boundaryCount={0}
            sx={{mt:2}}
            renderItem={(item) => {
                if(item.type === 'page' && item.selected){
                    return (
                        <Typography sx={{ml:2,mr:2}}>
                            {`Page ${item.page}/${pageCount}`}
                        </Typography>
                    )
                }
                if(item.type === 'next'){
                    return  (
                    <Box onClick={()=>item.onClick()} style={{
                        boxShadow: `3px 3px 15px ${theme.palette.primary.main}`,
                        width: 38,
                        height: 39,
                        cursor: 'pointer',
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 19,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <img width={23} height={15} src={arrowRight}/>
                    </Box>
                    )
                }
                if(item.type === 'previous'){
                    return (
                        <Box onClick={()=>item.onClick()} style={{
                            boxShadow: `3px 3px 15px ${theme.palette.primary.main}`,
                            width: 38,
                            height: 39,
                            cursor: 'pointer',
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: 19,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <img width={23} height={15} src={arrowLeft}/>
                        </Box>

                    )
                }
            }}

        />
    );
}

export default MyPagination;
