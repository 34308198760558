import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import YesNo from "../../components/YesNo";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import QuestionsLayout from "../../layout/QuestionsLayout";
import MissingReason from "../../components/MissingReason";


const ConcomitantMedicationsPage1 = () => {
    const {
        treatmentPatternsStore, dataStore,menuStore
    } = useStores()
    let params = useParams();
    useEffect(() => {
        treatmentPatternsStore.getTreatment(parseInt(params.patient),parseInt(params.number))
    }, [])
    const navigate = useNavigate()
    const [showMissing, setShowMissing] = React.useState('not active');

    const handleNext = async () => {
        if (!treatmentPatternsStore.checkMissing6) {
            if(treatmentPatternsStore.q16.main === 'false' || treatmentPatternsStore.q16.main === false){
                await treatmentPatternsStore.updateTreatment(
                    {
                        q16: JSON.stringify(treatmentPatternsStore.q16),
                    }, parseInt(params.number), parseInt(params.patient))
            }else if(treatmentPatternsStore.q16.main){
                await treatmentPatternsStore.updateTreatment(
                    {
                        q16: JSON.stringify(treatmentPatternsStore.q16),
                    }, parseInt(params.number), parseInt(params.patient))
            }

            if (dataStore.forceUpdate === true) {
                dataStore.setShowUpdateModal('not active')
            }
            if (dataStore.showUpdateModal !== 'active') {
                if (treatmentPatternsStore.q16?.main && treatmentPatternsStore.q16.main === 'true') {
                    navigate(`/concomitantMedicationsPage2/${parseInt(params.patient)}/${parseInt(params.number)}`)

                } else {
                    navigate(`/imagingAssessmentsPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)

                }
            }

        } else {
            alert('Please add reason for missing questions.')

            // if (!dataStore.globalMissing) {
            //     if (showMissing === 'active') {
            //         await treatmentPatternsStore.updateTreatment(
            //             {
            //                 q16: treatmentPatternsStore.q16?.main ? JSON.stringify(treatmentPatternsStore.q16) : dataStore.returnOtherObjectMissing,
            //             }, parseInt(params.number),parseInt(params.patient))
            //         if (treatmentPatternsStore.q16?.main && treatmentPatternsStore.q16.main === 'true') {
            //             navigate(`/concomitantMedicationsPage2/${parseInt(params.patient)}/${parseInt(params.number)}`)
            //         } else {
            //             navigate(`/imagingAssessmentsPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            //         }
            //         setShowMissing('not active')
            //     } else {
            //         setShowMissing('active')
            //     }
            // } else {
            //     await treatmentPatternsStore.updateTreatment(
            //         {
            //             q16: treatmentPatternsStore.q16?.main ? JSON.stringify(treatmentPatternsStore.q16) : dataStore.returnOtherObjectMissing,
            //         }, parseInt(params.number),parseInt(params.patient))
            //     if (treatmentPatternsStore.q16?.main && treatmentPatternsStore.q16.main === 'true') {
            //         navigate(`/concomitantMedicationsPage2/${parseInt(params.patient)}/${parseInt(params.number)}`)
            //     } else {
            //         navigate(`/imagingAssessmentsPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            //     }
            // }
        }
    }

    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={treatmentPatternsStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>
                    {parseInt(params.number) === 0 ?
                        <div>
                            <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                                Treatment patterns and healthcare resource use (Only Initial Treatment)
                            </Typography>
                            <Typography color={'txtGrey'} sx={{fontSize: 20,mt:1}}>
                                (Please complete this section only for initial treatment.)
                            </Typography>

                        </div> :
                        <div>
                            <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                                {`Treatment patterns and healthcare resource use for subsequent treatment n = ${params.number}`}
                            </Typography>
                            <Typography color={'txtGrey'} sx={{fontSize: 20,mt:1}}>
                                [Please complete this section for “Subsequent treatment (n=1, 2, …)”]
                            </Typography>
                        </div>
                    }
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>
                    <Typography sx={{fontWeight: 'bold', fontSize: 30,}}>
                        Concomitant medications used for disease management
                    </Typography>
                    <QuestionBox allInRow={true}>
                        <YesNo value={treatmentPatternsStore.q16?.main}
                               handleChange={(event) => {
                                   dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q16'), 1)
                                   if (event.target.value === 'true') {
                                       treatmentPatternsStore.setQ16({
                                           ...treatmentPatternsStore.q16,
                                           main: event.target.value,
                                       })
                                   }else{
                                       treatmentPatternsStore.setQ16({
                                           ...treatmentPatternsStore.q16,
                                           main: event.target.value,
                                           disease: [
                                               {
                                                   name: 'Anti-emetics 5HT3/Setrons',
                                                   extra: [],
                                                   ...treatmentPatternsStore.commonQ16Params
                                               },
                                               {
                                                   name: 'Anti-emetics Aprepitant/Fosaprepitant',
                                                   extra: [],
                                                   ...treatmentPatternsStore.commonQ16Params
                                               },
                                               {
                                                   name: 'Other anti-emetics',
                                                   extra: [],
                                                   ...treatmentPatternsStore.commonQ16Params
                                               },
                                               {
                                                   name: 'GrowthFactors',
                                                   extra: [],
                                                   ...treatmentPatternsStore.commonQ16Params
                                               },
                                               {
                                                   name: 'Corticosteroids',
                                                   extra: [],
                                                   ...treatmentPatternsStore.commonQ16Params
                                               },
                                               {
                                                   name: 'Hydrocortisone',
                                                   extra: [],
                                                   ...treatmentPatternsStore.commonQ16Params
                                               },
                                               {
                                                   name: 'Antidepressant drug',
                                                   extra: [],
                                                   ...treatmentPatternsStore.commonQ16Params
                                               },
                                               {
                                                   name: 'Anti-anxiety drug',
                                                   extra: [],
                                                   ...treatmentPatternsStore.commonQ16Params
                                               },
                                               {
                                                   name: 'Iron doses (intravenous or oral)',
                                                   extra: [],
                                                   ...treatmentPatternsStore.commonQ16Params
                                               },
                                               {
                                                   name: 'Antihistamine',
                                                   extra: [],
                                                   ...treatmentPatternsStore.commonQ16Params
                                               },
                                               {
                                                   name: 'Blood component transfusion',
                                                   extra: [],
                                                   ...treatmentPatternsStore.commonQ16Params
                                               },
                                               {
                                                   name: 'EPO/ASE',
                                                   extra: [],
                                                   ...treatmentPatternsStore.commonQ16Params
                                               },
                                               {
                                                   name: 'Grade 1 or 2 (non-Opioid) Analgesics',
                                                   extra: [],
                                                   ...treatmentPatternsStore.commonQ16Params
                                               },
                                               {
                                                   name: 'Grade 3 (Opioid) Analgesics',
                                                   extra: [],
                                                   ...treatmentPatternsStore.commonQ16Params
                                               },
                                               {
                                                   name: 'Antibiotics',
                                                   extra: [],
                                                   ...treatmentPatternsStore.commonQ16Params
                                               },
                                               {
                                                   name: 'LMWH (Low molecular weight heparin)',
                                                   extra: [],
                                                   ...treatmentPatternsStore.commonQ16Params
                                               },
                                               {
                                                   name: 'Immunosuppressants (ex, infliximab/others)',
                                                   extra: [],
                                                   ...treatmentPatternsStore.commonQ16Params
                                               },
                                           ]
                                       })
                                   }
                               }}
                        >
                            {dataStore.questionsMissing.includes('q16')
                                ? <MissingReason
                                    value={treatmentPatternsStore.q16.main}
                                    style={{right:60,marginTop:-3}}
                                    onSubmitMissing={async ()=>{
                                        treatmentPatternsStore.setQ16({
                                            ...treatmentPatternsStore.q16,
                                            main: dataStore.returnOtherTextMissing,
                                        })
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q16'), 1)
                                    }}/> : <></>}
                        </YesNo>
                    </QuestionBox>

                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(ConcomitantMedicationsPage1);
