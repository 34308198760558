/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoginOutSchema } from '../models/LoginOutSchema';
import type { Message } from '../models/Message';
import type { UserSchema } from '../models/UserSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Login
     * Login
     * @param formData
     * @param debug
     * @returns Message OK
     * @throws ApiError
     */
    public static login(
        formData: {
            username: string;
            password: string;
        },
        debug: boolean = true,
    ): CancelablePromise<Message> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/users/login',
            query: {
                'debug': debug,
            },
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * OTP
     * OTP
     * @param formData
     * @returns LoginOutSchema OK
     * @throws ApiError
     */
    public static otp(
        formData: {
            email: string;
            code: string;
        },
    ): CancelablePromise<LoginOutSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/users/otp',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get current user
     * Get current user
     * @returns UserSchema OK
     * @throws ApiError
     */
    public static getMe(): CancelablePromise<UserSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/me',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update current user
     * Update current user
     * @param formData
     * @returns UserSchema OK
     * @throws ApiError
     */
    public static updateMe(
        formData: {
            first_name?: string;
            last_name?: string;
            email?: string;
        },
    ): CancelablePromise<UserSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/users/me',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Invite user
     * Invite user
     * @param formData
     * @returns Message OK
     * @throws ApiError
     */
    public static inviteUser(
        formData: {
            first_name: string;
            last_name: string;
            role: number;
            email: string;
            center?: number;
        },
    ): CancelablePromise<Message> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/users/invite_user',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Invite OTP
     * Invite OTP
     * @param formData
     * @param debug
     * @returns Message OK
     * @throws ApiError
     */
    public static inviteOtp(
        formData: {
            token: string;
            phone: string;
        },
        debug: boolean = true,
    ): CancelablePromise<Message> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/users/invite_otp',
            query: {
                'debug': debug,
            },
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Accept invite
     * Accept invite
     * @param formData
     * @returns LoginOutSchema OK
     * @throws ApiError
     */
    public static acceptInvite(
        formData: {
            token: string;
            password: string;
            sms_token: string;
        },
    ): CancelablePromise<LoginOutSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/users/accept_invite',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Reset password
     * Reset password
     * @returns Message OK
     * @throws ApiError
     */
    public static resetPassword(): CancelablePromise<Message> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/users/reset_password',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Change password
     * Change password
     * @param formData
     * @returns Message OK
     * @throws ApiError
     */
    public static changePassword(
        formData: {
            token: string;
            password: string;
        },
    ): CancelablePromise<Message> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/users/change_password',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get delegates
     * Get delegates
     * @param userId
     * @returns UserSchema OK
     * @throws ApiError
     */
    public static getDelegates(
        userId: number,
    ): CancelablePromise<Array<UserSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/{user_id}/delegates',
            path: {
                'user_id': userId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get users
     * Get users
     * @param roles
     * @returns UserSchema OK
     * @throws ApiError
     */
    public static getUsers(
        roles?: string,
    ): CancelablePromise<Array<UserSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/users',
            query: {
                'roles': roles,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Delete user
     * Delete user
     * @param userId
     * @returns Message OK
     * @throws ApiError
     */
    public static deleteUser(
        userId: number,
    ): CancelablePromise<Message> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/users/users/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
