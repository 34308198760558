import {action, computed, makeObservable, observable,} from 'mobx'


class MenuStore {

    activeItem = 1
    selectedItem = null

    constructor() {

        makeObservable(this, {
            activeItem: observable,
            selectedItem: observable,

            setActiveItem: action,
            setSelectedItem: action,
            getSelectedItem: computed,
        },)
    }
    setSelectedItem = (value) =>{
        this.selectedItem = value
        sessionStorage.setItem('selectedMenu',value)
    }

    setActiveItem = (value) => {
        console.log('setActiveItem', this.activeItem)
        if (this.activeItem !== value) {
            this.activeItem = value
        }
    }

    get getSelectedItem(){
        return sessionStorage.getItem('selectedMenu')
    }

}


export default MenuStore
