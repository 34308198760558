import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {InputAdornment, RadioGroup, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import RadioItem from "../../components/RadioItem";
import TextField from "@mui/material/TextField";
import QuestionsLayout from "../../layout/QuestionsLayout";
import Joi from "joi";
import MissingReason from "../../components/MissingReason";


const SystemicTherapyPage2 = () => {
    const {
        systemicTherapyStore, dataStore, menuStore
    } = useStores()

    const navigate = useNavigate()
    const [showMissing, setShowMissing] = React.useState('not active');

    let params = useParams();
    useEffect(() => {
        systemicTherapyStore.getTreatment(parseInt(params.patient), parseInt(params.number))
    }, [])
    const StereoDoseSchema = Joi.number().min(5).max(200).integer().required();
    const RadialDoseSchema = Joi.number().min(5).max(150).integer().required();
    const FractionSchema = Joi.number().min(1).max(50).integer().required();

    const handleNext = async () => {
        if (systemicTherapyStore?.q12?.main === 'Stereotactic Body Radiation Therapy') {
            if (StereoDoseSchema.validate(systemicTherapyStore.q12.dose).error || FractionSchema.validate(systemicTherapyStore.q12.numberOfFractions).error) {
                if (!window.confirm("Do you confirm this value?")) {
                    return;
                }
            }
        }
        if (systemicTherapyStore?.q13?.main === 'Radial to the mediastinum and chest') {
            if (RadialDoseSchema.validate(systemicTherapyStore.q13.dose).error || FractionSchema.validate(systemicTherapyStore.q13.numberOfFractions).error) {
                if (!window.confirm("Do you confirm this value?")) {
                    return;
                }
            }
        }
        if (!systemicTherapyStore.checkMissing2) {
            await systemicTherapyStore.updateTreatment(
                {
                    q12: systemicTherapyStore.q12?.main ? JSON.stringify(systemicTherapyStore.q12) : JSON.stringify({
                        main: null,
                        dose: '',
                        numberOfFractions: '',
                    }),
                    q13: systemicTherapyStore.q13?.main ? JSON.stringify(systemicTherapyStore.q13) : JSON.stringify({
                        main: null,
                        dose: '',
                        numberOfFractions: '',
                        type: '',
                    }),
                }, parseInt(params.number),parseInt(params.patient))
            if (dataStore.forceUpdate === true) {
                dataStore.setShowUpdateModal('not active')
            }
            if (dataStore.showUpdateModal !== 'active') {
                navigate(`/systemicTherapyPage3/${parseInt(params.patient)}/${parseInt(params.number)}`)
            }

        } else {
            alert('Please add reason for missing questions.')

            // if (!dataStore.globalMissing) {
            //     if (showMissing === 'active') {
            //         await systemicTherapyStore.updateTreatment(
            //             {
            //                 q12: systemicTherapyStore.q12?.main ? JSON.stringify(systemicTherapyStore.q12) : dataStore.returnOtherObjectMissing,
            //                 q13: systemicTherapyStore.q13?.main ? JSON.stringify(systemicTherapyStore.q13) : dataStore.returnOtherObjectMissing,
            //             }, parseInt(params.number),parseInt(params.patient))
            //         navigate(`/systemicTherapyPage3/${parseInt(params.patient)}/${parseInt(params.number)}`)
            //         setShowMissing('not active')
            //     } else {
            //         setShowMissing('active')
            //     }
            // } else {
            //     await systemicTherapyStore.updateTreatment(
            //         {
            //             q12: systemicTherapyStore.q12?.main ? JSON.stringify(systemicTherapyStore.q12) : dataStore.returnOtherObjectMissing,
            //             q13: systemicTherapyStore.q13?.main ? JSON.stringify(systemicTherapyStore.q13) : dataStore.returnOtherObjectMissing,
            //         }, parseInt(params.number),parseInt(params.patient))
            //     navigate(`/systemicTherapyPage3/${parseInt(params.patient)}/${parseInt(params.number)}`)
            // }
        }
    }

    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={systemicTherapyStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>
                    {parseInt(params.number) === 0 ?
                        <div>
                            <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                                Treatment patterns and healthcare resource use (Only Initial Treatment)
                            </Typography>
                            <Typography color={'txtGrey'} sx={{fontSize: 20,mt:1}}>
                                (Please complete this section only for initial treatment.)
                            </Typography>

                        </div> :
                        <div>
                            <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                                {`Treatment patterns and healthcare resource use for subsequent treatment n = ${params.number}`}
                            </Typography>
                            <Typography color={'txtGrey'} sx={{fontSize: 20,mt:1}}>
                                [Please complete this section for “Subsequent treatment (n=1, 2, …)”]
                            </Typography>
                        </div>
                    }
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>
                    <Typography sx={{fontWeight: 'bold', fontSize: 30,}}>
                        Systemic Therapy+RT
                    </Typography>
                    <QuestionBox>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(event) => {
                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q12'), 1)

                                systemicTherapyStore.setQ12({
                                    ...systemicTherapyStore.q12,
                                    main: event.target.value,
                                })
                                systemicTherapyStore.setQ13({
                                    main: null,
                                    dose: '',
                                    numberOfFractions: '',
                                })
                            }}
                            value={systemicTherapyStore.q12.main}
                            sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
                        >
                            <RadioItem label={'Stereotactic Body Radiation Therapy'}/>
                        </RadioGroup>
                        {dataStore.questionsMissing.includes('q12')
                            ? <MissingReason
                                value={systemicTherapyStore?.q12?.main}
                                style={{right:60,marginTop:-1}}
                                onSubmitMissing={async ()=>{
                                    systemicTherapyStore.setQ12(
                                        {...systemicTherapyStore.q12, main: dataStore.returnOtherTextMissing,})
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q12'), 1)
                                }}/> : <></>}
                        {systemicTherapyStore.q12.main === 'Stereotactic Body Radiation Therapy' && <>
                            <Box sx={{display: 'flex', width: '35%', alignItems: 'center', ml: 5}}>
                                <li style={{fontSize: 18, flex: 1}}>Dose</li>
                                {StereoDoseSchema.validate(systemicTherapyStore.q12?.dose).error && <Typography
                                    variant="caption" color="error" sx={{mr: 2}}>Between 5 and 200</Typography>}
                                <TextField
                                    onWheel={(e) => e.target.blur()}
                                    size={'small'}
                                    sx={{width: 100}}
                                    placeholder={'000'}
                                    onChange={(event) => {
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q12dose'), 1)

                                        systemicTherapyStore.setQ12(
                                        {...systemicTherapyStore.q12, dose: event.target.value,}
                                    )}}
                                    error={StereoDoseSchema.validate(systemicTherapyStore.q12?.dose).error}
                                    value={systemicTherapyStore.q12?.dose && systemicTherapyStore.q12.dose.startsWith('missing|') ? '': systemicTherapyStore.q12.dose}

                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">Gy</InputAdornment>,
                                    }}
                                />
                                {dataStore.questionsMissing.includes('q12dose')
                                    ? <MissingReason
                                        value={systemicTherapyStore?.q12?.dose}
                                        style={{right:60,marginTop:-1}}
                                        onSubmitMissing={async ()=>{

                                            systemicTherapyStore.setQ12(
                                                {...systemicTherapyStore.q12, dose: dataStore.returnOtherTextMissing,})
                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q12dose'), 1)
                                        }}/> : <></>}

                            </Box>
                            <Box sx={{display: 'flex', width: '35%', alignItems: 'center', mt: 1, ml: 5}}>
                                <li style={{fontSize: 18, flex: 1}}>Number of fractions</li>
                                {FractionSchema.validate(systemicTherapyStore.q12?.numberOfFractions).error &&
                                    <Typography
                                        variant="caption" color="error" sx={{mr: 2}}>Between 1 and 50</Typography>}
                                <TextField
                                    onWheel={(e) => e.target.blur()}
                                    size={'small'}
                                    sx={{width: 100}}
                                    placeholder={'000'}
                                    error={FractionSchema.validate(systemicTherapyStore.q12?.numberOfFractions).error}
                                    onChange={(event) => {
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q12numberOfFractions'), 1)

                                        systemicTherapyStore.setQ12(
                                        {...systemicTherapyStore.q12, numberOfFractions: event.target.value,}
                                    )}}
                                    value={systemicTherapyStore.q12?.numberOfFractions && systemicTherapyStore.q12.numberOfFractions.startsWith('missing|') ? '': systemicTherapyStore.q12.numberOfFractions}

                                />
                                {dataStore.questionsMissing.includes('q12numberOfFractions')
                                    ? <MissingReason
                                        value={systemicTherapyStore?.q12?.numberOfFractions}
                                        style={{right:60,marginTop:-1}}
                                        onSubmitMissing={async ()=>{
                                            systemicTherapyStore.setQ12(
                                                {...systemicTherapyStore.q12, numberOfFractions:dataStore.returnOtherTextMissing,})
                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q12numberOfFractions'), 1)
                                        }}/> : <></>}
                            </Box>
                        </>}
                    </QuestionBox>
                    <QuestionBox>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(event) => {
                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q13'), 1)
                                systemicTherapyStore.setQ12({
                                    main: null,
                                    dose: '',
                                    numberOfFractions: '',
                                })
                                systemicTherapyStore.setQ13({
                                    ...systemicTherapyStore.q13,
                                    main: event.target.value,
                                })
                            }
                            }
                            value={systemicTherapyStore.q13.main}
                            sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
                        >
                            <RadioItem label={'Radial to the mediastinum and chest'}/>
                        </RadioGroup>
                        {dataStore.questionsMissing.includes('q13')
                            ? <MissingReason
                                value={systemicTherapyStore?.q13?.main}
                                style={{right:60,marginTop:-1}}
                                onSubmitMissing={async ()=>{
                                    systemicTherapyStore.setQ13(
                                        {...systemicTherapyStore.q13, main: dataStore.returnOtherTextMissing,})
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q13'), 1)
                                }}/> : <></>}
                        {systemicTherapyStore.q13.main === 'Radial to the mediastinum and chest' &&
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={(event) => {
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q13type'), 1)

                                    systemicTherapyStore.setQ13({
                                        ...systemicTherapyStore.q13,
                                        type: event.target.value,
                                    })
                                }}
                                value={systemicTherapyStore.q13.type}
                                sx={{display: 'flex', flexDirection: 'row', gap: "24px", rowGap: 0}}
                            >
                                <RadioItem label={'Sequential'}/>
                                <RadioItem label={'Concomitant'}/>
                            </RadioGroup>}
                        {dataStore.questionsMissing.includes('q13type')
                            ? <MissingReason
                                value={systemicTherapyStore?.q13?.type}
                                style={{right:60,marginTop:35}}
                                onSubmitMissing={async ()=>{
                                    systemicTherapyStore.setQ13({
                                        ...systemicTherapyStore.q13,
                                        type: dataStore.returnOtherTextMissing,
                                    })
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q13type'), 1)
                                }}/> : <></>}
                        {systemicTherapyStore.q13.type && <>
                            <Box sx={{display: 'flex', width: '35%', alignItems: 'center', ml: 5}}>
                                <li style={{fontSize: 18, flex: 1}}>Dose</li>
                                {RadialDoseSchema.validate(systemicTherapyStore.q13?.dose).error && <Typography
                                    variant="caption" color="error" sx={{mr: 2}}>Between 5 and 150</Typography>}
                                <TextField
                                    onWheel={(e) => e.target.blur()}
                                    size={'small'}
                                    sx={{width: 100}}
                                    placeholder={'000'}
                                    onChange={(event) =>{
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q13dose'), 1)

                                        systemicTherapyStore.setQ13(
                                        {...systemicTherapyStore.q13, dose: event.target.value,}
                                    )}}
                                    error={RadialDoseSchema.validate(systemicTherapyStore.q13?.dose).error}
                                    value={systemicTherapyStore.q13?.dose && systemicTherapyStore.q13.dose.startsWith('missing|') ? '': systemicTherapyStore.q13.dose}

                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">Gy</InputAdornment>,
                                    }}
                                />
                                {dataStore.questionsMissing.includes('q13dose')
                                    ? <MissingReason
                                        value={systemicTherapyStore?.q13?.dose}
                                        style={{right:60,marginTop:-1}}
                                        onSubmitMissing={async ()=>{
                                            systemicTherapyStore.setQ13(
                                                {...systemicTherapyStore.q13, dose: dataStore.returnOtherTextMissing,})

                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q13dose'), 1)
                                        }}/> : <></>}
                            </Box>
                            <Box sx={{display: 'flex', width: '35%', alignItems: 'center', mt: 1, ml: 5}}>
                                <li style={{fontSize: 18, flex: 1}}>Number of fractions</li>
                                {FractionSchema.validate(systemicTherapyStore.q13?.numberOfFractions).error &&
                                    <Typography
                                        variant="caption" color="error" sx={{mr: 2}}>Between 1 and 50</Typography>}
                                <TextField
                                    onWheel={(e) => e.target.blur()}
                                    size={'small'}
                                    sx={{width: 100}}
                                    placeholder={'000'}
                                    error={FractionSchema.validate(systemicTherapyStore.q13?.numberOfFractions).error}
                                    onChange={(event) => {
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q13numberOfFractions'), 1)

                                        systemicTherapyStore.setQ13(
                                        {...systemicTherapyStore.q13, numberOfFractions: event.target.value,}
                                    )}}
                                    value={systemicTherapyStore.q13?.numberOfFractions && systemicTherapyStore.q13.numberOfFractions.startsWith('missing|') ? '': systemicTherapyStore.q13.numberOfFractions}

                                />
                                {dataStore.questionsMissing.includes('q13numberOfFractions')
                                    ? <MissingReason
                                        value={systemicTherapyStore?.q13?.numberOfFractions}
                                        style={{right:60,marginTop:-1}}
                                        onSubmitMissing={async ()=>{
                                            systemicTherapyStore.setQ13(
                                                {...systemicTherapyStore.q13, numberOfFractions: dataStore.returnOtherTextMissing,})

                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q13numberOfFractions'), 1)
                                        }}/> : <></>}
                            </Box>
                        </>}
                    </QuestionBox>
                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(SystemicTherapyPage2);
