import {action, makeObservable, observable,} from 'mobx'
import {CrfService} from "../../services/openapi";

class InclusionCriteriaStore {

    loading = false
    completed = false
    q1 = null
    q2 = null

    constructor() {

        makeObservable(this, {
            loading: observable,
            completed: observable,
            q1: observable,
            q2: observable,
            setQ1: action,
            setQ2: action,
            setLoading: action,
            updateInclusionExclusion: action,
            getInclusionExclusion: action,
            reset: action,

        },)
    }

    reset = () => {
        this.q1 = null
        this.q2 = null
    }


    setQ1 = (value) => {
        this.q1 = value
    }

    setQ2 = (value) => {
        this.q2 = value

    }

    setLoading = (value) => {
        this.loading = value
    }

    updateInclusionExclusion = async (requestBody,patient) => {
        try {
            this.setLoading(true)
            const response = await CrfService.updateInclusionExclusion(patient, requestBody)
            console.log('updateInclusionExclusion response', response)
            this.setQ1(response.q1)
            this.setQ2(response.q2)
            this.setLoading(false)
        } catch (err) {
            this.setLoading(false)
            console.log('updateInclusionExclusion err', err)
        }
    }

    getInclusionExclusion = async (patient) => {
        try {
            this.setLoading(true)
            const response = await CrfService.getInclusionExclusion(patient)
            console.log('getInclusionExclusion response', response)
            console.log('getInclusionExclusion response.should_include', response.should_include)
            this.completed = response.should_include
            this.setQ1(response.q1)
            this.setQ2(response.q2)
            this.setLoading(false)
        } catch (err) {
            this.setLoading(false)
            console.log('getInclusionExclusion err', err)
        }
    }
}


export default InclusionCriteriaStore
