import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Stack} from "@mui/material";
import logo from "../images/svgs/Logo.svg";
import logoWhite from "../images/svgs/Logo-white.svg";

const Logo = ({type}) => {

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            p: 3,
            position: 'absolute',
            top: 10,
            left: 10,
        }}>
            <Stack sx={{alignItems: 'center'}}>
                <img src={type === 'green' ? logo : logoWhite}/>
                <Typography component="h1" variant="h4" color={'primary'}>
                    Characterize
                </Typography>
            </Stack>

        </Box>
    );
}

export default Logo;
