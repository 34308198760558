import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';

import Grid from '@mui/material/Grid';
import {Checkbox, FormControlLabel, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import TextField from "@mui/material/TextField";
import arrowDown from "../../images/svgs/Arrow-down.svg";
import MyButton from "../../components/MyButton";
import ConcomitantMenu from "./ConcomitantMenu";
import QuestionsLayout from "../../layout/QuestionsLayout";
import MissingReason from "../../components/MissingReason";


const ConcomitantMedicationsPage2 = () => {
    const {
        treatmentPatternsStore, dataStore,menuStore
    } = useStores()
    let params = useParams();
    useEffect(() => {
        treatmentPatternsStore.getTreatment(parseInt(params.patient),parseInt(params.number))
    }, [])

    const [showMissing, setShowMissing] = React.useState('not active');
    const navigate = useNavigate()
    const [otherOn, setOtherOn] = React.useState(false);
    const [openMenu, setOpenMenu] = React.useState(null);

    const handleNext = async () => {
        if (!treatmentPatternsStore.checkMissing6_1) {
            // treatmentPatternsStore.q16.main = 'true'
            // if(treatmentPatternsStore.q16?.missingDiseaseReason && treatmentPatternsStore.q16.missingDiseaseReason !== null){
                await treatmentPatternsStore.updateTreatment(
                    {
                        q16: JSON.stringify(treatmentPatternsStore.q16),
                    }, parseInt(params.number), parseInt(params.patient))
            // }

            if (dataStore.forceUpdate === true) {
                dataStore.setShowUpdateModal('not active')
            }
            if (dataStore.showUpdateModal !== 'active') {
                if (treatmentPatternsStore.q16.disease.filter((q) => q.selected === 'on').length > 0) {
                    navigate(`/concomitantMedicationsPage3/${parseInt(params.patient)}/${parseInt(params.number)}`)
                } else {
                    navigate(`/imagingAssessmentsPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
                }
            }


        } else {
            alert('Please add reason for missing questions.')

            // if (!dataStore.globalMissing) {
            //     if (showMissing === 'active') {
            //         await treatmentPatternsStore.updateTreatment(
            //             {
            //                 q16: treatmentPatternsStore.q16?.main ? JSON.stringify(treatmentPatternsStore.q16) : dataStore.returnOtherObjectMissing,
            //             }, parseInt(params.number),parseInt(params.patient))
            //         navigate(`/concomitantMedicationsPage3/${parseInt(params.patient)}/${parseInt(params.number)}`)
            //         setShowMissing('not active')
            //     } else {
            //         setShowMissing('active')
            //     }
            // } else {
            //     await treatmentPatternsStore.updateTreatment(
            //         {
            //             q16: treatmentPatternsStore.q16?.main ? JSON.stringify(treatmentPatternsStore.q16) : dataStore.returnOtherObjectMissing,
            //         }, parseInt(params.number),parseInt(params.patient))
            //     navigate(`/concomitantMedicationsPage3/${parseInt(params.patient)}/${parseInt(params.number)}`)
            // }
        }
    }

    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={treatmentPatternsStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>
                    {parseInt(params.number) === 0 ?
                        <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                            Treatment patterns and healthcare resource use (Only Initial Treatment)
                        </Typography> :
                        <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                            {`Treatment patterns and healthcare resource use for subsequent treatment n = ${params.number}`}
                        </Typography>
                    }
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>
                    <Typography sx={{fontWeight: 'bold', fontSize: 30,}}>
                        Concomitant medications used for disease management
                        {dataStore.questionsMissing.includes('q16disease')
                            ? <MissingReason
                                value={treatmentPatternsStore.q16.missingDiseaseReason}
                                style={{right:60,marginTop:3}}
                                onSubmitMissing={async ()=>{
                                    treatmentPatternsStore.setQ16({
                                        ...treatmentPatternsStore.q16,
                                        missingDiseaseReason: dataStore.returnOtherTextMissing,
                                    })

                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q16disease'), 1)
                                }}/> : <></>}
                    </Typography>
                    <QuestionBox>
                        {treatmentPatternsStore.q16?.main ?
                            <Box sx={{
                                display: 'flex', flex: 1,
                                flexDirection: 'column', width: '100%',
                            }}>
                                <Grid container rowSpacing={0.2} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                                    {treatmentPatternsStore.q16.disease.map((item, index) => {
                                        let disease = treatmentPatternsStore.q16.disease
                                        if(index < 3){
                                            return (
                                                <Grid key={'disease'+index} item xs={4}>
                                                    {index === 0 && <Typography sx={{fontWeight:'bold'}}>
                                                        Anti-emetics
                                                    </Typography>}
                                                    <Box sx={{width: '100%', display: "flex", alignItems: "center",mt:index === 1 || index === 2 ? 3 : 0}}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    onChange={(event) => {
                                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q16disease'), 1)

                                                                        console.log('item.selected', item.selected)
                                                                        if (event.target.value === 'on' && item.selected === 'on') {
                                                                            disease[index].duration = {
                                                                                type: null,
                                                                                amount: ''
                                                                            }
                                                                            disease[index].frequency = null
                                                                            disease[index].selected = null
                                                                            disease[index].extra = []
                                                                            treatmentPatternsStore.setQ16(
                                                                                {
                                                                                    ...treatmentPatternsStore.q16,
                                                                                    disease: disease
                                                                                }
                                                                            )

                                                                        } else {
                                                                            disease[index] = {
                                                                                ...item,
                                                                                selected: event.target.value
                                                                            }
                                                                            treatmentPatternsStore.setQ16(
                                                                                {
                                                                                    ...treatmentPatternsStore.q16,
                                                                                    disease: disease
                                                                                }
                                                                            )
                                                                        }

                                                                    }}
                                                                    checked={item.selected}
                                                                />
                                                            }
                                                            sx={{
                                                                '& .MuiFormControlLabel-label': {fontSize: 18},

                                                            }}
                                                            label={item.name}
                                                        />
                                                    </Box>
                                                </Grid>
                                            )
                                        }

                                    })}
                                </Grid>
                            </Box> : <div/>}
                    </QuestionBox>
                    <QuestionBox>
                        {treatmentPatternsStore.q16?.main ?
                            <Box sx={{
                                display: 'flex', flex: 1,
                                flexDirection: 'column', width: '100%',
                            }}>
                                <Grid container rowSpacing={0.2} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                                    {treatmentPatternsStore.q16.disease.map((item, index) => {
                                        let disease = treatmentPatternsStore.q16.disease
                                        if(index > 2){
                                            return (
                                                <Grid key={'disease'+index} item xs={4}>
                                                    <Box sx={{width: '100%', display: "flex", alignItems: "center"}}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    onChange={(event) => {
                                                                        console.log('item.selected', item.selected)
                                                                        if (event.target.value === 'on' && item.selected === 'on') {
                                                                            disease[index].duration = {
                                                                                type: null,
                                                                                amount: ''
                                                                            }
                                                                            disease[index].frequency = null
                                                                            disease[index].selected = null
                                                                            disease[index].extra = []
                                                                            treatmentPatternsStore.setQ16(
                                                                                {
                                                                                    ...treatmentPatternsStore.q16,
                                                                                    disease: disease
                                                                                }
                                                                            )

                                                                        } else {
                                                                            disease[index] = {
                                                                                ...item,
                                                                                selected: event.target.value
                                                                            }
                                                                            treatmentPatternsStore.setQ16(
                                                                                {
                                                                                    ...treatmentPatternsStore.q16,
                                                                                    disease: disease
                                                                                }
                                                                            )
                                                                        }

                                                                    }}
                                                                    checked={item.selected}
                                                                />
                                                            }
                                                            sx={{
                                                                '& .MuiFormControlLabel-label': {fontSize: 18},

                                                            }}
                                                            label={item.name}
                                                        />
                                                    </Box>
                                                </Grid>
                                            )
                                        }

                                    })}
                                </Grid>


                                <Stack sx={{
                                    display: 'flex',
                                }} direction={'row'} spacing={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(event) =>otherOn === 'on' ? setOtherOn(false) : setOtherOn(event.target.value)}
                                                checked={otherOn}
                                            />
                                        }
                                        sx={{
                                            '& .MuiFormControlLabel-label': {fontSize: 18},
                                        }}
                                        label="Other"
                                    />
                                    {otherOn ?
                                        <>
                                            <TextField
                                                size={'small'}
                                                label="Specify Here"
                                                name="otherTumorPage1"
                                                sx={{width: '200px', mr: 2}}
                                                onChange={(event) => treatmentPatternsStore.setNewDiseaseText(event.target.value)}
                                                value={treatmentPatternsStore.newDiseaseText}/>
                                            <MyButton sx={{px: 4, py: 0.5}} label={'Add'}
                                                      disabled={treatmentPatternsStore.newDiseaseText === ''}
                                                      onClick={treatmentPatternsStore.addDisease}/>
                                        </>
                                        : null}
                                </Stack>
                            </Box> : <div/>}
                    </QuestionBox>
                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(ConcomitantMedicationsPage2);
