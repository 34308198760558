import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import YesNo from "../../components/YesNo";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import QuestionsLayout from "../../layout/QuestionsLayout";
import MissingReason from "../../components/MissingReason";


const LaboratoryTestsPage1 = () => {
    const {
        treatmentPatternsStore, dataStore,menuStore
    } = useStores()

    const navigate = useNavigate()
    let params = useParams();
    useEffect(() => {
        treatmentPatternsStore.getTreatment(parseInt(params.patient),parseInt(params.number))
    }, [])
    const [showMissing, setShowMissing] = React.useState('not active');

    const handleNext = async () => {
        if (!treatmentPatternsStore.checkMissing8) {
                await treatmentPatternsStore.updateTreatment(
                    {
                        q18: JSON.stringify(treatmentPatternsStore.q18),
                    }, parseInt(params.number), parseInt(params.patient))

            if (dataStore.forceUpdate === true) {
                dataStore.setShowUpdateModal('not active')
            }
            if (dataStore.showUpdateModal !== 'active') {
                if (treatmentPatternsStore.q18.main === 'true') {
                    navigate(`/laboratoryTestsPage2/${parseInt(params.patient)}/${parseInt(params.number)}`)
                } else {
                    navigate(`/otherExamsPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
                }
            }


        } else {
            alert('Please add reason for missing questions.')

            // if (!dataStore.globalMissing) {
            //     if (showMissing === 'active') {
            //         await treatmentPatternsStore.updateTreatment(
            //             {
            //                 q18: treatmentPatternsStore.q18?.main ? JSON.stringify(treatmentPatternsStore.q18) : dataStore.returnOtherObjectMissing,
            //             }, parseInt(params.number),parseInt(params.patient))
            //         if (treatmentPatternsStore.q18.main === 'true') {
            //             navigate(`/laboratoryTestsPage2/${parseInt(params.patient)}/${parseInt(params.number)}`)
            //
            //         } else {
            //             navigate(`/otherExamsPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            //
            //         }
            //         setShowMissing('not active')
            //     } else {
            //         setShowMissing('active')
            //     }
            // } else {
            //     await treatmentPatternsStore.updateTreatment(
            //         {
            //             q18: treatmentPatternsStore.q18?.main ? JSON.stringify(treatmentPatternsStore.q18) : dataStore.returnOtherObjectMissing,
            //         }, parseInt(params.number),parseInt(params.patient))
            //     if (treatmentPatternsStore.q18.main === 'true') {
            //         navigate(`/laboratoryTestsPage2/${parseInt(params.patient)}/${parseInt(params.number)}`)
            //     } else {
            //         navigate(`/otherExamsPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            //     }
            // }
        }
    }

    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={treatmentPatternsStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>

                    {parseInt(params.number) === 0 ?
                        <div>
                            <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                                Treatment patterns and healthcare resource use (Only Initial Treatment)
                            </Typography>
                            <Typography color={'txtGrey'} sx={{fontSize: 20,mt:1}}>
                                (Please complete this section only for initial treatment.)
                            </Typography>

                        </div> :
                        <div>
                            <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                                {`Treatment patterns and healthcare resource use for subsequent treatment n = ${params.number}`}
                            </Typography>
                            <Typography color={'txtGrey'} sx={{fontSize: 20,mt:1}}>
                                [Please complete this section for “Subsequent treatment (n=1, 2, …)”]
                            </Typography>
                        </div>
                    }
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>
                    <Typography sx={{fontWeight: 'bold', fontSize: 30,}}>
                        Laboratory Tests
                    </Typography>
                    <QuestionBox allInRow={true}>
                        <YesNo value={treatmentPatternsStore.q18.main}
                               handleChange={(event) => {
                                   dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q18'), 1)
                                   if (event.target.value === 'true') {
                                       treatmentPatternsStore.setQ18({
                                           ...treatmentPatternsStore.q18,
                                           main: event.target.value,
                                       })
                                   }else{
                                       treatmentPatternsStore.setQ18({
                                           ...treatmentPatternsStore.q18,
                                           main: event.target.value,
                                           types: [
                                               {
                                                   name: 'Complete Blood Count',
                                                   ...treatmentPatternsStore.commonQ17Params
                                               },
                                               {
                                                   name: 'Albumin',
                                                   ...treatmentPatternsStore.commonQ17Params
                                               },
                                               {
                                                   name: 'Alkaline phosphatase',
                                                   ...treatmentPatternsStore.commonQ17Params
                                               },
                                               {
                                                   name: 'Glucose',
                                                   ...treatmentPatternsStore.commonQ17Params
                                               },
                                               {
                                                   name: 'Lactate Dehydrogenase',
                                                   ...treatmentPatternsStore.commonQ17Params
                                               },
                                               {
                                                   name: 'Phosphorus',
                                                   ...treatmentPatternsStore.commonQ17Params
                                               },
                                               {
                                                   name: 'Potassium',
                                                   ...treatmentPatternsStore.commonQ17Params
                                               },
                                               {
                                                   name: 'Sodium',
                                                   ...treatmentPatternsStore.commonQ17Params
                                               },
                                               {
                                                   name: 'Calcium',
                                                   ...treatmentPatternsStore.commonQ17Params
                                               },
                                               {
                                                   name: 'Total Bilirubin',
                                                   ...treatmentPatternsStore.commonQ17Params
                                               },
                                               {
                                                   name: 'Direct Bilirubin',
                                                   ...treatmentPatternsStore.commonQ17Params
                                               },
                                               {
                                                   name: 'Total Protein',
                                                   ...treatmentPatternsStore.commonQ17Params
                                               },
                                               {
                                                   name: 'Uric acid',
                                                   ...treatmentPatternsStore.commonQ17Params
                                               },
                                               {
                                                   name: 'Urea',
                                                   ...treatmentPatternsStore.commonQ17Params
                                               },
                                               {
                                                   name: 'PT (INR)',
                                                   ...treatmentPatternsStore.commonQ17Params
                                               },
                                               {
                                                   name: 'aPTT',
                                                   ...treatmentPatternsStore.commonQ17Params
                                               },
                                               {
                                                   name: 'Thyroid stimulating hormone (TSH)',
                                                   ...treatmentPatternsStore.commonQ17Params
                                               },
                                               {
                                                   name: ' Free thyroxine (FT4)',
                                                   ...treatmentPatternsStore.commonQ17Params
                                               },
                                               {
                                                   name: 'Creatinine',
                                                   ...treatmentPatternsStore.commonQ17Params
                                               },
                                               {
                                                   name: 'C-reactive Protein',
                                                   ...treatmentPatternsStore.commonQ17Params
                                               },
                                               {
                                                   name: 'Hemoculture',
                                                   ...treatmentPatternsStore.commonQ17Params
                                               },
                                               {
                                                   name: 'Urine Culture',
                                                   ...treatmentPatternsStore.commonQ17Params
                                               },
                                               {
                                                   name: 'Urinalysis - Blood',
                                                   ...treatmentPatternsStore.commonQ17Params
                                               },
                                               {
                                                   name: 'Urinalysis - Glucose',
                                                   ...treatmentPatternsStore.commonQ17Params
                                               },
                                               {
                                                   name: 'Urinalysis - Protein',
                                                   ...treatmentPatternsStore.commonQ17Params
                                               },
                                               {
                                                   name: 'Urinalysis - Specific Gravity',
                                                   ...treatmentPatternsStore.commonQ17Params
                                               },
                                           ]
                                       })
                                   }
                               }}
                        >
                            {dataStore.questionsMissing.includes('q18')
                                ? <MissingReason
                                    value={treatmentPatternsStore.q18.main}
                                    style={{right:60,marginTop:-3}}
                                    onSubmitMissing={()=>{
                                        treatmentPatternsStore.setQ18({
                                            ...treatmentPatternsStore.q18,
                                            main: dataStore.returnOtherTextMissing,
                                        })
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q18'), 1)
                                    }}/> : <></>}
                        </YesNo>
                    </QuestionBox>

                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(LaboratoryTestsPage1);
