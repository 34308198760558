import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import YesNo from "../../components/YesNo";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import TextField from "@mui/material/TextField";
import QuestionsLayout from "../../layout/QuestionsLayout";
import MissingReason from "../../components/MissingReason";


const EmergencyConsultationsPage1 = () => {
    const {
        treatmentPatternsStore, dataStore,menuStore
    } = useStores()

    const navigate = useNavigate()
    const [showMissing, setShowMissing] = React.useState('not active');

    let params = useParams();
    useEffect(() => {
        treatmentPatternsStore.getTreatment(parseInt(params.patient),parseInt(params.number))
    }, [])

    const handleNext = async () => {
        if (!treatmentPatternsStore.checkMissing12) {
            await treatmentPatternsStore.updateTreatment(
                {
                    q23: JSON.stringify(treatmentPatternsStore.q23),
                }, parseInt(params.number),parseInt(params.patient))

            if(dataStore.forceUpdate == true){
                dataStore.setShowUpdateModal('not active')
            }
            if(dataStore.showUpdateModal !== 'active'){
                navigate(`/imagingAssessmentsAfterInitialTreatmentPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            }

        } else {
            alert('Please add reason for missing questions.')

            // if (!dataStore.globalMissing) {
            //     if (showMissing === 'active') {
            //         await treatmentPatternsStore.updateTreatment(
            //             {
            //                 q23: treatmentPatternsStore.q23?.main ? JSON.stringify(treatmentPatternsStore.q23) : dataStore.returnOtherObjectMissing,
            //             }, parseInt(params.number),parseInt(params.patient))
            //         navigate(`/imagingAssessmentsAfterInitialTreatmentPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            //         setShowMissing('not active')
            //     } else {
            //         setShowMissing('active')
            //     }
            // } else {
            //     await treatmentPatternsStore.updateTreatment(
            //         {
            //             q23: treatmentPatternsStore.q23?.main ? JSON.stringify(treatmentPatternsStore.q23) : dataStore.returnOtherObjectMissing,
            //         }, parseInt(params.number),parseInt(params.patient))
            //     navigate(`/imagingAssessmentsAfterInitialTreatmentPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            // }
        }
    }

    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={treatmentPatternsStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>

                    {parseInt(params.number) === 0 ?
                        <div>
                            <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                                Treatment patterns and healthcare resource use (Only Initial Treatment)
                            </Typography>
                            <Typography color={'txtGrey'} sx={{fontSize: 20,mt:1}}>
                                (Please complete this section only for initial treatment.)
                            </Typography>

                        </div> :
                        <div>
                            <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                                {`Treatment patterns and healthcare resource use for subsequent treatment n = ${params.number}`}
                            </Typography>
                            <Typography color={'txtGrey'} sx={{fontSize: 20,mt:1}}>
                                [Please complete this section for “Subsequent treatment (n=1, 2, …)”]
                            </Typography>
                        </div>
                    }
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>
                    <Typography sx={{fontWeight: 'bold', fontSize: 30,}}>
                        Emergency Consultations

                    </Typography>
                    <QuestionBox allInRow={true}>
                        <YesNo value={treatmentPatternsStore.q23?.main}
                               handleChange={(event) => {
                                   dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q23'), 1)
                                   if (event.target.value === 'true') {
                                       treatmentPatternsStore.setQ23(
                                           {...treatmentPatternsStore.q23, main: event.target.value,})
                                   }else{
                                       treatmentPatternsStore.setQ23(
                                           {
                                               ...treatmentPatternsStore.q23, main: event.target.value, amount: ''
                                           });
                                   }
                               }}
                        >
                            {dataStore.questionsMissing.includes('q23')
                                ? <MissingReason
                                    style={{right:60,marginTop:-3}}
value={treatmentPatternsStore.q23.main}
                                    onSubmitMissing={async ()=>{
                                        treatmentPatternsStore.setQ23(
                                            {...treatmentPatternsStore.q23, main: dataStore.returnOtherTextMissing,})
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q23'), 1)
                                    }}/> : <></>}
                        </YesNo>
                        {treatmentPatternsStore.q23?.main && treatmentPatternsStore.q23.main === 'true' ?<Box sx={{display: 'flex', alignItems: 'center', mt: 1}}>
                            <Typography sx={{fontSize: 18, mr: 1}}>
                                {dataStore.questionsMissing.includes('q23amount')
                                    ? <MissingReason
                                        value={treatmentPatternsStore.q23.amount}
                                        style={{marginLeft:-80}}
                                        onSubmitMissing={async ()=>{
                                            treatmentPatternsStore.setQ23(
                                                {...treatmentPatternsStore.q23, amount: dataStore.returnOtherTextMissing,})
                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q23amount'), 1)
                                        }}/> : <></>}
                                Number
                            </Typography>
                            <TextField
                                type={"number"}
                                onWheel={(e) => e.target.blur()}
                                size={'small'}
                                sx={{width: 100, ml: 3, mr: 1}}
                                inputProps={{min: 0, style: {textAlign: 'center'}}}
                                placeholder={'00'}
                                value={treatmentPatternsStore.q23?.amount && treatmentPatternsStore.q23.amount.startsWith('missing|') ? '': treatmentPatternsStore.q23.amount}

                                onChange={(event) => {
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q23amount'), 1)

                                    treatmentPatternsStore.setQ23(
                                        {...treatmentPatternsStore.q23, amount: event.target.value,})
                                }}/>
                        </Box>:<></>}
                    </QuestionBox>
                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(EmergencyConsultationsPage1);
