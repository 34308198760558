import * as React from 'react';
import {Stack,} from "@mui/material";
import Typography from "@mui/material/Typography";
import close from "../images/svgs/Close.svg";
import Box from "@mui/material/Box";
import theme from "../styles/theme";
import {useStores} from "../store";
import {useNavigate, useParams} from "react-router-dom";


const QuestionHeader = ({patientNumberEnd, onClick}) => {
    const {dataStore} = useStores()
    const navigate = useNavigate()
    let urlParams = useParams();

    return (
        <Stack direction={'row'} sx={{
            alignItems: 'center',
            justifyContent: patientNumberEnd ? 'flex-end' : 'space-between',
            display: 'flex',
            px: 8
        }}>
            <Box/>
            <Typography color={patientNumberEnd ? 'txtGrey' : 'primary'}
                        sx={{mr: patientNumberEnd ? 3 : 0, fontSize: 25, fontWeight: 'bold'}}>
                #{dataStore.selectedPatient?.code}
            </Typography>
            <Box onClick={onClick ? onClick : () => navigate('/chooseArea/'+parseInt(urlParams.patient))} style={{
                boxShadow: `3px 3px 15px ${theme.palette.primary.main}`,
                width: 45, height: 45, backgroundColor: theme.palette.primary.main,
                borderRadius: 25, display: 'flex', alignItems: 'center', justifyContent: 'center',
                cursor: 'pointer'
            }}>
                <img width={28} height={21} src={close}/>
            </Box>
        </Stack>
    );
}

export default QuestionHeader;
