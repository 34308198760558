import * as React from 'react';
import theme from '../styles/theme'
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MissingQuestions from "../data/MissingQuestions";
import {useEffect, useState} from 'react';
import missingData from "../images/svgs/Missing-data_icon.svg";


const MissingReason = (props) => {
    const [showMissing, setShowMissing] = useState('not active');
    console.log('props.value',props.value)
    let type = 999
    let reason = ''
    if(props.value === 'missing|'){
        type = 0
    }else if(props.value === 'missing|Illegible'){
        type = 1
    }else if(props.value && props.value.startsWith('missing|')){
        type = 2
        reason = props.value.split('|')[1]
    }

    return (
        <span style={{position:'absolute',marginLeft:5,marginTop:-5,...props.style}}>
            {/*<img title="Add Missing Reason" onClick={()=>setShowMissing('active')}*/}
            {/*     style={{*/}
            {/*         cursor: 'pointer', width: 35, height: 35,*/}
            {/*     }}*/}
            {/*     src={missingData}/>*/}
            <Button
                variant="contained"
                color="primary"
                sx={{
                    fontSize: 11,
                    color: "white",
                    height: 42,
                    p: 1,
                    width:30,
                    fontWeight:'bold',
                    ml:1,
                    mt:0.2
                }}
                onClick={() => setShowMissing('active')}>
                                    Add Reason
                                </Button>
            {showMissing === 'active' ? <MissingQuestions onClose={()=>setShowMissing('not active')}
                                                          type={type}
                                                          reason={reason}
                                                          onSubmitMissing={props.onSubmitMissing}/> : <></>}
        </span>
    );
}

export default MissingReason;
