import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {InputAdornment, RadioGroup, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import YesNo from "../../components/YesNo";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import RadioItem from "../../components/RadioItem";
import TextField from "@mui/material/TextField";
import QuestionsLayout from "../../layout/QuestionsLayout";
import Joi from "joi";
import MissingReason from "../../components/MissingReason";


const TreatmentPatternsRTAlone = () => {
    const {
        treatmentPatternsStore, dataStore, menuStore
    } = useStores()
    const [showMissing, setShowMissing] = React.useState('not active');

    const navigate = useNavigate()
    let params = useParams();
    useEffect(() => {
        treatmentPatternsStore.getTreatment(parseInt(params.patient), parseInt(params.number))
    }, [])

    const StereoDoseSchema = Joi.number().min(5).max(200).integer().required();
    const RadialDoseSchema = Joi.number().min(5).max(150).integer().required();
    const FractionSchema = Joi.number().min(1).max(50).integer().required();

    const handleNext = async () => {
        if (treatmentPatternsStore?.q7?.main === 'Stereotactic Body Radiation Therapy') {
            if (StereoDoseSchema.validate(treatmentPatternsStore.q7.dose).error || FractionSchema.validate(treatmentPatternsStore.q7.numberOfFractions).error) {
                if (!window.confirm("Do you confirm this value?")) {
                    return;
                }
            }
        }

        if (treatmentPatternsStore?.q8?.main === 'Radial to the mediastinum and chest') {
            if (RadialDoseSchema.validate(treatmentPatternsStore.q8.dose).error || FractionSchema.validate(treatmentPatternsStore.q8.numberOfFractions).error) {
                if (!window.confirm("Do you confirm this value?")) {
                    return;
                }
            }
        }
        if (!treatmentPatternsStore.checkMissing4) {
            await treatmentPatternsStore.updateTreatment(
                {
                    q6: JSON.stringify(treatmentPatternsStore.q6),
                    q7: treatmentPatternsStore.q7?.main ? JSON.stringify(treatmentPatternsStore.q7) : JSON.stringify({
                        main: null,
                        dose: '',
                        numberOfFractions: '',
                    }),
                    q8: treatmentPatternsStore.q8?.main ? JSON.stringify(treatmentPatternsStore.q8) : JSON.stringify({
                        main: null,
                        dose: '',
                        numberOfFractions: '',
                    }),
                }, parseInt(params.number),parseInt(params.patient))
            if (dataStore.forceUpdate === true) {
                dataStore.setShowUpdateModal('not active')
            }
            if (dataStore.showUpdateModal !== 'active') {
                navigate(`/treatmentPatternsRadioFrequency/${parseInt(params.patient)}/${parseInt(params.number)}`)
            }

        } else {
            alert('Please add reason for missing questions.')

            // if (!dataStore.globalMissing) {
            //     if (showMissing === 'active') {
            //         await treatmentPatternsStore.updateTreatment(
            //             {
            //                 q6: treatmentPatternsStore.q6?.main ? JSON.stringify(treatmentPatternsStore.q6) : dataStore.returnOtherObjectMissing,
            //                 q7: treatmentPatternsStore.q7?.main ? JSON.stringify(treatmentPatternsStore.q7) : dataStore.returnOtherObjectMissing,
            //                 q8: treatmentPatternsStore.q8?.main ? JSON.stringify(treatmentPatternsStore.q8) : dataStore.returnOtherObjectMissing,
            //             }, parseInt(params.number),parseInt(params.patient))
            //         navigate(`/treatmentPatternsRadioFrequency/${parseInt(params.patient)}/${parseInt(params.number)}`)
            //         setShowMissing('not active')
            //     } else {
            //         setShowMissing('active')
            //     }
            // } else {
            //     await treatmentPatternsStore.updateTreatment(
            //         {
            //             q6: treatmentPatternsStore.q6?.main ? JSON.stringify(treatmentPatternsStore.q6) : dataStore.returnOtherObjectMissing,
            //             q7: treatmentPatternsStore.q7?.main ? JSON.stringify(treatmentPatternsStore.q7) : dataStore.returnOtherObjectMissing,
            //             q8: treatmentPatternsStore.q8?.main ? JSON.stringify(treatmentPatternsStore.q8) : dataStore.returnOtherObjectMissing,
            //         }, parseInt(params.number),parseInt(params.patient))
            //     navigate(`/treatmentPatternsRadioFrequency/${parseInt(params.patient)}/${parseInt(params.number)}`)
            // }
        }
    }
    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={treatmentPatternsStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>

                    {parseInt(params.number) === 0 ?
                        <div>
                            <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                                Treatment patterns and healthcare resource use (Only Initial Treatment)
                            </Typography>
                            <Typography color={'txtGrey'} sx={{fontSize: 20,mt:1}}>
                                (Please complete this section only for initial treatment.)
                            </Typography>

                        </div> :
                        <div>
                            <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                                {`Treatment patterns and healthcare resource use for subsequent treatment n = ${params.number}`}
                            </Typography>
                            <Typography color={'txtGrey'} sx={{fontSize: 20,mt:1}}>
                                [Please complete this section for “Subsequent treatment (n=1, 2, …)”]
                            </Typography>
                        </div>
                    }
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>
                    <Typography sx={{fontWeight: 'bold', fontSize: 30,}}>
                        RT alone
                    </Typography>
                    <QuestionBox allInRow={true}>
                        <YesNo value={treatmentPatternsStore.q6?.main}
                               handleChange={(event) => {
                                   dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q6'), 1)
                                   if(event.target.value === 'true'){
                                       treatmentPatternsStore.setQ6(
                                           {
                                               ...treatmentPatternsStore.q6, main: event.target.value,
                                               value: event.target.value === 'true' ? treatmentPatternsStore.q6.value : null
                                           })
                                   }else{
                                       treatmentPatternsStore.setQ6(
                                           {
                                                main: event.target.value,
                                               value: null
                                           })

                                       treatmentPatternsStore.setQ7(
                                           {
                                               main: null,
                                               dose: '',
                                               numberOfFractions: '',
                                           })
                                       treatmentPatternsStore.setQ8(
                                           {
                                               main: null,
                                               dose: '',
                                               numberOfFractions: '',
                                           })
                                   }

                               }}
                        >
                            {dataStore.questionsMissing.includes('q6value')
                                ? <MissingReason
                                    value={treatmentPatternsStore.q6.value}
                                    style={{left:280,marginTop:-3}}
                                    onSubmitMissing={async ()=>{
                                        treatmentPatternsStore.setQ6(
                                            {...treatmentPatternsStore.q6, value: dataStore.returnOtherTextMissing,}
                                        )
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q6value'), 1)
                                    }}/> : <></>}
                        </YesNo>
                        <Box sx={{width:50,marginTop:-3}}>
                        {dataStore.questionsMissing.includes('q6')
                            ? <MissingReason
                                style={{marginLeft:-20,marginTop:-9}}
                                value={treatmentPatternsStore.q6.main}
                                onSubmitMissing={async ()=>{
                                    treatmentPatternsStore.setQ6(
                                        {
                                            ...treatmentPatternsStore.q6, main: dataStore.returnOtherTextMissing,
                                        })
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q6'), 1)
                                }}/> : <></>}
                        </Box>
                        {treatmentPatternsStore.q6?.main && treatmentPatternsStore.q6.main === 'true' &&
                        treatmentPatternsStore.q4?.main && treatmentPatternsStore.q4.main === 'true' ? <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(event) => {
                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q6value'), 1)

                                treatmentPatternsStore.setQ6(
                                {...treatmentPatternsStore.q6, value: event.target.value,}
                            )}}
                            value={treatmentPatternsStore.q6.value}
                            sx={{display: 'flex', flexDirection: 'row', gap: "24px", rowGap: 0}}
                        >
                            <RadioItem label={'Before Surgery'}/>
                            <RadioItem label={'After surgery'}/>
                            <RadioItem label={'No surgery performed at this point'}/>
                        </RadioGroup>: <></>}

                    </QuestionBox>
                    {treatmentPatternsStore.q6?.main && treatmentPatternsStore.q6.main === 'true' ? <QuestionBox>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(event) => {
                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q7q8'), 1)

                                treatmentPatternsStore.setQ7({
                                    ...treatmentPatternsStore.q7,
                                    main: event.target.value,
                                })
                                treatmentPatternsStore.setQ8({
                                    ...treatmentPatternsStore.q8,
                                    main: null,
                                })
                            }}
                            value={treatmentPatternsStore.q7?.main && treatmentPatternsStore.q7.main}
                            sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
                        >

                            <RadioItem label={'Stereotactic Body Radiation Therapy'}/>
                            <Stack direction={'row'} sx={{width:50,marginTop:1}}>
                                {dataStore.questionsMissing.includes('q7q8')
                                    ? <MissingReason
                                        style={{marginLeft:-20,marginTop:-9}}
                                        value={treatmentPatternsStore.q7.main}
                                        onSubmitMissing={async ()=>{
                                            treatmentPatternsStore.setQ7({
                                                ...treatmentPatternsStore.q7,
                                                main:dataStore.returnOtherTextMissing,
                                            })
                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q7q8'), 1)
                                        }}/> : <></>}

                            </Stack>
                        </RadioGroup>

                        {treatmentPatternsStore.q7?.main && treatmentPatternsStore.q7.main === 'Stereotactic Body Radiation Therapy' ?
                            <>
                                <Box sx={{display: 'flex', width: '35%', alignItems: 'center', ml: 5}}>
                                    <li style={{fontSize: 18, flex: 1}}>Dose
                                        {dataStore.questionsMissing.includes('q7dose')
                                            ? <MissingReason
                                                style={{right:60,marginTop:-3}}
                                                value={treatmentPatternsStore.q7?.dose}
                                                onSubmitMissing={async ()=>{
                                                    treatmentPatternsStore.setQ7(
                                                        {...treatmentPatternsStore.q7, dose: dataStore.returnOtherTextMissing,}
                                                    )
                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q7dose'), 1)
                                                }}/> : <></>}
                                    </li>
                                    {StereoDoseSchema.validate(treatmentPatternsStore.q7?.dose).error && <Typography
                                        variant="caption" color="error" sx={{mr: 2}}>Between 5 and 200</Typography>}
                                    <TextField
                                        onWheel={(e) => e.target.blur()}
                                        size={'small'}
                                        sx={{width: 100}}
                                        placeholder={'000'}
                                        onChange={(event) => {
                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q7dose'), 1)

                                            treatmentPatternsStore.setQ7(
                                            {...treatmentPatternsStore.q7, dose: event.target.value,}
                                        )}}
                                        error={StereoDoseSchema.validate(treatmentPatternsStore.q7?.dose).error}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">Gy</InputAdornment>,
                                        }}
                                        value={treatmentPatternsStore.q7?.dose && treatmentPatternsStore.q7.dose.startsWith('missing|') ? '': treatmentPatternsStore.q7.dose}

                                    />
                                </Box>
                                <Box sx={{display: 'flex', width: '35%', alignItems: 'center', mt: 1, ml: 5}}>
                                    <li style={{fontSize: 18, flex: 1}}>Number of fractions
                                        {dataStore.questionsMissing.includes('q7numberOfFractions')
                                            ? <MissingReason
                                                style={{right:60}}
                                                value={treatmentPatternsStore.q7?.numberOfFractions}
                                                onSubmitMissing={async ()=>{
                                                    treatmentPatternsStore.setQ7(
                                                        {...treatmentPatternsStore.q7, numberOfFractions: dataStore.returnOtherTextMissing,}
                                                    )
                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q7numberOfFractions'), 1)
                                                }}/> : <></>}

                                    </li>
                                    {FractionSchema.validate(treatmentPatternsStore.q7?.numberOfFractions).error &&
                                        <Typography
                                            variant="caption" color="error" sx={{mr: 2}}>Between 1 and 50</Typography>}
                                    <TextField
                                        onWheel={(e) => e.target.blur()}
                                        size={'small'}
                                        sx={{width: 100}}
                                        placeholder={'000'}
                                        error={FractionSchema.validate(treatmentPatternsStore.q7?.numberOfFractions).error}
                                        onChange={(event) => {
                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q7numberOfFractions'), 1)

                                            treatmentPatternsStore.setQ7(
                                            {...treatmentPatternsStore.q7, numberOfFractions: event.target.value,}
                                        )}}
                                        value={treatmentPatternsStore.q7?.numberOfFractions && treatmentPatternsStore.q7.numberOfFractions.startsWith('missing|') ? '': treatmentPatternsStore.q7.numberOfFractions}

                                    />
                                </Box>
                            </> : null}
                    </QuestionBox> : <></>}
                    {treatmentPatternsStore.q6?.main && treatmentPatternsStore.q6.main === 'true' ? <QuestionBox>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(event) => {
                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q7q8'), 1)

                                treatmentPatternsStore.setQ7({
                                    ...treatmentPatternsStore.q7,
                                    main: null,
                                })
                                treatmentPatternsStore.setQ8({
                                    ...treatmentPatternsStore.q8,
                                    main: event.target.value,
                                })
                            }}
                            value={treatmentPatternsStore.q8.main}
                            sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
                        >
                            <RadioItem label={'Radial to the mediastinum and chest'}/>
                            <Stack direction={'row'} sx={{width:50,marginTop:1}}>

                            {dataStore.questionsMissing.includes('q7q8')
                                ? <MissingReason
                                    style={{marginLeft:-20,marginTop:-9}}

                                    value={treatmentPatternsStore.q8.main}
                                    onSubmitMissing={async ()=>{
                                        treatmentPatternsStore.setQ8({
                                            ...treatmentPatternsStore.q8,
                                            main:dataStore.returnOtherTextMissing,
                                        })
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q7q8'), 1)
                                    }}/> : <></>}
                            </Stack>
                        </RadioGroup>
                        {treatmentPatternsStore.q8.main === 'Radial to the mediastinum and chest' ?
                            <>
                                <Box sx={{display: 'flex', width: '35%', alignItems: 'center', ml: 5}}>
                                    <li style={{fontSize: 18, flex: 1}}>Dose
                                        {dataStore.questionsMissing.includes('q8dose')
                                            ? <MissingReason
                                                style={{right:60,marginTop:-3}}
                                                value={treatmentPatternsStore.q8?.dose}
                                                onSubmitMissing={async ()=>{
                                                    treatmentPatternsStore.setQ8(
                                                        {...treatmentPatternsStore.q8, dose:dataStore.returnOtherTextMissing,}
                                                    )
                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q8dose'), 1)
                                                }}/> : <></>}
                                    </li>
                                    {RadialDoseSchema.validate(treatmentPatternsStore.q8?.dose).error && <Typography
                                        variant="caption" color="error" sx={{mr: 2}}>Between 5 and 150</Typography>}
                                    <TextField
                                        onWheel={(e) => e.target.blur()}
                                        size={'small'}
                                        sx={{width: 100}}
                                        placeholder={'000'}
                                        onChange={(event) => {
                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q8value'), 1)

                                            treatmentPatternsStore.setQ8(
                                            {...treatmentPatternsStore.q8, dose: event.target.value,}
                                        )}}
                                        error={RadialDoseSchema.validate(treatmentPatternsStore.q8?.dose).error}
                                        value={treatmentPatternsStore.q8?.dose && treatmentPatternsStore.q8.dose.startsWith('missing|') ? '': treatmentPatternsStore.q8.dose}

                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">Gy</InputAdornment>,
                                        }}
                                    />
                                </Box>
                                <Box sx={{display: 'flex', width: '35%', alignItems: 'center', mt: 1, ml: 5}}>
                                    <li style={{fontSize: 18, flex: 1}}>Number of fractions
                                        {dataStore.questionsMissing.includes('q8numberOfFractions')
                                            ? <MissingReason
                                                style={{right:60}}
                                                value={treatmentPatternsStore.q8?.dose}
                                                onSubmitMissing={()=>{
                                                    treatmentPatternsStore.setQ8(
                                                        {...treatmentPatternsStore.q8, numberOfFractions:dataStore.returnOtherTextMissing,}
                                                    )
                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q8numberOfFractions'), 1)
                                                }}/> : <></>}
                                    </li>
                                    {FractionSchema.validate(treatmentPatternsStore.q8?.numberOfFractions).error &&
                                        <Typography
                                            variant="caption" color="error" sx={{mr: 2}}>Between 1 and 50</Typography>}
                                    <TextField
                                        onWheel={(e) => e.target.blur()}
                                        size={'small'}
                                        sx={{width: 100}}
                                        placeholder={'000'}
                                        error={FractionSchema.validate(treatmentPatternsStore.q8?.numberOfFractions).error}
                                        onChange={(event) => {
                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q8numberOfFractions'), 1)

                                            treatmentPatternsStore.setQ8(
                                            {...treatmentPatternsStore.q8, numberOfFractions: event.target.value,}
                                        )}}
                                        value={treatmentPatternsStore.q8?.numberOfFractions && treatmentPatternsStore.q8.numberOfFractions.startsWith('missing|') ? '': treatmentPatternsStore.q8.numberOfFractions}

                                    />
                                </Box>
                            </> : null}

                    </QuestionBox> : <></>}
                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(TreatmentPatternsRTAlone);
