import * as React from 'react';
import {Checkbox, ClickAwayListener, FormControlLabel, RadioGroup, Stack} from "@mui/material";
import Paper from "@mui/material/Paper";
import RadioItem from "../../components/RadioItem";
import arrowUp from "../../images/svgs/Arrow-up.svg";
import {useStores} from "../../store";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Joi from "joi";
import Box from "@mui/material/Box";
import MissingReason from "../../components/MissingReason";


const OtherExamsMenuAfterInitiate = ({setOpenMenu, text, sx, checked, index, item}) => {
    const {afterInitialTreatmentStore,dataStore} = useStores()
    const NrSchema = Joi.number().min(1).max(10).integer().required();

    return (
        <ClickAwayListener onClickAway={setOpenMenu}>

        <Paper sx={{
            display: 'flex',
            backgroundColor: 'white',
            mt: -6,
            ml: -3,
            position: 'absolute',
            p: 3,
            pt: 1,
            borderRadius: 3,
            flexDirection: 'column',
            zIndex: 999, ...sx,
            width:600

        }} elevation={5}>
            <Stack sx={{width: '100%', display: "flex", alignItems: "center"}} direction={'row'}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked}
                        />
                    }
                    sx={{
                        '& .MuiFormControlLabel-label': {fontSize: 18},

                    }}
                    onChange={(event) => {
                        console.log('item.selected', item.selected)
                        let disease = afterInitialTreatmentStore.q3.disease

                        if (event.target.value === 'on' && item.selected === 'on') {
                            disease[0].duration = {type: null, amount: ''}
                            disease[0].frequency = null
                            disease[0].selected = null
                            disease[0].extra = []
                            afterInitialTreatmentStore.setQ3(
                                {
                                    ...afterInitialTreatmentStore.q3,
                                    disease: disease
                                }
                            )

                        } else {
                            disease[0] = {...item, selected: event.target.value}
                            afterInitialTreatmentStore.setQ3(
                                {
                                    ...afterInitialTreatmentStore.q3,
                                    disease: disease
                                }
                            )
                        }

                    }}
                    label={text}
                />
                <img onClick={setOpenMenu} style={{
                    cursor: 'pointer',
                    width: 18,
                    height: 18,
                }} src={arrowUp}/>
                {dataStore.questionsMissing.includes('BiopsyAll') ?
                    <MissingReason
                        style={{right:10}}
                        value={afterInitialTreatmentStore.q3?.types[index]?.type1?.name}
                        onSubmitMissing={()=>{
                            let types = afterInitialTreatmentStore.q3.types || []

                            types[index] = {
                                ...afterInitialTreatmentStore.q3.types[index],
                                type1: {
                                    ...afterInitialTreatmentStore.q3.types[index].type1,
                                    name: dataStore.returnOtherTextMissing
                                },
                                type2: {
                                    ...afterInitialTreatmentStore.q3.types[index].type2,
                                    name: dataStore.returnOtherTextMissing
                                }
                            }

                            afterInitialTreatmentStore.setQ3(
                                {
                                    ...afterInitialTreatmentStore.q3,
                                    types: types
                                }
                            )
                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('BiopsyAll'), 1)
                        }}/>
                    : <></>}
            </Stack>
            <Stack direction={'column'} sx={{pl: 3}}>
                <Stack direction={'row'} sx={{justifyContent:'space-between'}}>

                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={(event) => {
                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('BiopsyAll'), 1)

                                let types = afterInitialTreatmentStore.q3.types || []
                                console.log(' afterInitialTreatmentStore.q3.types', afterInitialTreatmentStore.q3.types)
                                console.log('types', types)

                                if (event.target.value === 'on' &&
                                    afterInitialTreatmentStore.q3?.types && afterInitialTreatmentStore.q3.types.length > 0 &&
                                    afterInitialTreatmentStore.q3.types[index].type1?.name && afterInitialTreatmentStore.q3.types[index].type1.name === "Metastasis Biopsy") {
                                    types[index] = {
                                        ...afterInitialTreatmentStore.q3.types[index],
                                        type1: {...afterInitialTreatmentStore.q3.types[index].type, name: null}
                                    }
                                    afterInitialTreatmentStore.setQ3(
                                        {
                                            ...afterInitialTreatmentStore.q3,
                                            types: types
                                        }
                                    )
                                } else {
                                    types[index] = {
                                        ...afterInitialTreatmentStore.q3.types[index]
                                        ,
                                        type1: {
                                            ...afterInitialTreatmentStore.q3.types[index].type,
                                            name: "Metastasis Biopsy"
                                        }
                                    }

                                    afterInitialTreatmentStore.setQ3(
                                        {
                                            ...afterInitialTreatmentStore.q3,
                                            types: types
                                        }
                                    )
                                }
                            }}
                            checked={afterInitialTreatmentStore.q3?.types && afterInitialTreatmentStore.q3.types.length > 0 && afterInitialTreatmentStore.q3.types[index].type1?.name && afterInitialTreatmentStore.q3.types[index].type1.name === "Metastasis Biopsy"}
                        />
                    }
                    label="Metastasis Biopsy"
                />
                    {dataStore.questionsMissing.includes('Metastasis Biopsy') ?
                        <MissingReason
                            value={afterInitialTreatmentStore.q3?.types[index]?.type1?.name}
                            style={{right:10}}
                            onSubmitMissing={()=>{

                                let types = afterInitialTreatmentStore.q3.types

                                types[index] = {
                                    ...afterInitialTreatmentStore.q3.types[index]
                                    ,
                                    type1: {
                                        ...afterInitialTreatmentStore.q3.types[index].type1,
                                        name: dataStore.returnOtherTextMissing
                                    }
                                }

                                afterInitialTreatmentStore.setQ3(
                                    {
                                        ...afterInitialTreatmentStore.q3,
                                        types: types
                                    }
                                )
                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('Metastasis Biopsy'), 1)
                            }}/>
                        : <></>}
                </Stack>
                <Stack direction={'row'} sx={{justifyContent:'space-between'}}>

                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={(event) => {
                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('BiopsyAll'), 1)

                                let types = afterInitialTreatmentStore.q3.types || []

                                if (event.target.value === 'on' && afterInitialTreatmentStore.q3?.types && afterInitialTreatmentStore.q3.types.length > 0 && afterInitialTreatmentStore.q3.types[index].type2?.name && afterInitialTreatmentStore.q3.types[index].type2.name === "Tumor Biopsy") {
                                    types[index] = {
                                        ...afterInitialTreatmentStore.q3.types[index],
                                        type2: {...afterInitialTreatmentStore.q3.types[index].type2, name: null}
                                    }
                                    afterInitialTreatmentStore.setQ3(
                                        {
                                            ...afterInitialTreatmentStore.q3,
                                            types: types
                                        }
                                    )
                                } else {
                                    types[index] = {
                                        ...afterInitialTreatmentStore.q3.types[index]
                                        ,
                                        type2: {
                                            ...afterInitialTreatmentStore.q3.types[index].type2,
                                            name: "Tumor Biopsy"
                                        }
                                    }

                                    afterInitialTreatmentStore.setQ3(
                                        {
                                            ...afterInitialTreatmentStore.q3,
                                            types: types
                                        }
                                    )
                                }
                            }}
                            checked={afterInitialTreatmentStore.q3?.types && afterInitialTreatmentStore.q3.types.length > 0 && afterInitialTreatmentStore.q3.types[index].type2?.name && afterInitialTreatmentStore.q3.types[index].type2.name === "Tumor Biopsy"}
                        />

                    }
                    label="Tumor Biopsy"
                />
                    {dataStore.questionsMissing.includes("Tumor Biopsy")
                        ? <MissingReason
                            value={afterInitialTreatmentStore.q3?.types[index]?.type2?.name}
                            style={{right:10}}
                            onSubmitMissing={()=>{
                                let types = afterInitialTreatmentStore.q3.types

                                types[index] = {
                                    ...afterInitialTreatmentStore.q3.types[index]
                                    ,
                                    type2: {
                                        ...afterInitialTreatmentStore.q3.types[index].type2,
                                        name: dataStore.returnOtherTextMissing
                                    }
                                }

                                afterInitialTreatmentStore.setQ3(
                                    {
                                        ...afterInitialTreatmentStore.q3,
                                        types: types
                                    }
                                )
                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf("Tumor Biopsy"), 1)
                            }}/> : <></>}
                </Stack>
                {afterInitialTreatmentStore.q3.types[index].type2?.name && afterInitialTreatmentStore.q3.types[index].type2.name === "Tumor Biopsy" ?
                    <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(event) => {
                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf("Tumor Biopsy"), 1)

                        let types = afterInitialTreatmentStore.q3.types

                        types[index] = {
                            ...afterInitialTreatmentStore.q3.types[index]
                            , type2: {...afterInitialTreatmentStore.q3.types[index].type2, subName: event.target.value}
                        }
                        afterInitialTreatmentStore.setQ3(
                            {

                                ...afterInitialTreatmentStore.q3,
                                types: types
                            }
                        )
                    }}
                    value={afterInitialTreatmentStore.q3?.types[index]?.type2?.subName && afterInitialTreatmentStore.q3.types[index].type2.subName}
                    sx={{display: 'flex', flexDirection: 'column', ml: 5}}
                >
                    <RadioItem fontSize={15} label={'Extemporaneous examination'} value={'Extemporaneous examination'}/>
                    <RadioItem fontSize={15} label={'Histologic exam of the surgical specimen'} value={'Histologic exam of the surgical specimen'}/>
                    <RadioItem fontSize={15} label={'Both'} value={'Both'}/>
                        {dataStore.questionsMissing.includes('q3type2number')
                            ? <MissingReason
                                value={afterInitialTreatmentStore.q3?.types[index]?.type2?.subName}
                                style={{right:10}}
                                onSubmitMissing={()=>{
                                    let types = afterInitialTreatmentStore.q3.types

                                    types[index] = {
                                        ...afterInitialTreatmentStore.q3.types[index]
                                        , type2: {...afterInitialTreatmentStore.q3.types[index].type2, subName: dataStore.returnOtherTextMissing}
                                    }
                                    afterInitialTreatmentStore.setQ3(
                                        {

                                            ...afterInitialTreatmentStore.q3,
                                            types: types
                                        }
                                    )
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3type2number'), 1)
                                }}/> : <></>}
                </RadioGroup>: <></>}
            </Stack>


        </Paper>
        </ClickAwayListener>
    );
}

export default OtherExamsMenuAfterInitiate;
