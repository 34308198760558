import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {RadioGroup, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import YesNo from "../../components/YesNo";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import RadioItem from "../../components/RadioItem";
import QuestionsLayout from "../../layout/QuestionsLayout";
import MissingReason from "../../components/MissingReason";


const TreatmentPatternsRadioFrequency = () => {
    const {
        treatmentPatternsStore, dataStore,menuStore
    } = useStores()
    const [showMissing, setShowMissing] = React.useState('not active');

    const navigate = useNavigate()
    let params = useParams();
    useEffect(() => {
        treatmentPatternsStore.getTreatment(parseInt(params.patient),parseInt(params.number))
    }, [])

    const handleNext = async () => {
        if (!treatmentPatternsStore.checkMissing5) {

            await treatmentPatternsStore.updateTreatment(
                {
                    q9: JSON.stringify({
                        ...treatmentPatternsStore.q9,
                        catheter: treatmentPatternsStore.q9?.main && treatmentPatternsStore.q9.main === 'true' ? treatmentPatternsStore.q9.catheter : null
                    }),
                    q10: treatmentPatternsStore.q9?.main && treatmentPatternsStore.q9.main === 'true' ? treatmentPatternsStore.q10 : '',
                }, parseInt(params.number),parseInt(params.patient))
            if(dataStore.forceUpdate == true){
                dataStore.setShowUpdateModal('not active')
            }
            if(dataStore.showUpdateModal !== 'active'){
                if(treatmentPatternsStore.q6?.main && treatmentPatternsStore.q6.main == 'true'){
                    navigate(`/systemicTherapyPage4/${parseInt(params.patient)}/${parseInt(params.number)}`)
                }else{
                    navigate(`/systemicTherapyPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
                }
            }

        } else {
            alert('Please add reason for missing questions.')

            // if (!dataStore.globalMissing) {
            //     if (showMissing === 'active') {
            //         await treatmentPatternsStore.updateTreatment(
            //             {
            //                 q9: treatmentPatternsStore.q9?.main ? JSON.stringify(treatmentPatternsStore.q9) : dataStore.returnOtherObjectMissing,
            //                 q10: treatmentPatternsStore.q10 ? treatmentPatternsStore.q10 : dataStore.returnOtherObjectMissing,
            //             }, parseInt(params.number),parseInt(params.patient))
            //         navigate(`/systemicTherapyPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            //         setShowMissing('not active')
            //     } else {
            //         setShowMissing('active')
            //     }
            // } else {
            //     await treatmentPatternsStore.updateTreatment(
            //         {
            //             q9: treatmentPatternsStore.q9?.main ? JSON.stringify(treatmentPatternsStore.q9) : dataStore.returnOtherObjectMissing,
            //             q10: treatmentPatternsStore.q10 ? treatmentPatternsStore.q10 : dataStore.returnOtherObjectMissing,
            //         }, parseInt(params.number),parseInt(params.patient))
            //     navigate(`/systemicTherapyPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            // }
        }
    }
    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={treatmentPatternsStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>
                    {parseInt(params.number) === 0 ?
                        <div>
                            <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                                Treatment patterns and healthcare resource use (Only Initial Treatment)
                            </Typography>
                            <Typography color={'txtGrey'} sx={{fontSize: 20,mt:1}}>
                                (Please complete this section only for initial treatment.)
                            </Typography>

                        </div> :
                        <div>
                            <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                                {`Treatment patterns and healthcare resource use for subsequent treatment n = ${params.number}`}
                            </Typography>
                            <Typography color={'txtGrey'} sx={{fontSize: 20,mt:1}}>
                                [Please complete this section for “Subsequent treatment (n=1, 2, …)”]
                            </Typography>
                        </div>
                    }
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>
                    <Typography sx={{fontWeight: 'bold', fontSize: 30,}}>
                        Radiofrequency or microwaves ablation
                    </Typography>
                    <QuestionBox>
                        <YesNo value={treatmentPatternsStore.q9.main}
                               handleChange={(event) => {
                                   console.log('event.target.value',event.target.value)
                                   dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q9'), 1)
                                   if(event.target.value === 'true'){
                                       treatmentPatternsStore.setQ9(
                                           {...treatmentPatternsStore.q9, main: event.target.value,})
                                   }else{
                                       treatmentPatternsStore.setQ9(
                                           {catheter: null, main: event.target.value,})

                                       treatmentPatternsStore.setQ10(null)
                                   }

                               }}
                        >
                            {dataStore.questionsMissing.includes('q9')
                                ? <MissingReason
                                    value={treatmentPatternsStore.q9.main}
                                    style={{right:60,marginTop:-2}}
                                    onSubmitMissing={async ()=>{
                                        treatmentPatternsStore.setQ9(
                                            {...treatmentPatternsStore.q9, main: dataStore.returnOtherTextMissing,})
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q9'), 1)
                                    }}/> : <></>}
                        </YesNo>

                        {treatmentPatternsStore.q9.main === 'true' &&
                            <Stack direction={'row'} spacing={1} sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0

                            }}>
                                <li style={{fontSize: 18}}>Catheter Ablation by
                                    {dataStore.questionsMissing.includes('q9catheter')
                                        ? <MissingReason
                                            value={treatmentPatternsStore.q9.catheter}
                                            onSubmitMissing={async ()=>{
                                                treatmentPatternsStore.setQ9(
                                                    {...treatmentPatternsStore.q9, catheter: dataStore.returnOtherTextMissing,}
                                                )
                                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q9catheter'), 1)
                                            }}/> : <></>}
                                </li>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={(event) => {
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q9catheter'), 1)
                                        treatmentPatternsStore.setQ9(
                                        {...treatmentPatternsStore.q9, catheter: event.target.value,}
                                    )}}
                                    value={treatmentPatternsStore.q9.catheter}
                                    sx={{display: 'flex', flexDirection: 'row', gap: "24px", rowGap: 0}}
                                >
                                    <RadioItem label={'Radiofrequency'}/>
                                    <RadioItem label={'Microwave'}/>
                                </RadioGroup>
                            </Stack>
                        }
                    </QuestionBox>
                    {treatmentPatternsStore.q9.main === 'true' && (treatmentPatternsStore.q9.catheter && !treatmentPatternsStore.q9.catheter.includes('missing')) &&
                        treatmentPatternsStore.q4.main === 'true' && <QuestionBox>
                        <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 25,}}>
                            {treatmentPatternsStore.q9.catheter && treatmentPatternsStore.q9.catheter === 'Radiofrequency' ? 'Radiofrequency' :
                                'Microwave'}
                        </Typography>
                        <Stack direction={'row'} spacing={1} sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0
                        }}>
                            <li style={{fontSize: 18}}>Performed
                                {dataStore.questionsMissing.includes('q10')
                                    ? <MissingReason
                                        value={treatmentPatternsStore.q10}
                                        onSubmitMissing={async ()=>{
                                            treatmentPatternsStore.setQ10(
                                                dataStore.returnOtherTextMissing
                                            )
                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q10'), 1)
                                        }}/> : <></>}
                            </li>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={(event) => {
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q10'), 1)
                                    treatmentPatternsStore.setQ10(
                                    event.target.value
                                )}}
                                value={treatmentPatternsStore.q10}
                                sx={{display: 'flex', flexDirection: 'row', gap: "24px", rowGap: 0}}
                            >
                                <RadioItem label={'Before Surgery'}/>
                                <RadioItem label={'After surgery'}/>
                                <RadioItem label={'No surgery performed at this point'}/>
                            </RadioGroup>
                        </Stack>
                    </QuestionBox>}
                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(TreatmentPatternsRadioFrequency);
