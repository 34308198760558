import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {Modal, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import YesNo from "../../components/YesNo";
import QuestionText from "../../components/QuestionText";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import QuestionsLayout from "../../layout/QuestionsLayout";
import theme from "../../styles/theme";
import MyButton from "../../components/MyButton";


const ExclusionCriteriaPage1 = () => {
    const {
        exclusionCriteriaStore,menuStore
    } = useStores()
    let urlParams = useParams();
    const [showNotEligibleError, setShowNotEligibleError] = React.useState(false);

    const navigate = useNavigate()

    useEffect(() => {
        exclusionCriteriaStore.getInclusionExclusion(parseInt(urlParams.patient))
    }, [])

    const handleNext = () => {
        if(exclusionCriteriaStore.completed){
            navigate('/socioDemographicCharacteristicsPage1/'+parseInt(urlParams.patient))
        }else{
            alert('You need to answer all questions')
        }
    }

    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={exclusionCriteriaStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>
                    <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                        Exclusion Criteria
                    </Typography>
                    <Typography sx={{fontSize: 25,}}>
                        The patient must not meet <strong>any</strong> of the following exclusion criteria to be eligible for
                        the
                        study.
                    </Typography>
                </Stack>
                <Stack direction={'column'} spacing={1} sx={{width: '100%', mt: 3}}>
                    <QuestionBox allInRow={true}>
                        <QuestionText sx={{fontWeight:'normal'}}>
                            Patients under 18 years of age
                        </QuestionText>
                        <YesNo value={exclusionCriteriaStore.q1}
                               disabled={exclusionCriteriaStore.completed}
                               handleChange={(event) => {
                                   if (event.target.value === 'true') {
                                       setShowNotEligibleError(true)
                                   }
                                   exclusionCriteriaStore.updateInclusionExclusion({q3: event.target.value},parseInt(urlParams.patient))
                               }}/>
                    </QuestionBox>

                    {exclusionCriteriaStore.q1 === false ? <QuestionBox allInRow={true}>
                        <QuestionText sx={{fontWeight:'normal'}}>
                            NSCLC cases determined only during autopsy
                        </QuestionText>
                        <YesNo value={exclusionCriteriaStore.q2}
                               disabled={exclusionCriteriaStore.completed}
                               handleChange={(event) => {
                                   if (event.target.value === 'true') {
                                       setShowNotEligibleError(true)
                                   }
                                   exclusionCriteriaStore.updateInclusionExclusion({q4: event.target.value},parseInt(urlParams.patient))
                               }}/>
                    </QuestionBox> : <div/>}

                    {exclusionCriteriaStore.q2 === false ? <QuestionBox allInRow={true}>

                        <QuestionText sx={{fontWeight:'normal'}}>
                            Patients with an unconfirmed diagnosis of NSCLC
                        </QuestionText>
                        <YesNo value={exclusionCriteriaStore.q3}
                               disabled={exclusionCriteriaStore.completed}
                               handleChange={(event) => {
                                   if (event.target.value === 'true') {
                                       setShowNotEligibleError(true)
                                   }
                                   exclusionCriteriaStore.updateInclusionExclusion({q5: event.target.value},parseInt(urlParams.patient))
                               }}/>
                    </QuestionBox> : <div/>}

                    {exclusionCriteriaStore.q3 === false ? <QuestionBox allInRow={true}>
                        <QuestionText sx={{fontWeight:'normal'}}>
                            Patients with stage IV NSCLC
                        </QuestionText>
                        <YesNo value={exclusionCriteriaStore.q4}
                               disabled={exclusionCriteriaStore.completed}
                               handleChange={(event) => {
                                   if (event.target.value === 'true') {
                                       setShowNotEligibleError(true)
                                   }
                                   exclusionCriteriaStore.updateInclusionExclusion({q6: event.target.value},parseInt(urlParams.patient))
                               }}/>
                    </QuestionBox> : <div/>}

                    {exclusionCriteriaStore.q4 === false ? <QuestionBox allInRow={true}>

                        <QuestionText sx={{fontWeight:'normal'}}>
                            Patients currently or previously enrolled in interventional clinical studies
                            related
                            to NSCLC </QuestionText>
                        <YesNo value={exclusionCriteriaStore.q5}
                               disabled={exclusionCriteriaStore.completed}
                               handleChange={(event) => {
                                   if (event.target.value === 'true') {
                                       setShowNotEligibleError(true)
                                   }
                                   exclusionCriteriaStore.updateInclusionExclusion({q7: event.target.value},parseInt(urlParams.patient))
                               }}/>
                    </QuestionBox> : <div/>}

                    {exclusionCriteriaStore.q5 === false ? <QuestionBox allInRow={true}>

                        <QuestionText sx={{fontWeight:'normal'}}>
                            Patients with a diagnosis of unresectable stage III NSCLC
                        </QuestionText>
                        <YesNo value={exclusionCriteriaStore.q6}
                               disabled={exclusionCriteriaStore.completed}
                               handleChange={
                                   (event) => {
                                       exclusionCriteriaStore.updateInclusionExclusion({q8: event.target.value},parseInt(urlParams.patient))
                                       if (event.target.value === 'false') {
                                           navigate('/socioDemographicCharacteristicsPage1/'+parseInt(urlParams.patient))
                                       }
                                   }}/>
                    </QuestionBox> : <div/>}


                </Stack>
            </Box>

            <Modal
                open={showNotEligibleError}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '53%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    height:200,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius:5,
                    justifyContent:'center',
                    display:'flex',
                    alignItems:'center',
                    flexDirection:'column'
                }}>
                    <Typography id="modal-modal-title" variant="h5" component="h3" fontWeight="bold">
                        Are you sure patient is not eligible?
                    </Typography>
                    <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',mt:4}}>
                        <MyButton sx={{px: 6,mr:5}} label={'YES'} onClick={()=>navigate('/addData')}/>
                        <MyButton sx={{px: 6}} label={'NO'} onClick={()=>setShowNotEligibleError(false)}/>
                    </Box>
                </Box>
            </Modal>
        </QuestionsLayout>
    );
}

export default observer(ExclusionCriteriaPage1);
