import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';

import Grid from '@mui/material/Grid';
import {Checkbox, FormControlLabel, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import TextField from "@mui/material/TextField";
import MyButton from "../../components/MyButton";
import QuestionsLayout from "../../layout/QuestionsLayout";
import Joi from "joi";
import {toJS} from "mobx";
import MissingReason from "../../components/MissingReason";

const ImagingAssessmentsAfterInitialTreatmentPage2 = () => {
    const {
        afterInitialTreatmentStore, dataStore, menuStore
    } = useStores()
    const [showMissing, setShowMissing] = React.useState('not active');

    let params = useParams();
    useEffect(() => {
        afterInitialTreatmentStore.getAfterTreatment(parseInt(params.patient), parseInt(params.number))
    }, [])
    const navigate = useNavigate()
    const [otherOn, setOtherOn] = React.useState(false);

    const NrSchema = Joi.number().min(1).max(10).integer().required();
    const TypesSchema = Joi.array().items(NrSchema).optional();

    const handleNext = async () => {
        let jsTypes = toJS(afterInitialTreatmentStore.q1.types)
        let types = jsTypes.filter(t => t.selected === 'on').map((t) => t.number);

        if (!afterInitialTreatmentStore.checkMissing1_1) {
            if (types.length > 0 && TypesSchema.validate(types).error) {
                if (!window.confirm("Do you confirm this value?")) {
                    return;
                }
            }
            await afterInitialTreatmentStore.updateAfterTreatment(
                {
                    q1: JSON.stringify(afterInitialTreatmentStore.q1),
                }, parseInt(params.number), parseInt(params.patient))
            if (dataStore.forceUpdate === true) {
                dataStore.setShowUpdateModal('not active')
            }
            if (dataStore.showUpdateModal !== 'active') {
                navigate(`/laboratoryTestsAfterInitialTreatmentPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            }

        } else {
            alert('Please add reason for missing questions.')

            // if (!dataStore.globalMissing) {
            //     if (showMissing === 'active') {
            //         await afterInitialTreatmentStore.updateAfterTreatment(
            //             {
            //                 q1: afterInitialTreatmentStore.q1?.main ? JSON.stringify(afterInitialTreatmentStore.q1) : dataStore.returnOtherObjectMissing,
            //             }, parseInt(params.number),parseInt(params.patient))
            //         navigate(`/laboratoryTestsAfterInitialTreatmentPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            //         setShowMissing('not active')
            //     } else {
            //         setShowMissing('active')
            //     }
            // } else {
            //     await afterInitialTreatmentStore.updateAfterTreatment(
            //         {
            //             q1: afterInitialTreatmentStore.q1?.main ? JSON.stringify(afterInitialTreatmentStore.q1) : dataStore.returnOtherObjectMissing,
            //         }, parseInt(params.number),parseInt(params.patient))
            //     navigate(`/laboratoryTestsAfterInitialTreatmentPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            // }
        }
    }

    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={afterInitialTreatmentStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>

                    {parseInt(params.number) === 0 ?
                        <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                            Health Care Resource utilization after treatment completion or discontinuation
                        </Typography> :
                        <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                            {`Health Care Resource utilization after completion or discontinuation of treatment n = ${params.number}`}
                        </Typography>
                    }
                    <Typography color={'txtGrey'} sx={{fontWeight: 'bold', fontSize: 18,}}>
                        [Please complete this section only during the time between treatments. Subsequent treatment data to be collected in section “Subsequent Treatment (n=2, 3, x ….)”.]
                    </Typography>
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>
                    <Typography sx={{fontWeight: 'bold', fontSize: 30,}}>
                        Imaging Assessments
                        {dataStore.questionsMissing.includes('q1types')
                            ? <MissingReason
                                value={afterInitialTreatmentStore.q1.missingTypesReason}
                                onSubmitMissing={async ()=>{
                                    afterInitialTreatmentStore.setQ1({
                                        ...afterInitialTreatmentStore.q1,
                                        missingTypesReason: dataStore.returnOtherTextMissing,
                                    })
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q1'), 1)
                                }}/> : <></>}
                    </Typography>
                    <QuestionBox>
                        {afterInitialTreatmentStore.q1.main ?
                            <Box
                                sx={{
                                    display: 'flex', flex: 1,
                                    flexDirection: 'column', width: '100%',
                                }}
                            >
                                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                                    {afterInitialTreatmentStore.q1.types.map((item, index) => {
                                        let types = afterInitialTreatmentStore.q1.types
                                        return (
                                            <Grid item xs={6}>
                                                <Box sx={{
                                                    maxWidth: '420px',
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "space-between"
                                                }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={(event) => {
                                                                    console.log('item.selected', item.selected)
                                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q1'), 1)

                                                                    if (event.target.value === 'on' && item.selected === 'on') {
                                                                        types[index].selected = null
                                                                        types[index].numner = ''
                                                                        afterInitialTreatmentStore.setQ1(
                                                                            {
                                                                                ...afterInitialTreatmentStore.q1,
                                                                                types: types,
                                                                                missingTypesReason:null

                                                                            }
                                                                        )
                                                                    } else {
                                                                        types[index] = {
                                                                            ...item,
                                                                            selected: event.target.value
                                                                        }
                                                                        afterInitialTreatmentStore.setQ1(
                                                                            {
                                                                                ...afterInitialTreatmentStore.q1,
                                                                                types: types,
                                                                                missingTypesReason:null

                                                                            }
                                                                        )
                                                                    }

                                                                }}
                                                                checked={item.selected}
                                                            />
                                                        }
                                                        sx={{
                                                            '& .MuiFormControlLabel-label': {fontSize: 18},

                                                        }}
                                                        label={item.name}
                                                    />

                                                    {item.selected &&
                                                        <Box sx={{display: "flex", flexDirection: "column"}}>
                                                            <Stack direction={'row'} spacing={2}
                                                                   sx={{display: 'flex', alignItems: 'center'}}>
                                                                <Typography>
                                                                    {dataStore.questionsMissing.includes(item.name)
                                                                        ? <MissingReason
                                                                            value={types[index].selected ? types[index].selected : types[index].number}
                                                                            style={{marginLeft:-80,marginTop:-9}}
                                                                            onSubmitMissing={()=>{
                                                                                types[index].selected = dataStore.returnOtherTextMissing
                                                                                types[index].numner = dataStore.returnOtherTextMissing
                                                                                afterInitialTreatmentStore.setQ1(
                                                                                    {
                                                                                        ...afterInitialTreatmentStore.q1,
                                                                                        types: types
                                                                                    }
                                                                                )
                                                                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf(item.name), 1)
                                                                            }}/> : <></>}

                                                                    Number:</Typography>
                                                                <TextField
                                                                    size={'small'}
                                                                    placeholder={'000'}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    sx={{width: 100}}
                                                                    value={item?.number && item.number.startsWith('missing|') ? '': item.number}

                                                                    error={NrSchema.validate(item.number).error}
                                                                    onChange={(event) => {
                                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf(item.name), 1)

                                                                        types[index] = {
                                                                            ...item,
                                                                            number: event.target.value
                                                                        }
                                                                        afterInitialTreatmentStore.setQ1(
                                                                            {
                                                                                ...afterInitialTreatmentStore.q1,
                                                                                types: types
                                                                            }
                                                                        )
                                                                    }}
                                                                />
                                                            </Stack>
                                                            {item.number !== '' && NrSchema.validate(item.number).error &&
                                                                <Typography
                                                                    variant="caption" color="error"
                                                                    sx={{alignSelf: "flex-end"}}>Between 1
                                                                    and 10</Typography>}
                                                        </Box>
                                                    }
                                                </Box>
                                            </Grid>
                                        )
                                    })}
                                </Grid>

                                <Stack sx={{
                                    display: 'flex',
                                }} direction={'row'} spacing={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(event) => otherOn === 'on' ? setOtherOn(false) : setOtherOn(event.target.value)}
                                                checked={otherOn}
                                            />
                                        }
                                        sx={{
                                            '& .MuiFormControlLabel-label': {fontSize: 18},
                                        }}
                                        label="Other"
                                    />
                                    {otherOn ?
                                        <>
                                            <TextField
                                                size={'small'}
                                                label="Specify Here"
                                                name="otherTumorPage1"
                                                sx={{width: '200px', mr: 2}}
                                                onChange={(event) => afterInitialTreatmentStore.setNewImageAssessmentText(event.target.value)}
                                                value={afterInitialTreatmentStore?.newImageAssessmentText && afterInitialTreatmentStore.newImageAssessmentText.startsWith('missing|') ? '': afterInitialTreatmentStore.newImageAssessmentText}

                                            />
                                            <MyButton sx={{px: 4, py: 0.5}} label={'Add'}
                                                      disabled={afterInitialTreatmentStore.newImageAssessmentText === ''}
                                                      onClick={afterInitialTreatmentStore.addImageAssessmentType}/>
                                        </>
                                        : null}
                                </Stack>
                            </Box> : <div/>}
                    </QuestionBox>

                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(ImagingAssessmentsAfterInitialTreatmentPage2);
