import {Stores, useStores,} from '../../store'
import {useEffect, useState} from 'react';
import {RadioGroup, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import QuestionText from "../../components/QuestionText";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import RadioItem from "../../components/RadioItem";
import TextField from "@mui/material/TextField";
import MaleFemale from "../../components/MaleFemale";
import QuestionsLayout from "../../layout/QuestionsLayout";
import Required from "../../components/Required";
import MissingReason from "../../components/MissingReason";


const SocioDemographicCharacteristicsPage1 = () => {
    const {
        socioDemographicCharacteristicsStore, dataStore,menuStore
    } = useStores()
    const [showMissing, setShowMissing] = useState('not active');
    const [showUpdate, setShowUpdate] = useState('not active');
    let urlParams = useParams();

    const navigate = useNavigate()

    useEffect(() => {
        socioDemographicCharacteristicsStore.getSocioDemographic(parseInt(urlParams.patient))
    }, [])

    const handleNext = async () => {
        await socioDemographicCharacteristicsStore.checkMissing1()
        if (!socioDemographicCharacteristicsStore.questionsMissing) {
            if (socioDemographicCharacteristicsStore.q1 !== null && socioDemographicCharacteristicsStore.q3 !== null && socioDemographicCharacteristicsStore.q4 !== null) {
                await socioDemographicCharacteristicsStore.updateSocioDemographic(
                    {
                        q1: socioDemographicCharacteristicsStore.q1,
                        q2: socioDemographicCharacteristicsStore.q2,
                        q3: socioDemographicCharacteristicsStore.q3,
                        q4: socioDemographicCharacteristicsStore.q4
                    }, parseInt(urlParams.patient))
                if (dataStore.forceUpdate == true) {
                    dataStore.setShowUpdateModal('not active')
                }
                if (dataStore.showUpdateModal !== 'active') {
                    navigate('/clinicalHistoryPage1/' + parseInt(urlParams.patient))
                }
            } else {
                alert('Please answer all required questions.')
            }
        }else{
            alert('Please add reason for missing questions.')

        }

        // if (showMissing === 'active') {
        //     socioDemographicCharacteristicsStore.setQ2(dataStore.returnOtherTextMissing)
        //     await socioDemographicCharacteristicsStore.updateSocioDemographic(
        //         {
        //             q2: dataStore.returnOtherTextMissing,
        //         }, parseInt(urlParams.patient))
        //     setShowMissing('not active')
        //     socioDemographicCharacteristicsStore.setQuestionsMissing(false)
        // }else{
        //     setShowMissing('not active')
        //     dataStore.setShowUpdateModal('not active')
        // }


        // } else {
        //     if (!dataStore.globalMissing) {
        //         if (showMissing === 'active') {
        //             await socioDemographicCharacteristicsStore.updateSocioDemographic(
        //                 {
        //                     q1: socioDemographicCharacteristicsStore.q1 ? socioDemographicCharacteristicsStore.q1 : dataStore.returnOtherTextMissing,
        //                     q2: socioDemographicCharacteristicsStore.q2 ? socioDemographicCharacteristicsStore.q2 : dataStore.returnOtherTextMissing,
        //                     q3: socioDemographicCharacteristicsStore.q3 ? socioDemographicCharacteristicsStore.q3 : dataStore.returnOtherTextMissing,
        //                     q4: socioDemographicCharacteristicsStore.q4 ? socioDemographicCharacteristicsStore.q4 : dataStore.returnOtherTextMissing,
        //                 },parseInt(urlParams.patient))
        //             navigate('/clinicalHistoryPage1/'+parseInt(urlParams.patient))
        //             setShowMissing('not active')
        //         } else {
        //             setShowMissing('active')
        //
        //         }
        //     } else {
        //         await socioDemographicCharacteristicsStore.updateSocioDemographic(
        //             {
        //                 q1: socioDemographicCharacteristicsStore.q1 ? socioDemographicCharacteristicsStore.q1 : dataStore.returnOtherTextMissing,
        //                 q2: socioDemographicCharacteristicsStore.q2 ? socioDemographicCharacteristicsStore.q2 : dataStore.returnOtherTextMissing,
        //                 q3: socioDemographicCharacteristicsStore.q3 ? socioDemographicCharacteristicsStore.q3 : dataStore.returnOtherTextMissing,
        //                 q4: socioDemographicCharacteristicsStore.q4 ? socioDemographicCharacteristicsStore.q4 : dataStore.returnOtherTextMissing,
        //             },parseInt(urlParams.patient))
        //         navigate('/clinicalHistoryPage1/'+parseInt(urlParams.patient))
        //     }
        // }
    }

    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={socioDemographicCharacteristicsStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>
                    <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                        Socio-Demographic Characteristics at diagnosis
                    </Typography>
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>
                    <QuestionBox>
                        <QuestionText>
                            Age group at study inclusion <Required/>
                        </QuestionText>

                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(event) => socioDemographicCharacteristicsStore.setQ1(event.target.value)}
                            value={socioDemographicCharacteristicsStore.q1}
                            sx={{display: 'flex', flexDirection: 'row', gap: "24px", rowGap: 0}}
                        >
                            <RadioItem label={'18-29 years'}/>
                            <RadioItem label={'30-39 years'}/>
                            <RadioItem label={'40-49 years'}/>
                            <RadioItem label={'50-59 years'}/>
                            <RadioItem label={'60-69 years'}/>
                            <RadioItem label={'70-79 years'}/>
                            <RadioItem label={'≥80 years'}/>
                        </RadioGroup>
                    </QuestionBox>
                    <QuestionBox>

                        <QuestionText>
                            Geographic area of referral (city)
                                {socioDemographicCharacteristicsStore.questionsMissing === true
                                    ? <MissingReason
                                        value={socioDemographicCharacteristicsStore.q2}
                                        onSubmitMissing={async ()=>{
                                            // socioDemographicCharacteristicsStore.setQ2(dataStore.returnOtherTextMissing)
                                            socioDemographicCharacteristicsStore.setQ2(dataStore.returnOtherTextMissing)
                                            // await socioDemographicCharacteristicsStore.updateSocioDemographic(
                                            //     {
                                            //         q2: dataStore.returnOtherTextMissing,
                                            //     }, parseInt(urlParams.patient))
                                            socioDemographicCharacteristicsStore.setQuestionsMissing(false)
                                        }}/> : <></>}
                        </QuestionText>

                        <TextField
                            size={'small'}
                            label="Write your answer here"
                            name="GeographicReferral"
                            sx={{width: '100%', mt: 2}}
                            value={socioDemographicCharacteristicsStore.q2 && socioDemographicCharacteristicsStore.q2.startsWith('missing|') ? '': socioDemographicCharacteristicsStore.q2}
                            onChange={(event) => {
                                socioDemographicCharacteristicsStore.setQ2(event.target.value)
                                socioDemographicCharacteristicsStore.setQuestionsMissing(false)
                            }}
                        />

                    </QuestionBox>
                    <QuestionBox allInRow={true}>
                        <QuestionText>
                            Gender <Required/>
                        </QuestionText>
                        <MaleFemale
                            value={socioDemographicCharacteristicsStore.q3}
                            handleChange={(event) => socioDemographicCharacteristicsStore.setQ3(event.target.value)}/>
                    </QuestionBox>
                    <QuestionBox>
                        <QuestionText>
                            Education level <Required/>
                        </QuestionText>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(event) => socioDemographicCharacteristicsStore.setQ4(event.target.value)}
                            value={socioDemographicCharacteristicsStore.q4}
                            sx={{display: 'flex', flexDirection: 'row', gap: "24px", rowGap: 0}}
                        >
                            <RadioItem sx={{width:'30%'}} label={'Never attended school'}/>
                            <RadioItem sx={{width:'30%'}} label={'Elementary school (4th grade)'}/>
                            <RadioItem sx={{width:'30%'}} label={'Middle school (9th grade)'}/>
                            <RadioItem sx={{width:'30%'}} label={'High school (12th grade)'}/>
                            <RadioItem sx={{width:'30%'}} label={'University or higher'}/>
                            <RadioItem sx={{width:'30%'}} label={'Unknown'}/>
                        </RadioGroup>
                    </QuestionBox>
                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(SocioDemographicCharacteristicsPage1);
