/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExportAreaSchema } from '../models/ExportAreaSchema';
import type { ExportInSchema } from '../models/ExportInSchema';
import type { FileMessage } from '../models/FileMessage';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ExportService {

    /**
     * Get export areas
     * Get export areas
     * @returns ExportAreaSchema OK
     * @throws ApiError
     */
    public static getExportAreas(): CancelablePromise<ExportAreaSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/export_db/areas',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Export data
     * Export data
     * @param requestBody
     * @returns FileMessage OK
     * @throws ApiError
     */
    public static export(
        requestBody: ExportInSchema,
    ): CancelablePromise<FileMessage> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/export_db/export',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
