import * as React from 'react';
import QuestionWrapper from "../components/QuestionWrapper";
import QuestionHeader from "../components/QuestionHeader";
import QuestionFooter from "../components/QuestionFooter";
import Layout from "./Layout";

const QuestionsLayout = ({
                             children,
                             activeItem,
                             sx,
                             showMissing,
                             onCloseMissing,
                             questionHeaderProps,
                             questionFooterProps,
                             backPress,
                             nextPress,
                             hideFooter,
                             loading,
                             disabled,
                             showMissingForce,
                             overflow,
                             // showUpdateModal,
                             // onCloseUpdate,
                         }) => {
    return (
        <Layout sx={sx} activeItem={activeItem} loading={loading} overflow={overflow}>
            <QuestionWrapper
                showMissing={showMissing}
                onClose={onCloseMissing} onSubmitMissing={nextPress} disabled={disabled}>
                <QuestionHeader {...questionHeaderProps}/>
                {children}
                {!hideFooter && <QuestionFooter showMissingForce={showMissingForce} onSubmitMissing={nextPress} backPress={backPress} nextPress={nextPress} {...questionFooterProps}/>}
            </QuestionWrapper>

        </Layout>
    )
}

export default QuestionsLayout
