import * as React from 'react';
import {FormControlLabel, Radio} from "@mui/material";


const RadioItem = ({value,label, sx,fontSize}) => {

    return (
        <FormControlLabel sx={sx} componentsProps={{
            typography: {sx: {fontSize: fontSize?fontSize:20}}
        }} value={value ? value : label} control={<Radio/>} label={label}/>
    );
}

export default RadioItem;
