import {action, makeObservable, observable,} from 'mobx'
import {CrfService} from "../../services/openapi";
import {Stores} from "../../store";

class ClinicalCharacterizationStore {

    loading = false
    q1 = ''
    q2 = {
        type: null,
        value: '',
    }
    q3 = null
    q4 = {
        r1: null,
        r2: null,
        r3: null,
    }
    q5 = null
    q6 = null

    constructor() {

        makeObservable(this, {
            loading: observable,
            q1: observable,
            q2: observable,
            q3: observable,
            q4: observable,
            q5: observable,
            q6: observable,
            setQ1: action,
            setQ2: action,
            setQ3: action,
            setQ4: action,
            setQ5: action,
            setQ6: action,
            setLoading: action,
            updateClinicalCharacterization: action,
            getClinicalCharacterization: action,
            reset: action,
        },)
    }

    reset = () => {
        this.setQ1('')
        this.setQ2({
            type: null,
            value: '',
        })
        this.setQ3(null)
        this.setQ4({
            r1: null,
            r2: null,
            r3: null,
        })
        this.setQ5(null)
        this.setQ6(null)

    }

    get checkMissing1() {
         if(this.q1 === ''){
             Stores.dataStore.setQuestionsMissing('q1')
        }
         if(this.q2.type === null) {
             Stores.dataStore.setQuestionsMissing('q2')
         }
         if(this.q3 === null) {
             Stores.dataStore.setQuestionsMissing('q3')
         }
         if(this.q2.type !== null && !this.q2.type.includes('missing') && this.q2.value === ''){
             Stores.dataStore.setQuestionsMissing('q2value')
             return true
        }

        if(this.q1 === '' || this.q2.type === null || this.q3 === null) {
            return true
        }

         return false

    }

    get checkMissing2() {
        if(this.q5 == null){
            Stores.dataStore.setQuestionsMissing('q5')

            return true
        }

    }

    get checkMissingQ4Combination() {
        return this.q4 == null || this.q4.r1 == null || this.q4.r2 == null || this.q4.r3 == null || this.q5 == null;
    }

    setQ1 = (value) => {
        this.q1 = value
    }

    setQ2 = (value) => {
        this.q2 = value
    }
    setQ3 = (value) => {
        this.q3 = value
    }
    setQ4 = (value) => {
        this.q4 = value
    }
    setQ5 = (value) => {
        this.q5 = value
    }
    setQ6 = (value) => {
        this.q6 = value
    }


    setLoading = (value) => {
        this.loading = value
    }

    updateClinicalCharacterization = async (requestBody,patient) => {
        try {
            this.setLoading(true)
            console.log('requestBody', requestBody)
            let params = {...requestBody,force:Stores.dataStore.forceUpdate}

            if(Stores.dataStore.updateReason !== ''){
                params = {...params, reason:Stores.dataStore.updateReason}
            }
            console.log('updateClinicalCharacterization params', params)
            const response = await CrfService.updateClinicalCharacterization(patient, params)
            console.log('updateClinicalCharacterization response', response)
            if (response.q1 != null) {
                this.setQ1(response.q1)
            }
            if (response.q2 != null) {
                this.setQ2(response.q2)
            }
            if (response.q3 != null) {
                this.setQ3(response.q3)
            }
            if (response.q4 != null) {
                this.setQ4(response.q4)
            }
            if (response.q5 != null) {
                this.setQ5(response.q5)
            }
            Stores.dataStore.resetUpdateModalData()

            this.setLoading(false)

        } catch (err) {
            this.setLoading(false)
            console.log('updateClinicalCharacterization err', err)
            if(err?.body?.data?.updated){
                Stores.dataStore.setShowUpdateModal('active')
            }else {
                Stores.dataStore.setShowUpdateModal('not active')
            }
        }
    }


    getClinicalCharacterization = async (patient) => {
        try {
            this.setLoading(true)

            const response = await CrfService.getClinicalCharacterization(patient)
            console.log('getClinicalCharacterization response', response)
            if (response.q1 != null) {
                this.setQ1(response.q1)
                if(response.q1.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q1')
                }
            }
            if (response.q2 != null) {
                this.setQ2(response.q2)
                if(response.q2?.type && response.q2.type.startsWith('missing|')) {
                    Stores.dataStore.setQuestionsMissing('q2')
                }

                if(response.q2?.value && response.q2.value.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q2value')
                }
            }
            if (response.q3 != null) {
                this.setQ3(response.q3)
                if(response.q3.startsWith('missing|')) {
                    Stores.dataStore.setQuestionsMissing('q3')
                }
            }
            if (response.q4 != null) {
                this.setQ4(response.q4)
            }
            if (response.q5 != null) {
                this.setQ5(response.q5)
                if(response.q5.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q5')
                }
            }

            Stores.dataStore.setForceUpdate(false)

            this.setLoading(false)

        } catch (err) {
            this.setLoading(false)
            console.log('getClinicalCharacterization err', err)
        }
    }


    checkQ4Combinations = () =>{
        let userInputCombination = `${this.q4.r1}-${this.q4.r2}-${this.q4.r3}`
        console.log('userInputCombination',userInputCombination)
        let combinations = [
            'IA-T1-N0',
            'IA-Tx-Nx',
            'IA-T1-Nx',
            'IA-Tx-N0',
            'IB-T2-N0',
            'IB-Tx-Nx',
            'IB-T2-Nx',
            'IB-Tx-N0',
            'IIA-T1-N1',
            'IIA-T1-Nx',
            'IIA-T2-N1',
            'IIA-Tx-N1',
            'IIA-T2-N0',
            'IIA-Tx-Nx',
            'IIA-T2-Nx',
            'IIA-Tx-N0',
            'IIB-T2-N1',
            'IIB-T2-Nx',
            'IIB-Tx-N1',
            'IIB-T3-N0',
            'IIB-Tx-Nx',
            'IIB-T3-Nx',
            'IIB-Tx-N0',
            'IIIA-T1-N2',
            'IIIA-T1-Nx',
            'IIIA-T2-N2',
            'IIIA-T2-Nx',
            'IIIA-Tx-N2',
            'IIIA-T3-N1',
            'IIIA-T3-Nx',
            'IIIA-Tx-N1',
            'IIIA-T4-N0',
            'IIIA-T4-Nx',
            'IIIA-Tx-N0',
            'IIIA-T4-N1',
            'IIIA-Tx-Nx',
            'IIIB-T1-N3',
            'IIIB-T1-Nx',
            'IIIB-Tx-N3',
            'IIIB-T2-N3',
            'IIIB-T2-Nx',
            'IIIB-T3-N2',
            'IIIB-T3-Nx',
            'IIIB-Tx-N2',
            'IIIB-T4-N2',
            'IIIB-Tx-Nx',
            'IIIB-T4-Nx',
            'IIIC-T3-N3',
            'IIIC-Tx-Nx',
            'IIIC-T3-Nx',
            'IIIC-Tx-N3',
            'IIIC-T4-N3',
            'IIIC-T4-Nx',
        ]
        let userInputCorrect = combinations.find((c)=>c === userInputCombination)
        console.log('userInputCorrect',userInputCorrect)
        return userInputCorrect

    }


}


export default ClinicalCharacterizationStore
