import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {Checkbox, FormControlLabel, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import YesNo from "../../components/YesNo";
import QuestionText from "../../components/QuestionText";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import TextField from "@mui/material/TextField";
import QuestionsLayout from "../../layout/QuestionsLayout";
import MissingReason from "../../components/MissingReason";

const invalidChars = [
    "-",
    "+",
    "e",
];

const HospitalizationsPage1 = () => {
    const {
        treatmentPatternsStore, dataStore,menuStore
    } = useStores()
    let params = useParams();
    useEffect(() => {
        (async () => {
            await treatmentPatternsStore.getTreatment(parseInt(params.patient), parseInt(params.number))
            if (parseInt(treatmentPatternsStore.q20.numberOfSurgery) !== treatmentPatternsStore.q21.length) {
                for (let i = 0; i < parseInt(treatmentPatternsStore.q20.numberOfSurgery) - 1; i++) {
                    console.log('response.q20.numberOfSurgery', treatmentPatternsStore.q20.numberOfSurgery)
                    treatmentPatternsStore.q21.push({
                        ward: {selected: null, amount: ''},
                        intermediateCareUnit: {selected: null, amount: ''},
                        intensiveCareUnit: {selected: null, amount: ''},
                    })
                }
            }

        })();
    }, [])
    const navigate = useNavigate()
    const [selectedSurgery, setSelectedSurgery] = React.useState(1);
    const [showMissing, setShowMissing] = React.useState('not active');

    const handleNext = async () => {
        if (parseInt(treatmentPatternsStore.q20.numberOfSurgery) > selectedSurgery) {
            setSelectedSurgery(selectedSurgery + 1)
            return
        }
        if (!treatmentPatternsStore.checkMissing10) {
            await treatmentPatternsStore.updateTreatment(
                {
                    q20: JSON.stringify(treatmentPatternsStore.q20),
                    q21: JSON.stringify(treatmentPatternsStore.q21),
                }, parseInt(params.number),parseInt(params.patient))

            if(dataStore.forceUpdate === true){
                dataStore.setShowUpdateModal('not active')
            }
            if(dataStore.showUpdateModal !== 'active'){
                navigate(`/diseaseRelatedPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            }

        } else {
            alert('Please add reason for missing questions.')

            // if (!dataStore.globalMissing) {
            //     if (showMissing === 'active') {
            //         await treatmentPatternsStore.updateTreatment(
            //             {
            //                 q20: treatmentPatternsStore.q20?.main ? JSON.stringify(treatmentPatternsStore.q20) : dataStore.returnOtherObjectMissing,
            //                 q21: treatmentPatternsStore.q21?.main ? JSON.stringify(treatmentPatternsStore.q21) : dataStore.returnOtherObjectMissing,
            //             }, parseInt(params.number),parseInt(params.patient))
            //         navigate(`/diseaseRelatedPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            //         setShowMissing('not active')
            //     } else {
            //         setShowMissing('active')
            //     }
            // } else {
            //     await treatmentPatternsStore.updateTreatment(
            //         {
            //             q20: treatmentPatternsStore.q20?.main ? JSON.stringify(treatmentPatternsStore.q20) : dataStore.returnOtherObjectMissing,
            //             q21: treatmentPatternsStore.q21?.main ? JSON.stringify(treatmentPatternsStore.q21) : dataStore.returnOtherObjectMissing,
            //         }, parseInt(params.number),parseInt(params.patient))
            //     navigate(`/diseaseRelatedPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            // }
        }
    }

    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => {
                             if (selectedSurgery === 1) {
                                 navigate(-1)
                             } else {
                                 setSelectedSurgery(selectedSurgery - 1)
                             }
                         }}
                         loading={treatmentPatternsStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>
                    {parseInt(params.number) === 0 ?
                        <div>
                            <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                                Treatment patterns and healthcare resource use (Only Initial Treatment)
                            </Typography>
                            <Typography color={'txtGrey'} sx={{fontSize: 20,mt:1}}>
                                (Please complete this section only for initial treatment.)
                            </Typography>

                        </div> :
                        <div>
                            <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                                {`Treatment patterns and healthcare resource use for subsequent treatment n = ${params.number}`}
                            </Typography>
                            <Typography color={'txtGrey'} sx={{fontSize: 20,mt:1}}>
                                [Please complete this section for “Subsequent treatment (n=1, 2, …)”]
                            </Typography>
                        </div>
                    }
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>
                    <Typography sx={{fontWeight: 'bold', fontSize: 30,}}>
                        Hospitalizations
                    </Typography>
                    <QuestionBox allInRow={true}>
                        <YesNo value={treatmentPatternsStore.q20.main}
                               handleChange={(event) => {
                                   dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q20'), 1)

                                   if (event.target.value === 'false') {
                                       treatmentPatternsStore.setQ20(
                                           {...treatmentPatternsStore.q20, numberOfSurgery: '',}
                                       )
                                       treatmentPatternsStore.setQ21(
                                           [{
                                               ward: {selected: null, amount: ''},
                                               intermediateCareUnit: {selected: null, amount: ''},
                                               intensiveCareUnit: {selected: null, amount: ''},
                                           }]
                                       )
                                   }
                                   treatmentPatternsStore.setQ20(
                                       {...treatmentPatternsStore.q20, main: event.target.value,})
                               }}
                        >
                            {dataStore.questionsMissing.includes('q20')
                                ? <MissingReason
                                    value={treatmentPatternsStore.q20.main}
                                    style={{right:60,marginTop:-3}}
                                    onSubmitMissing={()=>{
                                        treatmentPatternsStore.setQ20(
                                            {...treatmentPatternsStore.q20, main:dataStore.returnOtherTextMissing,})
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q20'), 1)
                                    }}/> : <></>}
                        </YesNo>
                        {treatmentPatternsStore.q20.main === 'true' ?<Stack direction={'row'} sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography sx={{fontSize: 18, mr: 5}}>
                                {dataStore.questionsMissing.includes('q20numberOfSurgery') ?
                                    <MissingReason
                                        value={treatmentPatternsStore.q20.numberOfSurgery}
                                        style={{marginLeft:-80,marginTop:-9}}
                                        onSubmitMissing={()=>{
                                            treatmentPatternsStore.setQ20(
                                                {
                                                    ...treatmentPatternsStore.q20,
                                                    numberOfSurgery: dataStore.returnOtherTextMissing,
                                                }
                                            )
                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q20numberOfSurgery'), 1)
                                        }}/>
                                    : <></>}
                                Number of hospitalizations
                            </Typography>
                            <TextField
                                size={'small'}
                                type={"number"}
                                sx={{width: 100}}
                                inputProps={{min: 0, max: 10, style: {textAlign: 'center'}}}
                                onKeyDown={(e) => {
                                    if (invalidChars.includes(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                onWheel={(e) => e.target.blur()}
                                onChange={(event) => {
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q20numberOfSurgery'), 1)

                                    setSelectedSurgery(1)
                                    treatmentPatternsStore.setQ20(
                                        {
                                            ...treatmentPatternsStore.q20,
                                            numberOfSurgery: event.target.value,
                                        }
                                    )
                                    if (treatmentPatternsStore.q21.length > event.target.value) {
                                        let numberOfSurgeryRemove = treatmentPatternsStore.q21.length - treatmentPatternsStore.q20.numberOfSurgery
                                        for (let i = 0; i < numberOfSurgeryRemove; i++) {
                                            treatmentPatternsStore.q21.pop()
                                        }
                                    } else if (event.target.value > treatmentPatternsStore.q21.length) {
                                        let numberOfSurgeryAdd = treatmentPatternsStore.q20.numberOfSurgery - treatmentPatternsStore.q21.length
                                        for (let i = 0; i < numberOfSurgeryAdd; i++) {
                                            treatmentPatternsStore.q21.push({
                                                ward: {selected: null, amount: ''},
                                                intermediateCareUnit: {selected: null, amount: ''},
                                                intensiveCareUnit: {selected: null, amount: ''},
                                            })
                                        }
                                    }
                                    treatmentPatternsStore.setQ21(
                                        treatmentPatternsStore.q21
                                    )
                                }}
                                value={treatmentPatternsStore.q20?.numberOfSurgery && treatmentPatternsStore.q20.numberOfSurgery.startsWith('missing|') ? '': treatmentPatternsStore.q20.numberOfSurgery}

                            />
                        </Stack>:<></>}
                    </QuestionBox>
                    {treatmentPatternsStore.q20?.main && treatmentPatternsStore.q20.main === 'true' &&
                        treatmentPatternsStore.q20.numberOfSurgery !== '' && !treatmentPatternsStore.q20.numberOfSurgery.includes('missing') &&
                        treatmentPatternsStore.q21.map((item, index) => {
                            let value = null
                            if(treatmentPatternsStore.q21[index]?.ward && typeof treatmentPatternsStore.q21[index].ward.selected === 'string' && treatmentPatternsStore.q21[index].ward.selected.startsWith('missing|')){
                                value = treatmentPatternsStore.q21[index].ward.selected
                            }else if(treatmentPatternsStore.q21[index]?.intermediateCareUnit && typeof treatmentPatternsStore.q21[index].intermediateCareUnit.selected === 'string' && treatmentPatternsStore.q21[index].intermediateCareUnit.selected.startsWith('missing|')){
                                value = treatmentPatternsStore.q21[index].intermediateCareUnit.selected
                            }else if(treatmentPatternsStore.q21[index]?.intensiveCareUnit && typeof treatmentPatternsStore.q21[index].intensiveCareUnit.selected === 'string' && treatmentPatternsStore.q21[index].intensiveCareUnit.selected.startsWith('missing|')){
                                value = treatmentPatternsStore.q21[index].intensiveCareUnit.selected

                            }
                            if (index + 1 === selectedSurgery) {
                                return (
                                    <QuestionBox>
                                        <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 25,}}>
                                            Hospitalizations {index + 1}
                                            {dataStore.questionsMissing.includes('hosp'+index)
                                                ? <MissingReason
                                                    value={value}
                                                    style={{right:60,marginTop:3}}
                                                    onSubmitMissing={async ()=>{

                                                        let q21Array = treatmentPatternsStore.q21
                                                        q21Array[index].ward = {...q21Array[index].ward,selected: dataStore.returnOtherTextMissing}
                                                        q21Array[index].intermediateCareUnit = {...q21Array[index].intermediateCareUnit,selected: dataStore.returnOtherTextMissing}
                                                        q21Array[index].intensiveCareUnit = {...q21Array[index].intensiveCareUnit,selected: dataStore.returnOtherTextMissing}

                                                        treatmentPatternsStore.setQ21(
                                                            q21Array
                                                        )
                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('hosp'+index), 1)
                                                    }}/> : <></>}
                                        </Typography>

                                        <QuestionText>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                flexDirection: 'column',
                                                pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0

                                            }}>
                                                <li>level of hospitalization</li>
                                                <Box sx={{
                                                    width: '640px',
                                                    ml: 3,
                                                    display: "flex",
                                                    justifyContent: 'space-between',
                                                }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={(event) => {
                                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('hosp'+index), 1)
                                                                    let q21Array = treatmentPatternsStore.q21

                                                                    console.log('event.target.value',event.target.value)
                                                                console.log('q21Array[index].selected',q21Array[index].selected)

                                                                    if (q21Array[index].ward.selected) {
                                                                        q21Array[index] = {
                                                                            ...item,
                                                                            ward: {selected: null, amount: ''}
                                                                        }
                                                                        treatmentPatternsStore.setQ21(q21Array)

                                                                    } else {
                                                                        q21Array[index] = {
                                                                            ...item,
                                                                            ward: {
                                                                                ...q21Array[index].ward,
                                                                                selected: event.target.value
                                                                            }
                                                                        }
                                                                        treatmentPatternsStore.setQ21(q21Array)
                                                                    }

                                                                }}
                                                                checked={item.ward?.selected && item.ward.selected.startsWith('missing|') ? false : item.ward.selected}

                                                            />
                                                        }
                                                        sx={{
                                                            '& .MuiFormControlLabel-label': {fontSize: 18},

                                                        }}
                                                        label={'Ward'}
                                                    />

                                                    {item.ward.selected &&
                                                        <Stack direction={'row'} spacing={5} sx={{display:'flex',alignItems:'center'}}>
                                                            <Typography>
                                                                {dataStore.questionsMissing.includes('hosp'+index+'ward') ?
                                                                    <MissingReason
                                                                        value={treatmentPatternsStore.q21[index]?.ward?.amount}
                                                                        style={{marginLeft:-80,marginTop:-9}}
                                                                        onSubmitMissing={()=>{
                                                                            let q21Array = treatmentPatternsStore.q21
                                                                            q21Array[index] = {
                                                                                ...item,
                                                                                ward: {
                                                                                    ...q21Array[index].ward,
                                                                                    amount:dataStore.returnOtherTextMissing
                                                                                }
                                                                            }
                                                                            treatmentPatternsStore.setQ21(q21Array)

                                                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('hosp'+index+'ward'), 1)
                                                                        }}/>
                                                                    : <></>}
                                                                Number of Days
                                                            </Typography>
                                                            <TextField
                                                                size={'small'}
                                                                placeholder={'000'}
                                                                onWheel={(e) => e.target.blur()}
                                                                sx={{width: 100}}
                                                                value={item.ward?.amount && item.ward.amount.startsWith('missing|') ? '': item.ward.amount}

                                                                onChange={(event) => {
                                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('hosp'+index+'ward'), 1)

                                                                    let q21Array = treatmentPatternsStore.q21
                                                                    q21Array[index] = {
                                                                        ...item,
                                                                        ward: {
                                                                            ...q21Array[index].ward,
                                                                            amount: event.target.value
                                                                        }
                                                                    }
                                                                    treatmentPatternsStore.setQ21(q21Array)
                                                                }}
                                                            />
                                                        </Stack>

                                                    }
                                                </Box>


                                                <Box sx={{
                                                    width: '640px',
                                                    ml: 3,
                                                    display: "flex",
                                                    justifyContent: 'space-between',
                                                }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={(event) => {
                                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('hosp'+index), 1)
                                                                    let q21Array = treatmentPatternsStore.q21

                                                                    if (q21Array[index].intermediateCareUnit.selected) {
                                                                        q21Array[index] = {
                                                                            ...item,
                                                                            intermediateCareUnit: {
                                                                                selected: null,
                                                                                amount: ''
                                                                            }
                                                                        }
                                                                        treatmentPatternsStore.setQ21(q21Array)

                                                                    } else {
                                                                        q21Array[index] = {
                                                                            ...item,
                                                                            intermediateCareUnit: {
                                                                                ...q21Array[index].intermediateCareUnit,
                                                                                selected: event.target.value
                                                                            }
                                                                        }
                                                                        treatmentPatternsStore.setQ21(q21Array)
                                                                    }

                                                                }}
                                                                checked={item.intermediateCareUnit?.selected && item.intermediateCareUnit.selected.startsWith('missing|') ? false : item.intermediateCareUnit.selected}

                                                            />
                                                        }
                                                        sx={{
                                                            '& .MuiFormControlLabel-label': {fontSize: 18},

                                                        }}
                                                        label={'Intermediate care unit'}
                                                    />

                                                    {item.intermediateCareUnit.selected &&
                                                        <Stack direction={'row'} spacing={5} sx={{display:'flex',alignItems:'center'}}>
                                                            <Typography>
                                                                {dataStore.questionsMissing.includes('hosp'+index+'intensiveCareUnit') ?
                                                                    <MissingReason
                                                                        value={treatmentPatternsStore.q21[index]?.intermediateCareUnit?.amount}
                                                                        style={{marginLeft:-80,marginTop:-8}}
                                                                        onSubmitMissing={()=>{
                                                                            let q21Array = treatmentPatternsStore.q21
                                                                            q21Array[index] = {
                                                                                ...item,
                                                                                intermediateCareUnit: {
                                                                                    ...q21Array[index].intermediateCareUnit,
                                                                                    amount: dataStore.returnOtherTextMissing
                                                                                }
                                                                            }
                                                                            treatmentPatternsStore.setQ21(q21Array)

                                                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('hosp'+index+'intensiveCareUnit'), 1)
                                                                        }}/>
                                                                    : <></>}
                                                                Number of Days
                                                            </Typography>
                                                        <TextField
                                                            size={'small'}
                                                            placeholder={'000'}
                                                            onWheel={(e) => e.target.blur()}
                                                            sx={{width: 100}}
                                                            value={item.intermediateCareUnit?.amount && item.intermediateCareUnit.amount.startsWith('missing|') ? '': item.intermediateCareUnit.amount}

                                                            onChange={(event) => {
                                                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('hosp'+index+'intensiveCareUnit'), 1)

                                                                let q21Array = treatmentPatternsStore.q21
                                                                q21Array[index] = {
                                                                    ...item,
                                                                    intermediateCareUnit: {
                                                                        ...q21Array[index].intermediateCareUnit,
                                                                        amount: event.target.value
                                                                    }
                                                                }
                                                                treatmentPatternsStore.setQ21(q21Array)
                                                            }}
                                                        />
                                                        </Stack>
                                                    }
                                                </Box>

                                                <Box sx={{
                                                    width: '640px',
                                                    ml: 3,
                                                    display: "flex",
                                                    justifyContent: 'space-between',
                                                }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={(event) => {
                                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('hosp'+index), 1)
                                                                    let q21Array = treatmentPatternsStore.q21

                                                                    if (q21Array[index].intensiveCareUnit.selected) {
                                                                        q21Array[index] = {
                                                                            ...item,
                                                                            intensiveCareUnit: {
                                                                                selected: null,
                                                                                amount: ''
                                                                            }
                                                                        }
                                                                        treatmentPatternsStore.setQ21(q21Array)

                                                                    } else {
                                                                        q21Array[index] = {
                                                                            ...item,
                                                                            intensiveCareUnit: {
                                                                                ...q21Array[index].intensiveCareUnit,
                                                                                selected: event.target.value
                                                                            }
                                                                        }
                                                                        treatmentPatternsStore.setQ21(q21Array)
                                                                    }

                                                                }}
                                                                checked={item.intensiveCareUnit?.selected && item.intensiveCareUnit.selected.startsWith('missing|') ? false : item.intensiveCareUnit.selected}
                                                            />
                                                        }
                                                        sx={{
                                                            '& .MuiFormControlLabel-label': {fontSize: 18},

                                                        }}
                                                        label={'Intensive care unit'}
                                                    />

                                                    {item.intensiveCareUnit.selected &&
                                                        <Stack direction={'row'} spacing={5} sx={{display:'flex',alignItems:'center'}}>
                                                            <Typography>
                                                                {dataStore.questionsMissing.includes('hosp'+index+'intermediateCareUnit') ?
                                                                    <MissingReason
                                                                        value={treatmentPatternsStore.q21[index]?.intensiveCareUnit?.amount}
                                                                        style={{marginLeft:-80,marginTop:-7}}
                                                                        onSubmitMissing={()=>{
                                                                           let q21Array = treatmentPatternsStore.q21
                                                                            q21Array[index] = {
                                                                                ...item,
                                                                                intensiveCareUnit: {
                                                                                    ...q21Array[index].intensiveCareUnit,
                                                                                    amount: dataStore.returnOtherTextMissing
                                                                                }
                                                                            }
                                                                            treatmentPatternsStore.setQ21(q21Array)

                                                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('hosp'+index+'intermediateCareUnit'), 1)
                                                                        }}/>
                                                                    : <></>}

                                                                Number of Days
                                                            </Typography>
                                                        <TextField
                                                            size={'small'}
                                                            placeholder={'000'}
                                                            onWheel={(e) => e.target.blur()}
                                                            sx={{width: 100}}
                                                            value={item.intensiveCareUnit?.amount && item.intensiveCareUnit.amount.startsWith('missing|') ? '': item.intensiveCareUnit.amount}

                                                            onChange={(event) => {
                                                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('hosp'+index+'intermediateCareUnit'), 1)

                                                                let q21Array = treatmentPatternsStore.q21
                                                                q21Array[index] = {
                                                                    ...item,
                                                                    intensiveCareUnit: {
                                                                        ...q21Array[index].intensiveCareUnit,
                                                                        amount: event.target.value
                                                                    }
                                                                }
                                                                treatmentPatternsStore.setQ21(q21Array)
                                                            }}
                                                        />
                                                        </Stack>
                                                    }
                                                </Box>
                                            </Box>

                                        </QuestionText>
                                    </QuestionBox>
                                );
                            } else {
                                return (
                                    <></>
                                )
                            }

                        })
                    }

                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(HospitalizationsPage1);
