/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ObjectType {
    INITIAL = 'initial',
    SUBSEQUENT = 'subsequent',
}
