import {action, makeObservable, observable,} from 'mobx'
import {CrfService} from "../../services/openapi";
import {Stores} from "../../store";


class DispositionStore {

    loading = false
    q1 = null
    q2 = ''

    constructor() {

        makeObservable(this, {
            loading: observable,
            q1: observable,
            q2: observable,

            setQ1: action,
            setQ2: action,

            updateDisposition: action,
            getDisposition: action,
            setLoading: action,

        },)
    }

    setQ1 = (value) => {
        this.q1 = value
    }
    setQ2 = (value) => {
        this.q2 = value

    }
    reset = () => {
        this.setQ1(null)
        this.setQ2('')
    }

    setLoading = (value) => {
        this.loading = value
    }

    get checkMissing1() {
        if (this.q1 === null) {
            Stores.dataStore.setQuestionsMissing('q1');
        }
        if (this.q2 === '') {
            Stores.dataStore.setQuestionsMissing('q2');
        }
        if (this.q1 === null) {
            return true
        }
        if (this.q2 === '') {
            return true
        }

    }


    updateDisposition = async (requestBody,patient) => {
        try {
            this.setLoading(true)

            const response = await CrfService.updateDisposition(patient, requestBody)
            console.log('updateDisposition response', response)
            this.setQ1(response.q1)
            this.setQ2(response.q2)
            this.setLoading(false)

        } catch (err) {
            this.setLoading(false)
            console.log('updateDisposition err', err)
        }
    }


    getDisposition = async (patient) => {
        try {
            this.setLoading(true)

            const response = await CrfService.getDisposition(patient)
            console.log('getDisposition response', response)

            if(response.q1 !== null){
                this.setQ1(response.q1)
                if(response.q1 && response.q1.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q1');
                }
            }
            if(response.q2 !== null){
                this.setQ2(response.q2)
                if(response.q2 && response.q2.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q2');
                }
            }

            this.setLoading(false)

        } catch (err) {
            this.setLoading(false)
            console.log('getDisposition err', err)
        }
    }
}


export default DispositionStore
