import {action, makeObservable, observable,} from 'mobx'
import {CrfService} from "../../services/openapi";
import {Stores} from "../../store";

class AfterInitialTreatmentStore {
    loading = false
    q3CommonParams = {
        selected: null,
        dose: '',
        numberOfCyclesPlanned: '',
        numberOfCyclesActual: '',
        totalDurationInWeeks: '',
    }
    commonQ1Params = {
        selected: null,
        number: '',
    }
    q1 = {
        main: null,
        missingTypesReason:null,
        types: [
            {
                name: 'X-Ray thorax',
                ...this.commonQ1Params
            },
            {
                name: 'CT thorax',
                ...this.commonQ1Params
            },
            {
                name: 'PET-CT thorax',
                ...this.commonQ1Params
            },
            {
                name: 'MRI brain',
                ...this.commonQ1Params
            },
            {
                name: 'Bone scintigraphy',
                ...this.commonQ1Params
            },
            {
                name: 'Contrast enhanced- CT brain',
                ...this.commonQ1Params
            },
        ]
    }
    q2 = {
        main: null,
        missingTypesReason:null,
        types: [
            {
                name: 'Complete Blood Count',
                ...this.commonQ1Params
            },
            {
                name: 'Albumin',
                ...this.commonQ1Params
            },
            {
                name: 'Alkaline phosphatase',
                ...this.commonQ1Params
            },
            {
                name: 'Glucose',
                ...this.commonQ1Params
            },
            {
                name: 'Lactate Dehydrogenase',
                ...this.commonQ1Params
            },
            {
                name: 'Phosphorus',
                ...this.commonQ1Params
            },
            {
                name: 'Potassium',
                ...this.commonQ1Params
            },
            {
                name: 'Sodium',
                ...this.commonQ1Params
            },
            {
                name: 'Calcium',
                ...this.commonQ1Params
            },
            {
                name: 'Total Bilirubin',
                ...this.commonQ1Params
            },
            {
                name: 'Direct Bilirubin',
                ...this.commonQ1Params
            },
            {
                name: 'Total Protein',
                ...this.commonQ1Params
            },
            {
                name: 'Uric acid',
                ...this.commonQ1Params
            },
            {
                name: 'Urea',
                ...this.commonQ1Params
            },
            {
                name: 'PT (INR)',
                ...this.commonQ1Params
            },
            {
                name: 'aPTT',
                ...this.commonQ1Params
            },
            {
                name: 'Thyroid stimulating hormone (TSH)',
                ...this.commonQ1Params
            },
            {
                name: ' Free thyroxine (FT4)',
                ...this.commonQ1Params
            },
            {
                name: 'Creatinine',
                ...this.commonQ1Params
            },
            {
                name: 'C-reactive Protein',
                ...this.commonQ1Params
            },
            {
                name: 'Hemoculture',
                ...this.commonQ1Params
            },
            {
                name: 'Urine Culture',
                ...this.commonQ1Params
            },
            {
                name: 'Urinalysis - Blood',
                ...this.commonQ1Params
            },
            {
                name: 'Urinalysis - Glucose',
                ...this.commonQ1Params
            },
            {
                name: 'Urinalysis - Protein',
                ...this.commonQ1Params
            },
            {
                name: 'Urinalysis - Specific Gravity',
                ...this.commonQ1Params
            },
        ]
    }
    q3 = {
        main: null,
        missingTypesReason:null,
        types: [
            {
                name: 'ECG',
                ...this.commonQ1Params
            },
            {
                name: 'Echocardiogram',
                ...this.commonQ1Params
            },
            {
                name: 'Spirometry',
                ...this.commonQ1Params
            },
            {
                name: 'Plethysmography',
                ...this.commonQ1Params
            },
            {
                name: 'Biopsy',
                selected: null,
                number:'',
                type1: {name: null, subName: null, number:''},
                type2: {name: null, subName: null, number:''}
            },
            {
                name: 'CT-guided biopsy',
                ...this.commonQ1Params
            },
            {
                name: 'Bronchoscopy',
                ...this.commonQ1Params
            },
            {
                name: 'EBUS',
                ...this.commonQ1Params
            },
            {
                name: 'EUS mediastinal',
                ...this.commonQ1Params
            },
        ]
    }
    q4 = {
        main: null,
        numberOfSurgery: ''
    }
    q5 = [{
        ward: {selected: null, amount: ''},
        intermediateCareUnit: {selected: null, amount: ''},
        intensiveCareUnit: {selected: null, amount: ''},
    }]
    q6 = {
        main: null,
        oncology: {selected: null, amount: '', unknown: null},
        other: {
            selected: null,
            unknown: null,
            specialities: [
                {
                    speciality: '',
                    amount: ''
                },

            ],
        },
        gp: {selected: null, amount: '', unknown: null},
    };
    q7 = {
        main: null,
        amount: ''
    }
    q8=null
    q8Prev=null
    status=null
    newNeoText = ''
    newDiseaseText = ''
    newLabText = ''
    newExamText = ''
    newImageAssessmentText = ''
    newAddedNeo = []
    newAddedDisease = []

    constructor() {

        makeObservable(this, {
            loading: observable,
            q3CommonParams: observable,
            newNeoText: observable,
            newAddedNeo: observable,
            newDiseaseText: observable,
            newImageAssessmentText: observable,
            newAddedDisease: observable,
            commonQ1Params: observable,
            q1: observable,
            q2: observable,
            q3: observable,
            q4: observable,
            q5: observable,
            q6: observable,
            q7: observable,
            q8: observable,
            q8Prev: observable,
            status: observable,
            newLabText: observable,
            newExamText: observable,

            addNewExams: action,
            setNewExamText: action,
            addNewLabs: action,
            setNewLabText: action,
            setQ1: action,
            setQ2: action,
            setQ3: action,
            setQ4: action,
            setQ5: action,
            setQ6: action,
            setQ7: action,
            setQ8: action,
            setStatus: action,
            setLoading: action,
            updateAfterTreatment: action,
            getAfterTreatment: action,
            setNewImageAssessmentText: action,
            addImageAssessmentType: action,
            addNewSpeciality: action,
            reset: action,
        },)
    }

    reset = () => {
        this.setQ1({
            main: null,
            types: [
                {
                    name: 'X-Ray thorax',
                    ...this.commonQ1Params
                },
                {
                    name: 'CT thorax',
                    ...this.commonQ1Params
                },
                {
                    name: 'PET-CT thorax',
                    ...this.commonQ1Params
                },
                {
                    name: 'MRI brain',
                    ...this.commonQ1Params
                },
                {
                    name: 'Bone scintigraphy',
                    ...this.commonQ1Params
                },
                {
                    name: 'Contrast enhanced- CT brain',
                    ...this.commonQ1Params
                },
            ]
        })

        this.setQ2({
            main: null,
            types: [
                {
                    name: 'Complete Blood Count',
                    ...this.commonQ1Params
                },
                {
                    name: 'Albumin',
                    ...this.commonQ1Params
                },
                {
                    name: 'Alkaline phosphatase',
                    ...this.commonQ1Params
                },
                {
                    name: 'Glucose',
                    ...this.commonQ1Params
                },
                {
                    name: 'Lactate Dehydrogenase',
                    ...this.commonQ1Params
                },
                {
                    name: 'Phosphorus',
                    ...this.commonQ1Params
                },
                {
                    name: 'Potassium',
                    ...this.commonQ1Params
                },
                {
                    name: 'Sodium',
                    ...this.commonQ1Params
                },
                {
                    name: 'Calcium',
                    ...this.commonQ1Params
                },
                {
                    name: 'Total Bilirubin',
                    ...this.commonQ1Params
                },
                {
                    name: 'Direct Bilirubin',
                    ...this.commonQ1Params
                },
                {
                    name: 'Total Protein',
                    ...this.commonQ1Params
                },
                {
                    name: 'Uric acid',
                    ...this.commonQ1Params
                },
                {
                    name: 'Urea',
                    ...this.commonQ1Params
                },
                {
                    name: 'PT (INR)',
                    ...this.commonQ1Params
                },
                {
                    name: 'aPTT',
                    ...this.commonQ1Params
                },
                {
                    name: 'Thyroid stimulating hormone (TSH)',
                    ...this.commonQ1Params
                },
                {
                    name: ' Free thyroxine (FT4)',
                    ...this.commonQ1Params
                },
                {
                    name: 'Creatinine',
                    ...this.commonQ1Params
                },
                {
                    name: 'C-reactive Protein',
                    ...this.commonQ1Params
                },
                {
                    name: 'Hemoculture',
                    ...this.commonQ1Params
                },
                {
                    name: 'Urine Culture',
                    ...this.commonQ1Params
                },
                {
                    name: 'Urinalysis - Blood',
                    ...this.commonQ1Params
                },
                {
                    name: 'Urinalysis - Glucose',
                    ...this.commonQ1Params
                },
                {
                    name: 'Urinalysis - Protein',
                    ...this.commonQ1Params
                },
                {
                    name: 'Urinalysis - Specific Gravity',
                    ...this.commonQ1Params
                },
            ]
        })

        this.setQ3({
            main: null,
            types: [
                {
                    name: 'ECG',
                    ...this.q3CommonParams
                },
                {
                    name: 'Echocardiogram',
                    ...this.q3CommonParams
                },
                {
                    name: 'Spirometry',
                    ...this.q3CommonParams
                },
                {
                    name: 'Plethysmography',
                    ...this.q3CommonParams
                },
                {
                    name: 'Biopsy',
                    selected: null,
                    type1: {name: null, subName: null},
                    type2: {name: null, subName: null}
                },
                {
                    name: 'CT-guided biopsy',
                    ...this.q3CommonParams
                },
                {
                    name: 'Bronchoscopy',
                    ...this.q3CommonParams
                },
                {
                    name: 'EBUS',
                    ...this.q3CommonParams
                },
                {
                    name: 'EUS mediastinal',
                    ...this.q3CommonParams
                },
            ]
        })


        this.setQ4({
            main: null,
            numberOfSurgery: ''
        })
        this.setQ5([{
            ward: {selected: null, amount: ''},
            intermediateCareUnit: {selected: null, amount: ''},
            intensiveCareUnit: {selected: null, amount: ''},
        }])

        this.setQ6({
            main: null,
            oncology: {selected: null, amount: '', unknown: null},
            other: {
                selected: null,
                unknown: null,
                specialities: [
                    {
                        speciality: '',
                        amount: ''
                    },

                ],
            },
            gp: {selected: null, amount: '', unknown: null},
        });

        this.setQ7(null)
    }


    get checkMissing1() {
        if(this.q1 == null || this.q1.main == null){
            Stores.dataStore.setQuestionsMissing('q1')
            return true
        }
    }
    get checkMissing1_1() {
        if(this.q1 == null || this.q1.main == null){
            Stores.dataStore.setQuestionsMissing('q1')
        }
        if(this.q1.main == 'true' && !this.q1.types.find((t)=>t.selected != null) && !this.q1.missingTypesReason){
            Stores.dataStore.setQuestionsMissing('q1types')
        }
        if(this.q1.types.length > 0){
            for(const type of this.q1.types){
                if(type.selected === 'on' && type.number == ''){
                    Stores.dataStore.setQuestionsMissing(type.name)
                }
            }
        }

        if((this.q1 == null || this.q1.main == null) ||
            (this.q1.main == 'true' && !this.q1.types.find((t)=>t.selected != null) && !this.q1.missingTypesReason) ||
            this.q1.types.find((t)=>t.selected === 'on' && t.number == '')){
            return true
        }

    }

    get checkMissing2() {
        if(this.q2 == null || this.q2.main == null){
            Stores.dataStore.setQuestionsMissing('q2')

            return true
        }
    }
    get checkMissing2_1() {

        console.log('this.q2',this.q2)
        if(this.q2.main == 'true' && !this.q2.types.find((t)=>t.selected != null) && !this.q2.missingTypesReason){
            Stores.dataStore.setQuestionsMissing('q2type')
        }
        for(const t of this.q2.types){
            if(t.selected != null && t.number == ''){
                Stores.dataStore.setQuestionsMissing(t.name)
            }
        }

        if((this.q2 == null || this.q2.main == null) ||
            (this.q2.main == 'true' && !this.q2.types.find((t)=>t.selected != null)  && !this.q2.missingTypesReason) ||
            (this.q2.types.find((t)=>t.selected != null && t.number == ''))){
            return true
        }

    }

    get checkMissing3() {
        if(this.q3 == null || this.q3.main == null){
            Stores.dataStore.setQuestionsMissing('q3')
            return true
        }
    }
    get checkMissing3_1() {
        console.log('this.q3',this.q3)
        if(this.q3 == null || this.q3.main == null){
            Stores.dataStore.setQuestionsMissing('q3')
        }
        if(this.q3.main == 'true' && !this.q3.types.find((t)=>t.selected != null) && !this.q3.missingTypesReason){
            Stores.dataStore.setQuestionsMissing('q3type')
        }
        if((this.q3.types.find((t)=>t.name === 'Biopsy' && t.selected === 'on' && t.type1.name === null)) &&
            (this.q3.types.find((t)=>t.name === 'Biopsy' && t.selected === 'on' && t.type2.name === null))){
            Stores.dataStore.setQuestionsMissing('BiopsyAll')

        }
        // if(this.q19.types.find((t)=>t.name === 'Biopsy' && t.selected === 'on' && t.type1.name === null)){
        //     Stores.dataStore.setQuestionsMissing('Metastasis Biopsy')
        // }
        // if(this.q19.types.find((t)=>t.name === 'Biopsy' && t.selected === 'on' && t.type2.name === null)){
        //     Stores.dataStore.setQuestionsMissing('Tumor Biopsy')
        // }
        if (this.q3.types.find((t)=>t.type2?.name && t.type2.name != null && (!t.type2?.subName || t.type2.subName === null))){
            Stores.dataStore.setQuestionsMissing('q3type2number')
        }
        for(const t of this.q3.types){
            if(t.selected === 'on' && t.number == ''){
                Stores.dataStore.setQuestionsMissing(t.name)
            }
        }

        if((this.q3 == null || this.q3.main == null) ||
            (this.q3.main == 'true' && !this.q3.types.find((t)=>t.selected != null) && !this.q3.missingTypesReason)||
            ((this.q3.types.find((t)=>t.name === 'Biopsy' && t.selected === 'on' && t.type1.name === null)) &&
                (this.q3.types.find((t)=>t.name === 'Biopsy' && t.selected === 'on' && t.type2.name === null)))
            ||
            // (this.q19.types.find((t)=>(t.name === 'Biopsy' && t.selected === 'on' && t.type1.name === null) && (t.type1?.name && !t.type1.name.startsWith('missing|')))) ||
            // (this.q19.types.find((t)=>(t.name === 'Biopsy' && t.selected === 'on' && t.type2.name === null) && (t.type2?.name && !t.type2.name.startsWith('missing|')))) ||
            (this.q3.types.find((t)=>(t.name === 'Biopsy' && t.type2.name != null && t.type2.subName === null)))
            ||
            (this.q3.types.find((t)=>(t.selected != null && t.number == '')))){
            return true
        }
    }

    get checkMissing4() {
        console.log('this.q5',this.q5)
        if(this.q4 == null || this.q4.main == null){
            Stores.dataStore.setQuestionsMissing('q4')
        }

        if((this.q4.main == 'true' || this.q4.main == true) && this.q4.numberOfSurgery == ''){
            Stores.dataStore.setQuestionsMissing('q4numberOfSurgery')
        }


        if (this.q4.main == 'true') {
            for (let i=0;i<this.q5.length;i++) {
                if (this.q5[i].ward.selected == null && this.q5[i].intensiveCareUnit.selected == null && this.q5[i].intermediateCareUnit.selected == null) {
                    Stores.dataStore.setQuestionsMissing('hosp'+i)
                }
            }
        }

        for (let i=0;i<this.q5.length;i++) {
            if (this.q5[i].ward.selected  === 'on' && this.q5[i].ward.amount == ''){
                Stores.dataStore.setQuestionsMissing('hosp'+i+'ward')
            }
            if (this.q5[i].intensiveCareUnit.selected  === 'on' && this.q5[i].intensiveCareUnit.amount == ''){
                Stores.dataStore.setQuestionsMissing('hosp'+i+'intensiveCareUnit')
            }
            if (this.q5[i].intermediateCareUnit.selected  === "on" && this.q5[i].intermediateCareUnit.amount === ""){
                Stores.dataStore.setQuestionsMissing('hosp'+i+'intermediateCareUnit')
            }
        }

        console.log('this.q21',this.q21)

        if (this.q4.main == 'true' && (this.q4.numberOfSurgery != '' && !this.q4.numberOfSurgery.includes('missing'))) {
            for (let i=0;i<this.q5.length;i++) {
                if ((this.q5[i].ward.selected == null || (this.q5[i].ward.selected !== 'on' && !this.q5[i].ward.selected.startsWith('missing|'))) &&
                    (this.q5[i].intensiveCareUnit.selected == null  || (this.q5[i].intensiveCareUnit.selected !== 'on'  && !this.q5[i].intensiveCareUnit.selected.startsWith('missing|'))) &&
                    (this.q5[i].intermediateCareUnit.selected == null || (this.q5[i].intermediateCareUnit.selected !== 'on' && !this.q5[i].intermediateCareUnit.selected.startsWith('missing|')))) {
                    return true
                }
            }
        }

        if((this.q4 == null || this.q4.main == null) ||
            ((this.q4.main == 'true' || this.q4.main == true) && this.q4.numberOfSurgery == '') ||
            (this.q4.main == 'true' || this.q4.main == true) && this.q5.length == 0 ||
            (this.q5 == null || !Array.isArray(this.q5))
        ) {

            return true
        }

        if ((this.q4.main == 'true' || this.q4.main == true) && (this.q4.numberOfSurgery != '' && !this.q4.numberOfSurgery.includes('missing'))) {
            for (let q of this.q5) {
                if ((q.ward.selected === 'on' && (q.ward.amount == '' && !q.ward.amount.startsWith('missing|'))) ||
                    (q.intensiveCareUnit.selected  === 'on' && (q.intensiveCareUnit.amount == '' && !q.intensiveCareUnit.amount.startsWith('missing|'))) ||
                    (q.intermediateCareUnit.selected  === 'on' && (q.intermediateCareUnit.amount == '' && !q.intermediateCareUnit.amount.startsWith('missing|')))) {
                    return true
                }
            }
        }


    }

    get checkMissing5() {
        console.log('this.q6',this.q6)
        if (this.q6.main === null) {

            Stores.dataStore.setQuestionsMissing('q6');
        }
        if(this.q6.main === 'true' &&
            ((this.q6.oncology.selected === null || (this.q6.oncology.selected !== 'on' && !this.q6.oncology.selected.startsWith('missing|'))) &&
                (this.q6.other.selected === null || (this.q6.other.selected !== 'on' && !this.q6.other.selected.startsWith('missing|'))) &&
                (this.q6.gp.selected === null || (this.q6.gp.selected !== 'on' && !this.q6.gp.selected.startsWith('missing|'))))){
            Stores.dataStore.setQuestionsMissing('q6value')
        }
        if(this.q6.oncology.selected === 'on' && (this.q6.oncology.amount === '' && !this.q6.oncology.amount.startsWith('missing|') && this.q6.oncology.unknown === null)){
            Stores.dataStore.setQuestionsMissing('oncology')
        }
        // if(this.q22.other.selected === 'on' && (this.q22.other.specialities[0].speciality === '' && this.q22.other.unknown === null)){
        //     Stores.dataStore.setQuestionsMissing('other')
        // }
        if(this.q6.other.selected === 'on' && this.q6.other.specialities.length > 0  && this.q6.other.unknown === null){
            for(let i=0;i<this.q6.other.specialities.length;i++){
                let spec = this.q6.other.specialities[i]
                console.log('spec',spec)
                if(spec.speciality === ''){
                    Stores.dataStore.setQuestionsMissing('spec' + i + 'speciality')
                }
                if(spec.amount === ''){
                    Stores.dataStore.setQuestionsMissing('spec' + i + 'amount')
                }
            }
        }
        if(this.q6.gp.selected === 'on' && (this.q6.gp.amount === '' && this.q6.gp.unknown === null)){
            Stores.dataStore.setQuestionsMissing('gp')
        }
        if(this.q6.main === null){
            return true
        }
        if(this.q6.main === 'true' &&
            (this.q6.oncology.selected === null &&
                this.q6.other.selected === null && this.q6.gp.selected === null)){
            return true
        }
        if(this.q6.oncology.selected === 'on' && (this.q6.oncology.amount === '' && !this.q6.oncology.amount.startsWith('missing|') && this.q6.oncology.unknown === null)){
            return true
        }
        if(this.q6.other.selected === 'on' && (this.q6.other.specialities[0].speciality === '' && this.q6.other.unknown === null)){
            return true
        }
        if(this.q6.gp.selected === 'on' && (this.q6.gp.amount === '' && this.q6.gp.unknown === null)){
            return true
        }
        if(this.q6.other.selected === 'on' && this.q6.other.specialities.length > 0 && this.q6.other.unknown === null){
            for(let i=0;i<this.q6.other.specialities.length;i++){
                let spec = this.q6.other.specialities[i]
                console.log('spec=>',spec)
                if(spec.speciality === '' || spec.amount ===  ""){
                    return true
                }

            }
        }
        }

    get checkMissing6(){
        if(this.q7 == null || this.q7.main == null){
            Stores.dataStore.setQuestionsMissing('q7')
            return true
        }else if(this.q7.main == 'true' && this.q7.amount == ''){

            Stores.dataStore.setQuestionsMissing('q7amount')
            return true
        }
    }


    setNewExamText = (value) => {
        this.newExamText = value
    }
    setNewLabText = (value) => {
        this.newLabText = value
    }

    setNewImageAssessmentText = (value) => {
        this.newImageAssessmentText = value
    }

    setQ1 = (value) => {
        this.q1 = value
    }
    setQ2 = (value) => {
        this.q2 = value
    }
    setQ3 = (value) => {
        this.q3 = value
    }
    setQ4 = (value) => {
        this.q4 = value
    }
    setQ5 = (value) => {
        this.q5 = value
    }
    setQ6 = (value) => {
        this.q6 = value
    }
    setQ7 = (value) => {
        this.q7 = value
    }
    setQ8 = (value) => {
        this.q8 = value
    }
    setQ8Prev = (value) => {
        this.q8Prev = value
    }
    setStatus = (value) => {
        this.status = value
    }

    setLoading = (value) => {
        this.loading = value
    }

    updateAfterTreatment = async (requestBody, number, patient) => {
        try {
            this.setLoading(true)
            let params = {...requestBody,force:Stores.dataStore.forceUpdate}

            if(Stores.dataStore.updateReason !== ''){
                params = {...params, reason:Stores.dataStore.updateReason}
            }
            console.log('params', params)

            const response = await CrfService.updateAfterTreatment(patient,
                number,
                params,)
            console.log('updateAfterTreatment response', response)

            if (response.q1 != null) {
                if (this.q1.main == null) {
                    this.setQ1(response.q1)
                }
            }
            if (response.q2 != null) {
                if (this.q2.main == null) {
                    this.setQ2(response.q2)
                }
            }
            if (response.q3 != null) {
                if (this.q3.main == null) {
                    this.setQ3(response.q3)
                }
            }
            if (response.q4 != null) {
                this.setQ4(response.q4)
            }
            if (response.q5 != null) {
                this.q5 = [{
                    ward: {selected: null, amount: ''},
                    intermediateCareUnit: {selected: null, amount: ''},
                    intensiveCareUnit: {selected: null, amount: ''},
                }]
                for (let i = 0; i < response.q5.length; i++) {
                    this.q5[i] = response.q5[i]

                }
                console.log('this.q5.length', this.q5.length)
            }
            if (response.q6 != null) {
                this.setQ6(response.q6)
            }
            if (response.q7 != null) {
                this.setQ7(response.q7)
            }
            if (response.q8 != null) {
                this.setQ8(response.q8)
            }



            this.setLoading(false)

        } catch (err) {
            this.setLoading(false)
            console.log('updateTreatment err', err)
            if(err?.body?.data?.updated){
                console.log('err?.body?.data?.updated',err.body.data.updated)
                Stores.dataStore.setShowUpdateModal('active')
            }else {
                Stores.dataStore.setShowUpdateModal('not active')
            }
        }
    }


    getAfterTreatment = async (patient,number) => {
        try {
            this.setLoading(true)

            const response = await CrfService.getAfterTreatment(patient, number)
            console.log('getAfterTreatment response', response)
            if (response.q1 != null) {
                this.setQ1(response.q1)
                if(response.q1.main && response.q1.main.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q1')
                }
                if(response.q1.missingTypesReason  && response.q1.missingTypesReason.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q1types')
                }
                if(response.q1.types.length >0){
                    for(const type of response.q1.types){
                        if(type.selected != null && (type.number == '' || type.number.startsWith('missing|'))){
                            Stores.dataStore.setQuestionsMissing(type.name)
                        }
                    }
                }

            }
            if (response.q2 != null) {
                this.setQ2(response.q2)
                if(response.q2?.main && response.q2.main.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q2')
                }

                if(response.q2?.missingTypesReason && response.q2.missingTypesReason.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q2type')
                }
                if(response.q2?.types && response.q2.types.length > 0){
                    for(const t of response.q2.types){
                        if(t.selected === 'on' && (t.number == '' || t.number.startsWith('missing|'))){
                            Stores.dataStore.setQuestionsMissing(t.name)
                        }
                    }
                }
            }
            if (response.q3 != null) {
                this.setQ3(response.q3)
                if(response.q3?.main && response.q3.main.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q3')
                }

                if(response.q3?.missingTypesReason && response.q3.missingTypesReason.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q3type')
                }
                if((this.q3.types.find((t)=>t.name === 'Biopsy' && t.selected === 'on' && t.type1?.name &&t.type1.name.startsWith('missing|'))) &&
                    (this.q3.types.find((t)=>t.name === 'Biopsy' && t.selected === 'on' && t.type2.name.startsWith('missing|')))){
                    Stores.dataStore.setQuestionsMissing('BiopsyAll')

                }
                // if(response.q19.types.find((t)=>t.name === 'Biopsy' && t.selected === 'on' && t.type1?.name &&t.type1.name.startsWith('missing|')) ){
                //     Stores.dataStore.setQuestionsMissing('Metastasis Biopsy')
                // }
                // if(response.q19.types.find((t)=>t.name === 'Biopsy' && t.selected === 'on' && t.type2.name.startsWith('missing|'))){
                //     Stores.dataStore.setQuestionsMissing('Tumor Biopsy')
                // }
                if (response.q3.types.find((t)=>t.type2?.name && t.type2.name != null && (!t.type2?.subName || t.type2.subName.startsWith('missing|')))){
                    Stores.dataStore.setQuestionsMissing('q3type2number')
                }
                if(response.q3.types.length > 0){
                    for(const t of response.q3.types){
                        if((t.selected === 'on' && t.number.startsWith('missing|'))){
                            Stores.dataStore.setQuestionsMissing(t.name)
                        }
                    }
                }
            }
            if (response.q4 != null) {
                this.setQ4(response.q4)
                if(response.q4.main && response.q4.main.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q4')
                }

                if(response.q4?.numberOfSurgery && response.q4.numberOfSurgery.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q4numberOfSurgery')
                }
            }
            if (response.q5 != null) {
                this.q5 = [{
                    ward: {selected: null, amount: ''},
                    intermediateCareUnit: {selected: null, amount: ''},
                    intensiveCareUnit: {selected: null, amount: ''},
                }]
                for (let i = 0; i < response.q5.length; i++) {
                    this.q5[i] = response.q5[i]
                }
                if (response.q5.length >0) {
                    for (let i=0;i<response.q5.length;i++) {
                        if ((typeof response.q5[i].ward.selected === 'string' && response.q5[i].ward.selected.startsWith('missing|')) &&
                            (typeof response.q5[i].intensiveCareUnit.selected === 'string' &&response.q5[i].intensiveCareUnit.selected.startsWith('missing|')) &&
                            (typeof response.q5[i].intermediateCareUnit.selected === 'string' &&response.q5[i].intermediateCareUnit.selected.startsWith('missing|'))) {
                            Stores.dataStore.setQuestionsMissing('hosp'+i)
                        }
                        if (response.q5[i]?.ward?.amount && response.q5[i].ward.amount.startsWith('missing|')){
                            Stores.dataStore.setQuestionsMissing('hosp'+i+'ward')
                        }
                        if (response.q5[i]?.intensiveCareUnit?.amount && response.q5[i].intensiveCareUnit.amount.startsWith('missing|')){
                            Stores.dataStore.setQuestionsMissing('hosp'+i+'intensiveCareUnit')
                        }
                        if (response.q5[i]?.intermediateCareUnit?.amount && response.q5[i].intermediateCareUnit.amount.startsWith('missing|')){
                            Stores.dataStore.setQuestionsMissing('hosp'+i+'intermediateCareUnit')
                        }
                    }

                }

            }
            if (response.q6 != null) {
                this.setQ6(response.q6)
                if(response.q6.main && response.q6.main.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q6')
                }
                if(response.q6.oncology?.amount && response.q6.oncology.amount.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('oncology')
                }
                if(response.q6.oncology.selected && response.q6.oncology.selected.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('q6value')
                }
                if(response.q6.other.selected && response.q6.other.selected.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('other')
                }
                if(response.q6.gp?.amount && response.q6.gp.amount.startsWith('missing|')){
                    Stores.dataStore.setQuestionsMissing('gp')
                }
            }
            if (response.q7 != null) {
                this.setQ7(response.q7)
            }
            if (response.q8 != null) {
                this.setQ8(response.q8)
                this.setQ8Prev(response.q8)
            }
            if (response.status) {
                this.setStatus(response.status)
            }

            Stores.dataStore.setForceUpdate(false)


            this.setLoading(false)

        } catch (err) {
            this.setLoading(false)
            console.log('getAfterTreatment err', err)
        }
    }


    addImageAssessmentType = async () => {
        if(this.q1.types.find((t)=>t.name == this.newImageAssessmentText)){
            alert('You are trying to add duplicate value.')
            this.setNewImageAssessmentText('')
        }else{
            this.q1.types.push({name: this.newImageAssessmentText, number: '', selected: 'on'})
            this.setNewImageAssessmentText('')
        }
    }


    addNewLabs = async () => {

        if(this.q2.types.find((t)=>t.name == this.newLabText)){
            alert('You are trying to add duplicate value.')
            this.setNewLabText('')
        }else{
            this.q2.types.push({name: this.newLabText, number: '', selected: 'on'})
            this.setNewLabText('')
        }
    }

    addNewExams = async () => {

        if(this.q3.types.find((t)=>t.name == this.newExamText)){
            alert('You are trying to add duplicate value.')
            this.setNewExamText('')
        }else{
            this.q3.types.push({name: this.newExamText, number: '', selected: 'on'})
            this.setNewExamText('')
        }
    }


    addNewSpeciality = () => {
        Stores.dataStore.questionsMissing.splice(Stores.dataStore.questionsMissing.indexOf('other'), 1)

        this.setLoading(true)
        let speciality = this.q6.other.specialities
        if(speciality.length < 6 && speciality[speciality.length - 1].speciality !== ''){
            speciality.push({
                speciality: '',
                amount: ''
            })
        }


        this.setLoading(false)

    }

    removeNewSpeciality = (index) => {
        this.setLoading(true)
        let speciality = this.q6.other.specialities
        speciality.splice(index, 1)

        this.q6.other.specialities = speciality
        this.setLoading(false)

    }

}


export default AfterInitialTreatmentStore
