import {useStores,} from '../store'
import * as React from 'react';
import {observer} from "mobx-react";
import MyButton from "../components/MyButton";
import {TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import Layout from "../layout/Layout";
import Loading from "../components/Loading";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {ArrowBack} from "@mui/icons-material";
import theme from "../styles/theme";


const ChangeName = () => {
    const {
        myAccountStore
    } = useStores()
    const navigate = useNavigate()

    return (
        <Layout activeItem={'myAccount'}>
            <Button onClick={() => navigate(-1)} sx={{color: "txtGrey", m: 4, alignSelf: "flex-start"}}>
                <ArrowBack sx={{
                    mr: 2,
                    background: theme.palette.primary.main,
                    color: "white",
                    borderRadius: "50%",
                    width: 36,
                    height: 36,
                    p: 1
                }}/>
                Back
            </Button>
            <Box sx={{
                p: 8,
                display: "flex",
                flexDirection: "column",
                maxWidth: 600,
                margin: "auto",
                alignItems: "center"
            }}>
                <Typography component={'h1'} variant={'h3'} color={'primary'} sx={{fontWeight: 'bold', mb: 4}}>
                    Change Name
                </Typography>
                <TextField
                    autoFocus
                    margin="normal"
                    label="First Name"
                    fullWidth
                    value={myAccountStore.firstname}
                    onChange={(event) => myAccountStore.setFirstname(event.currentTarget.value)}
                />
                <TextField
                    margin="normal"
                    label="Last Name"
                    fullWidth
                    value={myAccountStore.lastname}
                    onChange={(event) => myAccountStore.setLastname(event.currentTarget.value)}
                />
                <MyButton sx={{mt: 4, px: 8}} label={'Change'}
                          disabled={myAccountStore.firstname === '' || myAccountStore.lastname === ''}
                          onClick={() => {
                              myAccountStore.changeNameRequest();
                              navigate(-1);
                          }} fullWidth/>
            </Box>
            {myAccountStore.loading && <Loading/>}
        </Layout>
    );
}

export default observer(ChangeName);
