import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import YesNo from "../../components/YesNo";
import QuestionText from "../../components/QuestionText";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import TextField from "@mui/material/TextField";
import QuestionsLayout from "../../layout/QuestionsLayout";
import Required from "../../components/Required";
import MissingReason from "../../components/MissingReason";


const DispositionDataPage1 = () => {
    const {
        dispositionStore,menuStore, dataStore
    } = useStores()

    const navigate = useNavigate()
    let params = useParams();

    useEffect(() => {
        dispositionStore.getDisposition(parseInt(params.patient))
    }, [])

    const handleNext = async () => {
        if (!dispositionStore.checkMissing1) {
            await dispositionStore.updateDisposition({
                q1: dispositionStore.q1,
                q2: dispositionStore.q2
            },parseInt(params.patient))
            navigate('/patientCompleted/'+parseInt(params.patient))
        } else {
            alert('Please add reason for missing questions.')
        }
    }
    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={dispositionStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>

                    <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                        Disposition data at cut-off date
                    </Typography>
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>
                    <QuestionBox allInRow={true}>
                        <QuestionText>
                            Death

                        </QuestionText>
                        {dataStore.questionsMissing.includes('q1') ?
                            <MissingReason
                                value={dispositionStore.q1}
                                style={{marginTop: -7,marginLeft:70}}
                                onSubmitMissing={() => {
                                    dispositionStore.setQ1(dataStore.returnOtherTextMissing)
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q1'), 1)
                                }}/>
                            : <></>}
                        <YesNo value={dispositionStore.q1}
                               handleChange={(event) => {
                                   dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q1'), 1)

                                   dispositionStore.setQ1(event.target.value)
                               }}
                        />
                    </QuestionBox>
                    <QuestionBox allInRow={true}>
                        <QuestionText width={'50%'}>
                            {dispositionStore.q1 == 'false' ? 'Time from start of initial treatment to last recorded clinical activity within the real-world database or end of follow-up period, whichever comes first' :
                                 'Time from initial treatment initiation to death'}

                        </QuestionText>

                        <Stack direction={'row'} sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography sx={{fontSize: 18, mr: 5}}>
                                {dataStore.questionsMissing.includes('q2') ?
                                    <MissingReason
                                        value={dispositionStore.q2}
                                        style={{marginTop: -7,marginLeft:-100}}
                                        onSubmitMissing={() => {
                                            dispositionStore.setQ2(dataStore.returnOtherTextMissing)
                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q2'), 1)
                                        }}/>
                                    : <></>}
                                Number of months
                            </Typography>
                            <TextField
                                size={'small'}
                                type={"number"}
                                onWheel={(e) => e.target.blur()}
                                placeholder={'000'}
                                id="numberWeek"
                                inputProps={{min: 0, style: {textAlign: 'center'}}}
                                sx={{width: 100}}
                                value={dispositionStore.q2}
                                onChange={(event) => {
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q2'), 1)
                                    dispositionStore.setQ2(event.target.value)
                                }}/>
                        </Stack>
                    </QuestionBox>

                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(DispositionDataPage1);
