import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import theme from '../styles/theme'
import Box from "@mui/material/Box";

const MenuItem = ({icon, label, active, onClick}) => {
    return (
        <Button sx={{
            display: 'flex', alignItems: 'center',
            backgroundColor: active ? 'white' : theme.palette.secondary.main,
            width: active ? '90%' : '80%', p: 2,
            borderTopRightRadius: 32,
            borderBottomRightRadius: 32,
            mt: 1,
            py: 2,
            justifyContent: "flex-start",
            textTransform: 'none',
            '&:hover': {
                background: active ? 'white' : theme.palette.secondary.main,
            }
        }} onClick={onClick} variant="contained">
            <Box sx={{display: "flex", ml: 3}}>

                <img src={icon} alt={label}/>
                <Typography color={active ? theme.palette.primary.main : 'white'}
                            sx={{ml: 2, fontWeight: 'bold', fontSize: 18}}>
                    {label}
                </Typography>
            </Box>
        </Button>
    );
}

export default MenuItem;
