import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {RadioGroup, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import QuestionText from "../../components/QuestionText";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import RadioItem from "../../components/RadioItem";
import TextField from "@mui/material/TextField";
import QuestionsLayout from "../../layout/QuestionsLayout";
import MissingReason from "../../components/MissingReason";


const TimeDurationPage1 = () => {
    const {
        treatmentPatternsStore, dataStore,menuStore
    } = useStores()
    const [showMissing, setShowMissing] = React.useState('not active');

    const navigate = useNavigate()

    let params = useParams();
    useEffect(() => {
        treatmentPatternsStore.getTreatment(parseInt(params.patient),parseInt(params.number))
    }, [])

    const handleNext = async () => {
        if (!treatmentPatternsStore.checkMissing16) {
            await treatmentPatternsStore.updateTreatment(
                {
                    s5: treatmentPatternsStore.s5,
                    s6: treatmentPatternsStore.s6,
                    s7: JSON.stringify(treatmentPatternsStore.s7),
                }, parseInt(params.number),parseInt(params.patient))
            if(dataStore.forceUpdate == true){
                dataStore.setShowUpdateModal('not active')
            }
            if(dataStore.showUpdateModal !== 'active'){
                navigate(`/treatmentPatternsNeoadjuvant/${parseInt(params.patient)}/${parseInt(params.number)}`)
            }
        } else {
            alert('Please add reason for missing questions.')

            // if (!dataStore.globalMissing) {
            //     if (showMissing === 'active') {
            //         await treatmentPatternsStore.updateTreatment(
            //             {
            //                 s5: treatmentPatternsStore.s5 ? treatmentPatternsStore.s5 : dataStore.returnOtherTextMissing,
            //                 s6: treatmentPatternsStore.s6 ? treatmentPatternsStore.s6 : dataStore.returnOtherTextMissing,
            //                 s7: treatmentPatternsStore.s7?.main ? JSON.stringify(treatmentPatternsStore.s7) : dataStore.returnOtherObjectMissing,
            //             }, parseInt(params.number),parseInt(params.patient))
            //         navigate(`/treatmentPatternsNeoadjuvant/${parseInt(params.patient)}/${parseInt(params.number)}`)
            //
            //         setShowMissing('not active')
            //     } else {
            //         setShowMissing('active')
            //     }
            // } else {
            //     await treatmentPatternsStore.updateTreatment(
            //         {
            //             s5: treatmentPatternsStore.s5 ? treatmentPatternsStore.s5 : dataStore.returnOtherTextMissing,
            //             s6: treatmentPatternsStore.s6 ? treatmentPatternsStore.s6 : dataStore.returnOtherTextMissing,
            //             s7: treatmentPatternsStore.s7?.main ? JSON.stringify(treatmentPatternsStore.s7) : dataStore.returnOtherObjectMissing,
            //         }, parseInt(params.number),parseInt(params.patient))
            //     navigate(`/treatmentPatternsNeoadjuvant/${parseInt(params.patient)}/${parseInt(params.number)}`)
            // }
        }
    }
    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={treatmentPatternsStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>

                    <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                        {`Treatment patterns and healthcare resource use for subsequent treatment n = ${params.number}`}
                    </Typography>
                    <Typography color={'txtGrey'} sx={{fontSize: 20,mt:1}}>
                        [Please complete this section for “Subsequent treatment (n=1, 2, …)”]
                    </Typography>
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>
                    <QuestionBox>
                        <QuestionText>
                            Time from previous treatment initiation to subsequent treatment initiation
                            {dataStore.questionsMissing.includes('s5')
                                ? <MissingReason
                                    value={treatmentPatternsStore.s5}
                                    onSubmitMissing={()=>{
                                        treatmentPatternsStore.setS5(dataStore.returnOtherTextMissing)
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('s5'), 1)
                                    }}/> : <></>}
                        </QuestionText>
                        <Stack direction={'row'} sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography sx={{fontSize: 18, mr: 5}}>
                                Number of months
                            </Typography>
                            <TextField
                                size={'small'}
                                onWheel={(e) => e.target.blur()}
                                placeholder={'000'}
                                id="numberWeek"
                                inputProps={{style: {textAlign: 'center'}}}
                                sx={{width: 100}}
                                value={treatmentPatternsStore.s5 && treatmentPatternsStore.s5.startsWith('missing|') ? '': treatmentPatternsStore.s5}

                                onChange={(event) => {
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('s5'), 1)
                                    treatmentPatternsStore.setS5(event.target.value)
                                }}
                            />
                        </Stack>
                    </QuestionBox>

                    <QuestionBox>
                        <QuestionText>
                            Time from previous treatment completion/discontinuation to subsequent treatment
                            initiation
                            {dataStore.questionsMissing.includes('s6')
                                ? <MissingReason
                                    value={treatmentPatternsStore.s6}
                                    onSubmitMissing={()=>{
                                        treatmentPatternsStore.setS6(dataStore.returnOtherTextMissing)
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('s6'), 1)
                                    }}/> : <></>}
                        </QuestionText>
                        <Stack direction={'row'} sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography sx={{fontSize: 18, mr: 5}}>
                                Number of months
                            </Typography>
                            <TextField
                                size={'small'}
                                placeholder={'000'}
                                inputProps={{style: {textAlign: 'center'}}}
                                onWheel={(e) => e.target.blur()}
                                sx={{width: 100}}
                                value={treatmentPatternsStore.s6 && treatmentPatternsStore.s6.startsWith('missing|') ? '': treatmentPatternsStore.s6}

                                onChange={(event) => {
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('s6'), 1)
                                    treatmentPatternsStore.setS6(event.target.value)
                                }}
                            />
                        </Stack>
                    </QuestionBox>
                    <QuestionBox>
                        <QuestionText>
                            {`Total Duration of subsequent treatment (${params.number})`}
                            {dataStore.questionsMissing.includes('s7')
                                ? <MissingReason
                                    value={treatmentPatternsStore.s7?.type}
                                    onSubmitMissing={()=>{
                                        treatmentPatternsStore.setS7(
                                            {...treatmentPatternsStore.s7, type: dataStore.returnOtherTextMissing,}
                                        )
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('s7'), 1)
                                    }}/> : <></>}
                            {dataStore.questionsMissing.includes('s7value')
                                ? <MissingReason
                                    value={treatmentPatternsStore.s7?.value}
                                    onSubmitMissing={()=>{
                                        treatmentPatternsStore.setS7(
                                            {...treatmentPatternsStore.s7, value: dataStore.returnOtherTextMissing,})

                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('s7value'), 1)
                                    }}/> : <></>}
                        </QuestionText>
                        <Stack direction={'row'} sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={(event) => {
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('s7'), 1)

                                    treatmentPatternsStore.setS7(
                                        {...treatmentPatternsStore.s7, type: event.target.value,}
                                    )
                                }}
                                value={treatmentPatternsStore.s7.type}
                                sx={{display: 'flex', flexDirection: 'row', gap: 3}}
                            >
                                <RadioItem label={'Weeks'}/>
                                <RadioItem label={'Days'}/>
                            </RadioGroup>
                            {treatmentPatternsStore.s7.type != null &&
                                <Stack direction={'row'} sx={{display: 'flex', alignItems: 'center'}}>
                                    <Typography sx={{fontSize: 18, mx: 3}}>
                                        {treatmentPatternsStore.s7.type === 'Weeks' ? 'Number of Weeks' : 'Number of Days'}
                                    </Typography>
                                    <TextField
                                        size={"small"}
                                        inputProps={{style: {textAlign: 'center'}}}
                                        onWheel={(e) => e.target.blur()}
                                        sx={{width: 100}}
                                        onChange={(event) => {
                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('s7value'), 1)

                                            treatmentPatternsStore.setS7(
                                            {...treatmentPatternsStore.s7, value: event.target.value,}
                                        )}}
                                        value={treatmentPatternsStore.s7?.value && treatmentPatternsStore.s7.value.startsWith('missing|') ? '': treatmentPatternsStore.s7.value}

                                    />
                                </Stack>}
                        </Stack>
                    </QuestionBox>


                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(TimeDurationPage1);
