import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {Checkbox, ClickAwayListener, FormControlLabel, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import Paper from "@mui/material/Paper";
import YesNo from "../../components/YesNo";
import QuestionText from "../../components/QuestionText";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import arrowDown from "../../images/svgs/Arrow-down.svg";
import QuestionsLayout from "../../layout/QuestionsLayout";
import Required from "../../components/Required";
import MissingReason from "../../components/MissingReason";
import arrowUp from "../../images/svgs/Arrow-up.svg";


const SubsequentTreatmentTumorBiologyPage2 = () => {
    const {
        treatmentPatternsStore, dataStore,menuStore
    } = useStores()

    const navigate = useNavigate()
    const [openMenu, setOpenMenu] = React.useState(false);
    const [showMissing, setShowMissing] = React.useState('not active');

    let params = useParams();
    useEffect(() => {
        treatmentPatternsStore.getTreatment(parseInt(params.patient),parseInt(params.number))
    }, [])
    const checkboxPress = (event) => {
        if (event.target.checked) {
            if (treatmentPatternsStore.s3.method.length === 0) {
                treatmentPatternsStore.setS3(
                    {
                        ...treatmentPatternsStore.s3,
                        method: [event.target.value],
                    }
                )
            } else {
                if (event.target.value === 'Unknown') {
                    treatmentPatternsStore.setS3(
                        {
                            ...treatmentPatternsStore.s3,
                            method: [event.target.value],
                        }
                    )
                } else {
                    let method = treatmentPatternsStore.s3.method
                    method.push(event.target.value)
                    treatmentPatternsStore.setS3(
                        {
                            ...treatmentPatternsStore.s3,
                            method: method,
                        }
                    )
                }
            }

        } else {
            if (event.target.value === 'Unknown') {
                treatmentPatternsStore.setS3(
                    {
                        ...treatmentPatternsStore.s3,
                        method: [],
                    }
                )
            } else {
                let methods = treatmentPatternsStore.s3.method.filter(text => text !== event.target.value);
                treatmentPatternsStore.setS3(
                    {
                        ...treatmentPatternsStore.s3,
                        method: methods
                    }
                )
            }
        }
    }

    const genomicPress = (enableParam, disableParam, event) => {
        if(treatmentPatternsStore.s3.genomic.Inconclusive == null || treatmentPatternsStore.s3.genomic.Inconclusive === false || enableParam.Inconclusive) {
            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('s3value'), 1)
            if (event.target.checked) {
                treatmentPatternsStore.setS3(
                    {
                        ...treatmentPatternsStore.s3,
                        genomic: {...treatmentPatternsStore.s3.genomic, ...enableParam,}
                    }
                )
            } else {
                treatmentPatternsStore.setS3(
                    {
                        ...treatmentPatternsStore.s3,
                        genomic: {...treatmentPatternsStore.s3.genomic, ...disableParam,}
                    }
                )
            }
        }
    }


    const EGFRPress = (event) => {
        if(treatmentPatternsStore.s3.genomic.Inconclusive == null || treatmentPatternsStore.s3.genomic.Inconclusive == false) {
            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('EGFR'), 1)

            if (event.target.checked) {
                if (treatmentPatternsStore.s3.genomic.EGFR?.values) {
                    let EGFR = treatmentPatternsStore.s3.genomic.EGFR.values
                    EGFR.push(event.target.value)
                    treatmentPatternsStore.setS3(
                        {
                            ...treatmentPatternsStore.s3,
                            genomic: {
                                ...treatmentPatternsStore.s3.genomic,
                                EGFR: {...treatmentPatternsStore.s3.genomic.EGFR, values: EGFR}
                            },

                        }
                    )
                } else {
                    treatmentPatternsStore.setS3(
                        {
                            ...treatmentPatternsStore.s3,
                            genomic: {
                                ...treatmentPatternsStore.s3.genomic,
                                EGFR: {...treatmentPatternsStore.s3.genomic.EGFR, values: [event.target.value]}
                            },
                        }
                    )
                }

            } else {
                let EGFRs = treatmentPatternsStore.s3.genomic.EGFR.values.filter(text => text !== event.target.value);
                treatmentPatternsStore.setS3(
                    {
                        ...treatmentPatternsStore.s3,
                        genomic: {
                            ...treatmentPatternsStore.s3.genomic,
                            EGFR: {
                                ...treatmentPatternsStore.s3.genomic.EGFR,
                                values: EGFRs
                            }
                        },
                    }
                )
            }
        }
    }

    const handleNext = async () => {
        if (!treatmentPatternsStore.checkMissing14) {
            if(treatmentPatternsStore.s3.main !== 'true' || treatmentPatternsStore.s3.method.length > 0) {

                await treatmentPatternsStore.updateTreatment(
                    {
                        s3: JSON.stringify(treatmentPatternsStore.s3),
                    }, parseInt(params.number), parseInt(params.patient))
                if (dataStore.forceUpdate === true) {
                    dataStore.setShowUpdateModal('not active')
                }
                if (dataStore.showUpdateModal !== 'active') {
                    navigate(`/subsequentTreatmentTumorBiologyPage3/${parseInt(params.patient)}/${parseInt(params.number)}`)

                }

            }else{
                alert('Please answer all required questions.')
            }
        } else {
            alert('Please add reason for missing questions.')

            // if (!dataStore.globalMissing) {
            //     if (showMissing === 'active') {
            //         await treatmentPatternsStore.updateTreatment(
            //             {
            //                 s3: treatmentPatternsStore.s3?.main ? JSON.stringify(treatmentPatternsStore.s3) : dataStore.returnOtherObjectMissing,
            //             }, parseInt(params.number),parseInt(params.patient))
            //         navigate(`/subsequentTreatmentTumorBiologyPage3/${parseInt(params.patient)}/${parseInt(params.number)}`)
            //         setShowMissing('not active')
            //     } else {
            //         setShowMissing('active')
            //     }
            // } else {
            //     await treatmentPatternsStore.updateTreatment(
            //         {
            //             s3: treatmentPatternsStore.s3?.main ? JSON.stringify(treatmentPatternsStore.s3) : dataStore.returnOtherObjectMissing,
            //         }, parseInt(params.number),parseInt(params.patient))
            //     navigate(`/subsequentTreatmentTumorBiologyPage3/${parseInt(params.patient)}/${parseInt(params.number)}`)
            // }
        }
    }

    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={treatmentPatternsStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>

                    <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                        {`Subsequent Treatment (n = ${params.number})`}
                    </Typography>
                </Stack>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>

                    <Typography sx={{fontWeight: 'bold', fontSize: 30,}}>
                        Tumor biology at subsequent treatments
                    </Typography>
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>

                    <QuestionBox>
                        <Stack direction={'row'}
                               sx={{width: '100%', display: 'flex', justifyContent: 'space-between',alignItems:'center'}}>
                            <QuestionText>
                                Genomic alterations test performed? <Required/>
                            </QuestionText>
                            <YesNo value={treatmentPatternsStore.s3.main}
                                   handleChange={(event) => treatmentPatternsStore.setS3(
                                       {
                                           ...treatmentPatternsStore.s3,
                                           main: event.target.value,
                                       }
                                   )}
                            />
                        </Stack>
                        {treatmentPatternsStore.s3.main === 'true' ?
                            <QuestionText width={'100%'}>
                                <Box sx={{
                                    display: 'flex',
                                    gap: "24px",
                                    rowGap: 0,
                                    width: '100%',
                                    alignItems: 'center'
                                }}>
                                    <li>Method</li>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(event) => checkboxPress(event)}
                                                checked={treatmentPatternsStore.s3?.method && treatmentPatternsStore.s3.method.includes('NGS')}
                                                value="NGS"
                                            />
                                        }
                                        label="NGS"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(event) => checkboxPress(event)}
                                                checked={treatmentPatternsStore.s3?.method && treatmentPatternsStore.s3.method.includes('PCR')}
                                                value="PCR"
                                            />
                                        }
                                        label="PCR"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(event) => checkboxPress(event)}
                                                checked={treatmentPatternsStore.s3?.method && treatmentPatternsStore.s3.method.includes('Unknown')}
                                                disabled={treatmentPatternsStore.s3?.method && treatmentPatternsStore.s3.method.includes('PCR') && treatmentPatternsStore.s3.method.includes('NGS')}

                                                value="Unknown"
                                            />
                                        }
                                        label="Unknown"
                                    />


                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    flexDirection: 'column'
                                }}>
                                    <li>Genomic alterations described: (select all applicable)

                                        {dataStore.questionsMissing.includes('s3value')
                                            ? <MissingReason
                                                value={treatmentPatternsStore.s3.missingGenomicReason}
                                                onSubmitMissing={async ()=>{

                                                    treatmentPatternsStore.setS3(
                                                        {...treatmentPatternsStore.s3, missingGenomicReason:dataStore.returnOtherTextMissing}
                                                    )
                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('s3value'), 1)
                                                }}/> : <></>}
                                    </li>
                                    <Box
                                        sx={{
                                            display: 'flex', flex: 1,
                                            flexDirection: 'column', width: '100%',
                                        }}
                                    >
                                        <Box sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => genomicPress(
                                                            {ALK: event.target.value}, {ALK: !event.target.value}, event)}
                                                        checked={treatmentPatternsStore.s3.genomic.ALK}
                                                    />
                                                }
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {fontSize: 18},
                                                    flex: 1
                                                }}
                                                label="ALK translocation"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => genomicPress(
                                                            {BRAF: event.target.value}, {BRAF: !event.target.value}, event)}
                                                        checked={treatmentPatternsStore.s3.genomic.BRAF}
                                                    />
                                                }
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {fontSize: 18},
                                                    flex: 1
                                                }}
                                                label="BRAF V600 mutation"
                                            />
                                        </Box>

                                        <Box sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            flex: 1
                                        }}>
                                            <Box sx={{width: '50%', display: "flex", alignItems: "center", flex: 1}}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onChange={async (event) => {
                                                                if(treatmentPatternsStore.s3.genomic.Inconclusive == null || treatmentPatternsStore.s3.genomic.Inconclusive == false){

                                                                    if (treatmentPatternsStore.s3.genomic.EGFR.selected === 'on') {
                                                                    await treatmentPatternsStore.setS3(
                                                                        {
                                                                            ...treatmentPatternsStore.s3,
                                                                            genomic: {
                                                                                ...treatmentPatternsStore.s3.genomic,
                                                                                EGFR: {
                                                                                    selected: null,
                                                                                    values: []
                                                                                }
                                                                            },
                                                                        }
                                                                    )
                                                                    setOpenMenu(false)

                                                                } else {
                                                                    await treatmentPatternsStore.setS3(
                                                                        {
                                                                            ...treatmentPatternsStore.s3,
                                                                            genomic: {
                                                                                ...treatmentPatternsStore.s3.genomic,
                                                                                EGFR: {
                                                                                    ...treatmentPatternsStore.s3.genomic.EGFR,
                                                                                    selected: event.target.value
                                                                                }
                                                                            },
                                                                        }
                                                                    )
                                                                    setOpenMenu(true)

                                                                }}

                                                                console.log('openMenu', openMenu)

                                                            }}

                                                            checked={treatmentPatternsStore.s3.genomic.EGFR.selected}
                                                        />
                                                    }
                                                    sx={{
                                                        '& .MuiFormControlLabel-label': {fontSize: 18},
                                                    }}
                                                    label="EGFR activating mutations"
                                                />
                                                <img onClick={async () => {
                                                    await treatmentPatternsStore.setS3(
                                                        {
                                                            ...treatmentPatternsStore.s3,
                                                            genomic: {
                                                                ...treatmentPatternsStore.s3.genomic,
                                                                EGFR: {
                                                                    ...treatmentPatternsStore.s3.genomic.EGFR,
                                                                    selected: 'on'
                                                                }
                                                            },
                                                        }
                                                    )
                                                    setOpenMenu(true)
                                                }} style={{
                                                    cursor: 'pointer',
                                                    width: 18,
                                                    height: 18,
                                                }}
                                                     src={arrowDown}/>
                                            </Box>
                                            {openMenu && <ClickAwayListener onClickAway={() => {
                                                setOpenMenu(false)
                                            }}><Paper sx={{
                                                display: 'flex',
                                                width: '50%',
                                                backgroundColor: 'white',
                                                mt: -1,
                                                ml: -3,
                                                position: 'absolute',
                                                p: 3,
                                                pt: 1,
                                                borderRadius: 3,
                                                flexDirection: 'column',
                                                zIndex: 999,
                                            }} elevation={5}>
                                                <Stack sx={{width: '60%', display: "flex", alignItems: "center"}}
                                                       direction={'row'}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={async () => {
                                                                    await treatmentPatternsStore.setS3(
                                                                        {
                                                                            ...treatmentPatternsStore.s3,
                                                                            genomic: {
                                                                                ...treatmentPatternsStore.s3.genomic,
                                                                                EGFR: {
                                                                                    selected: null,
                                                                                    values: []
                                                                                }
                                                                            },
                                                                        }
                                                                    )
                                                                    setOpenMenu(false)

                                                                    console.log('openMenu', openMenu)

                                                                }}
                                                                checked={treatmentPatternsStore.s3.genomic.EGFR.selected}
                                                            />
                                                        }
                                                        sx={{
                                                            '& .MuiFormControlLabel-label': {fontSize: 18},
                                                        }}
                                                        label="EGFR activating mutations"
                                                    />
                                                    <img onClick={() => setOpenMenu(false)}
                                                         style={{
                                                             cursor: 'pointer',
                                                             width: 18,
                                                             height: 18,
                                                         }}
                                                         src={arrowUp}/>
                                                    {dataStore.questionsMissing.includes('EGFR')
                                                        ? <MissingReason
                                                            value={treatmentPatternsStore.s3?.genomic?.EGFR?.selected}
                                                            style={{right:10,marginTop:3}}
                                                            onSubmitMissing={async ()=>{
                                                                treatmentPatternsStore.setS3(
                                                                    {
                                                                        ...treatmentPatternsStore.s3,
                                                                        genomic: {
                                                                            ...treatmentPatternsStore.s3.genomic,
                                                                            EGFR: {
                                                                                selected: dataStore.returnOtherTextMissing,
                                                                                values: []
                                                                            }
                                                                        },
                                                                    })
                                                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('EGFR'), 1)
                                                            }}/> : <></>}
                                                </Stack>
                                                <Stack direction={'row'} sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    width: '100%',
                                                    px: 3
                                                }}>
                                                    <Box sx={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        flex: 1
                                                    }}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    onChange={(event) => EGFRPress(event)}
                                                                    checked={treatmentPatternsStore.s3.genomic?.EGFR.values &&
                                                                        treatmentPatternsStore.s3.genomic.EGFR.values.length > 0 &&
                                                                        treatmentPatternsStore.s3.genomic.EGFR.values.includes('Exon 18 mutation')}
                                                                />
                                                            }
                                                            sx={{
                                                                '& .MuiFormControlLabel-label': {fontSize: 18},
                                                                flex: 1
                                                            }}
                                                            value={'Exon 18 mutation'}
                                                            label="Exon 18 mutation"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    onChange={(event) => EGFRPress(event)}
                                                                    checked={treatmentPatternsStore.s3.genomic?.EGFR.values &&
                                                                        treatmentPatternsStore.s3.genomic.EGFR.values.length > 0 &&
                                                                        treatmentPatternsStore.s3.genomic.EGFR.values.includes('Exon 19 mutation')}
                                                                />
                                                            }
                                                            sx={{
                                                                '& .MuiFormControlLabel-label': {fontSize: 18},
                                                                flex: 1
                                                            }}
                                                            value={'Exon 19 mutation'}
                                                            label="Exon 19 mutation"
                                                        />
                                                    </Box>

                                                    <Box sx={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        flex: 1
                                                    }}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    onChange={(event) => EGFRPress(event)}
                                                                    checked={treatmentPatternsStore.s3.genomic?.EGFR.values &&
                                                                        treatmentPatternsStore.s3.genomic.EGFR.values.length > 0 &&
                                                                        treatmentPatternsStore.s3.genomic.EGFR.values.includes('Exon 20 mutation')}
                                                                />
                                                            }
                                                            sx={{
                                                                '& .MuiFormControlLabel-label': {fontSize: 18},
                                                                flex: 1
                                                            }}
                                                            value={'Exon 20 mutation'}
                                                            label="Exon 20 mutation"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    onChange={(event) => EGFRPress(event)}
                                                                    checked={treatmentPatternsStore.s3.genomic?.EGFR.values &&
                                                                        treatmentPatternsStore.s3.genomic.EGFR.values.length > 0 &&
                                                                        treatmentPatternsStore.s3.genomic.EGFR.values.includes('Exon 21 mutation')}
                                                                />
                                                            }
                                                            sx={{
                                                                '& .MuiFormControlLabel-label': {fontSize: 18},
                                                                flex: 1
                                                            }}
                                                            value={'Exon 21 mutation'}
                                                            label="Exon 21 mutation"
                                                        />
                                                    </Box>

                                                    <Box sx={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        flex: 1
                                                    }}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    onChange={(event) => EGFRPress(event)}
                                                                    checked={treatmentPatternsStore.s3.genomic?.EGFR.values &&
                                                                        treatmentPatternsStore.s3.genomic.EGFR.values.length > 0 &&
                                                                        treatmentPatternsStore.s3.genomic.EGFR.values.includes('Other')}
                                                                />
                                                            }
                                                            sx={{
                                                                '& .MuiFormControlLabel-label': {fontSize: 18},
                                                                flex: 1
                                                            }}
                                                            value={'Other'}
                                                            label="Other"
                                                        />

                                                    </Box>
                                                </Stack>

                                            </Paper></ClickAwayListener>}

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => genomicPress(
                                                            {MET: event.target.value}, {MET: !event.target.value}, event)}
                                                        checked={treatmentPatternsStore.s3.genomic.MET}
                                                    />

                                                }
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {fontSize: 18},
                                                    flex: 1
                                                }}
                                                label="MET mutation or amplification"
                                            />
                                        </Box>

                                        <Box sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => genomicPress(
                                                            {NTRK: event.target.value}, {NTRK: !event.target.value}, event)}
                                                        checked={treatmentPatternsStore.s3.genomic.NTRK}
                                                    />
                                                }
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {fontSize: 18},
                                                    flex: 1
                                                }}
                                                label="NTRK fusions"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => genomicPress(
                                                            {ROS: event.target.value}, {ROS: !event.target.value}, event)}
                                                        checked={treatmentPatternsStore.s3.genomic.ROS}
                                                    />
                                                }
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {fontSize: 18},
                                                    flex: 1
                                                }}
                                                label="ROS 1 translocation"
                                            />
                                        </Box>

                                        <Box sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => genomicPress(
                                                            {HER2: event.target.value}, {HER2: !event.target.value}, event)}
                                                        checked={treatmentPatternsStore.s3.genomic.HER2}
                                                    />
                                                }
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {fontSize: 18},
                                                    flex: 1
                                                }}
                                                label="HER2 mutation"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => genomicPress(
                                                            {KRAS: event.target.value}, {KRAS: !event.target.value}, event)}
                                                        checked={treatmentPatternsStore.s3.genomic.KRAS}
                                                    />
                                                }
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {fontSize: 18},
                                                    flex: 1
                                                }}
                                                label="KRAS mutation"
                                            />
                                        </Box>

                                        <Box sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => genomicPress(
                                                            {RET: event.target.value}, {RET: !event.target.value}, event)}
                                                        checked={treatmentPatternsStore.s3.genomic.RET}
                                                    />
                                                }
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {fontSize: 18},
                                                    flex: 1
                                                }}
                                                label="RET translocation"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => genomicPress(
                                                            {Other: event.target.value}, {Other: !event.target.value}, event)}
                                                        checked={treatmentPatternsStore.s3.genomic.Other}
                                                    />
                                                }
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {fontSize: 18},
                                                    flex: 1
                                                }}
                                                label="Other"
                                            />
                                        </Box>
                                        <Box sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}>
                                        {treatmentPatternsStore.showInconclusive() ?
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => genomicPress(
                                                            {Inconclusive: event.target.value}, {Inconclusive: !event.target.value}, event)}
                                                        checked={treatmentPatternsStore.s3.genomic.Inconclusive}
                                                    />
                                                }
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {fontSize: 18},
                                                    flex: 1
                                                }}
                                                label="Inconclusive"
                                            />: <></>}
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => genomicPress(
                                                            {NoMutation: event.target.value}, {NoMutation: !event.target.value}, event)}
                                                        checked={treatmentPatternsStore.s3.genomic.NoMutation}
                                                    />
                                                }
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {fontSize: 18},
                                                    flex: 1
                                                }}
                                                label="No mutation"
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </QuestionText> : <></>}
                    </QuestionBox>


                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(SubsequentTreatmentTumorBiologyPage2);
