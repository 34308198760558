import {action, makeObservable, observable,} from 'mobx'
import {CrfService} from "../../services/openapi";
import {Stores} from "../../store";

class SocioDemographicCharacteristicsStore {

    loading = false
    q1 = null
    q2 = ''
    q3 = null
    q4 = null
    questionsMissing = false

    constructor() {

        makeObservable(this, {
            loading: observable,
            q1: observable,
            q2: observable,
            q3: observable,
            q4: observable,
            questionsMissing: observable,

            setQ1: action,
            setQ2: action,
            setQ3: action,
            setQ4: action,
            updateSocioDemographic: action,
            getSocioDemographic: action,
            setLoading: action,
            setQuestionsMissing: action,
            reset: action,
            checkMissing1: action,

        },)
    }

    setQuestionsMissing = (value) => {
        this.questionsMissing = value
    }
    reset = () => {
        this.setQ1(null)
        this.setQ2('')
        this.setQ3(null)
        this.setQ4(null)
    }

    checkMissing1 = () => {


        if (this.q2 === '' || this.q2 == null) {
            this.setQuestionsMissing(true)
        }else{
            this.setQuestionsMissing(false)

        }

    }


    setQ1 = (value) => {
        this.q1 = value
    }

    setQ2 = (value) => {
        this.q2 = value
    }
    setQ3 = (value) => {
        this.q3 = value
    }
    setQ4 = (value) => {
        this.q4 = value
    }

    setLoading = (value) => {
        this.loading = value
    }

    updateSocioDemographic = async (requestBody,patient) => {
        try {
            this.setLoading(true)
            let params = {...requestBody,force:Stores.dataStore.forceUpdate}

            if(Stores.dataStore.updateReason !== ''){
                params = {...params, reason:Stores.dataStore.updateReason}
            }
            console.log('updateSocioDemographic params', params)

            const response = await CrfService.updateSocioDemographic(patient, params)
            console.log('updateSocioDemographic response', response)
            this.setQ1(response.q1)
            this.setQ2(response.q2)
            this.setQ3(response.q3)
            this.setQ4(response.q4)
            Stores.dataStore.resetUpdateModalData()
            this.setLoading(false)

        } catch (err) {
            this.setLoading(false)
            console.log('updateSocioDemographic err', err)
            if(err?.body?.data?.updated){
                console.log('updateSocioDemographic err', err.body.data.updated)

                Stores.dataStore.setShowUpdateModal('active')
            }else {
                Stores.dataStore.setShowUpdateModal('not active')
            }
        }
    }


    getSocioDemographic = async (patient) => {
        try {
            this.setLoading(true)

            const response = await CrfService.getSocioDemographic(patient)
            console.log('getSocioDemographic response', response)

            this.setQ1(response.q1)
            console.log('response',response)
            console.log('response.q2',response.q2)
            if(response.q2 && response.q2.startsWith('missing|')){
                this.questionsMissing = true
            }
            this.setQ2(response.q2)
            this.setQ3(response.q3)
            this.setQ4(response.q4)

            Stores.dataStore.setForceUpdate(false)


            this.setLoading(false)

        } catch (err) {
            this.setLoading(false)
            console.log('getSocioDemographic err', err)
        }
    }

}


export default SocioDemographicCharacteristicsStore
