import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {Checkbox, FormControlLabel, RadioGroup, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import YesNo from "../../components/YesNo";
import QuestionText from "../../components/QuestionText";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import RadioItem from "../../components/RadioItem";
import TextField from "@mui/material/TextField";
import QuestionsLayout from "../../layout/QuestionsLayout";
import Grid from "@mui/material/Grid";
import MissingReason from "../../components/MissingReason";

const invalidChars = [
    "-",
    "+",
    "e",
];
const TreatmentPatternsSurgery = () => {
    const {
        treatmentPatternsStore, dataStore,menuStore
    } = useStores()
    let params = useParams();
    useEffect(() => {
        (async () => {
            await treatmentPatternsStore.getTreatment(parseInt(params.patient), parseInt(params.number))
            if (parseInt(treatmentPatternsStore.q4.numberOfSurgery) !== treatmentPatternsStore.q5.length) {
                for (let i = 0; i < parseInt(treatmentPatternsStore.q4.numberOfSurgery) - 1; i++) {
                    console.log('response.q4.numberOfSurgery', treatmentPatternsStore.q4.numberOfSurgery)
                    treatmentPatternsStore.q5.push({
                        surgicalProcedure: null,
                        anatomicalResection: [],
                    })
                }
            }
        })()
    }, [])
    const navigate = useNavigate()
    const [selectedSurgery, setSelectedSurgery] = React.useState(1);
    const [showMissing, setShowMissing] = React.useState('not active');
    const handleNext = async () => {
        if (parseInt(treatmentPatternsStore.q4.numberOfSurgery) > selectedSurgery) {
            setSelectedSurgery(selectedSurgery + 1)
            return
        }
        if (!treatmentPatternsStore.checkMissing3) {
            await treatmentPatternsStore.updateTreatment(
                {
                    q4: JSON.stringify(treatmentPatternsStore.q4),
                    q5: JSON.stringify(treatmentPatternsStore.q5),
                }, parseInt(params.number),parseInt(params.patient))
            if(dataStore.forceUpdate == true){
                dataStore.setShowUpdateModal('not active')
            }
            if(dataStore.showUpdateModal !== 'active'){
                navigate(`/treatmentPatternsRTAlone/${parseInt(params.patient)}/${parseInt(params.number)}`)
            }

        } else {
            alert('Please add reason for missing questions.')

            // if (!dataStore.globalMissing) {
            //     if (showMissing === 'active') {
            //         await treatmentPatternsStore.updateTreatment(
            //             {
            //                 q4: treatmentPatternsStore.q4?.main ? JSON.stringify(treatmentPatternsStore.q4) : dataStore.returnOtherObjectMissing,
            //                 q5: treatmentPatternsStore.q5?.main ? JSON.stringify(treatmentPatternsStore.q5) : dataStore.returnOtherObjectMissing,
            //             }, parseInt(params.number),parseInt(params.patient))
            //         navigate(`/treatmentPatternsRTAlone/${parseInt(params.patient)}/${parseInt(params.number)}`)
            //
            //         setShowMissing('not active')
            //     } else {
            //         setShowMissing('active')
            //     }
            // } else {
            //     await treatmentPatternsStore.updateTreatment(
            //         {
            //             q4: treatmentPatternsStore.q4?.main ? JSON.stringify(treatmentPatternsStore.q4) : dataStore.returnOtherObjectMissing,
            //             q5: treatmentPatternsStore.q5?.main ? JSON.stringify(treatmentPatternsStore.q5) : dataStore.returnOtherObjectMissing,
            //         }, parseInt(params.number),parseInt(params.patient))
            //     navigate(`/treatmentPatternsRTAlone/${parseInt(params.patient)}/${parseInt(params.number)}`)
            // }
        }
    }
    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => {
                             if (selectedSurgery === 1) {
                                 navigate(-1)
                             } else {
                                 setSelectedSurgery(selectedSurgery - 1)
                             }
                         }}
                         loading={treatmentPatternsStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>
                    {parseInt(params.number) === 0 ?
                        <div>
                            <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                                Treatment patterns and healthcare resource use (Only Initial Treatment)
                            </Typography>
                            <Typography color={'txtGrey'} sx={{fontSize: 20,mt:1}}>
                                (Please complete this section only for initial treatment.)
                            </Typography>

                        </div> :
                        <div>
                            <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                                {`Treatment patterns and healthcare resource use for subsequent treatment n = ${params.number}`}
                            </Typography>
                            <Typography color={'txtGrey'} sx={{fontSize: 20,mt:1}}>
                                [Please complete this section for “Subsequent treatment (n=1, 2, …)”]
                            </Typography>
                        </div>
                    }
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>
                    <Typography sx={{fontWeight: 'bold', fontSize: 30,}}>
                        Surgery
                    </Typography>
                    <QuestionBox allInRow={true}>
                        <YesNo value={treatmentPatternsStore.q4?.main}
                               handleChange={(event) => {
                                   dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q4'), 1)

                                   if (event.target.value === 'false') {
                                       treatmentPatternsStore.setQ4(
                                           {...treatmentPatternsStore.q4, numberOfSurgery: '',}
                                       )
                                       treatmentPatternsStore.setQ5(
                                           [{
                                               surgicalProcedure: null,
                                               anatomicalResection: [],
                                           }])
                                   }
                                   treatmentPatternsStore.setQ4(
                                       {...treatmentPatternsStore.q4, main: event.target.value,})
                               }}
                        >
                            {dataStore.questionsMissing.includes('q4')
                                ? <MissingReason
                                    value={treatmentPatternsStore.q4.main}
                                    style={{right:60,marginTop:-3}}
                                    onSubmitMissing={async ()=>{
                                        treatmentPatternsStore.setQ4(
                                            {...treatmentPatternsStore.q4, main: dataStore.returnOtherTextMissing,})
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q4'), 1)
                                    }}/> : <></>}
                        </YesNo>
                        {treatmentPatternsStore.q4?.main && treatmentPatternsStore.q4.main === 'true' ?<Stack direction={'row'} sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography sx={{fontSize: 18, mr: 5}}>
                                {dataStore.questionsMissing.includes('q4numberOfSurgery') ?
                                    <MissingReason
                                        value={treatmentPatternsStore.q4.numberOfSurgery}
                                        style={{marginTop:-8,marginLeft:-80}}
                                        onSubmitMissing={async ()=>{
                                            treatmentPatternsStore.setQ4(
                                                {
                                                    ...treatmentPatternsStore.q4,
                                                    numberOfSurgery: dataStore.returnOtherTextMissing,
                                                }
                                            )
                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q4numberOfSurgery'), 1)
                                        }}/>
                                    : <></>}
                                Number of surgeries
                            </Typography>
                            <TextField
                                size={'small'}
                                type={"number"}
                                sx={{width: 100}}
                                inputProps={{min: 0, max: 10, style: {textAlign: 'center'}}}
                                onKeyDown={(e) => {
                                    if (invalidChars.includes(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                onWheel={(e) => e.target.blur()}
                                onChange={(event) => {
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q4numberOfSurgery'), 1)

                                    setSelectedSurgery(1)
                                    treatmentPatternsStore.setQ4(
                                        {
                                            ...treatmentPatternsStore.q4,
                                            numberOfSurgery: event.target.value,
                                        }
                                    )
                                    if (treatmentPatternsStore.q5.length > event.target.value) {
                                        let numberOfSurgeryRemove = treatmentPatternsStore.q5.length - treatmentPatternsStore.q4.numberOfSurgery
                                        for (let i = 0; i < numberOfSurgeryRemove; i++) {
                                            treatmentPatternsStore.q5.pop()
                                        }
                                    } else if (event.target.value > treatmentPatternsStore.q5.length) {
                                        let numberOfSurgeryAdd = treatmentPatternsStore.q4.numberOfSurgery - treatmentPatternsStore.q5.length
                                        for (let i = 0; i < numberOfSurgeryAdd; i++) {
                                            treatmentPatternsStore.q5.push({
                                                surgicalProcedure: null,
                                                anatomicalResection: [],
                                            })
                                        }
                                    }
                                    treatmentPatternsStore.setQ5(
                                        treatmentPatternsStore.q5
                                    )
                                }}
                                value={treatmentPatternsStore.q4?.numberOfSurgery && treatmentPatternsStore.q4.numberOfSurgery.startsWith('missing|') ? '': treatmentPatternsStore.q4.numberOfSurgery}

                            />
                        </Stack> : <></>}
                    </QuestionBox>
                    {treatmentPatternsStore.q4?.main && treatmentPatternsStore.q4.main === 'true' &&
                        treatmentPatternsStore.q4.numberOfSurgery !== '' && !treatmentPatternsStore.q4.numberOfSurgery.includes('missing') &&
                        treatmentPatternsStore.q5.map((item, index) => {
                            if (index + 1 === selectedSurgery) {
                                return (
                                    <QuestionBox>
                                        <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 25,}}>
                                            Surgery {index + 1}

                                        </Typography>

                                        <QuestionText>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                flexDirection: 'column',
                                                pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0
                                            }}>
                                                <li>Surgical Procedure
                                                    {dataStore.questionsMissing.includes('surgicalProcedure'+index)
                                                        ? <MissingReason
                                                            value={ treatmentPatternsStore?.q5[index]?.surgicalProcedure}
                                                            style={{right:60,marginTop:3}}
                                                            onSubmitMissing={async ()=>{

                                                                let q5Array = treatmentPatternsStore.q5
                                                                q5Array[index] ={
                                                                    ...item,
                                                                    surgicalProcedure:dataStore.returnOtherTextMissing,
                                                                }
                                                                treatmentPatternsStore.setQ5(
                                                                    q5Array
                                                                )
                                                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('surgicalProcedure'+index), 1)
                                                            }}/> : <></>}
                                                </li>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                    onChange={(event) => {
                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('surgicalProcedure'+index), 1)

                                                        let q5Array = treatmentPatternsStore.q5
                                                        q5Array[index] = {
                                                            ...item,
                                                            surgicalProcedure: event.target.value
                                                        }
                                                        treatmentPatternsStore.setQ5(
                                                            q5Array
                                                        )
                                                    }}
                                                    value={item.surgicalProcedure}
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between'
                                                    }}
                                                >
                                                    <RadioItem
                                                        label={'Video-assisted thoracoscopic surgery (VATS)'}/>
                                                    <RadioItem label={'Thoracotomy'}/>
                                                    <RadioItem label={'VATS conversion to thoracotomy'}/>
                                                </RadioGroup>
                                            </Box>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                maxWidth: "780px",
                                                flexDirection: 'column',
                                                mt: 3,
                                                pl: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 2 : 0

                                            }}>
                                                <li>Anatomical Resection

                                                    {dataStore.questionsMissing.includes('anatomicalResection'+index)
                                                        ? <MissingReason
                                                            value={treatmentPatternsStore?.q5[index]?.missingAnatomicalReason}
                                                            style={{right:60,marginTop:3}}
                                                            onSubmitMissing={async ()=>{

                                                                let q5Array = treatmentPatternsStore.q5
                                                                q5Array[index] ={
                                                                    ...item,
                                                                    missingAnatomicalReason:dataStore.returnOtherTextMissing,
                                                                }
                                                                treatmentPatternsStore.setQ5(
                                                                    q5Array
                                                                )
                                                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('anatomicalResection'+index), 1)
                                                            }}/> : <></>}
                                                </li>
                                                <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                    <Grid item xs={4}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    onChange={() => {
                                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('anatomicalResection'+index), 1)

                                                                        let q5Array = treatmentPatternsStore.q5
                                                                        let anatomicalResection = q5Array[index].anatomicalResection
                                                                        if(anatomicalResection && anatomicalResection.length > 0){
                                                                            if(!anatomicalResection.includes('Segmentectomy')){
                                                                                anatomicalResection.push('Segmentectomy')
                                                                            }else{

                                                                                anatomicalResection = anatomicalResection.filter(e => e !== 'Segmentectomy')
                                                                            }
                                                                        }else{
                                                                            anatomicalResection = ['Segmentectomy']
                                                                        }

                                                                        q5Array[index] = {
                                                                            ...item,
                                                                            anatomicalResection: anatomicalResection,
                                                                            missingAnatomicalReason:null
                                                                        }

                                                                        treatmentPatternsStore.setQ5(
                                                                            q5Array
                                                                        )
                                                                        console.log(treatmentPatternsStore.q5)
                                                                    }}
                                                                    checked={item?.anatomicalResection && item.anatomicalResection.length>0 && item.anatomicalResection.includes('Segmentectomy')}

                                                                />
                                                            }
                                                            sx={{
                                                                '& .MuiFormControlLabel-label': {fontSize: 18}, flex: 1

                                                            }}
                                                            label={'Segmentectomy'}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    onChange={() => {
                                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('anatomicalResection'+index), 1)

                                                                        let q5Array = treatmentPatternsStore.q5
                                                                        let anatomicalResection = q5Array[index].anatomicalResection
                                                                        if(anatomicalResection && anatomicalResection.length > 0){
                                                                            if(!anatomicalResection.includes('WedgeResection')){
                                                                                anatomicalResection.push('WedgeResection')
                                                                            }else{

                                                                                anatomicalResection = anatomicalResection.filter(e => e !== 'WedgeResection')
                                                                            }
                                                                        }else{
                                                                            anatomicalResection = ['WedgeResection']
                                                                        }

                                                                        q5Array[index] = {
                                                                            ...item,
                                                                            anatomicalResection: anatomicalResection,
                                                                            missingAnatomicalReason:null
                                                                        }

                                                                        treatmentPatternsStore.setQ5(
                                                                            q5Array
                                                                        )
                                                                        console.log(treatmentPatternsStore.q5)
                                                                    }}
                                                                    checked={item?.anatomicalResection && item.anatomicalResection.length>0 && item.anatomicalResection.includes('WedgeResection')}

                                                                />
                                                            }
                                                            sx={{
                                                                '& .MuiFormControlLabel-label': {fontSize: 18}, flex: 1,
                                                            }}
                                                            label={'Wedge resection'}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    onChange={() => {
                                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('anatomicalResection'+index), 1)

                                                                        let q5Array = treatmentPatternsStore.q5
                                                                        let anatomicalResection = q5Array[index].anatomicalResection
                                                                        if(anatomicalResection && anatomicalResection.length > 0){
                                                                            if(!anatomicalResection.includes('Lobectomy')){
                                                                                anatomicalResection.push('Lobectomy')
                                                                            }else{

                                                                                anatomicalResection = anatomicalResection.filter(e => e !== 'Lobectomy')
                                                                            }
                                                                        }else{
                                                                            anatomicalResection = ['Lobectomy']
                                                                        }

                                                                        q5Array[index] = {
                                                                            ...item,
                                                                            anatomicalResection: anatomicalResection,
                                                                            missingAnatomicalReason:null

                                                                        }

                                                                        treatmentPatternsStore.setQ5(
                                                                            q5Array
                                                                        )
                                                                        console.log(treatmentPatternsStore.q5)
                                                                    }}
                                                                    checked={item?.anatomicalResection && item.anatomicalResection.length>0 && item.anatomicalResection.includes('Lobectomy')}

                                                                />
                                                            }
                                                            sx={{
                                                                '& .MuiFormControlLabel-label': {fontSize: 18}, flex: 1

                                                            }}
                                                            label={'Lobectomy'}
                                                        />
                                                        {/*</Stack>*/}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    onChange={() => {
                                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('anatomicalResection'+index), 1)

                                                                        let q5Array = treatmentPatternsStore.q5
                                                                        let anatomicalResection = q5Array[index].anatomicalResection
                                                                        if(anatomicalResection && anatomicalResection.length > 0){
                                                                            if(!anatomicalResection.includes('Bilobectomy')){
                                                                                anatomicalResection.push('Bilobectomy')
                                                                            }else{

                                                                                anatomicalResection = anatomicalResection.filter(e => e !== 'Bilobectomy')
                                                                            }
                                                                        }else{
                                                                            anatomicalResection = ['Bilobectomy']
                                                                        }

                                                                        q5Array[index] = {
                                                                            ...item,
                                                                            anatomicalResection: anatomicalResection,
                                                                            missingAnatomicalReason:null

                                                                        }

                                                                        treatmentPatternsStore.setQ5(
                                                                            q5Array
                                                                        )
                                                                        console.log(treatmentPatternsStore.q5)
                                                                    }}
                                                                    checked={item?.anatomicalResection && item.anatomicalResection.length>0 && item.anatomicalResection.includes('Bilobectomy')}

                                                                />
                                                            }
                                                            sx={{
                                                                '& .MuiFormControlLabel-label': {fontSize: 18}, flex: 1

                                                            }}
                                                            label={'Bilobectomy'}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    onChange={() => {
                                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('anatomicalResection'+index), 1)

                                                                        let q5Array = treatmentPatternsStore.q5
                                                                        let anatomicalResection = q5Array[index].anatomicalResection
                                                                        if(anatomicalResection && anatomicalResection.length > 0){
                                                                            if(!anatomicalResection.includes('Pneumonectomy')){
                                                                                anatomicalResection.push('Pneumonectomy')
                                                                            }else{

                                                                                anatomicalResection = anatomicalResection.filter(e => e !== 'Pneumonectomy')
                                                                            }
                                                                        }else{
                                                                            anatomicalResection = ['Pneumonectomy']
                                                                        }

                                                                        q5Array[index] = {
                                                                            ...item,
                                                                            anatomicalResection: anatomicalResection,
                                                                            missingAnatomicalReason:null

                                                                        }

                                                                        treatmentPatternsStore.setQ5(
                                                                            q5Array
                                                                        )
                                                                        console.log(treatmentPatternsStore.q5)
                                                                    }}
                                                                    checked={item?.anatomicalResection && item.anatomicalResection.length>0 && item.anatomicalResection.includes('Pneumonectomy')}

                                                                />
                                                            }
                                                            sx={{
                                                                '& .MuiFormControlLabel-label': {fontSize: 18}, flex: 1

                                                            }}
                                                            label={'Pneumonectomy'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </QuestionText>
                                    </QuestionBox>
                                );
                            } else {
                                return (
                                    <></>
                                )
                            }

                        })
                    }

                </Stack>
            </Box>
        </QuestionsLayout>);
}

export default observer(TreatmentPatternsSurgery);
