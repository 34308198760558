import {Stores, useStores,} from '../store'
import * as React from 'react';
import {useEffect} from 'react';
import {Backdrop, RadioGroup,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import YesNo from "../components/YesNo";
import Typography from "@mui/material/Typography";
import RadioItem from "../components/RadioItem";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import MyButton from "../components/MyButton";
import theme from "../styles/theme";
import close from "../images/svgs/Close.svg";
import {useParams} from "react-router-dom";


const UpdateModal = ({onSubmitMissing, onClose}) => {
    const {
        dataStore
    } = useStores()
    let urlParams = useParams();

    const [showQOther, setShowQOther] = React.useState(false);
    const [showQInformation, setShowQInformation] = React.useState(false);

    useEffect(() => {
        dataStore.setMissingOtherText('')
        dataStore.getPatient(parseInt(urlParams.patient))
    }, [])

    return (
        <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}
        >
            <Paper sx={{
                position: 'absolute',
                zIndex: 999,
                top: '15%',
                p: 12,

                width: '1024px',
            }}>
                <Box onClick={onClose} style={{
                    boxShadow: `3px 3px 15px ${theme.palette.primary.main}`,
                    width: 45, height: 45, backgroundColor: theme.palette.primary.main,
                    borderRadius: 25, display: 'flex', alignItems: 'center', justifyContent: 'center',
                    cursor: 'pointer',
                    position: "absolute",
                    top: 32,
                    right: 32
                }}>
                    <img width={28} height={21} src={close}/>
                </Box>
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Box width={'90%'} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', pb: 3}}>
                        <Typography color={'primary'} sx={{fontSize: 50, fontWeight: 'bold'}}>Update Data</Typography>
                    </Box>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={(event) => {
                          if (event.target.value === 'Input Error') {
                                setShowQOther(false)
                              setShowQInformation(true)
                              dataStore.setUpdateReason('Input Error')
                          } else {
                              dataStore.setUpdateReason('')
                                setShowQOther(true)
                              setShowQInformation(false)
                          }
                        }}
                        // value={treatmentPatternsStore.s7.type}
                        sx={{display: 'flex', flexDirection: 'row'}}
                    >
                        <RadioItem value={'Input Error'} label={'Input Error'}/>
                        <RadioItem value={'Other'} label={'Other'}/>
                    </RadioGroup>

                    {showQOther ? <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        my: 2,
                        flexDirection: 'column'
                    }}>
                        <Typography sx={{fontSize: 25, mb: 2}}>Justify your answer:</Typography>
                        <TextField
                            size={'medium'}
                            sx={{width: '480px'}}
                            multiline={true}
                            rows={1}
                            placeholder={'Write your answer here'}
                            value={dataStore.updateReason}
                            onChange={(event) => dataStore.setUpdateReason(event.currentTarget.value)}
                        />
                    </Box> : <></>}


                    {
                        showQInformation || showQOther ? <MyButton label={'Continue'}
                                                                   onClick={async () => {
                                                                       dataStore.setForceUpdate(true)
                                                                       await onSubmitMissing()
                                                                   }} sx={{mt: 4,
                            backgroundColor:showQOther && dataStore.missingOtherText === '' ? 'txtGrey' : 'primary',
                            boxShadow: `2px 2px 10px ${showQOther && dataStore.missingOtherText === '' ? 'txtGrey' : 'primary'}`,
                        }}/> : null
                    }
                </Box>
            </Paper>
        </Backdrop>
    );
}

export default observer(UpdateModal);
