import * as React from 'react';
import {Checkbox, ClickAwayListener, FormControlLabel, Stack} from "@mui/material";
import Paper from "@mui/material/Paper";
import arrowUp from "../../images/svgs/Arrow-up.svg";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {useStores} from "../../store";
import MyButton from "../../components/MyButton";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MissingReason from "../../components/MissingReason";


const SpecialityMenu = ({setOpenMenu, sx,}) => {
    const {treatmentPatternsStore,dataStore} = useStores()
console.log('dataStore.questionsMissing',dataStore.questionsMissing)
    return (
        <ClickAwayListener onClickAway={setOpenMenu}>
            <Paper sx={{
                display: 'flex',
                width: 960,
                backgroundColor: 'white',
                mt: -6,
                ml: -3,
                position: 'absolute',
                p: 3,
                pt: 1,
                borderRadius: 3,
                flexDirection: 'column',
                zIndex: 999, ...sx
            }} elevation={5}>
                <Stack direction={'row'} sx={{
                    width: '100%', display: 'flex', justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <Box sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(event) => {
                                        if (event.target.value === 'on' && treatmentPatternsStore.q22.other.selected === 'on') {
                                            setOpenMenu()

                                            treatmentPatternsStore.setQ22(
                                                {
                                                    ...treatmentPatternsStore.q22,
                                                    other: {
                                                        selected: null,
                                                        unknown: null,
                                                        specialities: [
                                                            {
                                                                speciality: '',
                                                                amount: ''
                                                            }
                                                        ],
                                                    }
                                                })

                                        } else {
                                            treatmentPatternsStore.setQ22(
                                                {
                                                    ...treatmentPatternsStore.q22,
                                                    other: {
                                                        ...treatmentPatternsStore.q22.other,
                                                        selected: event.target.value,
                                                        unknown: null
                                                    }
                                                })
                                        }

                                    }}
                                    checked={treatmentPatternsStore.q22.other.selected}
                                />
                            }
                            sx={{
                                '& .MuiFormControlLabel-label': {fontSize: 18},

                            }}
                            label={'Other Hospital Specialists'}
                        />
                        <img onClick={setOpenMenu} style={{
                            cursor: 'pointer',
                            width: 18,
                            height: 18,
                        }} src={arrowUp}/>
                        {dataStore.questionsMissing.includes('other') ?
                            <MissingReason
                                value={treatmentPatternsStore.q22?.other?.selected}
                                style={{left:310,marginTop:3}}

                                onSubmitMissing={()=>{
                                    treatmentPatternsStore.setQ22(
                                        {
                                            ...treatmentPatternsStore.q22,
                                            other: {
                                                ...treatmentPatternsStore.q22.other,
                                                selected: dataStore.returnOtherTextMissing,
                                            }
                                        })
                                    setOpenMenu()
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('other'), 1)
                                }}/>
                            : <></>}
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center', ml: 1}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(event) => {
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('other'), 1)

                                        treatmentPatternsStore.setQ22(
                                            {
                                                ...treatmentPatternsStore.q22,
                                                other: {
                                                    ...treatmentPatternsStore.q22.other,
                                                    unknown: event.target.value,
                                                    specialities: [
                                                        {
                                                            speciality: '',
                                                            amount: ''
                                                        }
                                                    ]
                                                }
                                            })
                                        setOpenMenu()


                                    }}
                                    checked={treatmentPatternsStore.q22.other.unknown}
                                />
                            }
                            sx={{
                                '& .MuiFormControlLabel-label': {fontSize: 18},

                            }}
                            label={'Unknown'}
                        />
                    </Box>
                </Stack>
                <Grid container rowSpacing={0.2} columnSpacing={{xs: 1, sm: 2, md: 3}}>


                    {treatmentPatternsStore.q22.other.specialities.map((item, index) => {
                        let specialities = treatmentPatternsStore.q22.other.specialities
                        return (
                            <Grid item xs={5}>

                                <Stack key={'item' + index} direction={'column'}>
                                    <ul>
                                        <Box sx={{display: 'flex', flex:1, alignItems: 'center',justifyContent:'center'}}>
                                            <li style={{fontSize: 18,}}>Specialty</li>
                                            <TextField
                                                size={'small'}
                                                sx={{width: 200,ml:2}}
                                                placeholder={'Write Here'}
                                                value={item?.speciality && item.speciality.startsWith('missing|') ? '': item.speciality}

                                                onChange={(event) => {
                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('spec' + index + 'speciality'), 1)

                                                    specialities[index] = {...item, speciality: event.target.value}
                                                    if(treatmentPatternsStore.q22.other.unknown === 'on'){
                                                        treatmentPatternsStore.q22.other.unknown = null
                                                    }
                                                    treatmentPatternsStore.setQ22(
                                                        {
                                                            ...treatmentPatternsStore.q22,
                                                            specialities: specialities
                                                        })
                                                }}
                                            />
                                            {dataStore.questionsMissing.includes('spec' + index + 'speciality') ?
                                                <MissingReason
                                                    style={{position:'relative'}}
                                                    value={item?.speciality}
                                                    onSubmitMissing={()=>{
                                                        specialities[index] = {...item, speciality: dataStore.returnOtherTextMissing}
                                                        treatmentPatternsStore.setQ22(
                                                            {
                                                                ...treatmentPatternsStore.q22,
                                                                specialities: specialities
                                                            })
                                                        setOpenMenu()
                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('spec' + index + 'speciality'), 1)
                                                    }}/>
                                                : <></>}
                                        </Box>
                                        <Box sx={{display: 'flex', width: '100%', alignItems: 'center', mt: 1}}>
                                            <li style={{fontSize: 18, flex: 1}}>Number</li>
                                            <TextField
                                                size={'small'}
                                                type={"number"}
                                                onWheel={(e) => e.target.blur()}
                                                sx={{width: 200,ml:2}}
                                                inputProps={{min: 0,}}
                                                value={item?.amount && item.amount.startsWith('missing|') ? '': item.amount}

                                                placeholder={'00'}
                                                onChange={(event) => {
                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('spec' + index + 'amount'), 1)

                                                    if(treatmentPatternsStore.q22.other.unknown === 'on'){
                                                        treatmentPatternsStore.q22.other.unknown = null
                                                    }
                                                    specialities[index] = {...item, amount: event.target.value}
                                                    treatmentPatternsStore.setQ22(
                                                        {
                                                            ...treatmentPatternsStore.q22,
                                                            specialities: specialities
                                                        })
                                                }}
                                            />
                                            {dataStore.questionsMissing.includes('spec' + index + 'amount') ?
                                                <MissingReason
                                                    value={item?.amount}
                                                    style={{position:'relative'}}
                                                    onSubmitMissing={()=>{
                                                        specialities[index] = {...item, amount: dataStore.returnOtherTextMissing}
                                                        treatmentPatternsStore.setQ22(
                                                            {
                                                                ...treatmentPatternsStore.q22,
                                                                specialities: specialities
                                                            })
                                                        setOpenMenu()
                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('spec' + index + 'amount'), 1)
                                                    }}/>
                                                : <></>}
                                        </Box>

                                    </ul>

                                    {specialities[index].speciality !== '' ? <Button
                                        variant="contained"
                                        onClick={()=>treatmentPatternsStore.removeNewSpeciality(index)}
                                        sx={{
                                            color: "white",
                                            fontWeight: 'bold',
                                            borderRadius: 12,
                                            py: 1,
                                            fontSize: 18,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            boxShadow: `2px 2px 10px #D3302F`,
                                            textTransform: 'none',
                                            "&.Mui-disabled": {
                                                color: '#ffffff'
                                            },
                                            px: 6, width:'56%',alignSelf:'flex-end'
                                        }}
                                        color={'error'}
                                    >Delete</Button> : <></>}

                                </Stack>
                            </Grid>
                        )
                    })

                    }
                </Grid>

                <MyButton sx={{px: 2, py: 1, width: 350, borderRadius: 6, alignSelf: 'flex-end',mt:2}}
                          label={'Add Other Speciality'}
                          onClick={treatmentPatternsStore.addNewSpeciality}/>
            </Paper>
        </ClickAwayListener>
    );
}

export default SpecialityMenu;
