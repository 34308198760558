import {useStores,} from '../store'
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import backImage from "../images/backImage.png";
import {observer} from "mobx-react";
import AuthFooter from "./AuthFooter";
import Logo from "../components/Logo";
import MyButton from "../components/MyButton";
import {useNavigate, useParams,} from "react-router-dom";
import AlertModal from "../components/AlertModal";
import Loading from "../components/Loading";

const ResetPassword = () => {
    const {authStore} = useStores()

    const params = useParams();

    const navigate = useNavigate()


    return (
        <div>
            <Grid container component="main" sx={{height: '100vh'}}>
                <Grid item md={6} sm={12} sx={{
                    position: "relative",
                    backgroundPosition: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: "center"
                }}>
                    <Box onClick={() => navigate('/')} sx={{cursor: 'pointer'}}>
                        <Logo type={'green'}/>
                    </Box>
                    <Box sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <Typography component="h1" variant="h2" color={'primary'} sx={{fontWeight: 'bold', mb: 3}}>
                            Reset Password
                        </Typography>
                        <Box component="form" noValidate sx={{mt: 1, width: '60%'}}>
                            <TextField
                                margin="normal"
                                required
                                label="Password"
                                name="Password"
                                type="password"
                                autoComplete="current-password"
                                sx={{width: '100%'}}
                                value={authStore.definePassword}
                                onChange={(event) => authStore.setDefinePassword(event.currentTarget.value)}

                            />
                            <TextField
                                margin="normal"
                                required
                                sx={{width: '100%'}}
                                name="confirmPassword"
                                label="Confirm Password"
                                type="password"
                                id="confirmPassword"
                                autoComplete="current-password"
                                value={authStore.confirmPassword}
                                onChange={(event) => authStore.setConfirmPassword(event.currentTarget.value)}

                            />

                        </Box>
                        <Box>
                            <MyButton sx={{
                                mt: 10,
                                mb: 2,
                                px: 15,
                                color: 'white',
                                fontWeight: 'bold',
                                borderRadius: 10,
                                py: 2,
                                fontSize: 23
                            }}
                                      label={'Send'}
                                      onClick={() => authStore.resetPassword(params.token)}/>
                        </Box>
                    </Box>
                    <AuthFooter/>
                </Grid>
                <Grid
                    item
                    md={6}
                    sm={12}
                    sx={{
                        backgroundImage: `url(${backImage})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h2" variant="h2"
                                sx={{fontWeight: 'bold', color: 'white', px: 12, maxWidth: 600}}>
                        Register your patients’ data
                    </Typography>
                </Grid>
            </Grid>
            {authStore.loading && <Loading/>}
            <AlertModal open={authStore.successAlertEnable}
                        text={'Password Successfully changed.'}
                        onClose={() => {
                            authStore.setSuccessAlertEnable(false)
                            navigate('/')
                        }}
                        title={'Succeed!'}/>
            <AlertModal
                open={authStore.showErrMessage}
                onClose={() => {
                    authStore.setShowErrMessage(false)
                    authStore.setErrorMessage('')
                }}
                text={authStore.errorMessage}
                title={'Error!'}/>
        </div>
    );
}

export default observer(ResetPassword)
