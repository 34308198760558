import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {Checkbox, FormControlLabel, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import YesNo from "../../components/YesNo";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import TextField from "@mui/material/TextField";
import SpecialityMenu from "./SpecialityMenu";
import arrowDown from "../../images/svgs/Arrow-down.svg";
import QuestionsLayout from "../../layout/QuestionsLayout";
import Required from "../../components/Required";
import MissingReason from "../../components/MissingReason";


const DiseaseRelatedPage1 = () => {
    const {
        treatmentPatternsStore, dataStore, menuStore
    } = useStores()
    const [openMenu, setOpenMenu] = React.useState(false);
    const [showMissing, setShowMissing] = React.useState('not active');

    const navigate = useNavigate()

    let params = useParams();
    useEffect(() => {
        treatmentPatternsStore.getTreatment(parseInt(params.patient), parseInt(params.number))
    }, [])

    const handleNext = async () => {
        if (treatmentPatternsStore.q22.main !== null) {

            if (!treatmentPatternsStore.checkMissing11) {
                await treatmentPatternsStore.updateTreatment(
                    {
                        q22: JSON.stringify(treatmentPatternsStore.q22),
                    }, parseInt(params.number), parseInt(params.patient))

                if (dataStore.forceUpdate == true) {
                    dataStore.setShowUpdateModal('not active')
                }
                if (dataStore.showUpdateModal !== 'active') {
                    navigate(`/emergencyConsultationsPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
                }
            } else {
                alert('Please add reason for missing questions.')
            }
        } else {
            alert('Please answer all required questions.')
        }
    }
        return (
            <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                             questionHeaderProps={{patientNumberEnd: true}}
                             showMissing={showMissing}
                             onCloseMissing={() => setShowMissing('not active')}
                             nextPress={handleNext}
                             backPress={() => navigate(-1)}
                             loading={treatmentPatternsStore.loading}>
                <Box sx={{width: '100%', px: 4, mb: 0, mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                    <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>
                        {parseInt(params.number) === 0 ?
                            <div>
                                <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32, lineHeight: 1.2}}>
                                    Treatment patterns and healthcare resource use (Only Initial Treatment)
                                </Typography>
                                <Typography color={'txtGrey'} sx={{fontSize: 20, mt: 1}}>
                                    (Please complete this section only for initial treatment.)
                                </Typography>

                            </div> :
                            <div>
                                <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32, lineHeight: 1.2}}>
                                    {`Treatment patterns and healthcare resource use for subsequent treatment n = ${params.number}`}
                                </Typography>
                                <Typography color={'txtGrey'} sx={{fontSize: 20, mt: 1}}>
                                    [Please complete this section for “Subsequent treatment (n=1, 2, …)”]
                                </Typography>
                            </div>
                        }
                    </Stack>
                    <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>
                        <Typography sx={{fontWeight: 'bold', fontSize: 30,}}>
                            Disease-related Outpatient Consultations <Required/>
                        </Typography>
                        <QuestionBox allInRow={true}>
                            <YesNo value={treatmentPatternsStore.q22.main}
                                   handleChange={(event) => {
                                       dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q22'), 1)

                                       if (event.target.value === 'true') {
                                           treatmentPatternsStore.setQ22(
                                               {...treatmentPatternsStore.q22, main: event.target.value,})
                                       } else {
                                           treatmentPatternsStore.setQ22(
                                               {
                                                   ...treatmentPatternsStore.q22, main: event.target.value,
                                                   oncology: {selected: null, amount: '', unknown: null},
                                                   other: {
                                                       selected: null,
                                                       unknown: null,
                                                       specialities: [
                                                           {
                                                               speciality: '',
                                                               amount: ''
                                                           },

                                                       ],
                                                   },
                                                   gp: {selected: null, amount: '', unknown: null},
                                               })
                                       }
                                   }}
                            >
                                {dataStore.questionsMissing.includes('q22') ?
                                    <MissingReason
                                        value={treatmentPatternsStore.q22.main}
                                        style={{right: 60, marginTop: -3}}
                                        onSubmitMissing={() => {
                                            treatmentPatternsStore.setQ22(
                                                {
                                                    ...treatmentPatternsStore.q22,
                                                    main: dataStore.returnOtherTextMissing,
                                                })
                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q22'), 1)
                                        }}/>
                                    : <></>}
                            </YesNo>
                        </QuestionBox>
                        {treatmentPatternsStore.q22.main === 'true' ? <QuestionBox>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    maxWidth: 912,
                                }}
                            >
                                <Stack direction={'row'} sx={{
                                    width: '100%', display: 'flex', justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(event) => {
                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q22value'), 1)

                                                    if (event.target.value === 'on' && treatmentPatternsStore.q22.oncology.selected === 'on') {
                                                        treatmentPatternsStore.setQ22(
                                                            {
                                                                ...treatmentPatternsStore.q22,
                                                                oncology: {
                                                                    selected: null,
                                                                    amount: '',
                                                                    unknown: null
                                                                }
                                                            })

                                                    } else {
                                                        treatmentPatternsStore.setQ22(
                                                            {
                                                                ...treatmentPatternsStore.q22,
                                                                oncology: {
                                                                    ...treatmentPatternsStore.q22.oncology,
                                                                    selected: event.target.value,
                                                                }
                                                            })
                                                    }

                                                }}
                                                checked={(treatmentPatternsStore.q22.oncology?.selected && treatmentPatternsStore.q22.oncology.selected.startsWith('missing|')) ? false : treatmentPatternsStore.q22.oncology.selected}
                                            />
                                        }
                                        sx={{
                                            '& .MuiFormControlLabel-label': {fontSize: 18},
                                        }}
                                        label={'Oncology Follow-up Visits'}
                                    />
                                    {treatmentPatternsStore.q22.oncology.selected &&
                                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                                            <Typography sx={{fontSize: 18, mr: 1}}>
                                                {dataStore.questionsMissing.includes('oncology') ?
                                                    <MissingReason
                                                        value={treatmentPatternsStore.q22.oncology.amount}
                                                        style={{marginLeft: -80}}
                                                        onSubmitMissing={() => {
                                                            treatmentPatternsStore.setQ22(
                                                                {
                                                                    ...treatmentPatternsStore.q22,
                                                                    oncology: {
                                                                        ...treatmentPatternsStore.q22.oncology,
                                                                        amount: dataStore.returnOtherTextMissing,
                                                                    }
                                                                })
                                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('oncology'), 1)
                                                        }}/>
                                                    : <></>}
                                                Number
                                            </Typography>
                                            <TextField
                                                type={"number"}
                                                onWheel={(e) => e.target.blur()}
                                                size={'small'}
                                                sx={{width: 100, ml: 3, mr: 3}}
                                                placeholder={'00'}
                                                inputProps={{min: 0, style: {textAlign: 'center'}}}
                                                onChange={(event) => {
                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('oncology'), 1)

                                                    treatmentPatternsStore.setQ22(
                                                        {
                                                            ...treatmentPatternsStore.q22,
                                                            oncology: {
                                                                ...treatmentPatternsStore.q22.oncology,
                                                                amount: event.target.value, unknown: null
                                                            }
                                                        })
                                                }}
                                                value={treatmentPatternsStore.q22.oncology?.amount && treatmentPatternsStore.q22.oncology.amount.startsWith('missing|') ? '' : treatmentPatternsStore.q22.oncology.amount}

                                            />
                                            <FormControlLabel

                                                control={
                                                    <Checkbox
                                                        onChange={(event) => {
                                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('oncology'), 1)

                                                            if(treatmentPatternsStore.q22?.oncology?.unknown && treatmentPatternsStore.q22?.oncology?.unknown === 'on'){
                                                                treatmentPatternsStore.setQ22(
                                                                    {
                                                                        ...treatmentPatternsStore.q22,
                                                                        oncology: {
                                                                            ...treatmentPatternsStore.q22.oncology,
                                                                            unknown: false,
                                                                            amount: ''
                                                                        }
                                                                    })
                                                            }else{
                                                                treatmentPatternsStore.setQ22(
                                                                    {
                                                                        ...treatmentPatternsStore.q22,
                                                                        oncology: {
                                                                            ...treatmentPatternsStore.q22.oncology,
                                                                            unknown: event.target.value,
                                                                            amount: ''
                                                                        }
                                                                    })
                                                            }



                                                        }}
                                                        checked={(treatmentPatternsStore.q22.oncology?.unknown && treatmentPatternsStore.q22.oncology.unknown.startsWith('missing|')) ? false : treatmentPatternsStore.q22.oncology.unknown}
                                                    />
                                                }
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {fontSize: 18},

                                                }}
                                                label={'Unknown'}
                                            />

                                        </Box>}
                                    {dataStore.questionsMissing.includes('q22value') ?
                                        <MissingReason
                                            value={treatmentPatternsStore.q22.oncology.selected}
                                            style={{right: 60, marginTop: 3}}
                                            onSubmitMissing={() => {

                                                treatmentPatternsStore.setQ22(
                                                    {
                                                        ...treatmentPatternsStore.q22,
                                                        oncology: {
                                                            ...treatmentPatternsStore.q22.oncology,
                                                            selected: dataStore.returnOtherTextMissing,
                                                        }
                                                    })
                                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q22value'), 1)
                                            }}/>
                                        : <></>}
                                </Stack>
                                <Stack direction={'row'} sx={{
                                    width: '100%', display: 'flex', justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                    <Box sx={{width: '100%',}}>
                                        <Box sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => {
                                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q22value'), 1)

                                                            if (event.target.value === 'on' && treatmentPatternsStore.q22.other.selected === 'on') {
                                                                treatmentPatternsStore.setQ22(
                                                                    {
                                                                        ...treatmentPatternsStore.q22,
                                                                        other: {
                                                                            selected: null,
                                                                            unknown: null,
                                                                            specialities: [
                                                                                {
                                                                                    speciality: '',
                                                                                    amount: ''
                                                                                }
                                                                            ],
                                                                        }
                                                                    })

                                                            } else {
                                                                setOpenMenu(true)
                                                                treatmentPatternsStore.setQ22(
                                                                    {
                                                                        ...treatmentPatternsStore.q22,
                                                                        other: {
                                                                            ...treatmentPatternsStore.q22.other,
                                                                            selected: event.target.value,
                                                                            unknown: null
                                                                        }
                                                                    })
                                                            }

                                                        }}
                                                        checked={(treatmentPatternsStore.q22.other?.selected && treatmentPatternsStore.q22.other.selected.startsWith('missing|')) ? false : treatmentPatternsStore.q22.other.selected}
                                                    />
                                                }
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {fontSize: 18},

                                                }}
                                                label={'Other Hospital Specialists'}
                                            />
                                            {treatmentPatternsStore.q22.other.selected &&
                                                <img onClick={() => setOpenMenu(true)} style={{
                                                    cursor: 'pointer',
                                                    width: 18,
                                                    height: 18,
                                                }}
                                                     src={arrowDown}/>}
                                        </Box>
                                        {openMenu && <SpecialityMenu setOpenMenu={() => setOpenMenu(false)}/>}
                                    </Box>
                                    {treatmentPatternsStore.q22.other.selected &&
                                        <Box sx={{display: 'flex', alignItems: 'center', ml: 1}}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => {
                                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('other'), 1)

                                                            console.log('event.target.value', event.target.value)
                                                            if (treatmentPatternsStore.q22.other.unknown !== null) {
                                                                treatmentPatternsStore.setQ22(
                                                                    {
                                                                        ...treatmentPatternsStore.q22,
                                                                        other: {
                                                                            ...treatmentPatternsStore.q22.other,
                                                                            unknown: null,
                                                                            specialities: [
                                                                                {
                                                                                    speciality: '',
                                                                                    amount: ''
                                                                                }
                                                                            ]
                                                                        }
                                                                    })
                                                            } else {
                                                                treatmentPatternsStore.setQ22(
                                                                    {
                                                                        ...treatmentPatternsStore.q22,
                                                                        other: {
                                                                            ...treatmentPatternsStore.q22.other,
                                                                            unknown: 'on',
                                                                            specialities: [
                                                                                {
                                                                                    speciality: '',
                                                                                    amount: ''
                                                                                }
                                                                            ]
                                                                        }
                                                                    })
                                                            }
                                                        }}
                                                        checked={(treatmentPatternsStore.q22.other?.unknown && treatmentPatternsStore.q22.other.unknown.startsWith('missing|')) ? false : treatmentPatternsStore.q22.other.unknown}
                                                    />
                                                }
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {fontSize: 18},

                                                }}
                                                label={'Unknown'}
                                            />
                                        </Box>}


                                </Stack>
                                <Stack direction={'row'} sx={{
                                    width: '100%', display: 'flex', justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(event) => {
                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q22value'), 1)

                                                    if (event.target.value === 'on' && treatmentPatternsStore.q22.gp.selected === 'on') {
                                                        treatmentPatternsStore.setQ22(
                                                            {
                                                                ...treatmentPatternsStore.q22,
                                                                gp: {selected: null, amount: '', unknown: null}
                                                            })

                                                    } else {
                                                        treatmentPatternsStore.setQ22(
                                                            {
                                                                ...treatmentPatternsStore.q22,
                                                                gp: {
                                                                    ...treatmentPatternsStore.q22.gp,
                                                                    selected: event.target.value,
                                                                }
                                                            })
                                                    }

                                                }}
                                                checked={(treatmentPatternsStore.q22.gp?.selected && treatmentPatternsStore.q22.gp.selected.startsWith('missing|')) ? false : treatmentPatternsStore.q22.gp.selected}
                                            />
                                        }
                                        sx={{
                                            '& .MuiFormControlLabel-label': {fontSize: 18},

                                        }}
                                        label={'GP'}
                                    />
                                    {treatmentPatternsStore.q22.gp.selected &&
                                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                                            <Typography sx={{fontSize: 18, mr: 1}}>
                                                {dataStore.questionsMissing.includes('gp') ?
                                                    <MissingReason
                                                        value={treatmentPatternsStore.q22?.gp?.amount}
                                                        style={{marginLeft: -80}}
                                                        onSubmitMissing={() => {
                                                            treatmentPatternsStore.setQ22(
                                                                {
                                                                    ...treatmentPatternsStore.q22,
                                                                    gp: {
                                                                        ...treatmentPatternsStore.q22.gp,
                                                                        amount: dataStore.returnOtherTextMissing
                                                                    }
                                                                })
                                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('gp'), 1)
                                                        }}/>
                                                    : <></>}
                                                Number
                                            </Typography>
                                            <TextField
                                                type={"number"}
                                                onWheel={(e) => e.target.blur()}
                                                size={'small'}
                                                sx={{width: 100, ml: 3, mr: 3}}
                                                placeholder={'00'}
                                                inputProps={{min: 0, style: {textAlign: 'center'}}}
                                                onChange={(event) => {
                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('gp'), 1)

                                                    treatmentPatternsStore.setQ22(
                                                        {
                                                            ...treatmentPatternsStore.q22,
                                                            gp: {
                                                                ...treatmentPatternsStore.q22.gp,
                                                                amount: event.target.value, unknown: null
                                                            }
                                                        })
                                                }}
                                                value={treatmentPatternsStore.q22?.gp?.amount && treatmentPatternsStore.q22.gp.amount.startsWith('missing|') ? '' : treatmentPatternsStore.q22.gp.amount}

                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => {
                                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('gp'), 1)

                                                            if(treatmentPatternsStore.q22?.gp?.unknown && treatmentPatternsStore.q22.gp.unknown === 'on'){
                                                                treatmentPatternsStore.setQ22(
                                                                    {
                                                                        ...treatmentPatternsStore.q22,
                                                                        gp: {
                                                                            ...treatmentPatternsStore.q22.gp,
                                                                            unknown: false, amount: ''
                                                                        }
                                                                    })
                                                            }else{
                                                                treatmentPatternsStore.setQ22(
                                                                    {
                                                                        ...treatmentPatternsStore.q22,
                                                                        gp: {
                                                                            ...treatmentPatternsStore.q22.gp,
                                                                            unknown: event.target.value, amount: ''
                                                                        }
                                                                    })
                                                            }



                                                        }}
                                                        checked={(treatmentPatternsStore.q22.gp?.unknown && treatmentPatternsStore.q22.gp.unknown.startsWith('missing|')) ? false : treatmentPatternsStore.q22.gp.unknown}
                                                    />
                                                }
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {fontSize: 18},

                                                }}
                                                label={'Unknown'}
                                            />
                                        </Box>}
                                </Stack>

                            </Box>
                        </QuestionBox> : <></>}

                    </Stack>
                </Box>
            </QuestionsLayout>
        );
}

export default observer(DiseaseRelatedPage1);
