import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';

import Grid from '@mui/material/Grid';
import {Checkbox, FormControlLabel, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import TextField from "@mui/material/TextField";
import arrowDown from "../../images/svgs/Arrow-down.svg";
import DoseMenu from "../treatmentPatterns/DoseMenu";
import MyButton from "../../components/MyButton";
import QuestionsLayout from "../../layout/QuestionsLayout";
import Joi from "joi";
import {toJS} from "mobx";
import MissingReason from "../../components/MissingReason";

const SystemicTherapyPage3 = () => {
    const {
        systemicTherapyStore, dataStore, menuStore
    } = useStores()
    let params = useParams();
    useEffect(() => {
        systemicTherapyStore.getTreatment(parseInt(params.patient), parseInt(params.number))
    }, [])

    const navigate = useNavigate()
    const [otherOn, setOtherOn] = React.useState(false);
    const [openMenu, setOpenMenu] = React.useState(null);
    const [showMissing, setShowMissing] = React.useState('not active');

    const TherapySchema = Joi.object({
        dose: Joi.number().min(25).max(250).integer().required(),
        numberOfCyclesPlanned: Joi.number().min(1).max(6).integer().required(),
        numberOfCyclesActual: Joi.number().min(0).max(6).integer().required(),
        totalDurationInWeeks: Joi.number().min(1).max(24).integer().required(),
        selected: Joi.optional(),
    });

    const TherapiesSchema = Joi.array().items(TherapySchema).optional();

    const handleNext = async () => {
        let jsTherapies = toJS(systemicTherapyStore.q14.therapies)
        let therapies = jsTherapies.filter(t => t.params.selected === 'on').map((t) => t.params);
        if (TherapiesSchema.validate(therapies).error) {
            if (!window.confirm("Do you confirm this value?")) {
                return;
            }
        }
        if (!systemicTherapyStore.checkMissing3) {
            await systemicTherapyStore.updateTreatment(
                {
                    q14: JSON.stringify(systemicTherapyStore.q14),
                }, parseInt(params.number), parseInt(params.patient))
            if (dataStore.forceUpdate === true) {
                dataStore.setShowUpdateModal('not active')
            }
            if (dataStore.showUpdateModal !== 'active') {
                navigate(`/concomitantMedicationsPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            }

        } else {
            alert('Please add reason for missing questions.')

            // if (!dataStore.globalMissing) {
            //     if (showMissing === 'active') {
            //         await systemicTherapyStore.updateTreatment(
            //             {
            //                 q14: systemicTherapyStore.q14?.main ? JSON.stringify(systemicTherapyStore.q14) : dataStore.returnOtherObjectMissing,
            //             }, parseInt(params.number),parseInt(params.patient))
            //         navigate(`/concomitantMedicationsPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            //         setShowMissing('not active')
            //     } else {
            //         setShowMissing('active')
            //     }
            // } else {
            //     await systemicTherapyStore.updateTreatment(
            //         {
            //             q14: systemicTherapyStore.q14?.main ? JSON.stringify(systemicTherapyStore.q14) : dataStore.returnOtherObjectMissing,
            //         }, parseInt(params.number),parseInt(params.patient))
            //     navigate(`/concomitantMedicationsPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            // }
        }
    }

    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={systemicTherapyStore.loading}
                         overflow={true}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>

                    {parseInt(params.number) === 0 ?
                        <div>
                            <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                                Treatment patterns and healthcare resource use (Only Initial Treatment)
                            </Typography>
                            <Typography color={'txtGrey'} sx={{fontSize: 20,mt:1}}>
                                (Please complete this section only for initial treatment.)
                            </Typography>

                        </div> :
                        <div>
                            <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                                {`Treatment patterns and healthcare resource use for subsequent treatment n = ${params.number}`}
                            </Typography>
                            <Typography color={'txtGrey'} sx={{fontSize: 20,mt:1}}>
                                [Please complete this section for “Subsequent treatment (n=1, 2, …)”]
                            </Typography>
                        </div>
                    }
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>
                    <Typography sx={{fontWeight: 'bold', fontSize: 30,}}>
                        Systemic Therapy+RT
                    </Typography>
                    <QuestionBox>
                        <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 25,}}>
                            Concomitant chemotherapy ({systemicTherapyStore.q12.main ? systemicTherapyStore.q12.main : systemicTherapyStore.q13.main})
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex', flex: 1,
                                flexDirection: 'column', width: '100%',
                            }}
                        >
                            {dataStore.questionsMissing.includes('SystemTherapies')
                                ? <MissingReason
                                    value={systemicTherapyStore.q14.missingTherapiesReason}
                                    style={{right:60,marginTop:3}}
                                    onSubmitMissing={async ()=>{
                                        systemicTherapyStore.setQ14({
                                            ...systemicTherapyStore.q14,
                                            missingTherapiesReason: dataStore.returnOtherTextMissing,})
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('SystemTherapies'), 1)
                                    }}/> : <></>}
                            <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                                {systemicTherapyStore.q14.therapies.map((item, index) => {
                                    let therapies = systemicTherapyStore.q14.therapies
                                    return (
                                        <Grid key={'th'+index} item xs={6}>
                                            <Box sx={{width: '60%', display: "flex", alignItems: "center"}}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onChange={(event) => {
                                                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('SystemTherapies'), 1)

                                                                if (event.target.value === 'on' && item.params.selected === 'on') {
                                                                    setOpenMenu(null)
                                                                    therapies[index].params = {
                                                                        selected: null,
                                                                        dose: '',
                                                                        numberOfCyclesPlanned: '',
                                                                        numberOfCyclesActual: '',
                                                                        totalDurationInWeeks: '',
                                                                    }
                                                                    systemicTherapyStore.setQ14(
                                                                        {
                                                                            ...systemicTherapyStore.q14,
                                                                            therapies: therapies,missingTherapiesReason:null
                                                                        }
                                                                    )

                                                                } else {
                                                                    setOpenMenu(index)
                                                                    therapies[index].params = {
                                                                        ...item.params,
                                                                        selected: event.target.value
                                                                    }
                                                                    systemicTherapyStore.setQ14(
                                                                        {
                                                                            ...systemicTherapyStore.q14,
                                                                            therapies: therapies,missingTherapiesReason:null
                                                                        }
                                                                    )
                                                                }

                                                            }}
                                                            checked={item.params.selected}
                                                            color={TherapySchema.validate(item.params).error ? "error" : "primary"}
                                                        />
                                                    }
                                                    sx={{
                                                        '& .MuiFormControlLabel-label': {fontSize: 18},

                                                    }}
                                                    label={item.name}
                                                />

                                                {item.params.selected && <img onClick={() => setOpenMenu(index)}
                                                                              style={{
                                                                                  cursor: 'pointer',
                                                                                  width: 18,
                                                                                  height: 18,
                                                                              }}
                                                                              src={arrowDown}/>}
                                            </Box>
                                            {openMenu === index &&
                                                <DoseMenu
                                                    store={systemicTherapyStore}
                                                    question={'q14'}
                                                    therapies={therapies}
                                                    item={item}
                                                    index={index}
                                                    checked={item.params.selected}
                                                    setOpenMenu={() => setOpenMenu(null)}
                                                    text={item.name}
                                                    sx={{width:500}}
                                                    deletePress={()=>{
                                                        if(!['Cisplatin','Etoposide','Vinorelbine','Pemetrexed','Carboplatin','Paclitaxel'].find((t)=>t === systemicTherapyStore.q14.therapies[index].name)){
                                                            systemicTherapyStore.q14.therapies.splice(index, 1)
                                                        }else{
                                                            systemicTherapyStore.q14.therapies[index].params = systemicTherapyStore.q3CommonParams
                                                        }
                                                        setOpenMenu(null)
                                                    }}
                                                />
                                            }
                                        </Grid>
                                    )
                                })}
                            </Grid>


                            <Stack sx={{
                                mt: 2,
                                display: 'flex',
                            }} direction={'row'} spacing={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(event) =>otherOn === 'on' ? setOtherOn(false) : setOtherOn(event.target.value)}
                                            checked={otherOn}
                                        />
                                    }
                                    sx={{
                                        '& .MuiFormControlLabel-label': {fontSize: 18},
                                    }}
                                    label="Other"
                                />
                                {otherOn ?
                                    <>
                                        <TextField
                                            size={'small'}
                                            label="Specify Here"
                                            name="otherTumorPage1"
                                            sx={{width: '200px', mr: 2}}
                                            onChange={(event) => systemicTherapyStore.setNewNeoText(event.target.value)}
                                            value={systemicTherapyStore.newNeoText}/>
                                        <MyButton sx={{px: 4, py: 0.5}} label={'Add'}
                                                  disabled={systemicTherapyStore.newNeoText === ''}
                                                  onClick={systemicTherapyStore.addNeo}/>
                                    </>
                                    : null}
                            </Stack>
                        </Box>
                    </QuestionBox>
                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(SystemicTherapyPage3);
