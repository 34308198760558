import * as React from 'react';
import Typography from "@mui/material/Typography";


const QuestionText = (props) => {

    return (
        <Typography sx={{fontSize: 20, width: props.width ? props.width : '70%', fontWeight: 'bold',...props.sx}}>
            {props.children}
        </Typography>
    );
}

export default QuestionText;
