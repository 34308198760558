import {action, computed, makeObservable, observable,} from 'mobx'
import {CrfService} from "../services/openapi";
import moment from "moment"

class DataStore {

    loading = false
    patients = []
    patient = {}
    patientsInfo = null
    globalMissing = null
    selectedPatient = null
    selectionModel = []
    subTreatmentsNumber = 0
    areas = []
    lastModification = null
    missingOtherText = ''
    patientCompleted = false
    dataView = {}
    showMissing = null
    datePicker = null
    searchTxt = ''
    page = 1
    count = 0
    forceUpdate = false
    updateReason = ''
    showUpdateModal = 'not active'
    questionsMissing = []


    constructor() {

        makeObservable(this, {
            loading: observable,
            patients: observable,
            patientsInfo: observable,
            selectedPatient: observable,
            selectionModel: observable,
            subTreatmentsNumber: observable,
            areas: observable,
            lastModification: observable,
            globalMissing: observable,
            missingOtherText: observable,
            patientCompleted: observable,
            dataView: observable,
            datePicker: observable,
            searchTxt: observable,
            page: observable,
            count: observable,
            forceUpdate: observable,
            updateReason: observable,
            showUpdateModal: observable,
            questionsMissing: observable,

            setSelectedPatient: action,
            setSelectionModel: action,
            setGlobalMissing: action,
            setCount: action,

            setSearchTxt: action,
            deletePatient: action,
            setPatients: action,
            setPatientsInfo: action,
            getPatients: action,
            createPatient: action,
            setPatient: action,
            getPatient: action,
            setAreas: action,
            setLastModification: action,
            setSubTreatmentsNumber: action,
            setPatientCompleted: action,
            updatePatient: action,
            fillAreas: action,
            fillSubTreatments: action,
            setMissingOtherText: action,
            returnOtherTextMissing: computed,
            returnOtherObjectMissing: computed,
            setShowMissing: action,
            viewPatients: action,
            setDataView: action,
            setDatePicker: action,
            setPage: action,
            setForceUpdate: action,
            setUpdateReason: action,
            setShowUpdateModal: action,
            resetUpdateModalData: action,

        },)
    }
    setShowUpdateModal = (value) => {
        this.showUpdateModal = value
    }

    setPage = (value) => {
        this.page = value
        this.viewPatients()
    }
    setQuestionsMissing = (value) => {
        if(!this.questionsMissing.find((qm)=>qm === value)){
            this.questionsMissing.push(value)
        }
    }
    setForceUpdate = (value) => {
        this.forceUpdate = value
    }
    setUpdateReason = (value) => {
        this.updateReason = value
    }
    setCount = (value) => {
        this.count = value
    }
    setSearchTxt=(value)=>{
        this.searchTxt = value
    }
    setDatePicker=(value)=>{
        this.datePicker = value
    }
    setShowMissing=(value)=>{
        this.showMissing = value
    }

    get returnOtherTextMissing() {
        return `missing|${this.missingOtherText}`
    }

    get returnOtherObjectMissing() {
        return JSON.stringify({missing: this.missingOtherText})
    }

    cleanPatient = () => {
        this.setSelectedPatient(null)
    }

    setPatientCompleted = (value) => {
        this.patientCompleted = value
    }

    setMissingOtherText = (value) => {
        this.missingOtherText = value
    }

    setGlobalMissing = (value) => {
        this.globalMissing = value
    }

    setLastModification = (value) => {
        this.lastModification = value
    }

    setSubTreatmentsNumber = (value) => {
        this.subTreatmentsNumber = value
    }

    setPatientsInfo = (value) => {
        this.patientsInfo = value
    }

    setSelectedPatient = (value) => {
        this.selectedPatient = value
    }

    fillAreas = (patientId) => {
        console.log('this.patient',this.patient)
        this.areas = [
            {
                id: 1,
                title: 'Inclusion and Exclusion Criteria',
                date: this.patient.inclusion_exclusion.updated_at ? moment(this.patient.inclusion_exclusion.updated_at).format('DD/MM/YYYY') : '-',
                completed: this.patient.inclusion_exclusion.completed,
                link: '/inclusionCriteriaPage1/'+patientId,
            },
        ]
        if (this.patient.inclusion_exclusion.completed) {
            this.areas.push({
                id: 2,
                title: 'Socio-Demographic Characteristics at diagnosis',
                date: this.patient.sociodemographic.updated_at ? moment(this.patient.sociodemographic.updated_at).format('DD/MM/YYYY') : '-',
                completed: this.patient.sociodemographic.completed,
                link: '/socioDemographicCharacteristicsPage1/'+patientId,
            })
            if (this.patient.sociodemographic.completed === 'completed') {
                this.areas.push({
                    id: 3,
                    title: 'Clinical History at diagnosis',
                    date: this.patient.clinical_history.updated_at ? moment(this.patient.clinical_history.updated_at).format('DD/MM/YYYY') : '-',
                    completed: this.patient.clinical_history.completed,
                    link: '/clinicalHistoryPage1/'+patientId,
                })
                if (this.patient.clinical_history.completed === 'completed') {
                    this.areas.push({
                        id: 4,
                        title: 'Environmental Exposure to Carcinogenic Agents at diagnosis',
                        date: this.patient.environmental_exposure.updated_at ? moment(this.patient.environmental_exposure.updated_at).format('DD/MM/YYYY') : '-',
                        completed: this.patient.environmental_exposure.completed,
                        link: '/environmentalExposurePage1/'+patientId,
                    })
                    if (this.patient.environmental_exposure.completed === 'completed') {
                        this.areas.push({
                            id: 5,
                            title: 'Clinical Characterization',
                            date: this.patient.clinical_characterization.updated_at ? moment(this.patient.clinical_characterization.updated_at).format('DD/MM/YYYY') : '-',
                            completed: this.patient.clinical_characterization.completed,
                            link: '/clinicalCharacterizationPage1/'+patientId,
                        })
                        if (this.patient.clinical_characterization.completed === 'completed') {
                            this.areas.push({
                                id: 6,
                                title: 'Tumor biology at diagnosis',
                                date: this.patient.tumor_biology.updated_at ? moment(this.patient.tumor_biology.updated_at).format('DD/MM/YYYY') : '-',
                                completed: this.patient.tumor_biology.completed,
                                link: '/tumorBiologyPage1/'+patientId,
                            })
                            if (this.patient.tumor_biology.completed === 'completed') {
                                this.areas.push({
                                    id: 7,
                                    title: 'Initial Treatment',
                                    date: this.patient.initial_treatment.updated_at ? moment(this.patient.initial_treatment.updated_at).format('DD/MM/YYYY') : '-',
                                    completed: this.patient.initial_treatment.completed,
                                    link: `/treatmentPatternsDuration/${patientId}/0`,
                                })
                                if (this.patient.initial_treatment.completed === 'completed') {
                                    this.areas.push({
                                        id: 8,
                                        title: 'After Initial Treatment',
                                        date: this.patient.after_initial_treatment.updated_at ? moment(this.patient.after_initial_treatment.updated_at).format('DD/MM/YYYY') : '-',
                                        completed: this.patient.after_initial_treatment.completed,
                                        link: `/imagingAssessmentsAfterInitialTreatmentPage1/${patientId}/0`,
                                    })
                                    if (this.patient.after_initial_treatment.completed === 'completed') {
                                        for (let i = 0; i < this.patient.sub_treatments.length; i++) {
                                            this.areas.push({
                                                id: 9 + i,
                                                title: `Subsequent Treatment n = ${i + 1}`,
                                                date: this.patient.sub_treatments[i].treatment_updated_at ? moment(this.patient.sub_treatments[i].updated_at).format('DD/MM/YYYY') : '-',
                                                completed: this.patient.sub_treatments[i].treatment_completed,
                                                link: `/subsequentTreatmentTumorBiologyPage1/${patientId}/${i + 1}`,
                                            })
                                            this.areas.push({
                                                id: 10 + i,
                                                title: `After Subsequent Treatment n = ${i + 1}`,
                                                date: this.patient.sub_treatments[i].after_updated_at ? moment(this.patient.sub_treatments[i].after_updated_at).format('DD/MM/YYYY') : '-',
                                                completed: this.patient.sub_treatments[i].after_completed,
                                                link: `/imagingAssessmentsAfterInitialTreatmentPage1/${patientId}/${i + 1}`,
                                            })
                                        }
                                        if (this.areas[this.areas.length - 1].completed === 'completed') {
                                            this.areas.push({
                                                id: 11 + this.patient.sub_treatments.length,
                                                title: 'Disposition Data at Cut-Off Date',
                                                date: this.patient.disposition.updated_at ? moment(this.patient.disposition.updated_at).format('DD/MM/YYYY') : '-',
                                                completed: this.patient.disposition.completed,
                                                link: '/dispositionDataPage1/'+patientId,
                                            })
                                        }
                                        if (this.patientCompleted) {
                                            this.areas.push({
                                                id: 12 + this.patient.sub_treatments.length,
                                                title: 'Patient Complete',
                                                date: this.patient.disposition.updated_at ? moment(this.patient.disposition.updated_at).format('DD/MM/YYYY') : '-',
                                                completed: true,
                                                link: '/patientCompleted/'+patientId,
                                            })
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    setPatient = (value) => {
        this.patient = value
    }

    setPatients = (value) => {
        this.patients = value
    }
    setDataView = (value) => {
        this.dataView = value
    }

    setAreas = (value) => {
        this.areas = value
    }

    setLoading = (value) => {
        this.loading = value
    }

    setSelectionModel = (value) => {
        this.selectionModel.push(value)
    }

    setSelectionModelGrid = (value) => {
        this.selectionModel = value
    }

    getPatients = async (compact) => {
        try {
            this.setLoading(true)
            let response = await CrfService.getPatients(compact)
            console.log('getPatients===>',response)
            this.setPatients(response)
            this.setLoading(false)

        } catch (err) {
            console.log('getPatients err', err)
            this.setLoading(false)
        }
    }

    viewPatients = async () => {
        try {
            console.log('this.datePicker',this.datePicker)
            console.log('this.searchTxt',this.searchTxt)
            this.setLoading(true)
            if(this.datePicker !== null){
                let date = moment(this.datePicker.$d)
                this.datePicker = date.format('YYYY-MM-DD')
            }
            let response = await CrfService.viewPatients(this.searchTxt,this.datePicker, this.page)
            console.log('viewPatients===>',response)
            this.setDataView(response.items)
            this.setCount(response.count)

            this.setLoading(false)

        } catch (err) {
            console.log('viewPatients err', err)
            this.setLoading(false)
        }
    }

    getPatient = async (patientId) => {
        try {
            this.setLoading(true)
            const response = await CrfService.getPatient(patientId)
            console.log('getPatient response', response)

            this.setPatient(response)
            this.setSelectedPatient(response)

            this.setSubTreatmentsNumber(response.sub_treatments)

            if (response.sociodemographic.completed === 'completed') {
                this.setSelectionModel(2)
            }
            if (response.tumor_biology.completed === 'completed') {
                this.setSelectionModel(6)
            }
            if (response.initial_treatment.completed === 'completed') {
                this.setSelectionModel(7)
            }
            if (response.inclusion_exclusion.completed === true) {
                this.setSelectionModel(1)
            }
            if (response.environmental_exposure.completed === 'completed') {
                this.setSelectionModel(4)
            }
            if (response.clinical_history.completed === 'completed') {
                this.setSelectionModel(3)
            }
            if (response.clinical_characterization.completed === 'completed') {
                this.setSelectionModel(5)
            }
            if (response.after_initial_treatment.completed === 'completed') {
                this.setSelectionModel(8)
            }

            this.setGlobalMissing(response.global_missing)

            console.log('response.sub_treatments.length', response.sub_treatments.length)


            if (response.last_modification) {
                this.setLastModification(response.last_modification)
            }
            if(response.completed){
                this.setPatientCompleted(true)
            }
            console.log('this.areas', this.areas)
            this.fillAreas(patientId)
            this.fillSubTreatments(response,patientId)
            this.setLoading(false)

        } catch (err) {
            console.log('getPatient err', err)
            this.setLoading(false)
        }
    }


    deletePatient = async (patientId) => {
        try {
            this.setLoading(true)
            const response = await CrfService.deletePatient(patientId)
            console.log('deletePatient response', response)
            this.setLoading(false)

        } catch (err) {
            console.log('deletePatient err', err)
            this.setLoading(false)
        }
    }


    fillSubTreatments = (patient,patientId) => {
        if (patient.sub_treatments.length > 0) {
            for (let i = 0; i < patient.sub_treatments.length; i++) {
                console.log('response.sub_treatments.length', patient.sub_treatments[i].after_completed)
                if (!this.areas.find((a) => a.title === `Subsequent Treatment n = ${i + 1}`)) {
                    this.areas.push({
                        id: (patient.sub_treatments.length * 8) + (i + 1),
                        title: `Subsequent Treatment n = ${i + 1}`,
                        date: patient.sub_treatments[i].treatment_updated_at ?
                            moment(patient.sub_treatments[i].treatment_updated_at).format('DD-MM-YYYY HH:mm') : '-',
                        completed: patient.sub_treatments[i].treatment_completed,
                        link: `/subsequentTreatmentTumorBiologyPage1/${patientId}/${i + 1}`,
                    });
                    if (patient.sub_treatments[i].treatment_completed === 'completed') {
                        this.setSelectionModel((patient.sub_treatments.length * 8) + (i + 1))

                    }

                }
                if (!this.areas.find((a) => a.title === `After Subsequent Treatment n = ${i + 1}`)) {
                    this.areas.push({
                        id: (patient.sub_treatments.length * 9) + (i + 1),
                        title: `After Subsequent Treatment n = ${i + 1}`,
                        date: patient.sub_treatments[i].after_updated_at ?
                            moment(patient.sub_treatments[i].after_updated_at).format('DD-MM-YYYY HH:mm') : '-',
                        completed: patient.sub_treatments[i].after_completed,
                        link: `/imagingAssessmentsAfterInitialTreatmentPage1/${patientId}/${i + 1}`,
                    });
                    if (patient.sub_treatments[i].after_completed === 'completed') {
                        this.setSelectionModel((patient.sub_treatments.length * 9) + (i + 1))
                    }

                }


            }
        }
    }

    createPatient = async () => {
        try {

            this.setLoading(true)
            const response = await CrfService.createPatient()
            console.log('getPatients response', response)
            this.setPatientsInfo(response)
            this.setSelectedPatient(response)
            this.setLoading(false)

        } catch (err) {
            console.log('createPatient err', err)
            this.setLoading(false)
        }
    }

    updatePatient = async (patientId, requestBody) => {
        try {
            this.setLoading(true)
            const response = await CrfService.updatePatient(patientId, requestBody)
            console.log('updatePatient response', response)
            this.setPatient(response)

            this.setSubTreatmentsNumber(response.sub_treatments)

            if (response.sociodemographic.completed === 'completed') {
                this.setSelectionModel(2)
            }
            if (response.tumor_biology.completed === 'completed') {
                this.setSelectionModel(6)
            }
            if (response.initial_treatment.completed === 'completed') {
                this.setSelectionModel(7)
            }
            if (response.inclusion_exclusion.completed === true) {
                this.setSelectionModel(1)
            }
            if (response.environmental_exposure.completed === 'completed') {
                this.setSelectionModel(4)
            }
            if (response.clinical_history.completed === 'completed') {
                this.setSelectionModel(3)
            }
            if (response.clinical_characterization.completed === 'completed') {
                this.setSelectionModel(5)
            }
            if (response.after_initial_treatment.completed === 'completed') {
                this.setSelectionModel(8)
            }
            if (response.global_missing) {
                this.setGlobalMissing(true)
            }
            console.log('response.sub_treatments.length', response.sub_treatments.length)


            if (response.last_modification) {
                this.setLastModification(response.last_modification)
            }
        } catch (err) {
            console.log('updatePatient err', err)
            this.setLoading(false)
        }
    }

    resetUpdateModalData = () =>{
        this.setShowUpdateModal('not active')
        this.setForceUpdate(false)
        this.setUpdateReason('')
    }


}


export default DataStore
