import * as React from 'react';
import {Checkbox, ClickAwayListener, FormControlLabel, RadioGroup, Stack} from "@mui/material";
import Paper from "@mui/material/Paper";
import RadioItem from "../../components/RadioItem";
import arrowUp from "../../images/svgs/Arrow-up.svg";
import {useStores} from "../../store";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Joi from "joi";
import Box from "@mui/material/Box";
import MissingReason from "../../components/MissingReason";


const OtherExamsMenu = ({setOpenMenu, text, sx, checked, index, item}) => {
    const {
        treatmentPatternsStore,dataStore
    } = useStores()
    const NrSchema = Joi.number().min(1).max(10).integer().required();
    let types = treatmentPatternsStore.q19.types


    return (
        <ClickAwayListener onClickAway={setOpenMenu}>
            <Paper sx={{
                display: 'flex',
                backgroundColor: 'white',
                mt: -6,
                ml: -3,
                position: 'absolute',
                p: 3,
                pt: 1,
                borderRadius: 3,
                flexDirection: 'column',
                zIndex: 999, ...sx,
                width:600
            }} elevation={5}>
                <Stack sx={{width: '100%', alignItems: "center"}} direction={'row'}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={(event) => {
                                    console.log('item.selected', item.selected)
                                    let types = treatmentPatternsStore.q19.types

                                    if (event.target.value === 'on' && item.selected === 'on') {
                                        setOpenMenu(null)
                                        types[index].selected = null
                                        types[index].numner = ''
                                        treatmentPatternsStore.setQ19(
                                            {
                                                ...treatmentPatternsStore.q19,
                                                types: types
                                            }
                                        )

                                    } else {
                                        types[index] = {
                                            ...item,
                                            selected: event.target.value
                                        }
                                        treatmentPatternsStore.setQ19(
                                            {
                                                ...treatmentPatternsStore.q19,
                                                types: types
                                            }
                                        )
                                    }
                                    setOpenMenu(index)

                                }}
                                checked={checked}
                            />
                        }
                        sx={{
                            '& .MuiFormControlLabel-label': {fontSize: 18},

                        }}

                        label={text}
                    />
                    <img onClick={setOpenMenu} style={{
                        cursor: 'pointer',
                        width: 18,
                        height: 18,
                    }} src={arrowUp}/>
                    {dataStore.questionsMissing.includes('BiopsyAll') ?
                        <MissingReason
                            style={{right:10}}
                            value={treatmentPatternsStore.q19?.types[index]?.type1?.name}
                            onSubmitMissing={()=>{

                                types[index] = {
                                    ...treatmentPatternsStore.q19.types[index],
                                    type1: {
                                        ...treatmentPatternsStore.q19.types[index].type1,
                                        name: dataStore.returnOtherTextMissing
                                    },
                                    type2: {
                                        ...treatmentPatternsStore.q19.types[index].type2,
                                        name: dataStore.returnOtherTextMissing
                                    }
                                }

                                treatmentPatternsStore.setQ19(
                                    {
                                        ...treatmentPatternsStore.q19,
                                        types: types
                                    }
                                )
                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('BiopsyAll'), 1)
                            }}/>
                        : <></>}
                </Stack>
                <Stack direction={'column'} sx={{pl: 3}}>
                    <Stack direction={'row'} sx={{justifyContent:'space-between'}}>

                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={(event) => {
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('BiopsyAll'), 1)

                                    let types = treatmentPatternsStore.q19.types

                                    if (event.target.value === 'on' && treatmentPatternsStore.q19.types[index].type1?.name &&
                                        treatmentPatternsStore.q19.types[index].type1.name === "Metastasis Biopsy") {
                                        types[index] = {
                                            ...treatmentPatternsStore.q19.types[index],
                                            type1: {...treatmentPatternsStore.q19.types[index].type1, name: null}
                                        }
                                        treatmentPatternsStore.setQ19(
                                            {
                                                ...treatmentPatternsStore.q19,
                                                types: types
                                            }
                                        )
                                    } else {
                                        types[index] = {
                                            ...treatmentPatternsStore.q19.types[index]
                                            ,
                                            type1: {
                                                ...treatmentPatternsStore.q19.types[index].type1,
                                                name: "Metastasis Biopsy"
                                            }
                                        }

                                        treatmentPatternsStore.setQ19(
                                            {
                                                ...treatmentPatternsStore.q19,
                                                types: types
                                            }
                                        )
                                    }
                                }}
                                checked={treatmentPatternsStore.q19.types[index].type1?.name && treatmentPatternsStore.q19.types[index].type1.name === "Metastasis Biopsy"}
                            />
                        }
                        label="Metastasis Biopsy"
                    />
                        {dataStore.questionsMissing.includes('Metastasis Biopsy') ?
                            <MissingReason
                                value={treatmentPatternsStore.q19?.types[index]?.type1?.name}
                                style={{right:10}}
                                onSubmitMissing={()=>{
                                    let types = treatmentPatternsStore.q19.types

                                    types[index] = {
                                        ...treatmentPatternsStore.q19.types[index]
                                        ,
                                        type1: {
                                            ...treatmentPatternsStore.q19.types[index].type1,
                                            name: dataStore.returnOtherTextMissing
                                        }
                                    }

                                    treatmentPatternsStore.setQ19(
                                        {
                                            ...treatmentPatternsStore.q19,
                                            types: types
                                        }
                                    )
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('Metastasis Biopsy'), 1)
                                }}/>
                        : <></>}
                    </Stack>
                    <Stack direction={'row'} sx={{justifyContent:'space-between'}}>

                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={(event) => {
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf("Tumor Biopsy"), 1)
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('BiopsyAll'), 1)

                                    let types = treatmentPatternsStore.q19.types
                                    if (event.target.value === 'on' && treatmentPatternsStore.q19.types[index].type2?.name && treatmentPatternsStore.q19.types[index].type2.name === "Tumor Biopsy") {
                                        types[index] = {
                                            ...treatmentPatternsStore.q19.types[index],
                                            type2: {...treatmentPatternsStore.q19.types[index].type2, name: null}
                                        }
                                        treatmentPatternsStore.setQ19(
                                            {
                                                ...treatmentPatternsStore.q19,
                                                types: types
                                            }
                                        )
                                    } else {
                                        types[index] = {
                                            ...treatmentPatternsStore.q19.types[index]
                                            ,
                                            type2: {
                                                ...treatmentPatternsStore.q19.types[index].type2,
                                                name: "Tumor Biopsy"
                                            }
                                        }

                                        treatmentPatternsStore.setQ19(
                                            {
                                                ...treatmentPatternsStore.q19,
                                                types: types
                                            }
                                        )
                                    }
                                }}
                                checked={treatmentPatternsStore.q19.types[index].type2?.name && treatmentPatternsStore.q19.types[index].type2.name === "Tumor Biopsy"}
                            />

                        }
                        label="Tumor Biopsy"
                    />
                        {dataStore.questionsMissing.includes("Tumor Biopsy")
                            ? <MissingReason
                                value={treatmentPatternsStore.q19?.types[index]?.type2?.name}
                                style={{right:10}}
                                onSubmitMissing={()=>{
                                    let types = treatmentPatternsStore.q19.types

                                    types[index] = {
                                        ...treatmentPatternsStore.q19.types[index]
                                        ,
                                        type2: {
                                            ...treatmentPatternsStore.q19.types[index].type2,
                                            name: dataStore.returnOtherTextMissing
                                        }
                                    }

                                    treatmentPatternsStore.setQ19(
                                        {
                                            ...treatmentPatternsStore.q19,
                                            types: types
                                        }
                                    )
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf("Tumor Biopsy"), 1)
                                }}/> : <></>}
                    </Stack>
                    {treatmentPatternsStore.q19.types[index].type2?.name && treatmentPatternsStore.q19.types[index].type2.name === "Tumor Biopsy" ? <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={(event) => {
                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q19type2number'), 1)

                            let types = treatmentPatternsStore.q19.types

                            types[index] = {
                                ...treatmentPatternsStore.q19.types[index]
                                , type2: {...treatmentPatternsStore.q19.types[index].type2, subName: event.target.value}
                            }
                            treatmentPatternsStore.setQ19(
                                {

                                    ...treatmentPatternsStore.q19,
                                    types: types
                                }
                            )
                        }}
                        value={treatmentPatternsStore.q19.types[index].type2?.subName}
                        sx={{display: 'flex', flexDirection: 'column', ml: 5}}
                    >
                        <RadioItem fontSize={15} label={'Extemporaneous examination'}/>
                        <RadioItem fontSize={15} label={'Histologic exam of the surgical specimen'}/>
                        <RadioItem fontSize={15} label={'Both'}/>
                        {dataStore.questionsMissing.includes('q19type2number')
                            ? <MissingReason
                                value={treatmentPatternsStore.q19?.types[index]?.type2?.subName}
                                style={{right:10}}
                                onSubmitMissing={()=>{
                                    let types = treatmentPatternsStore.q19.types

                                    types[index] = {
                                        ...treatmentPatternsStore.q19.types[index]
                                        , type2: {...treatmentPatternsStore.q19.types[index].type2, subName: dataStore.returnOtherTextMissing}
                                    }
                                    treatmentPatternsStore.setQ19(
                                        {

                                            ...treatmentPatternsStore.q19,
                                            types: types
                                        }
                                    )
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q19type2number'), 1)
                                }}/> : <></>}
                    </RadioGroup> : <></>}

                </Stack>
            </Paper>
        </ClickAwayListener>
    );
}

export default OtherExamsMenu;
