import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {RadioGroup, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import QuestionText from "../../components/QuestionText";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import RadioItem from "../../components/RadioItem";
import TextField from "@mui/material/TextField";
import QuestionsLayout from "../../layout/QuestionsLayout";
import MissingReason from "../../components/MissingReason";


const ClinicalCharacterizationPage1 = () => {
    const {
        clinicalCharacterizationStore, dataStore,menuStore
    } = useStores()
    const [openDate, setOpenDate] = React.useState(false);
    const [showMissing, setShowMissing] = React.useState('not active');
    let urlParams = useParams();

    const navigate = useNavigate()

    useEffect(() => {
        clinicalCharacterizationStore.getClinicalCharacterization(parseInt(urlParams.patient))
    }, [])

    const handleNext = async () => {
        if (!clinicalCharacterizationStore.checkMissing1) {
            await clinicalCharacterizationStore.updateClinicalCharacterization(
                {
                    q1: clinicalCharacterizationStore.q1,
                    q2: JSON.stringify(clinicalCharacterizationStore.q2),
                    q3: clinicalCharacterizationStore.q3,
                },parseInt(urlParams.patient))
            if(dataStore.forceUpdate == true){
                dataStore.setShowUpdateModal('not active')
            }
            if(dataStore.showUpdateModal !== 'active'){
                navigate('/clinicalCharacterizationPage2/'+parseInt(urlParams.patient))
            }
        } else {
            alert('Please add reason for missing questions.')

            // if (!dataStore.globalMissing) {
            //     if (showMissing === 'active') {
            //         await clinicalCharacterizationStore.updateClinicalCharacterization(
            //             {
            //                 q1: clinicalCharacterizationStore.q1 ? clinicalCharacterizationStore.q1 : dataStore.returnOtherTextMissing,
            //                 q2: clinicalCharacterizationStore.q2.main ? JSON.stringify(clinicalCharacterizationStore.q2) : dataStore.returnOtherObjectMissing,
            //                 q3: clinicalCharacterizationStore.q3 ? clinicalCharacterizationStore.q3 : dataStore.returnOtherTextMissing,
            //
            //             },parseInt(urlParams.patient))
            //         navigate('/clinicalCharacterizationPage2/'+parseInt(urlParams.patient))
            //         setShowMissing('not active')
            //     } else {
            //         setShowMissing('active')
            //     }
            // } else {
            //     await clinicalCharacterizationStore.updateClinicalCharacterization(
            //         {
            //             q1: clinicalCharacterizationStore.q1 ? clinicalCharacterizationStore.q1 : dataStore.returnOtherTextMissing,
            //             q2: clinicalCharacterizationStore.q2.main ? JSON.stringify(clinicalCharacterizationStore.q2) : dataStore.returnOtherObjectMissing,
            //             q3: clinicalCharacterizationStore.q3 ? clinicalCharacterizationStore.q3 : dataStore.returnOtherTextMissing,
            //
            //         },parseInt(urlParams.patient))
            //     navigate('/clinicalCharacterizationPage2/'+parseInt(urlParams.patient))
            // }
        }
    }

    return (<QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                             questionHeaderProps={{patientNumberEnd: true}}
                             showMissing={showMissing}
                             onCloseMissing={() => setShowMissing('not active')}
                             nextPress={handleNext}
                             backPress={() => navigate(-1)}
                             loading={clinicalCharacterizationStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>

                    <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                        Clinical Characterization
                    </Typography>
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>

                    <QuestionBox>
                        <QuestionText>
                            Time between the onset of the symptoms and referral
                            {dataStore.questionsMissing.includes('q1')
                                ? <MissingReason
                                    value={clinicalCharacterizationStore.q1}
                                    onSubmitMissing={async ()=>{
                                        clinicalCharacterizationStore.setQ1(
                                            dataStore.returnOtherTextMissing)
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q1'), 1)
                                    }}/> : <></>}
                        </QuestionText>
                        <Stack direction={'row'} sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
                            <Typography sx={{fontSize: 18, mr: 5}}>
                                Number weeks
                            </Typography>
                            <TextField
                                size={'small'}
                                type={"number"}
                                onWheel={(e) => e.target.blur()}
                                sx={{width: 100}}
                                inputProps={{min: 0, style: {textAlign: 'center'}}}
                                onChange={(event) => {
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q1'), 1)
                                    clinicalCharacterizationStore.setQ1(
                                    event.target.value)
                                }}
                                value={clinicalCharacterizationStore?.q1 && clinicalCharacterizationStore.q1.startsWith('missing|') ? '': clinicalCharacterizationStore.q1}

                            />
                        </Stack>
                    </QuestionBox>
                    <QuestionBox>
                        <QuestionText>
                            Time between the referral and the 1st oncology consultation
                            {dataStore.questionsMissing.includes('q2')
                                ? <MissingReason
                                    value={clinicalCharacterizationStore.q2.type}
                                    onSubmitMissing={async ()=>{
                                        clinicalCharacterizationStore.setQ2(
                                            {...clinicalCharacterizationStore.q2, type:  dataStore.returnOtherTextMissing,}
                                        )
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q2'), 1)
                                    }}/> : <></>}
                            {dataStore.questionsMissing.includes('q2value')
                                ? <MissingReason
                                    value={clinicalCharacterizationStore.q2.value}
                                    onSubmitMissing={async ()=>{
                                        clinicalCharacterizationStore.setQ2(
                                            {...clinicalCharacterizationStore.q2, value:  dataStore.returnOtherTextMissing,}
                                        )
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q2value'), 1)
                                    }}/> : <></>}
                        </QuestionText>
                        <Stack direction={'row'} sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={(event) => {
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q2'), 1)

                                    clinicalCharacterizationStore.setQ2(
                                        {...clinicalCharacterizationStore.q2, type: event.target.value,}
                                    )
                                    setOpenDate(true)
                                }}
                                value={clinicalCharacterizationStore.q2.type}
                                sx={{display: 'flex', flexDirection: 'row', gap: "24px", rowGap: 0}}
                            >
                                <RadioItem label={'Weeks'}/>
                                <RadioItem label={'Days'}/>
                            </RadioGroup>
                            {clinicalCharacterizationStore.q2.type != null ? <Stack direction={'row'} sx={{display: 'flex', alignItems: 'center'}}>
                                <Typography sx={{fontSize: 18, mr: 5}}>
                                    {clinicalCharacterizationStore.q2.type === 'Weeks' ? 'Number of Weeks' : 'Number of Days'}
                                </Typography>
                                {clinicalCharacterizationStore.q2.type !== '' && <TextField
                                    size={"small"}
                                    type={"number"}
                                    onWheel={(e) => e.target.blur()}
                                    sx={{width: 100}}
                                    inputProps={{min: 0, style: {textAlign: 'center'}}}
                                    onChange={(event) => {
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q2value'), 1)
                                        clinicalCharacterizationStore.setQ2(
                                        {...clinicalCharacterizationStore.q2, value: event.target.value,}
                                    )}}
                                    value={clinicalCharacterizationStore.q2?.value && clinicalCharacterizationStore.q2.value.startsWith('missing|') ? '': clinicalCharacterizationStore.q2.value}

                                />}

                            </Stack> : <></>}
                        </Stack>
                    </QuestionBox>

                    <QuestionBox allInRow={true}>
                        <QuestionText width="auto">
                            Diagnosis Date
                            {dataStore.questionsMissing.includes('q3')
                                ? <MissingReason
                                    value={clinicalCharacterizationStore.q3}
                                    onSubmitMissing={async ()=>{
                                        clinicalCharacterizationStore.setQ3(
                                            dataStore.returnOtherTextMissing
                                        )
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3'), 1)
                                    }}/> : <></>}
                        </QuestionText>
                        <Stack direction={'row'} sx={{display: 'flex', alignItems: 'center'}}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={(event) => {
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3'), 1)
                                    clinicalCharacterizationStore.setQ3(
                                    event.target.value
                                )}}
                                value={clinicalCharacterizationStore.q3}
                                sx={{display: 'flex', flexDirection: 'row', gap: "24px", rowGap: 0}}
                            >
                                <RadioItem label={'2S2016'}/>
                                <RadioItem label={'1S2017'}/>
                                <RadioItem label={'2S2017'}/>
                            </RadioGroup>
                        </Stack>
                    </QuestionBox>

                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(ClinicalCharacterizationPage1);
