import {action, makeObservable, observable,} from 'mobx'
import {CrfService} from "../../services/openapi";
import {Stores} from "../../store";

class EnvironmentalExposureStore {

    loading = false
    q1 = {
        main: null,
        exposure: null,
        uma: ''
    }
    q2 = null
    q3 = {selected: null, value: []}

    constructor() {
        makeObservable(this, {
            loading: observable,
            q1: observable,
            q2: observable,
            q3: observable,
            setQ1: action,
            setQ2: action,
            setQ3: action,
            setLoading: action,
            updateEnvironmentalExposure: action,
            getEnvironmentalExposure: action,
            reset: action,
        },)
    }

    reset = () => {
        this.setQ1({
            main: null,
            exposure: null,
            uma: ''
        })
        this.setQ2(null)
        this.setQ3({selected: null, value: []})
    }

    get checkMissing() {
        if (this.q3 == null || this.q3.selected == null) {
            Stores.dataStore.setQuestionsMissing('q3')

            return true
        } else if(this.q3.selected == 'true' && this.q3.value.length == 0 && !this.q3?.missingValueReason){
            Stores.dataStore.setQuestionsMissing('q3value')

            return true
        }
        return false
    }


    setQ1 = (value) => {
        this.q1 = value
    }

    setQ2 = (value) => {
        this.q2 = value
    }
    setQ3 = (value) => {
        this.q3 = value
    }


    setLoading = (value) => {
        this.loading = value
    }

    updateEnvironmentalExposure = async (requestBody,patient) => {
        try {
            this.setLoading(true)
            console.log('requestBody', requestBody)
            let params = {...requestBody,force:Stores.dataStore.forceUpdate}

            if(Stores.dataStore.updateReason !== ''){
                params = {...params, reason:Stores.dataStore.updateReason}
            }
            console.log('updateEnvironmentalExposure params', params)
            const response = await CrfService.updateEnvironmentalExposure(patient, params)
            console.log('updateEnvironmentalExposure response', response)
            if (response.q1 != null) {
                this.setQ1(response.q1)
            }
            if (response.q2 != null) {
                this.setQ2(response.q2)
            }
            if (response.q3 != null) {
                this.setQ3(response.q3)
            }
            Stores.dataStore.resetUpdateModalData()

            this.setLoading(false)

        } catch (err) {
            this.setLoading(false)
            console.log('updateEnvironmentalExposure err', err)
            if(err?.body?.data?.updated){
                Stores.dataStore.setShowUpdateModal('active')
            }else {
                Stores.dataStore.setShowUpdateModal('not active')
            }
        }
    }


    getEnvironmentalExposure = async (patient) => {
        try {
            this.setLoading(true)

            const response = await CrfService.getEnvironmentalExposure(patient)
            console.log('getEnvironmentalExposure response', response)

            if (response.q1 != null) {
                this.setQ1(response.q1)
            }
            if (response.q2 != null) {
                this.setQ2(response.q2)
            }
            if (response.q3 != null) {
                this.setQ3(response.q3);
                if (response.q3?.selected && response.q3.selected.startsWith('missing|')) {
                    Stores.dataStore.setQuestionsMissing('q3')
                }
                if (response.q3?.missingValueReason && response.q3.missingValueReason.startsWith('missing|')) {
                    Stores.dataStore.setQuestionsMissing('q3value')
                }
            }

            Stores.dataStore.setForceUpdate(false)

            this.setLoading(false)

        } catch (err) {
            this.setLoading(false)
            console.log('getEnvironmentalExposure err', err)
        }
    }


}


export default EnvironmentalExposureStore
