/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AfterInitialTreatmentSchema } from '../models/AfterInitialTreatmentSchema';
import type { AfterSubsequentTreatmentSchema } from '../models/AfterSubsequentTreatmentSchema';
import type { ClinicalCharacterizationSchema } from '../models/ClinicalCharacterizationSchema';
import type { ClinicalHistorySchema } from '../models/ClinicalHistorySchema';
import type { DispositionSchema } from '../models/DispositionSchema';
import type { EnvironmentalExposureSchema } from '../models/EnvironmentalExposureSchema';
import type { InclusionExclusionSchema } from '../models/InclusionExclusionSchema';
import type { InitialTreatmentSchema } from '../models/InitialTreatmentSchema';
import type { Message } from '../models/Message';
import type { PagedPatientSchema } from '../models/PagedPatientSchema';
import type { PatientSchema } from '../models/PatientSchema';
import type { SociodemographicSchema } from '../models/SociodemographicSchema';
import type { SubsequentTreatmentSchema } from '../models/SubsequentTreatmentSchema';
import type { TumorBiologySchema } from '../models/TumorBiologySchema';
import type { UpdateAfterTreatmentSchema } from '../models/UpdateAfterTreatmentSchema';
import type { UpdateClinicalCharacterizationSchema } from '../models/UpdateClinicalCharacterizationSchema';
import type { UpdateClinicalHistorySchema } from '../models/UpdateClinicalHistorySchema';
import type { UpdateDispositionSchema } from '../models/UpdateDispositionSchema';
import type { UpdateEnvironmentalExposureSchema } from '../models/UpdateEnvironmentalExposureSchema';
import type { UpdateInclusionExclusionSchema } from '../models/UpdateInclusionExclusionSchema';
import type { UpdatePatientSchema } from '../models/UpdatePatientSchema';
import type { UpdateSociodemographicSchema } from '../models/UpdateSociodemographicSchema';
import type { UpdateTreatmentSchema } from '../models/UpdateTreatmentSchema';
import type { UpdateTumorBiologySchema } from '../models/UpdateTumorBiologySchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CrfService {

    /**
     * Get patients
     * Get patients
     * @param compact
     * @returns PatientSchema OK
     * @throws ApiError
     */
    public static getPatients(
        compact: boolean,
    ): CancelablePromise<Array<PatientSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/crf/patients',
            query: {
                'compact': compact,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * View patients
     * View patients
     * @param search
     * @param date
     * @param page
     * @returns PagedPatientSchema OK
     * @throws ApiError
     */
    public static viewPatients(
        search?: string,
        date?: string,
        page: number = 1,
    ): CancelablePromise<PagedPatientSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/crf/patients/view',
            query: {
                'search': search,
                'date': date,
                'page': page,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get inclusion exclusion
     * Get inclusion exclusion
     * @param patientId
     * @returns InclusionExclusionSchema OK
     * @throws ApiError
     */
    public static getInclusionExclusion(
        patientId: number,
    ): CancelablePromise<InclusionExclusionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/crf/patient/{patient_id}/inclusion_exclusion',
            path: {
                'patient_id': patientId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update inclusion exclusion
     * Update inclusion exclusion
     * @param patientId
     * @param requestBody
     * @returns InclusionExclusionSchema OK
     * @throws ApiError
     */
    public static updateInclusionExclusion(
        patientId: number,
        requestBody: UpdateInclusionExclusionSchema,
    ): CancelablePromise<InclusionExclusionSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/crf/patient/{patient_id}/inclusion_exclusion',
            path: {
                'patient_id': patientId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get socio demographic
     * Get socio demographic
     * @param patientId
     * @returns SociodemographicSchema OK
     * @throws ApiError
     */
    public static getSocioDemographic(
        patientId: number,
    ): CancelablePromise<SociodemographicSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/crf/patient/{patient_id}/socio_demographic',
            path: {
                'patient_id': patientId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update socio demographic
     * Update socio demographic
     * @param patientId
     * @param requestBody
     * @returns SociodemographicSchema OK
     * @throws ApiError
     */
    public static updateSocioDemographic(
        patientId: number,
        requestBody: UpdateSociodemographicSchema,
    ): CancelablePromise<SociodemographicSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/crf/patient/{patient_id}/socio_demographic',
            path: {
                'patient_id': patientId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get clinical history
     * Get clinical history
     * @param patientId
     * @returns ClinicalHistorySchema OK
     * @throws ApiError
     */
    public static getClinicalHistory(
        patientId: number,
    ): CancelablePromise<ClinicalHistorySchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/crf/patient/{patient_id}/clinical_history',
            path: {
                'patient_id': patientId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update clinical history
     * Update clinical history
     * @param patientId
     * @param requestBody
     * @returns ClinicalHistorySchema OK
     * @throws ApiError
     */
    public static updateClinicalHistory(
        patientId: number,
        requestBody: UpdateClinicalHistorySchema,
    ): CancelablePromise<ClinicalHistorySchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/crf/patient/{patient_id}/clinical_history',
            path: {
                'patient_id': patientId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get environmental exposure
     * Get environmental exposure
     * @param patientId
     * @returns EnvironmentalExposureSchema OK
     * @throws ApiError
     */
    public static getEnvironmentalExposure(
        patientId: number,
    ): CancelablePromise<EnvironmentalExposureSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/crf/patient/{patient_id}/environmental_exposure',
            path: {
                'patient_id': patientId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update environmental exposure
     * Update environmental exposure
     * @param patientId
     * @param requestBody
     * @returns EnvironmentalExposureSchema OK
     * @throws ApiError
     */
    public static updateEnvironmentalExposure(
        patientId: number,
        requestBody: UpdateEnvironmentalExposureSchema,
    ): CancelablePromise<EnvironmentalExposureSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/crf/patient/{patient_id}/environmental_exposure',
            path: {
                'patient_id': patientId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get clinical characterization
     * Get clinical characterization
     * @param patientId
     * @returns ClinicalCharacterizationSchema OK
     * @throws ApiError
     */
    public static getClinicalCharacterization(
        patientId: number,
    ): CancelablePromise<ClinicalCharacterizationSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/crf/patient/{patient_id}/clinical_characterization',
            path: {
                'patient_id': patientId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update clinical characterization
     * Update clinical characterization
     * @param patientId
     * @param requestBody
     * @returns ClinicalCharacterizationSchema OK
     * @throws ApiError
     */
    public static updateClinicalCharacterization(
        patientId: number,
        requestBody: UpdateClinicalCharacterizationSchema,
    ): CancelablePromise<ClinicalCharacterizationSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/crf/patient/{patient_id}/clinical_characterization',
            path: {
                'patient_id': patientId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get tumor biology
     * Get tumor biology
     * @param patientId
     * @returns TumorBiologySchema OK
     * @throws ApiError
     */
    public static getTumorBiology(
        patientId: number,
    ): CancelablePromise<TumorBiologySchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/crf/patient/{patient_id}/tumor_biology',
            path: {
                'patient_id': patientId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update tumor biology
     * Update tumor biology
     * @param patientId
     * @param requestBody
     * @returns TumorBiologySchema OK
     * @throws ApiError
     */
    public static updateTumorBiology(
        patientId: number,
        requestBody: UpdateTumorBiologySchema,
    ): CancelablePromise<TumorBiologySchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/crf/patient/{patient_id}/tumor_biology',
            path: {
                'patient_id': patientId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get treatment
     * Get treatment
     * @param patientId
     * @param number
     * @returns any OK
     * @throws ApiError
     */
    public static getTreatment(
        patientId: number,
        number: number,
    ): CancelablePromise<(InitialTreatmentSchema | SubsequentTreatmentSchema)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/crf/patient/{patient_id}/treatment/{number}',
            path: {
                'patient_id': patientId,
                'number': number,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update treatment
     * Update treatment
     * @param patientId
     * @param number
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateTreatment(
        patientId: number,
        number: number,
        requestBody: UpdateTreatmentSchema,
    ): CancelablePromise<(InitialTreatmentSchema | SubsequentTreatmentSchema)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/crf/patient/{patient_id}/treatment/{number}',
            path: {
                'patient_id': patientId,
                'number': number,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get after treatment
     * Get after treatment
     * @param patientId
     * @param number
     * @returns any OK
     * @throws ApiError
     */
    public static getAfterTreatment(
        patientId: number,
        number: number,
    ): CancelablePromise<(AfterInitialTreatmentSchema | AfterSubsequentTreatmentSchema)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/crf/patient/{patient_id}/after_treatment/{number}',
            path: {
                'patient_id': patientId,
                'number': number,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update after treatment
     * Update after treatment
     * @param patientId
     * @param number
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateAfterTreatment(
        patientId: number,
        number: number,
        requestBody: UpdateAfterTreatmentSchema,
    ): CancelablePromise<(AfterInitialTreatmentSchema | AfterSubsequentTreatmentSchema)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/crf/patient/{patient_id}/after_treatment/{number}',
            path: {
                'patient_id': patientId,
                'number': number,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Delete subsequent treatment
     * Delete subsequent treatment
     * @param patientId
     * @returns Message OK
     * @throws ApiError
     */
    public static deleteSubsequentTreatment(
        patientId: number,
    ): CancelablePromise<Message> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/crf/patient/{patient_id}/subsequent_treatment',
            path: {
                'patient_id': patientId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get disposition
     * Get disposition
     * @param patientId
     * @returns DispositionSchema OK
     * @throws ApiError
     */
    public static getDisposition(
        patientId: number,
    ): CancelablePromise<DispositionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/crf/patient/{patient_id}/disposition',
            path: {
                'patient_id': patientId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update disposition
     * Update disposition
     * @param patientId
     * @param requestBody
     * @returns DispositionSchema OK
     * @throws ApiError
     */
    public static updateDisposition(
        patientId: number,
        requestBody: UpdateDispositionSchema,
    ): CancelablePromise<DispositionSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/crf/patient/{patient_id}/disposition',
            path: {
                'patient_id': patientId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get patient
     * Get patient
     * @param patientId
     * @returns PatientSchema OK
     * @throws ApiError
     */
    public static getPatient(
        patientId: number,
    ): CancelablePromise<PatientSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/crf/patient/{patient_id}',
            path: {
                'patient_id': patientId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update patient
     * Update patient
     * @param patientId
     * @param requestBody
     * @returns PatientSchema OK
     * @throws ApiError
     */
    public static updatePatient(
        patientId: number,
        requestBody: UpdatePatientSchema,
    ): CancelablePromise<PatientSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/crf/patient/{patient_id}',
            path: {
                'patient_id': patientId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Delete patient
     * Delete patient
     * @param patientId
     * @returns Message OK
     * @throws ApiError
     */
    public static deletePatient(
        patientId: number,
    ): CancelablePromise<Message> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/crf/patient/{patient_id}',
            path: {
                'patient_id': patientId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Create patient
     * Create patient
     * @returns PatientSchema OK
     * @throws ApiError
     */
    public static createPatient(): CancelablePromise<PatientSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/crf/patient',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
