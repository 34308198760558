import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import YesNo from "../../components/YesNo";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import QuestionsLayout from "../../layout/QuestionsLayout";
import MissingReason from "../../components/MissingReason";


const OtherExamsAfterInitialPage1 = () => {
    const {
        afterInitialTreatmentStore, dataStore,menuStore
    } = useStores()
    const [showMissing, setShowMissing] = React.useState('not active');

    const navigate = useNavigate()
    let params = useParams();
    useEffect(() => {
        afterInitialTreatmentStore.getAfterTreatment(parseInt(params.patient),parseInt(params.number))
    }, [])

    const handleNext = async () => {
        if (!afterInitialTreatmentStore.checkMissing3) {
                await afterInitialTreatmentStore.updateAfterTreatment(
                    {
                        q3: JSON.stringify(afterInitialTreatmentStore.q3),
                    }, parseInt(params.number), parseInt(params.patient))

            if (dataStore.forceUpdate === true) {
                dataStore.setShowUpdateModal('not active')
            }
            if (dataStore.showUpdateModal !== 'active') {
                if (afterInitialTreatmentStore.q3?.main && afterInitialTreatmentStore.q3.main === 'true') {
                    navigate(`/otherExamsAfterInitialPage2/${parseInt(params.patient)}/${parseInt(params.number)}`)

                } else {
                    navigate(`/hospitalizationsAfterInitialTreatmentPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)

                }
            }

        } else {
            alert('Please add reason for missing questions.')

            // if (!dataStore.globalMissing) {
            //     if (showMissing === 'active') {
            //         await afterInitialTreatmentStore.updateAfterTreatment(
            //             {
            //                 q3: afterInitialTreatmentStore.q3?.main ? JSON.stringify(afterInitialTreatmentStore.q3) : dataStore.returnOtherObjectMissing,
            //             }, parseInt(params.number),parseInt(params.patient))
            //         if (afterInitialTreatmentStore.q3?.main && afterInitialTreatmentStore.q3.main === 'true') {
            //             navigate(`/otherExamsAfterInitialPage2/${parseInt(params.patient)}/${parseInt(params.number)}`)
            //         } else {
            //             navigate(`/hospitalizationsAfterInitialTreatmentPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            //         }
            //         setShowMissing('not active')
            //     } else {
            //         setShowMissing('active')
            //     }
            // } else {
            //     await afterInitialTreatmentStore.updateAfterTreatment(
            //         {
            //             q3: afterInitialTreatmentStore.q3?.main ? JSON.stringify(afterInitialTreatmentStore.q3) : dataStore.returnOtherObjectMissing,
            //         }, parseInt(params.number),parseInt(params.patient))
            //     if (afterInitialTreatmentStore.q3?.main && afterInitialTreatmentStore.q3.main === 'true') {
            //         navigate(`/otherExamsAfterInitialPage2/${parseInt(params.patient)}/${parseInt(params.number)}`)
            //     } else {
            //         navigate(`/hospitalizationsAfterInitialTreatmentPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
            //     }
            // }
        }
    }

    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={afterInitialTreatmentStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>

                    {parseInt(params.number) === 0 ?
                        <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                            Health Care Resource utilization after treatment completion or discontinuation
                        </Typography> :
                        <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                            {`Health Care Resource utilization after completion or discontinuation of treatment n = ${params.number}`}
                        </Typography>
                    }
                    <Typography color={'txtGrey'} sx={{fontWeight: 'bold', fontSize: 18,}}>
                        [Please complete this section only during the time between treatments. Subsequent treatment data to be collected in section “Subsequent Treatment (n=2, 3, x ….)”.]
                    </Typography>
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>
                    <Typography sx={{fontWeight: 'bold', fontSize: 30,}}>
                        Other Exams
                    </Typography>
                    <QuestionBox allInRow={true}>
                        <YesNo value={afterInitialTreatmentStore.q3?.main && afterInitialTreatmentStore.q3.main}
                               handleChange={(event) => {
                                   dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3'), 1)

                                   if (event.target.value === 'true') {
                                       afterInitialTreatmentStore.setQ3({
                                   ...afterInitialTreatmentStore.q3,
                                   main: event.target.value,
                               })
                                   }else {
                                       afterInitialTreatmentStore.setQ3({
                                           ...afterInitialTreatmentStore.q3,
                                           main: event.target.value,
                                           types: [
                                               {
                                                   name: 'ECG',
                                                   ...afterInitialTreatmentStore.commonQ1Params
                                               },
                                               {
                                                   name: 'Echocardiogram',
                                                   ...afterInitialTreatmentStore.commonQ1Params
                                               },
                                               {
                                                   name: 'Spirometry',
                                                   ...afterInitialTreatmentStore.commonQ1Params
                                               },
                                               {
                                                   name: 'Plethysmography',
                                                   ...afterInitialTreatmentStore.commonQ1Params
                                               },
                                               {
                                                   name: 'Biopsy',
                                                   selected: null,
                                                   type1: {name: null, subName: null},
                                                   type2: {name: null, subName: null}
                                               },
                                               {
                                                   name: 'CT-guided biopsy',
                                                   ...afterInitialTreatmentStore.commonQ1Params
                                               },
                                               {
                                                   name: 'Bronchoscopy',
                                                   ...afterInitialTreatmentStore.commonQ1Params
                                               },
                                               {
                                                   name: 'EBUS',
                                                   ...afterInitialTreatmentStore.commonQ1Params
                                               },
                                               {
                                                   name: 'EUS mediastinal',
                                                   ...afterInitialTreatmentStore.commonQ1Params
                                               },
                                           ]
                                       })

                                   }

                               }}
                        >
                            {dataStore.questionsMissing.includes('q3')
                                ? <MissingReason
                                    value={afterInitialTreatmentStore.q3.main}

                                    style={{right:60,marginTop:-3}}
                                    onSubmitMissing={()=>{
                                        afterInitialTreatmentStore.setQ3({
                                            ...afterInitialTreatmentStore.q3,
                                            main: dataStore.returnOtherTextMissing,
                                        })
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q3'), 1)
                                    }}/> : <></>}
                        </YesNo>
                    </QuestionBox>

                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(OtherExamsAfterInitialPage1);
