import * as React from 'react';
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";


const Required = () => {

    return (
        <span style={{fontSize:25,color:'red',position:'absolute',marginLeft:5}}>*</span>
    );
}

export default Required;
