import * as React from 'react';
import Menu from "../menu/Menu";
import Loading from "../components/Loading";
import Box from "@mui/material/Box";

const Layout = ({children, activeItem, loading, sx}) => {
    return (<Box component="main" sx={{height: '100vh', display: "flex", overflow: "hidden", ...sx}}>
            <Menu activeItem={activeItem}/>
            <Box sx={{height: "100vh", width: "100%", overflow: "auto",}}>
                {children}
            </Box>
            {loading && <Loading/>}
        </Box>)
}

export default Layout
