/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FileMessage } from '../models/FileMessage';
import type { FilterLogs } from '../models/FilterLogs';
import type { PagedLogSchema } from '../models/PagedLogSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuditService {

    /**
     * Get logs
     * Get logs
     * @param sort
     * @param page
     * @param requestBody
     * @returns PagedLogSchema OK
     * @throws ApiError
     */
    public static getLogs(
        sort?: string,
        page: number = 1,
        requestBody?: FilterLogs,
    ): CancelablePromise<PagedLogSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/audit/logs',
            query: {
                'sort': sort,
                'page': page,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Download logs
     * Download logs
     * @param sort
     * @param requestBody
     * @returns FileMessage OK
     * @throws ApiError
     */
    public static downloadLogs(
        sort?: string,
        requestBody?: FilterLogs,
    ): CancelablePromise<FileMessage> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/audit/logs/download',
            query: {
                'sort': sort,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
