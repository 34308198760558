import {useStores,} from '../../store'
import * as React from 'react';
import {useEffect} from 'react';
import {Checkbox, FormControlLabel, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import YesNo from "../../components/YesNo";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import TextField from "@mui/material/TextField";
import arrowDown from "../../images/svgs/Arrow-down.svg";
import SpecialityMenuAfterInitial from "./SpecialityMenuAfterInitial";
import QuestionsLayout from "../../layout/QuestionsLayout";
import Required from "../../components/Required";
import MissingReason from "../../components/MissingReason";


const DiseaseRelatedAfterInitialPage1 = () => {
    const {
        afterInitialTreatmentStore, dataStore,menuStore
    } = useStores()
    const [openMenu, setOpenMenu] = React.useState(false);
    const [showMissing, setShowMissing] = React.useState('not active');

    const navigate = useNavigate()

    let params = useParams();
    useEffect(() => {
        afterInitialTreatmentStore.getAfterTreatment(parseInt(params.patient),parseInt(params.number))
    }, [])

    const handleNext = async () => {
        if (afterInitialTreatmentStore.q6.main !== null) {

            if (!afterInitialTreatmentStore.checkMissing5) {
                await afterInitialTreatmentStore.updateAfterTreatment(
                    {
                        q6: JSON.stringify(afterInitialTreatmentStore.q6),
                    }, parseInt(params.number), parseInt(params.patient))
                if (dataStore.forceUpdate == true) {
                    dataStore.setShowUpdateModal('not active')
                }
                if (dataStore.showUpdateModal !== 'active') {
                    navigate(`/afterInitialEmergencyConsultationsPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
                }
            } else {
                alert('Please add reason for missing questions.')
            }
        }else{
            alert('Please answer all required questions.')

        }
        // } else {
        //     if (!dataStore.globalMissing) {
        //         if (showMissing === 'active') {
        //             await afterInitialTreatmentStore.updateAfterTreatment(
        //                 {
        //                     q6: afterInitialTreatmentStore.q6?.main ? JSON.stringify(afterInitialTreatmentStore.q6) : dataStore.returnOtherObjectMissing,
        //                 }, parseInt(params.number),parseInt(params.patient))
        //             navigate(`/afterInitialEmergencyConsultationsPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
        //
        //             setShowMissing('not active')
        //         } else {
        //             setShowMissing('active')
        //         }
        //     } else {
        //         await afterInitialTreatmentStore.updateAfterTreatment(
        //             {
        //                 q6: afterInitialTreatmentStore.q6?.main ? JSON.stringify(afterInitialTreatmentStore.q6) : dataStore.returnOtherObjectMissing,
        //             }, parseInt(params.number),parseInt(params.patient))
        //         navigate(`/afterInitialEmergencyConsultationsPage1/${parseInt(params.patient)}/${parseInt(params.number)}`)
        //     }
        // }
    }

    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={afterInitialTreatmentStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>
                    {parseInt(params.number) === 0 ?
                        <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                            Health Care Resource utilization after treatment completion or discontinuation
                        </Typography> :
                        <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                            {`Health Care Resource utilization after completion or discontinuation of treatment n = ${params.number}`}
                        </Typography>
                    }
                    <Typography color={'txtGrey'} sx={{fontWeight: 'bold', fontSize: 18,}}>
                        [Please complete this section only during the time between treatments. Subsequent treatment data to be collected in section “Subsequent Treatment (n=2, 3, x ….)”.]
                    </Typography>
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>
                    <Typography sx={{fontWeight: 'bold', fontSize: 30,}}>
                        Disease-related Outpatient Consultations <Required/>
                    </Typography>
                    <QuestionBox allInRow={true}>
                        <YesNo value={afterInitialTreatmentStore.q6?.main}
                               handleChange={(event) => {
                                   dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q6'), 1)

                                   if (event.target.value === 'true') {
                                       afterInitialTreatmentStore.setQ6(
                                           {...afterInitialTreatmentStore.q6, main: event.target.value,})
                                   }else{
                                       afterInitialTreatmentStore.setQ6(
                                           {
                                               ...afterInitialTreatmentStore.q6, main: event.target.value,
                                               oncology: {selected: null, amount: '', unknown: null},
                                               other: {
                                                   selected: null,
                                                   unknown: null,
                                                   specialities: [
                                                       {
                                                           speciality: '',
                                                           amount: ''
                                                       },

                                                   ],
                                               },
                                               gp: {selected: null, amount: '', unknown: null},
                                           });
                                   }
                               }}
                        >
                            {dataStore.questionsMissing.includes('q6') ?
                                <MissingReason
                                    value={afterInitialTreatmentStore.q6.main}
                                    style={{right:60,marginTop:-3}}
                                    onSubmitMissing={()=>{
                                        afterInitialTreatmentStore.setQ6(
                                            {...afterInitialTreatmentStore.q6, main: dataStore.returnOtherTextMissing,})
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q6'), 1)
                                    }}/>
                                : <></>}
                        </YesNo>
                    </QuestionBox>
                    {afterInitialTreatmentStore.q6.main === 'true' ? <QuestionBox>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                width: '100%'
                            }}
                        >
                            <Stack direction={'row'} sx={{
                                width: '100%', display: 'flex', justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(event) => {
                                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q6value'), 1)

                                                if (event.target.value === 'on' && afterInitialTreatmentStore.q6.oncology.selected === 'on') {
                                                    afterInitialTreatmentStore.setQ6(
                                                        {
                                                            ...afterInitialTreatmentStore.q6,
                                                            oncology: {
                                                                selected: null,
                                                                amount: '',
                                                                unknown: null
                                                            }
                                                        })

                                                } else {
                                                    afterInitialTreatmentStore.setQ6(
                                                        {
                                                            ...afterInitialTreatmentStore.q6,
                                                            oncology: {
                                                                ...afterInitialTreatmentStore.q6.oncology,
                                                                selected: event.target.value,
                                                            }
                                                        })
                                                }

                                            }}
                                            checked={(afterInitialTreatmentStore.q6.oncology?.selected && afterInitialTreatmentStore.q6.oncology.selected.startsWith('missing|')) ? false : afterInitialTreatmentStore.q6.oncology.selected}

                                        />
                                    }
                                    sx={{
                                        '& .MuiFormControlLabel-label': {fontSize: 18},

                                    }}
                                    label={'Oncology Follow-up Visits'}
                                />
                                {afterInitialTreatmentStore.q6.oncology.selected &&
                                <Box sx={{display: 'flex', alignItems: 'center',}}>
                                    <Typography sx={{fontSize: 18, mr: 1}}>

                                        {dataStore.questionsMissing.includes('oncology') ?
                                            <MissingReason
                                                value={afterInitialTreatmentStore.q6.oncology.amount}
                                                style={{marginLeft:-80}}
                                                onSubmitMissing={()=>{
                                                    afterInitialTreatmentStore.setQ6(
                                                        {
                                                            ...afterInitialTreatmentStore.q6,
                                                            oncology: {
                                                                ...afterInitialTreatmentStore.q6.oncology,
                                                                amount: dataStore.returnOtherTextMissing,
                                                            }
                                                        })
                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('oncology'), 1)
                                                }}/>
                                            : <></>}

                                        Number
                                    </Typography>
                                    <TextField
                                        id="numberMonths"
                                        type={"number"}
                                        onWheel={(e) => e.target.blur()}
                                        size={'small'}
                                        inputProps={{min: 0, style: {textAlign: 'center'}}}
                                        sx={{width: 100, ml: 3, mr: 3}}
                                        placeholder={'00'}
                                        onChange={(event) => {
                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('oncology'), 1)

                                            afterInitialTreatmentStore.setQ6(
                                                {
                                                    ...afterInitialTreatmentStore.q6,
                                                    oncology: {
                                                        ...afterInitialTreatmentStore.q6.oncology,
                                                        amount: event.target.value, unknown: null
                                                    }
                                                })
                                        }}
                                        value={afterInitialTreatmentStore.q6.oncology?.amount && afterInitialTreatmentStore.q6.oncology.amount.startsWith('missing|') ? '': afterInitialTreatmentStore.q6.oncology.amount}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(event) => {
                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('oncology'), 1)

                                                    afterInitialTreatmentStore.setQ6(
                                                        {
                                                            ...afterInitialTreatmentStore.q6,
                                                            oncology: {
                                                                ...afterInitialTreatmentStore.q6.oncology,
                                                                unknown: event.target.value, amount: ''
                                                            }
                                                        })


                                                }}
                                                checked={(afterInitialTreatmentStore.q6.oncology?.unknown && afterInitialTreatmentStore.q6.oncology.unknown.startsWith('missing|')) ? false : afterInitialTreatmentStore.q6.oncology.unknown}

                                            />
                                        }
                                        sx={{
                                            '& .MuiFormControlLabel-label': {fontSize: 18},

                                        }}
                                        label={'Unknown'}
                                    />
                                </Box>}
                                {dataStore.questionsMissing.includes('q6value') ?
                                    <MissingReason
                                        value={afterInitialTreatmentStore.q6.oncology.selected}
                                        style={{right:60,marginTop:3}}
                                        onSubmitMissing={()=>{
                                            afterInitialTreatmentStore.setQ6(
                                                {
                                                    ...afterInitialTreatmentStore.q6,
                                                    oncology: {
                                                        ...afterInitialTreatmentStore.q6.oncology,
                                                        selected: dataStore.returnOtherTextMissing,
                                                    }                                                })
                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q6value'), 1)
                                        }}/>
                                    : <></>}
                            </Stack>
                            <Stack direction={'row'} sx={{
                                width: '100%', display: 'flex', justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                                <Box sx={{width: '100%',}}>
                                    <Box sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={(event) => {
                                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q6value'), 1)

                                                        if (event.target.value === 'on' && afterInitialTreatmentStore.q6.other.selected === 'on') {
                                                            afterInitialTreatmentStore.setQ6(
                                                                {
                                                                    ...afterInitialTreatmentStore.q6,
                                                                    other: {
                                                                        selected: null,
                                                                        unknown: null,
                                                                        specialities: [
                                                                            {
                                                                                speciality: '',
                                                                                amount: ''
                                                                            }
                                                                        ],
                                                                    }
                                                                })

                                                        } else {
                                                            setOpenMenu(true)
                                                            afterInitialTreatmentStore.setQ6(
                                                                {
                                                                    ...afterInitialTreatmentStore.q6,
                                                                    other: {
                                                                        ...afterInitialTreatmentStore.q6.other,
                                                                        selected: event.target.value,
                                                                        unknown: null
                                                                    }
                                                                })
                                                        }

                                                    }}
                                                    checked={(afterInitialTreatmentStore.q6.other?.selected && afterInitialTreatmentStore.q6.other.selected.startsWith('missing|')) ? false : afterInitialTreatmentStore.q6.other.selected}

                                                />
                                            }
                                            sx={{
                                                '& .MuiFormControlLabel-label': {fontSize: 18},

                                            }}
                                            label={'Other Hospital Specialists'}
                                        />
                                        {afterInitialTreatmentStore.q6.other.selected &&
                                            <img onClick={() => setOpenMenu(true)} style={{
                                                cursor: 'pointer',
                                                width: 18,
                                                height: 18,
                                            }}
                                                 src={arrowDown}/>}
                                    </Box>
                                    {openMenu &&
                                        <SpecialityMenuAfterInitial setOpenMenu={() => setOpenMenu(false)}/>}
                                </Box>
                                {afterInitialTreatmentStore.q6.other.selected &&
                                <Box sx={{display: 'flex', alignItems: 'center', mt: 1}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(event) => {
                                                    afterInitialTreatmentStore.setQ6(
                                                        {
                                                            ...afterInitialTreatmentStore.q6,
                                                            other: {
                                                                ...afterInitialTreatmentStore.q6.other,
                                                                unknown: event.target.value,
                                                                specialities: [
                                                                    {
                                                                        speciality: '',
                                                                        amount: ''
                                                                    }
                                                                ]
                                                            }
                                                        })


                                                }}
                                                checked={(afterInitialTreatmentStore.q6.other?.unknown && afterInitialTreatmentStore.q6.other.unknown.startsWith('missing|')) ? false :afterInitialTreatmentStore.q6.other.unknown}

                                            />
                                        }
                                        sx={{
                                            '& .MuiFormControlLabel-label': {fontSize: 18},

                                        }}
                                        label={'Unknown'}
                                    />
                                </Box>}


                            </Stack>
                            <Stack direction={'row'} sx={{
                                width: '100%', display: 'flex', justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(event) => {
                                                dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q6value'), 1)


                                                if (event.target.value === 'on' && afterInitialTreatmentStore.q6.gp.selected === 'on') {
                                                    afterInitialTreatmentStore.setQ6(
                                                        {
                                                            ...afterInitialTreatmentStore.q6,
                                                            gp: {selected: null, amount: '', unknown: null}
                                                        })

                                                } else {
                                                    afterInitialTreatmentStore.setQ6(
                                                        {
                                                            ...afterInitialTreatmentStore.q6,
                                                            gp: {
                                                                ...afterInitialTreatmentStore.q6.gp,
                                                                selected: event.target.value,
                                                            }
                                                        })
                                                }

                                            }}
                                            checked={(afterInitialTreatmentStore.q6.gp?.selected && afterInitialTreatmentStore.q6.gp.selected.startsWith('missing|')) ? false :afterInitialTreatmentStore.q6.gp.selected}

                                        />
                                    }
                                    sx={{
                                        '& .MuiFormControlLabel-label': {fontSize: 18},

                                    }}
                                    label={'GP'}
                                />
                                {afterInitialTreatmentStore.q6.gp.selected &&
                                <Box sx={{display: 'flex', alignItems: 'center', mt: 1}}>
                                    <Typography sx={{fontSize: 18, mr: 1}}>

                                        {dataStore.questionsMissing.includes('gp') ?
                                            <MissingReason
                                                value={afterInitialTreatmentStore.q6?.gp?.amount}
                                                style={{marginLeft:-80}}
                                                onSubmitMissing={()=>{
                                                    afterInitialTreatmentStore.setQ6(
                                                        {
                                                            ...afterInitialTreatmentStore.q6,
                                                            gp: {
                                                                ...afterInitialTreatmentStore.q6.gp,
                                                                amount: dataStore.returnOtherTextMissing
                                                            }
                                                        })
                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('gp'), 1)
                                                }}/>
                                            : <></>}
                                        Number
                                    </Typography>
                                    <TextField
                                        type={"number"}
                                        onWheel={(e) => e.target.blur()}
                                        size={'small'}
                                        sx={{width: 100, ml: 3, mr: 3}}
                                        placeholder={'00'}
                                        inputProps={{min: 0, style: {textAlign: 'center'}}}
                                        onChange={(event) => {
                                            dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('gp'), 1)

                                            afterInitialTreatmentStore.setQ6(
                                                {
                                                    ...afterInitialTreatmentStore.q6,
                                                    gp: {
                                                        ...afterInitialTreatmentStore.q6.gp,
                                                        amount: event.target.value, unknown: null
                                                    }
                                                })
                                        }}
                                        value={afterInitialTreatmentStore.q6.gp?.amount && afterInitialTreatmentStore.q6.gp.amount.startsWith('missing|') ? '': afterInitialTreatmentStore.q6.gp.amount}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(event) => {
                                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('gp'), 1)

                                                    afterInitialTreatmentStore.setQ6(
                                                        {
                                                            ...afterInitialTreatmentStore.q6,
                                                            gp: {
                                                                ...afterInitialTreatmentStore.q6.gp,
                                                                unknown: event.target.value, amount: ''
                                                            }
                                                        })


                                                }}
                                                checked={(afterInitialTreatmentStore.q6.gp?.unknown && afterInitialTreatmentStore.q6.gp.unknown.startsWith('missing|')) ? false : afterInitialTreatmentStore.q6.gp.unknown}

                                            />
                                        }
                                        sx={{
                                            '& .MuiFormControlLabel-label': {fontSize: 18},

                                        }}
                                        label={'Unknown'}
                                    />
                                </Box>}
                            </Stack>
                        </Box>
                    </QuestionBox> : <></>}
                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(DiseaseRelatedAfterInitialPage1);
