import {useStores,} from '../../store'
import * as React from 'react';
import {Divider, RadioGroup, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import QuestionText from "../../components/QuestionText";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import QuestionBox from "../../components/QuestionBox";
import RadioItem from "../../components/RadioItem";
import QuestionsLayout from "../../layout/QuestionsLayout";
import {useEffect} from "react";
import MissingReason from "../../components/MissingReason";
import Required from "../../components/Required";


const ClinicalCharacterizationPage2 = () => {
    const {
        clinicalCharacterizationStore, dataStore,menuStore
    } = useStores()
    const [showMissing, setShowMissing] = React.useState('not active');
    let urlParams = useParams();

    const navigate = useNavigate()

    useEffect(() => {
        clinicalCharacterizationStore.getClinicalCharacterization(parseInt(urlParams.patient))
    }, [])

    const handleNext = async () => {
        if (!clinicalCharacterizationStore.checkMissing2) {
            if(clinicalCharacterizationStore.q4.r1 !== null || clinicalCharacterizationStore.q4.r2 !== null || clinicalCharacterizationStore.q4.r3 !== null){
                if(clinicalCharacterizationStore.checkQ4Combinations()){
                    await clinicalCharacterizationStore.updateClinicalCharacterization(
                        {
                            q4: JSON.stringify(clinicalCharacterizationStore.q4),
                            q5: clinicalCharacterizationStore.q5,
                        },parseInt(urlParams.patient))
                    if(dataStore.forceUpdate == true){
                        dataStore.setShowUpdateModal('not active')
                    }
                    if(dataStore.showUpdateModal !== 'active'){
                        navigate('/tumorBiologyPage1/'+parseInt(urlParams.patient))
                    }

                }else{
                    alert('Combination Wrong')
                }
            }else{
                alert('Please answer all required questions.')

            }
        } else {
            alert('Please add reason for missing questions.')

            // if (!dataStore.globalMissing) {
            //     if (showMissing === 'active') {
            //         await clinicalCharacterizationStore.updateClinicalCharacterization(
            //             {
            //                 q4: clinicalCharacterizationStore.q4?.main ? JSON.stringify(clinicalCharacterizationStore.q4) : dataStore.returnOtherObjectMissing,
            //                 q5: clinicalCharacterizationStore.q5 ? clinicalCharacterizationStore.q5 : dataStore.returnOtherTextMissing,
            //
            //             },parseInt(urlParams.patient))
            //         navigate('/tumorBiologyPage1/'+parseInt(urlParams.patient))
            //         setShowMissing('not active')
            //     } else {
            //         setShowMissing('active')
            //     }
            // } else {
            //     await clinicalCharacterizationStore.updateClinicalCharacterization(
            //         {
            //             q4: clinicalCharacterizationStore.q4?.main ? JSON.stringify(clinicalCharacterizationStore.q4) : dataStore.returnOtherObjectMissing,
            //             q5: clinicalCharacterizationStore.q5 ? clinicalCharacterizationStore.q5 : dataStore.returnOtherTextMissing,
            //
            //         },parseInt(urlParams.patient))
            //     navigate('/tumorBiologyPage1/'+parseInt(urlParams.patient))
            // }
        }
    }

    return (
        <QuestionsLayout activeItem={menuStore.selectedItem !== null ? menuStore.selectedItem : 'addData'}
                         questionHeaderProps={{patientNumberEnd: true}}
                         showMissing={showMissing}
                         onCloseMissing={() => setShowMissing('not active')}
                         nextPress={handleNext}
                         backPress={() => navigate(-1)}
                         loading={clinicalCharacterizationStore.loading}>
            <Box sx={{width: '100%',px: 4, mb: 0,mt: 2, display: 'flex', flexDirection: 'column', flex: 1}}>
                <Stack direction={'column'} spacing={1} sx={{width: '100%',}}>

                    <Typography color={'primary'} sx={{fontWeight: 'bold', fontSize: 32,lineHeight:1.2}}>
                        Clinical Characterization
                    </Typography>
                </Stack>
                <Stack direction={'column'} spacing={2} sx={{width: '100%', mt: 3}}>

                    <QuestionBox>
                        <QuestionText>
                            Stage at NSCLC diagnosis <Required/>
                        </QuestionText>
                        <Stack direction={'column'} spacing={2} sx={{width: '100%', display: 'flex', py: 2}}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={(event) => clinicalCharacterizationStore.setQ4(
                                    {...clinicalCharacterizationStore.q4, r1: event.target.value,}
                                )}
                                value={clinicalCharacterizationStore.q4.r1}
                                sx={{display: 'flex', flexDirection: 'row', gap: "24px", rowGap: 0}}
                            >
                                <RadioItem label={'IA'}/>
                                <RadioItem label={'IB'}/>
                                <RadioItem label={'IIA'}/>
                                <RadioItem label={'IIB'}/>
                                <RadioItem label={'IIIA'}/>
                                <RadioItem label={'IIIB'}/>
                                <RadioItem label={'IIIC'}/>
                            </RadioGroup>
                            <Divider/>

                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={(event) => clinicalCharacterizationStore.setQ4(
                                    {...clinicalCharacterizationStore.q4, r2: event.target.value,}
                                )}
                                value={clinicalCharacterizationStore.q4.r2}
                                sx={{display: 'flex', flexDirection: 'row', gap: "24px", rowGap: 0}}
                            >
                                <RadioItem label={'T1'}/>
                                <RadioItem label={'T2'}/>
                                <RadioItem label={'T3'}/>
                                <RadioItem label={'T4'}/>
                                <RadioItem label={'Tx'}/>
                            </RadioGroup>
                            <Divider/>

                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={(event) => clinicalCharacterizationStore.setQ4(
                                    {...clinicalCharacterizationStore.q4, r3: event.target.value,}
                                )}
                                value={clinicalCharacterizationStore.q4.r3}
                                sx={{display: 'flex', flexDirection: 'row', gap: "24px", rowGap: 0}}
                            >
                                <RadioItem label={'N0'}/>
                                <RadioItem label={'N1'}/>
                                <RadioItem label={'N2'}/>
                                <RadioItem label={'N3'}/>
                                <RadioItem label={'Nx'}/>
                            </RadioGroup>
                            <Divider/>
                        </Stack>
                    </QuestionBox>


                    <QuestionBox allInRow={true}>
                        <QuestionText width={400}>
                            ECOG status at diagnosis
                            {dataStore.questionsMissing.includes('q5')
                                ? <MissingReason
                                    style={{right:80,marginTop:-6}}
                                    value={clinicalCharacterizationStore.q5}
                                    onSubmitMissing={async ()=>{
                                        clinicalCharacterizationStore.setQ5(
                                            dataStore.returnOtherTextMissing
                                        )
                                        dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q5'), 1)
                                    }}/> : <></>}
                        </QuestionText>
                        <Stack direction={'column'} spacing={2} sx={{width: '100%', display: 'flex', py: 2}}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={(event) => {
                                    dataStore.questionsMissing.splice(dataStore.questionsMissing.indexOf('q5'), 1)

                                    clinicalCharacterizationStore.setQ5(
                                    event.target.value
                                )}}
                                value={clinicalCharacterizationStore.q5}
                                sx={{display: 'flex', flexDirection: 'row', gap: "24px", rowGap: 0}}
                            >
                                <RadioItem label={'0'}/>
                                <RadioItem label={'1'}/>
                                <RadioItem label={'2'}/>
                                <RadioItem label={'3'}/>
                                <RadioItem label={'4'}/>
                            </RadioGroup>
                        </Stack>
                    </QuestionBox>

                </Stack>
            </Box>
        </QuestionsLayout>
    );
}

export default observer(ClinicalCharacterizationPage2);
