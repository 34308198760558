import * as React from 'react';
import Button from '@mui/material/Button';
import theme from '../styles/theme'
import {CircularProgress} from "@mui/material";


const MyButton = ({label, sx, icon, iconStyle, loading, ...other}) => {

    return (
        <Button
            variant="contained"
            sx={{
                px: 4,
                color: "white",
                fontWeight: 'bold',
                borderRadius: 12,
                py: 1,
                fontSize: 18,
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: `2px 2px 10px ${theme.palette.primary.main}`,
                textTransform: 'none',
                "&.Mui-disabled": {
                    color: '#ffffff'
                },
                ...sx
            }}
            {...other}
        >
            {icon && <img style={{marginRight: 7, ...iconStyle}} src={icon} color={'white'}/>}
            {loading ?
                <CircularProgress/>
                : label}
        </Button>
    );
}

export default MyButton;
